import { useState, useEffect } from "react";
import { simpleGet } from "../functions";

const useFetchData = (url) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        simpleGet(url)
            .then((res) => {
                setData(res)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("ERR: ", err)
                setError({message: "Error en la carga de datos"});
                setIsLoading(false);
            })
    }, [url])
    return [data, isLoading, error]
}

export default useFetchData;
