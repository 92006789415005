import React from "react";
import useModal from "../../hooks/useModal";

const ProcedimientoObservacion = () => {
    const { modalData: { procedimiento } } = useModal();
    return (
        <span style={{whiteSpace: "break-spaces", fontSize: "15px"}}>
            {procedimiento.observaciones}
        </span>
    )
}

export default ProcedimientoObservacion;
