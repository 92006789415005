import React from "react";
import { ContactContextProvider } from "./ContactContext";
import { MessageContextProvider } from "./MessageContext";
import { SearchContextProvider } from "./SearchContex";
import { WebsocketConnectionProvider } from "./WebsocketConnection";

function WabachatProvider({children}){
    return(
      <WebsocketConnectionProvider>
        <SearchContextProvider>
          <MessageContextProvider>
            <ContactContextProvider>
            {children}
            </ContactContextProvider>
          </MessageContextProvider>
        </SearchContextProvider>
      </WebsocketConnectionProvider>
    )
}

export default WabachatProvider
