import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'

import './AdminSucursal.css'
import AdminSucursalContent from './AdminSucursalContent'
import Sidebar from './SideBar'
import { simpleGet } from '../../../functions'

const AdminSucursal = () => {

  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState(null);

  const [selectedSucursal, setSelectedSucursal] = useState(null);

  /* States: Create, view, edit */
  const [stateEmpresaForm, setStateEmpresaForm] = useState('view')

  const getEmpresas = () => {
    simpleGet('maestro/empresa?sucursales=true')
      .then(data => {
        /* Filtrar data por orden alfabético de acuerdo a la razón social */
        data.sort((a, b) => a.razon_social.localeCompare(b.razon_social))

        setEmpresas(data);
        setSelectedEmpresa(data[0]);
        setSelectedSucursal(data[0].sucursales[0] || null)
      })
  }

  useEffect(() => {
    getEmpresas();
  },[])

  const handleEmpresa = (empresa) => {
    setSelectedEmpresa(empresa)
  }

  const handleSucursal = (sucursal) => {
    setSelectedSucursal(sucursal)
  }

  const handleStateCreate = () => {
    if (stateEmpresaForm == 'create') {
      setStateEmpresaForm('view')
    } else {
      setStateEmpresaForm('create')
    }
  }

  return (
    <Container fluid style={{
      width: '100%',
      height: '100%'
    }}>
      <Row style={{
        height: '100%'
      }}>
        <Col className='container-menu' xs="6" sm="4" md="2">
          <Sidebar empresas={empresas} handleStateCreate={handleStateCreate} stateForm={stateEmpresaForm} handleEmpresa={handleEmpresa} handleSucursal={handleSucursal} />
        </Col>
        <Col className='container-body'>
          <AdminSucursalContent getEmpresas={getEmpresas} selectedEmpresa={selectedEmpresa} setSelectedEmpresa={setSelectedEmpresa} stateForm={stateEmpresaForm} setStateForm={setStateEmpresaForm} selectedSucursal={selectedSucursal} handleSucursal={handleSucursal} />
        </Col>
      </Row>
    </Container>
  )
}

export default AdminSucursal;
