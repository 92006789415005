import { handleErrorResponse, simplePostData } from "../../../functions";

const createNota = (nota) => {
  simplePostData("atencion/nota/", nota)
    .then(() =>
      handleErrorResponse(
        "custom",
        "Exito",
        "Nota creada exitosamente",
        "success"
      )
    )
    .catch(() =>
      handleErrorResponse(
        "custom",
        "Error",
        "Ha ocurrido un error al crear la nota",
        "danger"
      )
    );
};

export default createNota;
