import React, { useState, useEffect, useRef, useContext } from 'react'
import { useParams } from "react-router-dom"
import {
  simpleGet,
  simplePostData,
} from '../../functions'
import {
  PageTitle,
  RegularModalCentered,
  ModalCancel
} from '../bits'
import { NavigationContext } from '../Navigation'
import Loader from '../loader/Loader'
import Datatable from '../datatable/Datatable'
import { CardCobranzaActions } from './CardCobranzaActions'
import { CardCobranzacharacteristics } from './CardCobranzacharacteristics'

// Constant
const __debug__ = process.env.REACT_APP_DEBUG == "true"


const HistorialPagos = () => {
  const { current_sucursal } = useContext(NavigationContext)
  let __params__ = useParams()
  const html_proc_annul = "html_proc_annul"

  const [patientxpagos, setPxP] = useState(false)
  const [selected_pago, selectPago] = useState(false)
  let annul_proc = useRef(-1)

  const getPxP = () => {
    if (__debug__) console.log("HistorialPagos getPxP")

    simpleGet(`finanzas/sucursal/${current_sucursal}/paciente/${__params__.patient}/`)
      .then(setPxP)
  }
  const askAnnulPayment = _pk => {
    annul_proc.current = _pk
    window.$('#' + html_proc_annul).modal('show')
  }
  const annulPayment = () => {
    let _pk = annul_proc.current
    if (_pk == -1) return
    simplePostData(`finanzas/pago/revert/`, { pagomaestro: _pk })
      .then(() => {
        annul_proc.current = -1
        setPxP(patientxpagos.filter(pxp => pxp.pk != _pk))
      })
  }

  useEffect(() => {
    if (__debug__) console.log("HistorialPagos useEffect getPxP")
    getPxP()
  }, [])

  const columnas = [
    {
      id: "realizado",
      columnHeaderLabel: "Realizado",
      cellRender: item => new Date(item.created),
      columnType: "datetime",
      isSortable: true,
    },
    {
      id: "monto",
      columnHeaderLabel: "Monto",
      cellRender: item => item.monto.toString(),
      isSortable: true,
    },
    {
      id: "mediopago",
      columnHeaderLabel: "Medio de Pago",
      cellRender: item => item.mediopago_descripcion,
      isSortable: true,
    },
    {
      id: "origen",
      columnHeaderLabel: "Origen",
      cellRender: item => (
        <span className={`badge badge-${item.plantrabajo ? "info" : "success"} badge-pill`} style={{ cursor: "pointer" }}>
          {item.plantrabajo ? "Plan de trabajo" : "Atencion"}
        </span>
      ),
      isSortable: false,
    },
    {
      id: "detalle",
      columnHeaderLabel: "Detalle",
      cellRender: item => (
        <button className="btn-primary btn-pills waves-effect"
          data-toggle="modal" data-target="#pago-detalle"
          onClick={() => selectPago(item)}>Detalle</button>
      ),
      isSortable: false,
    },
    {
      id: "comprobante",
      columnHeaderLabel: "Comprobante",
      cellRender: item => {
        let pdf_url = `fe/comprobante/nofe/${item.pk}/`;
        if (item.comprobante) {
          pdf_url = `fe/comprobante/view/${item.comprobante}/`;
        }
        return (
          <a className="btn btn-primary btn-sm btn-icon waves-effect waves-themed"
            target='_blank' href={process.env.REACT_APP_PROJECT_API + pdf_url}>
            <i className="fal fa-print"></i>
          </a>
        )
      },
      isSortable: false,
    },
    {
      id: "anular",
      columnHeaderLabel: "Anular",
      cellRender: item => (
        <button className="btn-secondary btn-pills" onClick={() => askAnnulPayment(item.pk)}>
          <i className="fal fa-trash-alt"></i>
        </button>
      ),
      isSortable: false,
    }
  ]

  return (
    <>
      <PageTitle title={"Pagos del paciente"} />
      {!patientxpagos
        ? <Loader scale={2} />
        : (
          <div className="row">
            <div className="col-lg-9">
              <Datatable columns={columnas} data={patientxpagos} />

              <RegularModalCentered
                _id={"pago-detalle"}
                _title={"Detalle de pago"}
                _body={
                  <ModalPagoDetalle pago={selected_pago} />
                } />
              <ModalCancel
                _id={html_proc_annul}
                _title={"Eliminar Pago"}
                _action_text={"Eliminar"}
                _action_func={annulPayment}
                _body_text={"Esta seguro que quiere eliminar este Pago? En caso de haber emitido un comprobante de pago este se anulará"} />
            </div>
            <div className="col-lg-3">
              {/* Actions */}
              <div className='panel'>
                <CardCobranzaActions patientPk={__params__.patient} />
              </div>
              {/* Characteristics */}
              <CardCobranzacharacteristics patientPk={__params__.patient} />
            </div>
          </div>
        )
      }
    </>
  )
}
const ModalPagoDetalle = ({ pago }) => {
  return !pago
    ? 'loading'
    : pago.detalle
}


export default HistorialPagos
