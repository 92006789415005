import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../loader/Loader";
import { PageTitle } from "../bits";
import { VL_NOTA_ESTADO_PENDIENTE } from "./params";
import NotaList from "./NotaList";
import NotaForm from "./NotaForm";
import NotaActions from "./NotaActions";
import { NotaContext } from "./context/NotaContext";

const Nota = () => {
  const __params__ = useParams();
  const {
    current_sucursal,
    setNotas,
    nota,
    setNota,
    tipoNotas,
    listNotas,
    setTipoNotas,
    handleToggleNotaForm,
    listTipoNotas,
  } = useContext(NotaContext);

  ////---- CRUD functions -----------------------------------------------------
  // - Notas
  const filterNotas = () => {
    listNotas(
      `sucursal_id=${current_sucursal}&estado=${VL_NOTA_ESTADO_PENDIENTE}&cita_id=${__params__.cita_pk}`,
      setNotas
    );
  };

  ////---- Hooks --------------------------------------------------------------
  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      listTipoNotas(setTipoNotas);
    }

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_sucursal]);

  useEffect(() => {
    setNota({ ...nota, cita: __params__.cita_pk });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !tipoNotas ? (
    <Loader scale={2} />
  ) : (
    <>
      <PageTitle title={"Notas"} />

      <div className="row">
        <div className="col-lg-9">
          <div style={{ marginBottom: "25px" }}>
            <NotaList filterNotas={filterNotas} />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="panel">
            {<NotaActions handleAddClick={handleToggleNotaForm} />}
          </div>
        </div>
      </div>

      <NotaForm />
    </>
  );
};

export default Nota;
