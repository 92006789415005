import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const ModalComponent = ({title, body, onSubmit, isOpen, toggle, isValidation=false, submitRef, onClosed}) => {

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isValidation){
      setIsLoading(true)
      const timer = setTimeout(() => {
        setIsLoading(false)
      }, 5000)
      return () => clearTimeout(timer)
      }
  },[isOpen])

  return (
    <Modal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        {body}
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => {
          if(onSubmit) onSubmit()
          toggle()
        }} disabled={isLoading} innerRef={submitRef}>
          {isLoading ? '¿Está seguro?' : 'Confirmar'}
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalComponent
