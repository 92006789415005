import React, { createContext, useContext, useState } from "react";
import { handleErrorResponse, simpleGet } from "../../../functions";

export const StockContext = createContext();

export const useStock = () => {
  const context = useContext(StockContext);
  if (!context) {
      throw new Error("useStock must be used within a StockContext");
  }
  return context;
};

export const StockContextProvider = ({ children }) => {
  const [stocks, setStocks] = useState(false)

  const getListStocks = () => {
    simpleGet("inventario/almacen/stock/")
    .then(setStocks)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  return (
    <StockContext.Provider
        value={{
            stocks,
            getListStocks, 
        }}
    >
        {children}
    </StockContext.Provider>
  );
};
