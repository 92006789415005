export const INITIAL_NOTAS = null;
export const INITIAL_NOTA = {
  id: null,
  tiponota: null,
  sucursal: null,
  paciente: null,
  cita: null,
  intervalo_unidad: null,
  texto: null,
  fechahora_programada: null,
  intervalo_cantidad: null,  
}

export const VL_NOTA_ESTADO_PENDIENTE = "1"
export const VL_NOTA_ESTADO_COMPLETADO = "2"
export const VL_NOTA_ESTADO_CHOICES = [
  {
    key: VL_NOTA_ESTADO_PENDIENTE,
    value: "PENDIENTE"
  },
  {
    key: VL_NOTA_ESTADO_COMPLETADO,
    value: "COMPLETADO"
  },
]

export const VL_NOTA_IU_DIA = "1"
export const VL_NOTA_IU_SEMANA = "2"
export const VL_NOTA_IU_MES = "3"
export const VL_NOTA_IU_CHOICES = [
  {
    key: "",
    value: "Elija una unidad..."
  },
  {
    key: VL_NOTA_IU_DIA,
    value: "DIAS"
  },
  {
    key: VL_NOTA_IU_SEMANA,
    value: "SEMANAS"
  },
  {
    key: VL_NOTA_IU_MES,
    value: "MESES"
  },
]
