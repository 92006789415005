import { handleErrorResponse, simpleDelete } from "../../../functions";

const deleteNota = (id, notas, setup) => {
  simpleDelete(`atencion/nota/${id}/`)
    .then(() => {
      let newNotas = notas.filter((item) => item.id !== id);

      handleErrorResponse(
        "custom",
        "Exito",
        "Nota eliminada exitosamente",
        "success"
      );
      setup(newNotas);
    })
    .catch(() =>
      handleErrorResponse(
        "custom",
        "Error",
        "Ha ocurrido un error al eliminar la nota",
        "danger"
      )
    );
};

export default deleteNota;
