import React from "react"
import { PageTitle } from "../../bits";
import StockList from "./StockList";

function Stock() {
  return (
    <>
      <PageTitle title={"Stock"} />
      <div className="row">
        <div className="col-lg-12">
          <StockList />
        </div>
      </div>
    </>
  )
}

export default Stock
