import React from 'react'

function ChatDefaultContent() {
  return (
    <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center p-3'>
      <div className='flex-row mb-2'>
          <img src="/img/logo_muelitas_image.png" alt="icon" width={"60px"} height={"60px"} className='mr-3'/>
          <img src="/img/logo_whatsapp.png" alt="icon" width={"60px"} height={"60px"}/>
      </div>
      <h3 style={{fontWeight: "bold"}}>Muelitas | WhatsApp</h3>
    </div>
  )
}

export default ChatDefaultContent
