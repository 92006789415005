import React, { createContext, useState } from "react";

const ModalContext = createContext({});

export const ModalContextProvider = ({ children }) => {
    const [modal, setModal] = useState(false)
    const [modalData, setModalData] = useState(null)

    const toggle = () => setModal(!modal);

    return <ModalContext.Provider
        value={{ modal, toggle, modalData, setModalData }}
    >
        {children}
    </ModalContext.Provider>
}

export default ModalContext;

