import React, { forwardRef } from 'react'
import { VL_NOTA_IU_CHOICES } from './params'

const NotaIntervaloUnidadSelect = forwardRef(({nota, handleChange}, ref) => {
  const options = VL_NOTA_IU_CHOICES.map((item) => {
    return (
      <option key={item.key} value={item.key}>
        {item.value}
      </option>
    )
  })

  return (
    <select
      className="form-control"
      id="id-intervalo_unidad"
      ref={ref}
      value={nota.intervalo_unidad || ""}
      onChange={({target}) => handleChange(target.value)}
    >
      {options}
    </select>
  )
})

export default NotaIntervaloUnidadSelect
