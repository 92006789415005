import React, { useState, useEffect, useRef, useContext } from 'react'
import {
  Switch,
  Route,
  Redirect,
  useParams,
} from "react-router-dom"
import {
  simpleGet,
  simplePostData,
  addRequestValidation,
  handleErrorResponse,
  capitalizeFirstLetter as cFL,
  getDataByPK,
  simpleDelete,
} from '../../functions'
import { ListSavedMedicine } from '../prescripcion/Prescripcion'
import {
  Icon,
  PageTitle,
  RegularModalCentered
} from '../bits'
import MassiveNotification from './MassiveNotification'
import NewEditPatient from './NewEditPatient'
import CuestionarioPaciente from './CuestionarioPaciente'
import ArchivosPaciente from './GDriveFiles'
import Loader from '../loader/Loader'
import { NavigationContext } from '../Navigation'
import Datatable from '../datatable/Datatable'
import { Button, Table, Input, PaginationItem, PaginationLink, Pagination} from 'reactstrap'
import SimpleSpinner from '../loader/SimpleSpinner'

// Constant
const __debug__ = process.env.REACT_APP_DEBUG == "true"
const html_format_id = 'html_format_id'
const html_instant_notification_id = 'html_instant_notification_id'
const html_atender_modal_id = 'html_atender_modal_id'
const html_cuestionario_modal_id = 'html_cuestionario_modal_id'
export const tipo_documento = {
  1: "DNI",
  2: "CARNET DE EXTRANJERIA",
  3: "PASAPORTE",
  0: "OTRO",
}


const Admision = () => (
  <div>
    <PageTitle />

    <Switch>
      <Route exact path="/nav/admision">
        <AdmisionHome />
      </Route>
      <Route exact path="/nav/admision/editar/:patient">
        <NewEditPatient />
      </Route>
      {/* <Route exact path="/nav/admision/mensaje">
        <MassiveNotification />
      </Route> */}
      <Route exact path="/nav/admision/:patient/detalle">
        <AdmisionDetail />
      </Route>
      <Route exact path="/nav/admision/:patient/archivos">
        <ArchivosPaciente />
      </Route>
      <Route exact path="/nav/admision/:patient/cuestionario/:cuestionario_pk">
        <CuestionarioPaciente />
      </Route>

      <Route>
        <Redirect to="/nav/admision" />
      </Route>
    </Switch>
  </div>
)

// General
const AdmisionHome = () => {
  const {current_sucursal, redirectTo} = useContext(NavigationContext)

  return (
    <div className="row">
      <div className="col-lg-9">
        <div style={{marginBottom: "25px"}}>
          {/* <SearchPatient current_sucursal={current_sucursal} redirectTo={redirectTo} /> */}
          <PatientList />
        </div>
      </div>
      <div className="col-lg-3">
        <div className="panel">
          <LinksHome redirectTo={redirectTo} />
        </div>
      </div>
    </div>
  )
}

const PatientList = () => {
  // Component for search patients this on demand
  const {current_sucursal, user, redirectTo} = useContext(NavigationContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchAPI, setSearchAPI] = useState(buildUrlLastAttendedPatients());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const pageSize = 10;

  useEffect(() => {
    fetchData();
  }, [searchAPI, currentPage]);

  const startRange = (currentPage - 1) * pageSize + 1;
  const endRange = Math.min(currentPage * pageSize, count);

  const fetchData = async () => {
    try {
      if (searchAPI.includes('search')) {
        const response = await simpleGet(searchAPI + `&page=${currentPage}&page_size=${pageSize}`);
        setData(response.results);
        setTotalPages(Math.ceil(response.count / pageSize));
        setCount(response.count)
      } else {
        const response = await simpleGet(searchAPI);
        setData(response);
      }
      setLoading(false);
    } catch(e) {
      console.log("ERROR: ", e)
    }
  }

  const deactivatePaciente = (id) => {
    simpleDelete(`atencion/paciente/${id}/`)
    .then(() => {
      let newData = data.filter((item) => item.paciente !== id)
      
      handleErrorResponse(
        "custom", "Exito", "Paciente desactivado exitosamente", "success"
      )
      setData(newData)
    })
    .catch(() => handleErrorResponse(
      "custom", "Error", "Ha ocurrido un error al desactivar al paciente", "danger"
    ))
  }

  function buildUrlLastAttendedPatients () {
    const ndays = 7
    let _day = new Date()
    _day.setDate(_day.getDate()-ndays)
    _day = _day.toDateInputValue()
    return `atencion/cita/?filtro={"sucursal":"${current_sucursal}", "estado":"5", "fecha_desde":"${_day}", "sort":"true"}`
  }

  const handleSearch = async(event) => {
    const value = event.target.value;
    setSearch(value)
    if (value.trim() === "") {
      setSearchAPI(buildUrlLastAttendedPatients())
    }
  }

  const startSearchApi = () => {
    setSearchAPI(`atencion/paciente/search/?search=${search}`);
    setCurrentPage(1);
  }

  const handleEnterKeyPress = (e) => {
    if (search == "") return;
    if (e.key == "Enter") {
      startSearchApi()
    }
  }

  const handleButtonSearch = () => {
    if (search == "") return;
    startSearchApi()
  }

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const deactivateButton = (item) => {
    return (
      <button
        className="btn btn-sm btn-danger btn-pills waves-effect"
        onClick={() => deactivatePaciente(item.paciente)}
      >
        <i className="fas fa-trash-alt"></i>
      </button>
    )
  }

  const TBody = () => {
    return <tbody>
    {data.map((item) => (
      <tr key={item.paciente + "_" + item.sucursal} className='patients-list-row'>
        <td>{`${cFL(item.paciente_data?.nombre_principal ?? item.nombre_principal)} ${cFL(item.paciente_data?.nombre_secundario ?? item.nombre_secundario ?? "")}`}</td>
        <td>{cFL(item.paciente_data?.ape_paterno ?? item.ape_paterno) + ' ' + cFL(item.paciente_data?.ape_materno ?? item.ape_materno)}</td>
        <td>{item.paciente_data?.dni ?? item.dni}</td>
        <td>
          <Button
            className='btn btn-primary btn-pills waves-effect mr-2'
            color="primary"
            size="sm"
            onClick={() => redirectTo(`/nav/admision/${item.paciente_data?.pk ?? item.pk}/detalle`)}>
              Ver
          </Button>
          {user.personal.is_admin && deactivateButton(item)}
        </td>
      </tr>
    ))}
    </tbody> 
  }

  const renderTableBody = () => {
    if (searchAPI.includes('search') && data.length == 0) {
      return <tbody>
        <tr>
          <td colSpan="4" className='text-center'>No se encontraron resultados</td>
        </tr>
      </tbody>
    } else if (data.length == 0) {
      return <tbody>
        <tr>
          <td colSpan="4" className='text-center'>No hay pacientes atendidos últimamente</td>
        </tr>
      </tbody>
    }
    return <TBody />
  }

  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationNumbers.push(
        <PaginationItem key={i} disabled={currentPage === i}>
              <PaginationLink onClick={() => goToPage(i)}>
                {i}
              </PaginationLink>
        </PaginationItem>
      );
    }
    return paginationNumbers;
  };

  return (
    <div>
      <div style={{display: 'flex', gap: '10px'}}>
        <Input type="text" 
          placeholder="Buscar..." 
          onChange={handleSearch}
          onKeyDown={handleEnterKeyPress} 
          style={{width: "50%"}}/>
        <Button 
          className="search-icon btn btnprimary btn-pills waves-effect"
          onClick={handleButtonSearch}
          color='primary'>
          <i className="fas fa-search"></i>
        </Button>
      </div>
      {loading ? (
        <SimpleSpinner />
      ) : (
        <>
          {searchAPI.includes("filtro") && <p className='ml-2 mb-0 mt-3'>Pacientes atendidos en la última semana</p>}
          <Table borderless responsive size="sm" className='mt-3'>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>DNI</th>
                <th>Acciones</th>
              </tr>
            </thead>
            {renderTableBody()}
          </Table>
          {searchAPI.includes('search') && (
          <div>
            <Pagination aria-label="Page navigation example" size='sm'>
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink onClick={goToPreviousPage}>
                  Anterior
                </PaginationLink>
              </PaginationItem>
              {generatePaginationNumbers()}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink onClick={goToNextPage}>
                  Siguiente
                </PaginationLink>
              </PaginationItem>
            </Pagination>
            <div>
              Mostrando {startRange} al {endRange} de {count}
            </div>
          </div>
          )}
        </>
      )}
      <style>
        {`
          .patients-list-row td{
            vertical-align: middle;
          }
          .patients-list-row {
            transition: all 0.2.2s ease-in-out;
        }
          .patients-list-row:hover {
            background-color: #dfe3e6;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
            z-index: 1;
        }`}
      </style>
    </div>
  );
}

const LinksHome = ({redirectTo}) => (
  <div className="card col-12" style={{padding: "0px"}}>
    <div className="card-header">
      <div className="card-title">
        Acciones
      </div>
    </div>
    <div className="card-body">
      <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
        <Icon type="new-patient" onClick={() => redirectTo("/nav/admision/editar/0")} />
        <span style={{fontSize: "0.9rem"}}>Nuevo</span>
      </div>
      {/* <div className="col-4" style={{display: "inline-block", textAlign: "center"}}>
        <Icon type="letter" onClick={() => redirectTo("/nav/admision/mensaje")} />
        <span style={{fontSize: "0.9rem"}}>Mensajes masivos</span>
      </div> */}
    </div>
  </div>
)

// By patient
const AdmisionDetail = () => {
  const {current_sucursal, redirectTo} = useContext(NavigationContext)
  const _params_ = useParams()
  const [patient, setPatient] = useState(false)

  const getPatientByID = _id => getDataByPK('atencion/paciente/admision/pk', _id).then(setPatient)

  useEffect(() => {
    getPatientByID(_params_.patient)
  }, [])
  useEffect(() => () => {
    window.$('#'+html_format_id).modal('hide')
    window.$('#'+html_instant_notification_id).modal('hide')
    window.$('#'+html_atender_modal_id).modal('hide')
    window.$('#'+html_cuestionario_modal_id).modal('hide')
  }, [])

  return !patient
    ? "loading"
    : (
      <div className="row">
        <div className="col-lg-6">
          <div className="panel">
            <div className="card col-12" style={{padding: "0px"}}>
              <div className="card-header">
                <div className="card-title">
                  Paciente
                </div>
              </div>
              <PatientDataList patient={patient} />
              <PatientExtraDataList patient={patient} />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <LinksDetail
            patient={patient}
            redirectTo={redirectTo} />
          <div className="panel">
            <PatientPrescription
              patient={patient}
              redirectTo={redirectTo} />
          </div>
        </div>
        <ModalFormatos patient={patient} current_sucursal={current_sucursal} />
        <InstantNotification patient_pk={patient.pk} current_sucursal={current_sucursal} />
        <AtenderPaciente patient_pk={patient.pk} current_sucursal={current_sucursal} redirectTo={redirectTo} />
        <ListCuestionario patient_pk={patient.pk} redirectTo={redirectTo} />
      </div>
    )
}
export const PatientDataList = ({patient}) => (
  <div className="card-body">
    <h5>Nombres: <span style={{color:"black"}}>{cFL(patient.nombre_principal)+
      (patient.nombre_secundario?" "+
      cFL(patient.nombre_secundario):"")}</span>
    </h5>
    <h5>Apellidos: <span style={{color:"black"}}>{cFL(patient.ape_paterno)+" "+
      cFL(patient.ape_materno)}</span>
    </h5>

    <h5>{tipo_documento[patient.tipo_documento]}: <span style={{color:"black"}}>{patient.dni?patient.dni:patient.dni_otro}</span></h5>

    <h5>Genero: <span style={{color:"black"}}>{patient.sexo=="1"?"Masculino":"Femenino"}</span></h5>
    {!patient.fecha_nacimiento ? ""
      : <h5>Fecha de nacimiento: <span style={{color:"black"}}>{patient.fecha_nacimiento}</span></h5>
    }
    {!patient.celular ? ""
      : <h5>Número de contacto: <span style={{color:"black"}}>{patient.celular}</span></h5>
    }
    {!patient.direccion ? ""
      : <h5>Dirección: <span style={{color:"black"}}>{patient.direccion}</span></h5>
    }
    {!patient.procedencia ? ""
      : <h5>Procedencia: <span style={{color:"black"}}>{patient.procedencia}</span></h5>
    }
    {!patient.residencia ? ""
      : <h5>Residencia: <span style={{color:"black"}}>{patient.residencia}</span></h5>
    }
  </div>
)
const PatientExtraDataList = ({patient}) => {
  let ant = patient.antecedentes
  let extra = patient.camposextra.length == 0 ? false : patient.camposextra.filter(e => e.mostrar_en_admision)
  const printBlack = val => <span style={{color:"black"}}>{val}</span>
  const printExBlack = ex => <h5 key={"extra-field-"+ex.pk}>{ex.texto}: {printBlack(ex.respuesta || ex.respuesta_descripcion)}</h5>

  return (
    <div className="card-body">
      <hr style={{borderBottomWidth: "2px", borderBottomStyle: "solid", marginTop: "-30px"}}/> {/* Separator */}
      <h5>Alergias: {printBlack(ant.alergias)}</h5>
      <h5>Medicamentos: {printBlack(ant.medicamentos)}</h5>
      <h5>Operaciones: {printBlack(ant.operaciones)}</h5>

      {ant.diabetes && <h5>Diabetes: SI</h5>}
      {ant.enf_cardiovascular && <h5>Enfermedad Cardiovascular: SI</h5>}
      {ant.hemorragia && <h5>Hemorragia: SI</h5>}
      {ant.hepatitis && <h5>Hepatitis: SI</h5>}

      {extra && extra.map(printExBlack)}
    </div>
  )
}
const PatientPrescription = ({patient}) => {
  const [prescription_list, setPrescriptionList] = useState(false)

  const removeMedicineFromList = _medc_pk => {
    // Remove medicine by index in array
    let _tmp = prescription_list.filter(i => i.pk!=_medc_pk)

    setPrescriptionList(_tmp)
  }
  const getPrescriptionMedicine = _patient_pk => simpleGet(`atencion/prescripcion/?filtro={"paciente":"${_patient_pk}"}`).then(setPrescriptionList)

  useEffect(() => {
    getPrescriptionMedicine(patient.pk)
  }, [])

  return !prescription_list
    ? "loading"
    : (
      <div className="card col-12" style={{padding: "0px"}}>
        <div className="card-header">
          <div className="card-title">
            Prescripciones actuales del paciente
          </div>
        </div>
        <ListSavedMedicine
            removeMedicineFromList={removeMedicineFromList}
            medicine_list={prescription_list} />
      </div>
    )
}
const LinksDetail = ({patient, redirectTo}) => {
  // WARNING: This component strongly depends on it's props
  return (
    <>
      <div className="panel">
        <div className="card col-12" style={{padding: "0px"}}>
          <div className="card-header">
            <div className="card-title">
              Acciones
            </div>
          </div>
          <div className="card-body">
            {/* editar */}
            <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
              <Icon type="edit-patient"
                onClick={() => redirectTo(`/nav/admision/editar/${patient.pk}`)} />
              <span style={{fontSize: "0.9rem"}}>Editar</span>
            </div>
            {/* cita */}
            <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
              <Icon type="add" onClick={() => redirectTo('/nav/cita/', {patient: patient})} />
              <span style={{fontSize: "0.9rem"}}>Crear Cita</span>
            </div>
            {/* pagos */}
            <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
              <Icon type="finance" onClick={() => redirectTo(`/nav/cobranza/${patient.pk}/pagos`)} />
              <span style={{fontSize: "0.9rem"}}>Pagos</span>
            </div>
            {/* atender */}
            <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
              <Icon type="add" onClick={() => window.$('#'+html_atender_modal_id).modal('show')} />
              <span style={{fontSize: "0.9rem"}}>Atender sin Cita</span>
            </div>
            {/* cobrar */}
            {/* <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
              <Icon type="finance"
                onClick={() => redirectTo(`/nav/cobranza/${patient.pk}/detalle`)} />
              <span style={{fontSize: "0.9rem"}}>Cobrar</span>
            </div> */}
            {/* Separador*/} <div style={{width:"100%", height:"20px"}}></div>
            {/* historia */}
            <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
              <Icon type="clinic-history"
                onClick={() => redirectTo(`/nav/historiaclinica/${patient.pk}/`)} />
              <span style={{fontSize: "0.9rem"}}>Historia</span>
            </div>
            {/* pdt */}
            <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
              <Icon type="plandetrabajo"
                onClick={() => redirectTo(`/nav/plandetrabajo/paciente/${patient.pk}/`)} />
              <span style={{fontSize: "0.9rem"}}>Plan de Trabajo</span>
            </div>
            {/* Notificacion instantanea */}
            {/* <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
              <Icon type="letter" onClick={() => window.$('#'+html_instant_notification_id).modal('show')} />
              <span style={{fontSize: "0.9rem"}}>Mensaje</span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="panel">
        <div className="card col-12" style={{padding: "0px"}}>
          <div className="card-header">
            <div className="card-title">
              Acciones extras
            </div>
          </div>
          <div className="card-body">
            {/* odontograma */}
            <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
              <Icon type="odontogram" onClick={() => redirectTo(`/nav/odontograma/${patient.pk}/evolucion/`)} />
              <span style={{fontSize: "0.9rem"}}>Odont.</span>
            </div>
            {/* archivos */}
            <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
              <Icon type="files" onClick={() => redirectTo(`/nav/admision/${patient.pk}/archivos`)} />
              <span style={{fontSize: "0.9rem"}}>Archivos</span>
            </div>
            {/* Formatos */}
            <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
              <Icon type="pdf" onClick={() => window.$('#'+html_format_id).modal('show')} />
              <span style={{fontSize: "0.9rem"}}>Formatos</span>
            </div>
            {/* Cuestionarios */}
            <div className="col-3" style={{display: "inline-block", textAlign: "center"}}>
              <Icon type="list" onClick={() => window.$('#'+html_cuestionario_modal_id).modal('show')} />
              <span style={{fontSize: "0.9rem"}}>Cuestionario</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
// Formatos
const ModalFormatos = ({patient, current_sucursal}) => {
  const [format, selectFormat] = useState(false)
  const [over, setOver] = useState(false)
  const html_address_id = 'html_format_address_id'

  const byPassFunction = _f => {
    if(_f.bypass){
      selectFormat(_f)
      setOver(false)
    }else redirectToFormat(_f.link)
  }
  const redirectToFormat = endpoint => window.open(process.env.REACT_APP_PROJECT_API+endpoint, '_blank')
  // Aditional functions
  const openDireccionModal = () => window.$('#'+html_address_id).modal("show")
  const closeByPass = () => {
    setOver(true)
    window.$('#'+html_address_id).modal("hide")
  }
  const saveCustomAddress = () => {
    let data = {
      direccion_sec: window.document.getElementById("address-direccion_sec").value,
      direccion_ref: window.document.getElementById("address-direccion_ref").value
    }
    simplePostData(`atencion/paciente/${patient.pk}/`, data, "PATCH")
    .then(closeByPass)
  }
  // Formats
  let formats = [
    {
      text: "Cuidados de la ortodoncia",
      link: `atencion/viewdoc/1/${current_sucursal}/${patient.pk}/`,
      sucursal: [2, 3, 4, 5],
      bypass: false,
    }, {
      text: "Certificado de atencion",
      link: `atencion/viewdoc/101/${current_sucursal}/${patient.pk}/`,
      sucursal: [2, 3],
      bypass: false,
    }, {
      text: "Formato de Historia",
      link: `atencion/viewdoc/historia/${current_sucursal}/${patient.pk}/`,
      sucursal: [2, 4],
      bypass: false,
    }, {
      text: "Dirección de Paciente",
      link: `atencion/viewdoc/103/${current_sucursal}/${patient.pk}/`,
      sucursal: [2, 3, 4],
      bypass: openDireccionModal,
    },
  ]

  useEffect(() => () => {
    // Assure modals will be closed before leaving current page
    window.$('#'+html_format_id).modal("hide")
  }, [])
  useEffect(() => {
    if(!over && format){
      window.$('#'+html_format_id).modal("hide")
      format.bypass()
    }
    if(over && format){
      selectFormat(false)
      redirectToFormat(format.link)
    }
  }, [over, format])

  return (
    <React.Fragment>
      <RegularModalCentered
        _id={html_format_id}
        _title={"Generar formato"}
        _body={
          <div>
            {formats.map((f, inx) => (f.sucursal.indexOf(current_sucursal) != -1) && (
              <button className="btn btn-primary" key={"format-"+inx}
              style={{display: "block"}} onClick={() => byPassFunction(f)}>
                {f.text}
              </button>
            ))}
          </div>
        } />
      <RegularModalCentered
        _id={html_address_id}
        _title={"Formulario direccion"}
        _body={
          <div>
            <div>
              <label className="form-label" htmlFor="address-direccion_sec">Dirección:</label>
              <input className="form-control" id="address-direccion_sec" type="text"
              maxLength="255" defaultValue={patient.direccion_sec||""} />
            </div>
            <div>
              <label className="form-label" htmlFor="address-direccion_ref">Referencia</label>
              <input className="form-control" id="address-direccion_ref" type="text"
              maxLength="255" defaultValue={patient.direccion_ref||""} />
            </div>
            <br/>
            <div><button className="btn btn-primary" onClick={saveCustomAddress}>Continuar</button></div>
          </div>
        } />
    </React.Fragment>
  )
}
// Send Instant Notification
const InstantNotification = ({patient_pk, current_sucursal}) => {
  let [checkbox_now, setCheckboxNow] = useState(true)
  // Current sucursal
  const ctx_nv = useContext(NavigationContext)
  let cur_suc = ctx_nv.sucursales.find(s => s.pk==ctx_nv.current_sucursal)
  let signature = `- ${cur_suc.empresa_data.razon_social.toUpperCase()}`
  // Current datetime
  let _dt = new Date()
  let datetime_now = _dt.toDateInputValue()+'T'+_dt.getHours()+":"+String(_dt.getMinutes()).padStart(2, "0")

  const saveInstantNotification = () => {
    if(__debug__) console.log("InstantNotification saveInstantNotification");

    let data = {
      message: window.document.getElementById('in-msg').value.trim(),
      now: window.document.getElementById('in-now').checked,
      fecha: window.document.getElementById('in-fecha').value.split('T')[0],
      hora: window.document.getElementById('in-fecha').value.split('T')[1],
    }

    // Verificar valores
    if(data.message.length==0){
      alert("El mensaje no puede estar vacio")
      return
    }else if(/[áéíóúÁÉÍÓÚñÑ]/.test(data.message)){
      alert("El mensaje no puede contener tildes o ñ")
      return
    }
    // Add signature
    data.message += '\n'+signature

    console.log("data", data)
    // Enviar data al API
    simplePostData(`atencion/notification/instant/paciente/${patient_pk}/sucursal/${current_sucursal}/`, data)
    .then(r => console.log("r", r))
    .then(
      () => handleErrorResponse("custom", "Enviado", "El mensaje fue enviado exitosamente", "info")
    )
    .then(() => window.$('#'+html_instant_notification_id).modal('hide'))
  }

  useEffect(() => () => {
    // Assure modals will be closed before leaving current page
    window.$('#'+html_format_id).modal("hide")
  }, [])

  return (
    <RegularModalCentered
      _id={html_instant_notification_id}
      _title={"Enviar mensaje al paciente"}
      _body={
        <div>
          <div className="form-group">
            <label className="form-label" htmlFor="input-descripcion">Mensaje</label>
            <textarea className="form-control"
            placeholder="Mensaje aqui" id="in-msg" rows="3" maxLength={140-signature.length}
            style={{borderBottom: "none", resize: "none", borderRadius: "0"}}></textarea>
            <span className="form-control" style={{
              display: "block", borderTop: "none", borderRadius: "0",
            }}>{signature}</span>
            <span className="help-block">
              {140-signature.length} caracteres maximo, solo letras y numeros permitidos (no tildes, ñ o simbolos)
            </span>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="input-fecha">Cuándo enviar?</label>
            <div style={{display: "flex", alignItems: "center"}}>
              <div className="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" className="custom-control-input" id="in-now" defaultChecked onChange={e => setCheckboxNow(e.target.checked)} />
                <label className="custom-control-label" htmlFor="in-now">Ahora</label>
              </div>
              <input type="datetime-local" id="in-fecha" className="form-control col-3" disabled={checkbox_now}
              defaultValue={datetime_now} />
            </div>
          </div>
          <button className="btn btn-primary" onClick={saveInstantNotification}>Enviar mensaje</button>
        </div>
      } />
  )
}
const AtenderPaciente = ({patient_pk, current_sucursal, redirectTo}) => {
  let [latest_citas, setLatestCitaInfo] = useState(false)
  let [personal, setPersonal] = useState(false)

  const getLatestCitasInfo = (pac_pk, _sucursal_pk) => {
    if(__debug__) console.log("AtenderPaciente getUnfinishedANP")
    simplePostData(`atencion/noprogramado/unfinished/`, {paciente_pk: pac_pk, sucursal_pk: _sucursal_pk})
    .then(res => setLatestCitaInfo(res.citas))
  }
  const getPersonal = () => simpleGet(`maestro/empresa/personal/?filtro={"atencion":"true"}`).then(setPersonal)
  const submitAtender = () => {
    let personal_pk = window.document.getElementById('last_cita_personal').value
    simplePostData(`atencion/noprogramado/create/`, {paciente: patient_pk, sucursal: current_sucursal, personal: personal_pk})
    .then( c => redirectTo(`/nav/atencion/${c.pk}/detalle`))
  }
  const goPastAttention = () => {
    let cita_pk = window.document.getElementById('last_cita').value
    redirectTo(`/nav/atencion/${cita_pk}/detalle`)
  }

  useEffect(() => {
    if(__debug__) console.log("AtenderPaciente useEffect")

    getLatestCitasInfo(patient_pk, current_sucursal)
    getPersonal()
  }, [])

  return (
    <RegularModalCentered
      _id={html_atender_modal_id}
      _title={"Atender Paciente"}
      _body={
        <div>
          <div className="form-group">
            <label className="form-label" htmlFor="last_cita">Atenciones del día de hoy: </label>
            {!latest_citas
              ? <Loader scale={2} />
              : latest_citas.length == 0
                ? <h5 style={{color: "purple"}}>No se encontraron atenciones</h5>
                : (
                  <div className="row">
                    <div className="col-8">
                      <select className="custom-select form-control" id="last_cita">
                        {latest_citas.map(c =>
                          <option key={c.pk} value={c.pk}>{c.hora.slice(0, 5)} - {c.personal.fullname.toUpperCase()}</option>)}
                      </select>
                    </div>
                    <div className="col-2">
                      <button className="btn btn-primary" onClick={goPastAttention}>Ir</button>
                    </div>
                  </div>
                )
            }
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="last_cita_personal">Crear atención sin cita: </label>
            <div className="row">
              <div className="col-8">
                {!personal
                  ? <Loader scale={2} />
                  : (
                    <select className="custom-select form-control" id="last_cita_personal">
                      {personal.map(p => <option key={p.pk} value={p.pk}>{p.fullname.toUpperCase()}</option>)}
                    </select>
                  )}
              </div>
              <div className="col-2">
                <button className="btn btn-primary" onClick={submitAtender}>Crear</button>
              </div>
            </div>
          </div>
        </div>
      } />
  )
}
const ListCuestionario = ({patient_pk, redirectTo}) => {
  const [cuestionarios, setCuestionarios] = useState(false)

  const getCuestionarios = () => simpleGet(`atencion/cuestionario/?filtro={"activo":"1"}`).then(setCuestionarios)

  useEffect(() => {
    getCuestionarios()
  }, [])

  return (
    <RegularModalCentered
      _id={html_cuestionario_modal_id}
      _title={"Cuestionarios activos"}
      _body={
        <div className="form-group col-md-12">
          <table><tbody>
          {cuestionarios && cuestionarios.length == 0 && (
            <tr><td>No hay cuestionarios disponibles, puedes crear uno <a href="/nav/admin/cuestionario">aquí</a></td></tr>
          )}
          {cuestionarios && cuestionarios.map(c => (
            <tr key={"cuestionario-tr-"+c.pk}>
              <td>
                <button className="btn btn-primary"
                onClick={() => redirectTo(`/nav/admision/${patient_pk}/cuestionario/${c.pk}/`)}>{c.titulo}</button>
              </td>
            </tr>
          ))}
          </tbody></table>
        </div>
      } />
  )
}


export default Admision

/*
* Add cita (optional|maybe later)
*/
