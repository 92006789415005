import React, { useEffect, useRef, useContext } from 'react'
import {
  Switch,  // Allow to change only content
  Route,  // Route handling
  Redirect,  // Redirect url
  withRouter,  // Allow us access to route props
  Link
} from "react-router-dom"  // https://reacttraining.com/react-router/web/api/
import '../Navigation.css'
import {
  capitalizeFirstLetter as cFL,
  deleteUserLogIn,
} from '../../functions'
import { NavigationContext } from '../Navigation';
import PageFooter from '../layout/PageFooter';
import PageHeader from '../layout/PageHeader';
import { addResources } from '../../functions';
import SucursalesReport from './sucursales/SucursalesReport';
import VerticalTabNavigation from '../layout/VerticalTabNavigation';
import AdminSucursal from '../admin/Sucursal/AdminSucursal';
import ScriptOdontograma from '../odontograma/ScriptOdontograma';
import PersonalesSucursal from '../admin/Personal/PersonalesSucursal';
import PersonalView from '../admin/Personal/PersonalView';
import PersonalForm from '../admin/Personal/PersonalForm';


// Constant
const __debug__ = process.env.REACT_APP_DEBUG == "true"

const SuperUserNavigation = ({ user, history }) => {
  const redirect = useRef(false)

  if (__debug__ === "true") console.log(`%c --------- MOUNTING ${history.location.pathname} ---------`, 'background: black; color: red')
  if (__debug__ === "true") console.log(`%c PROPS:`, 'color: yellow', user, redirect.current)

  const redirectTo = (url, data = null) => {
    if (data) redirect.current = data
    history.push(url)
  }

  useEffect(() => {
    // Add resources
    addResources()
  }, [])

  const context = {
    user, redirectTo, history
  }

  return (
    <NavigationContext.Provider value={context}>
      <div>
        <div className="page-wrapper">
          <div className="page-inner">
            <div className="page-content-overlay" data-action="toggle" data-class="mobile-nav-on"></div>
            <PageContent />
          </div>
        </div>
      </div>
    </NavigationContext.Provider>
  )
}

/*** COMPONENTS ***/
const SuperuserSelectComponent = () => {
  return (<main id="js-page-content" role="main" className="page-content">
    <Switch>
      <Route exact path='/navsuperuser/sucursalreports/'>
        <SucursalesReport />
      </Route>
    </Switch>
  </main>)
}

/*** PAGE ***/
const PageContent = () => {
  return (
    <div className="page-content-wrapper bg-brand-gradient">
      <PageHeader
        ActionApps={SuperuserActionApps}
        ActionSettings={SuperuserActionSettings}
        ActionUser={SuperuserActionUser}
      />
      <Switch>
        <Route exact path="/navsuperuser/">
            <Redirect to="/navsuperuser/sucursalreports/" />
        </Route>
        <Route exact path='/navsuperuser/sucursalreports/' > 
          <SucursalesReport />
        </Route>
        <Route exact path="/navsuperuser/sucursal/">
          <AdminSucursal />
        </Route>
        <Route exact path="/navsuperuser/sucursal/:pk/personal">
          <PersonalesSucursal />
        </Route>
        <Route exact path="/navsuperuser/sucursal/:pk/personal/:id_personal/detalle">
          <PersonalView />
        </Route>
        <Route exact path="/navsuperuser/sucursal/:pk/personal/:id_personal/editar">
          <PersonalForm />
        </Route>
        <Route exact path="/navsuperuser/sucursal/:pk/personal/new">
          <PersonalForm />
        </Route>
        <Route exact path="/navsuperuser/utils/save-odontogramas">
          <ScriptOdontograma />
        </Route>
      </Switch>
      <PageFooter />
    </div>
  )
}

const SuperuserActionApps = () => <div />


const SuperuserActionSettings = () => {
  const { redirectTo } = useContext(NavigationContext)
  return (
    <div>
      <a href="#" className="header-icon" data-toggle="dropdown">
        <i className="fal fa-cog text-white"></i>
      </a>
      <div className="dropdown-menu dropdown-menu-animated w-auto h-auto">
        <div className="dropdown-header d-flex justify-content-center align-items-center rounded-top" style={{ backgroundColor: "#2b5d84" }}>
          <h4 className="m-0 text-center color-white">
            Configuración
            <small className="mb-0 opacity-80">Herramientas de administración</small>
          </h4>
        </div>
        <div className="custom-scroll h-100">
          <ul className="app-list">
            <li id='sucursal-link'>
              <a className="app-list-item hover-white" onClick={() => redirectTo("/navsuperuser/sucursal/")}>
                <span className="icon-stack">
                  <i className="base-5 icon-stack-3x color-primary-500"></i>
                  <i className="fal fa-list icon-stack-1x text-white"></i>
                </span>
                <span className="app-list-name">
                  Sucursal
                </span>
              </a>
            </li>
            <li id='reportes-link'>
              <a className="app-list-item hover-white" onClick={() => redirectTo("/navsuperuser/sucursalreports/")}>
                <span className="icon-stack">
                  <i className="base-5 icon-stack-3x color-primary-500"></i>
                  <i className="fal fa-chart-line icon-stack-1x text-white"></i>
                </span>
                <span className="app-list-name">
                  Reportes
                </span>
              </a>
            </li>
            <li id='reportes-link'>
              <a className="app-list-item hover-white" onClick={() => redirectTo("/navsuperuser/utils/save-odontogramas")}>
                <span className="icon-stack">
                  <i className="base-5 icon-stack-3x color-primary-500"></i>
                  <i className="fal fa-stethoscope icon-stack-1x text-white"></i>
                </span>
                <span className="app-list-name">
                  Script para actualizar odontogramas
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const SuperuserActionUser = () => {
  const { user } = useContext(NavigationContext)
  const userLogOut = () => {
    deleteUserLogIn()  // Delete token from localstorage
    window.location.replace("/")  // Reload page
  }

  return (
    <div>
      <a href="#" data-toggle="dropdown" className="header-icon d-flex align-items-center justify-content-center">
        <img className="rounded-circle" style={{ width: "2rem", height: "2rem" }}
          src="/img/unk_user_male.jpg"/>
          
      </a>
      <div className="dropdown-menu dropdown-menu-animated dropdown-lg">
        {/* User card */}
        <div className="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
          <div className="d-flex flex-row align-items-center mt-1 mb-1 color-white">
            <span className="mr-2">
              <img className="rounded-circle profile-image"
                src="/img/unk_user_male.jpg" />
            </span>
            <div className="info-card-text">
              <div className="fs-lg text-truncate text-truncate-lg">
                {user.username}
              </div>
              <span className="text-truncate text-truncate-md opacity-80">
                {user.is_superuser ? "Administrador" : cFL(user.personal.especialidad)}
              </span>
            </div>
          </div>
        </div>
        <a href="#" className="dropdown-item" data-action="app-fullscreen">
          <span data-i18n="drpdwn.fullscreen">Pantalla Completa</span>
        </a>
        <a className="dropdown-item fw-500 pt-3 pb-3" onClick={userLogOut}>
          <span data-i18n="drpdwn.page-logout">Cerrar sesion</span>
        </a>
      </div>
    </div>
  )
}

/* NOTES
* Functions components can return only one element,
  to return more than only one, you should put them inside another tag
  altough this is a walkaround it's a bad practice bc every function should return only 1 value
  <> content </>
* Handle routes params
  let { path, url } = useRouteMatch()
  let { topicId } = useParams()
*/

export default withRouter(SuperUserNavigation)
// // eslint-disable-next-line react-hooks/exhaustive-deps