import React, { useEffect, useState } from 'react';
import OdontogramaNavigation from './Odontograma';
import { simpleGet, simplePostData } from '../../functions';

const ScriptOdontograma = () => {
  /* Atencion PKs son todas las atenciones relacionadas a un odontograma*/
  const [atencion_pks, setAtencion_pks] = useState([]);
  const [contador, setContador] = useState({
    total: 0,
    exitosos: 0,
    fallidos: 0,
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [componentMounted, setComponentMounted] = useState(true);
  const [functionRunning, setFunctionRunning] = useState(false);

  useEffect(() => {
    setComponentMounted(true);
  }, [currentIndex]);

  useEffect(() => {
    fetchAtencionsPKs();
  }, []);

  /* Get the atenciones pks */
  const fetchAtencionsPKs = async () => {
    try {
      const response = await simpleGet('atencion/atencionesall');
      setAtencion_pks(response);
      setContador({
        total: response.length,
        exitosos: 0,
        fallidos: 0,
      });
    } catch (error) {
      console.log(error);
    }
  };

  /* Se envía el Base64 a la Api para su guardado en la DB */
  const postCanva = async (data) => {
    try {
      const response = await simplePostData(
        `atencion/${atencion_pks[currentIndex].cita_pk}/odontograma/saveimg/`,
        {
          image: data,
        }
      );
      setComponentMounted(false);
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setContador((prevContador) => ({
        ...prevContador,
        exitosos: prevContador.exitosos + 1,
      }));
    } catch (error) {
      setContador((prevContador) => ({
        ...prevContador,
        fallidos: prevContador.fallidos + 1,
      }));
    }
  };

  return (
    <div style={{
      margin: 'auto',
    }}>
      <div>
        <h1 style={{fontSize: '30px'}}>Almacenamiento de los Odontogramas en la DB</h1>
        {!functionRunning && (
          <>
            <p>¿Desea iniciar con la transferencia?</p>
            <button onClick={() => setFunctionRunning(true)}>Iniciar</button>
          </>
        )}
        {functionRunning && atencion_pks && currentIndex < atencion_pks.length && (
          <>
            <p>¿Desea parar con la transferencia?</p>
            <button onClick={() => setFunctionRunning(false)}>Parar</button>
          </>
        )}
        <p>Contador: {contador.total}</p>
        <p>Exitosos: {contador.exitosos}</p>
        <p>Fallidos: {contador.fallidos}</p>
      </div>
      {/* Se renderiza el componente Odontograma al cual se le pasa la funcion post, una vez que el
      canvas haya renderizado las incidencias, llama al post y se itera al nuevo odontograma */}
      {functionRunning &&
        componentMounted &&
        currentIndex < atencion_pks.length && (
          <OdontogramaNavigation
            data={atencion_pks[currentIndex]}
            postCanva={postCanva}
          />
        )}
    </div>
  );
};

export default ScriptOdontograma;
