import { handleErrorResponse, simpleGet } from "../../../functions";

const listNotas = (queryParams = null, setup) => {
  simpleGet(`atencion/nota/?${queryParams}`)
    .then(setup)
    .catch(() =>
      handleErrorResponse(
        "custom",
        "Error",
        "Ha ocurrido un error al recuperar los datos",
        "danger"
      )
    );
};

export default listNotas;
