import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import ModalComponent from '../../utils/ModalComponent'
import { simplePostData } from '../../../functions'
import { useContext } from 'react'
import { NavigationContext } from '../../Navigation'

const SucursalForm = ({idEmpresa, selectedSucursal, handleSucursal, setAlertData, getEmpresas}) => {

  const {redirectTo} = useContext(NavigationContext)

  const [stateForm, setStateForm] = useState('view')

  const [tempSucursal, setTempSucursal] = useState(null)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const direccionRef = useRef();
  const ubigeoRef = useRef();
  const celularRef = useRef();

  const buttonSaveLabel = stateForm === 'create' ? 'Cancelar' : 'Crear Nueva Sucursal';

  useEffect(() => {
    if (selectedSucursal) {
      setTempSucursal(selectedSucursal)
    }
    if(direccionRef.current && celularRef.current  && ubigeoRef.current){
      direccionRef.current.value = selectedSucursal?.direccion || "";
      ubigeoRef.current.value = selectedSucursal?.ubigeo || "";
      celularRef.current.value = selectedSucursal?.celular || "";
    }
  }, [selectedSucursal]);


  const handleCreateStateForm = () => {
    if(stateForm === 'create') {
      setStateForm('view')
      handleSucursal(tempSucursal)
      setTempSucursal(null)
    } else {
      setTempSucursal(selectedSucursal)
      setStateForm('create')
      handleSucursal(null)
    }
  }

  const handleStateForm = () => {
    if (stateForm == 'view') {
      setStateForm('edit')
    } else {
      setStateForm('view')
    }
  }

  const getDataToSubmit = () => {
    return {
      empresa: idEmpresa,
      direccion: direccionRef.current.value,
      ubigeo: ubigeoRef.current.value,
      celular: celularRef.current.value,
    }
  }

  const showAlert = (response, httpMethod) => {
    if(response){
        setAlertData({
          color: 'success',
          message: httpMethod === 'POST' ? 'Sucursal creada correctamente' : 'Sucursal actualizada correctamente'
        })
        handleSucursal(response);
        handleStateForm();
        getEmpresas();
    } else {
      setAlertData({
        color: 'danger',
        message: httpMethod === 'POST' ? 'Error al crear la sucursal' : 'Error al actualizar la sucursal'
      })
      handleSucursal(selectedSucursal);
    }
  }

  const handleCreateSucursal = (e) => {
    e.preventDefault()
    const formData = getDataToSubmit()
    simplePostData('maestro/empresa/sucursal/', formData)
    .then( response => {
      showAlert(response, 'POST')
    })
    .catch( err => {
      showAlert(null, 'POST')
    })
  }

  const handleUpdateSucursal = (e) => {
    e.preventDefault()
    const formData = getDataToSubmit()
    simplePostData(`maestro/empresa/sucursal/${selectedSucursal.pk}/`, formData, 'PUT')
    .then( response => {
      showAlert(response, 'PUT')
    })
    .catch( err => {
      showAlert(null, 'PUT')
    })
  }

  //TODO - Implement in future
  /* const handleDeleteSucursal = () => {
    simpleDelete(`maestro/empresa/sucursal/${selectedSucursal.pk}/`)
    .then( response => {
      if(response){
        setAlertData({
          color: 'success',
          message: 'Sucursal eliminada correctamente'
        })
        handleSucursal(null)
      } else {
        setAlertData({
          color: 'danger',
          message: 'Error al eliminar la sucursal'
        })
      }
    })
  } */

  return (
    <Container className='pl-0 mt-3' >
      <Row>
        <Col >
          <h4>Sucursal </h4>
        </Col>
        <Col style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <Button
            className='mr-2'
            onClick={() => redirectTo(`/navsuperuser/sucursal/${selectedSucursal.pk}/personal/`)}
          >
            Personales
          </Button>
          {
            stateForm != 'edit' && (
              <Button
                color={stateForm == 'create' ? 'danger' : 'primary'}
                className='mr-2'
                onClick={handleCreateStateForm}
              >
                {buttonSaveLabel}
              </Button>
            )
          }
          {
            stateForm != 'create' && selectedSucursal && (
              <>
                <Button
                  color={stateForm === 'view' ? 'warning' : 'success'}
                  className='mr-2'
                  onClick={ stateForm == 'edit' ? handleUpdateSucursal : handleStateForm }
                >
                  {stateForm === 'view' ? 'Editar' : 'Guardar'}
                </Button>
                <Button
                  className='mr-2'
                  color='danger'
                  onClick={toggleModal}
                >
                  Eliminar
                </Button>
                <ModalComponent onSubmit={() => alert('Método no disponible')} isOpen={isModalOpen} toggle={toggleModal} title="Confirmación" body="¿Desea eliminar la sucursal?" />
              </>
            )
          }
        </Col>
      </Row>
      {
        selectedSucursal || stateForm === 'create' ? (
          <Form
            className='pl-2'
          >
            {/* Required fields */}
            <Row>
              <Col md={6} lg={6}>
                <FormGroup>
                  <Label for="razon_social">Dirección*</Label>
                  <Input
                    id="razon_social"
                    name="razon_social"
                    type="text"
                    innerRef={direccionRef}
                    required
                    disabled={stateForm == "view" ? true : false}
                  />
                </FormGroup>
              </Col>
              <Col md={6} lg={6}>
                <FormGroup>
                  <Label for="ruc">Ubigeo*</Label>
                  <Input
                    id="ubigeo"
                    name="ubigeo"
                    type="text"
                    innerRef={ubigeoRef}
                    required
                    disabled={stateForm == "view" ? true : false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={6}>
                <FormGroup>
                  <Label for="celular">Celular*</Label>
                  <Input
                    id="celular"
                    name="celular"
                    type="text"
                    innerRef={celularRef}
                    required
                    disabled={stateForm == "view" ? true : false}
                  />
                </FormGroup>
              </Col>
            </Row>
            {
              stateForm == 'create' && (
                <FormGroup className='justify-content-end mt-2'>
                  <Button
                    type='submit'
                    color='primary'
                    onClick={handleCreateSucursal}
                  >
                    Guardar
                  </Button>
                </FormGroup>
              )
            }
          </Form>
        )
        : (
          <h4> No existen Sucursales </h4>
        )
      }
    </Container>
  )
}

export default SucursalForm