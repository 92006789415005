import React, { createContext, useContext, useState } from "react";
import { handleErrorResponse, simpleGet } from "../../../functions";

export const ProveedorContext = createContext();

export const useProveedor = () => {
  const context = useContext(ProveedorContext);
  if (!context) {
    throw new Error("useProveedor must be used within a ProveedorContext");
  }
  return context;
};

export const ProveedorContextProvider = ({ children }) => {
  const [proveedores, setProveedores] = useState(false)

  const getListProveedores = () => {
    simpleGet("maestro/empresa/proveedor/")
    .then(setProveedores)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  return (
    <ProveedorContext.Provider
      value={{
        proveedores,
        getListProveedores, 
      }}
    >
      {children}
    </ProveedorContext.Provider>
  );
};
