import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useCompra } from "../context/CompraContext";
import { useDetalleCompra } from "../context/DetalleCompraContext";
import { useEffect } from "react";

function ModalTerminarCompra({isOpen, closeModal}) {
  const {compra, finishCompra} = useCompra();
  const {detalleCompras, getListDetalleCompra} = useDetalleCompra();

  const isValidTerminarCompra = (detalleCompras) => {
    let isValid = false

    if (detalleCompras.length === 0) return isValid
    isValid = true

    return isValid
  }

  const handleSubmit = () =>{
    let is_valid_terminar_compra = isValidTerminarCompra(detalleCompras)
    
    if(!is_valid_terminar_compra) return
    finishCompra(compra.id, compra, closeModal)
  }

  useEffect(() => {
    if(compra.id) getListDetalleCompra(compra.id)
  }, [])

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <span className="h3">Terminar</span>
      </ModalHeader>
      <ModalBody>
        <div style={{paddingTop: "10px", display: "flex", gap: "10px"}}>
          <button className="btn btn-primary" onClick={handleSubmit}>Finalizar</button>
          <button className="btn btn-secondary" type="button" onClick={closeModal}>Cancelar</button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalTerminarCompra
