import { useEffect, useRef } from 'react'
import { useMessage } from '../context/MessageContext';

export function useScroll() {
  const { messages, getListMessages } = useMessage();
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current){
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom()
}, [messages, getListMessages]);

  return {
    messagesEndRef
  }
}
