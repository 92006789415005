import React from "react";
import { Table, Button } from "reactstrap";
import useModal from "../../../hooks/useModal";

const SucursalesTable = ({ data }) => {
    const { toggle, setModalData } = useModal()

    // remove duplicated, only for show list sucursal
    const sucursales = data.filter((resumenBaja, index) => {
        return index === data.findIndex(obj => obj.sucursal == resumenBaja.sucursal)
    });

    const openModal = (pk) => {
        // filter only resumens own from sucursal
        const resumenesBajas = data.filter((resumenBaja) => resumenBaja.sucursal == pk)
        setModalData({ resumenesBajas })
        toggle()
    }

    return (
        <Table borderless responsive>
            <thead>
                <tr>
                    <th>Empresa</th>
                    <th>RUC</th>
                    <th>Sucursal</th>
                    <th>Contacto</th>
                    <th>Reporte</th>
                </tr>
            </thead>
            <tbody>
                {sucursales.map(sucursal => {
                    return (
                        <tr key={sucursal.sucursal} className="sucursalRow">
                            <td>{sucursal.razonsocial}</td>
                            <td>{sucursal.ruc}</td>
                            <td>{sucursal.sucursal_direccion}</td>
                            <td>{sucursal.sucursal_contacto}</td>
                            <td><Button
                                color="primary"
                                size="sm"
                                onClick={() => openModal(sucursal.sucursal)}
                            >
                                reportes
                            </Button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
            <style>
                {`
                    .sucursalRow {
                        transition: all 0.2.2s ease-in-out;
                    }
                    .sucursalRow:hover {
                        background-color: #cde8e7;
                        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.3);
                    }
                    .sucursalRow td {
                        vertical-align: middle;
                    }
                `}
            </style>
        </Table>
    )
}

export default SucursalesTable;
