import React from 'react'
import { useSearch } from '../context/SearchContex';

function SearchInput() {
  const {handleSearch, searchTerm} = useSearch();

  const handleOnChange = (e) => {
    const value = e.target.value.trim().toLowerCase()
    handleSearch(value);
  }

  return (
    <section className="px-3 pt-3">
      <input type="text" className="form-control mb-3" placeholder="Buscar contactos..." value={searchTerm} onChange={handleOnChange}/>
    </section>
  )
}

export default SearchInput
