import React from "react"

const PageHeader = ({ ActionApps, ActionSettings, ActionUser }) => {
    return (
        <header className="page-header" style={{
            backgroundColor: "#2b4c81",
            backgroundImage: "linear-gradient(270deg,rgba(46,182,151,.18),transparent)",
            order: "unset",
        }}>
            {/* Logo */}
            <div className="press-scale-down">
                <div className="page-logo-link d-flex align-items-center position-relative">
                    <img src="/img/logo_muelitas_image.png" style={{
                        display: "inline-block",
                        width: "58px", height: "58px",
                    }} />
                    <img src="/img/logo_muelitas_text.png" style={{
                        display: "inline-block",
                        height: "50px",
                        filter: "invert(1)",
                    }} />
                </div>
            </div>

            {/* Actions */}
            <div className="ml-auto d-flex">
                <ActionApps />
                <ActionSettings />
                <ActionUser />
            </div>
        </header>
    )
}

export default PageHeader;
