import { handleErrorResponse, simpleGet } from "../../../functions";

const listMedioPagos = (filter = null, setup) => {
  simpleGet(`maestro/empresa/mediopago/?${filter}`)
    .then((data) => setup(data))
    .catch(() =>
      handleErrorResponse(
        "custom",
        "Error",
        "Ha ocurrido un error al recuperar los datos",
        "danger"
      )
    );
};

export default listMedioPagos;
