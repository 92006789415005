import React, { createContext, useContext, useState } from "react";
import { getDataByPK, handleErrorResponse, simpleGet, simplePostData } from "../../../functions";

export const MessageContext = createContext();

export const useMessage = () => {
  const context = useContext(MessageContext);
  if (!context) {
      throw new Error("useMessage must be used within a MessageContext");
    }
  return context;
};

let defaultMessage = {
  id: "",
  contact: "",
  msg_id: "",
  status: "",
  content: "",
  is_sender: "",
  created_at: "",
  ws_connected: "",
}

export const MessageContextProvider = ({ children }) => {
  const [message, setMessage] = useState(defaultMessage)
  const [messages, setMessages] = useState(false)

  const getListMessages = (contact_id) => {
    simpleGet(`wabachat/message/${contact_id}/`)
    .then(setMessages)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const getMessage = (id) => {
    getDataByPK("wabachat/message/", id)
    .then(setMessage)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const sendMessage = (data) => {
    simplePostData("wabachat/message/send/", data)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  };

  const addMessage = (data) => {
    setMessages([...messages, data])
  }

  return (
    <MessageContext.Provider
      value={{
        message,
        messages,
        getListMessages,
        getMessage,
        sendMessage,
        addMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
