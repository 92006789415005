import React, { useEffect, useState } from "react";
import "./register-payment.css";
import useModal from "../../hooks/useModal";
import { UpdateComponentHelper, simpleGet } from "../../functions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CobranzaDetail from "./CobranzaDetail";


const Footer = ({ planTrabajoPk }) => {
  if (!planTrabajoPk) return null;
  return (
    <div className="footer text-center">
      <Link
        to={"/nav/plandetrabajo/" + planTrabajoPk}
        className="btn btn-secondary btn-sm"
      >
        <div className="d-flex align-items-center justify-content-center">
          <p className="m-0 mr-2">Ver plan</p>
          <i className="fal fa-sign-in-alt" />
        </div>
      </Link>
    </div>
  )
};

const RegisterPayment = () => {
  const { modalData } = useModal();
  const [activeTab, setActiveTab] = useState(0)
  const [tabs, setTabs] = useState([{}]);
  const [planTrabajoPk, setPlanTrabajoPk] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [serverError, setServerError] = useState(false)

  const toggleTab = (index, planTrabajoPk) => {
    setActiveTab(index)
    setPlanTrabajoPk(planTrabajoPk)
  }

  const getTabs = async () => {
    try {
      const url = `finanzas/cuentacorriente/atencionplantrabajo/pagos/paciente/${modalData.patientPk}/`
      const pagosAtencionYplanTrabajos = await simpleGet(url);
      setTabs(pagosAtencionYplanTrabajos)
    } catch (e) {
      setServerError(true)
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getTabs()
  }, [modalData.patientPk]);

  useEffect(() => {
    // seteando siempte el tab inicial
    if (tabs.length > 0) setPlanTrabajoPk(tabs[0].pk)
  }, [tabs])


  if (isLoading) return (<div className="text-center"><h2>Cargando...</h2></div>)

  if (serverError) return (<div className="text-center alert alert-danger">
    <h2>Error Interno, comuníquese con soporte</h2>
  </div>)

  if (tabs.length === 0) return (<div className="text-center alert alert-info"><h2>No hay pagos pendientes</h2></div>)

  return (
    <>
      <div className="custom-tabs">
        {tabs.map((tab, index) => (
          <div
            className={`custom-tab ${activeTab === index ? "active" : ""}`}
            onClick={() => toggleTab(index, tab.pk)}
            key={tab.pk || `atencioneslibreskey-${index}`}
          >
            {tab.nombre}
          </div>
        ))}
      </div>
      {/* el componente creado originalmente usa un context */}
      <UpdateComponentHelper>
        <CobranzaDetail patientPk={modalData.patientPk} planTrabajoPk={planTrabajoPk} />
      </UpdateComponentHelper>

      <Footer planTrabajoPk={planTrabajoPk} />
    </>
  );
};

export default RegisterPayment
