import React, { useEffect } from "react"
import Loader from '../../loader/Loader'
import Datatable from "../../datatable/Datatable";
import { useStock } from "../context/StockContext";

function StockList() {
  const {stocks, getListStocks} = useStock();  
  
  useEffect(() => {
    getListStocks();
  }, [])
  
  const columnas = [
    {
      id: 'id',
      columnHeaderLabel: 'ID',
      cellRender: item => item.id,
      isSortable: true
    },
    {
      id: 'producto',
      columnHeaderLabel: 'Producto',
      cellRender: item => item.producto_descripcion,
      isSortable: true
    },
    {
        id: 'cantidadUnidad',
        columnHeaderLabel: 'Cantidad de Unidades',
        cellRender: item => item.cantidad_unidad,
        isSortable: true
    },
    {
      id: 'cantidadDescriptiva',
      columnHeaderLabel: 'Cantidad Descriptiva',
      cellRender: item => item.cantidad_descriptiva,
      isSortable: true
    },
  ]  

  return (
    !stocks
    ? <Loader scale={2} />
    : (
      <div>
        <div className="datatable-container col-12">
          <Datatable data={stocks} columns={columnas} />
        </div>
      </div>
    )
  )
  }

  export default StockList
