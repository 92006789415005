import React from "react";
import { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useProductPresentation } from "../context/ProductoPresentacionContext";
import { usePresentation } from "../context/PresentacionContext";
import { useParams } from "react-router-dom";

function ModalFormProductoPresentacion({
  isOpen,
  closeModal,
  shouldCreateBasePP,
}) {
  const { product_id } = useParams();
  const {
    defaultBasePPCantidadUnidad,
    productPresentation,
    setProductPresentation,
    createProductPresentation,
    getProductPresentation,
    updateProductPresentation,
  } = useProductPresentation();
  const { presentations } = usePresentation();

  ////---- Helpers ------------------------------------------------------------
  const isBasePPContext = (_productPresentation) => {
    return shouldCreateBasePP || _productPresentation.is_base;
  };

  const composeModalTitle = (_productPresentation) => {
    let title = "Añadir Presentación";
    const isBasePP = isBasePPContext(_productPresentation);

    if (_productPresentation.id) {
      title = "Editar Presentación";
    }
    if (isBasePP) {
      title = `${title} Base`;
    }

    return title;
  };

  const isValidForm = (_productPresentation) => {
    let isValid = false;

    if (!_productPresentation.presentacion) {
      return isValid;
    }
    isValid = true;

    return isValid;
  };

  const getValidatedData = (_productPresentation) => {
    const isBasePP = isBasePPContext(_productPresentation);
    let data = _productPresentation;

    if (isBasePP) {
      data = {
        ..._productPresentation,
        cantidad_unidad: defaultBasePPCantidadUnidad,
        is_base: true,
      };
    }

    return data;
  };

  const handleSubmit = async () => {
    let is_valid_form = isValidForm(productPresentation);

    if (!is_valid_form) return;
    let data = getValidatedData(productPresentation);

    if (productPresentation.id) {
      updateProductPresentation(productPresentation.id, data, closeModal);
      return;
    }
    createProductPresentation(product_id, data, closeModal);
  };

  ////---- Hooks --------------------------------------------------------------
  useEffect(() => {
    if (productPresentation.id) getProductPresentation(productPresentation.id);
  }, [productPresentation.id]);

  ////---- Return -------------------------------------------------------------
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <span className="h3">{composeModalTitle(productPresentation)}</span>
      </ModalHeader>
      <ModalBody>
        {isBasePPContext(productPresentation) ? (
          <div
            className="alert alert-secondary d-flex align-items-center mb-3"
            role="alert"
          >
            <i className="fas fa-info-circle"></i>
            <span className="ml-2">
              La <b>Cantidad de unidades</b> de la presentación base siempre se
              establece como <code className="bg-light">1</code> a fin de
              facilitar la conversion de cantidades.
            </span>
          </div>
        ) : null}

        <div>
          {/* presentaciones */}
          <div className="mb-3">
            <label className="form-label" htmlFor="presentaciones">
              Presentación &nbsp;
            </label>
            <select
              className="custom-select form-control custom-select-lg"
              id="presentaciones"
              value={productPresentation.presentacion}
              onChange={({ target }) =>
                setProductPresentation({
                  ...productPresentation,
                  presentacion: target.value,
                })
              }
            >
              <option value="" disabled>
                Seleccione una presentación...
              </option>
              {presentations &&
                presentations.map((presentation) => (
                  <option key={presentation.id} value={presentation.id}>
                    {presentation.descripcion}
                  </option>
                ))}
            </select>
          </div>
          {/* cantidad unidad */}
          <div className="mb-3">
            <label className="form-label" htmlFor="cantidad_unidad">
              Cantidad de Unidades Base:
            </label>
            <input
              type="number"
              className="form-control"
              id="cantidad_unidad"
              value={
                isBasePPContext(productPresentation)
                  ? defaultBasePPCantidadUnidad
                  : productPresentation.cantidad_unidad
              }
              disabled={isBasePPContext(productPresentation)}
              min="0"
              onChange={({ target }) =>
                setProductPresentation({
                  ...productPresentation,
                  cantidad_unidad: target.value,
                })
              }
            />
          </div>
          {/* precio compra presentacion */}
          <div className="mb-3">
            <label className="form-label" htmlFor="precio_compra_presentacion">
              Precio Compra Presentacion:
            </label>
            <input
              type="number"
              className="form-control"
              id="precio_compra_presentacion"
              value={productPresentation.precio_compra_presentacion}
              min="0"
              onChange={({ target }) =>
                setProductPresentation({
                  ...productPresentation,
                  precio_compra_presentacion: target.value,
                })
              }
            />
          </div>
          <div style={{ paddingTop: "10px", display: "flex", gap: "10px" }}>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Guardar
            </button>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={closeModal}
            >
              Cancelar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalFormProductoPresentacion;
