import React, { useEffect, useState } from 'react'
import { Alert } from 'reactstrap'

const AlertComponent = ({AlertData}) => {

  /* Interface for alertData: {
    message: string,
    color: string
    duration?: number
  } */

  const [alertData, setAlertData] = useState(null)

  const showAlert = (alertData) => {
    setAlertData(alertData)
    setTimeout(() => {
      setAlertData(null)
    }, alertData.duration || 5000)
  }

  useEffect(() => {
    if(AlertData) {
      showAlert(AlertData)
    }
  },[AlertData])

  return (
    alertData && (
      <Alert
        color={alertData.color}
        style={{
          top: "10px",
        }}
      >
        {alertData.message}
      </Alert>
    )
  )
}

export default AlertComponent