import React, { useContext, useEffect } from "react"
import { Modal, ModalBody, ModalHeader, FormGroup, Label, Input } from "reactstrap"
import { useCompra } from "../context/CompraContext";
import { NavigationContext } from "../../Navigation";
import { useProveedor } from "../context/ProveedorContext";

function ModalFormCompra({isOpen, closeModal}) {
  const {user} = useContext(NavigationContext)
  const {compra, setCompra, createCompra} = useCompra();
  const {proveedores, getListProveedores} = useProveedor();

  const isValidCompra = (compra)  => {
    let isValid = false

    if (!compra.proveedor) return isValid
    isValid = true

    return isValid
  }  
  
  const handleSubmit = () => {
    let is_valid_compra = isValidCompra(compra)
    if (!is_valid_compra) return
    createCompra({...compra, asignado: user.pk}, closeModal)
  }

  useEffect(() => {
    getListProveedores();
  }, [])

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <span className="h3">{"Crear Compra"}</span>
      </ModalHeader>
      <ModalBody>
        <div>
          {/* Proveedor */}
          <FormGroup className="mb-3">
            <Label className="form-label" for="proveedor">Proveedor:</Label>
            <Input className="form-control" type="select" name="proveedor" id="proveedor" defaultValue="" onChange={({target}) => {
              setCompra({...compra, proveedor: target.value})
            }}>
              <option value="" disabled>Seleccione un Proveedor</option>
              {proveedores && proveedores.map((proveedor) =>
                <option key={proveedor.id} value={proveedor.id}>{proveedor.descripcion}</option>)}
            </Input>
          </FormGroup>
          <div style={{paddingTop: "10px", display: "flex", gap: "10px"}}>
            <button className="btn btn-primary" onClick={handleSubmit}>Continuar</button>
            <button className="btn btn-secondary" type="button" onClick={closeModal}>Cancelar</button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalFormCompra
