import React, { useState } from 'react'
import AlertComponent from '../../utils/AlertComponent';
import EmpresaForm from './EmpresaForm'
import { PageTitle } from '../../bits'
import SucursalForm from './SucursalForm';

const AdminSucursalContent = ({selectedEmpresa, setSelectedEmpresa, stateForm, setStateForm, selectedSucursal, handleSucursal, getEmpresas}) => {

  const [alertData, setAlertData] = useState(null);
  const formIsCreate = stateForm != 'create';

  return (
    <>
      <EmpresaForm
        stateForm={stateForm}
        selectedEmpresa={stateForm != 'create' ? selectedEmpresa : null}
        setAlertData={setAlertData}
        setSelectedEmpresa={setSelectedEmpresa}
        setStateForm={setStateForm}
        getEmpresas={getEmpresas}
      />
      <hr className='mt-3'/>
      {
        selectedEmpresa && formIsCreate && (
          <SucursalForm
            idEmpresa={selectedEmpresa.pk}
            selectedSucursal={selectedSucursal}
            setAlertData={setAlertData}
            handleSucursal={handleSucursal}
            getEmpresas={getEmpresas}
          />
        )
      }
      <AlertComponent AlertData={alertData} />
    </>
  )
}

export default AdminSucursalContent;
