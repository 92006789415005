import React, { useState, createContext } from 'react'
import { useContext } from 'react';

const SearchContext = createContext();

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
      throw new Error("useSearch must be used within a SearchContext");
    }
  return context;
}

export function SearchContextProvider({ children }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (searchQuery) => {
    setSearchTerm(searchQuery.trim().toLowerCase());
  };

  return (
    <SearchContext.Provider value={{ searchTerm, handleSearch }}>
      {children}
    </SearchContext.Provider>
  )

}
