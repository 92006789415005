import React, { useState } from "react";
import useModal from "../../../hooks/useModal";
import { Button, Table } from "reactstrap";
import { downloadFileService } from "../../../functions";

const SucursalesReportMonth = () => {
    const { modalData: { resumenesBajas } } = useModal()
    const { razonsocial, sucursal_direccion } = resumenesBajas[0]

    return <div>
        <h6 className="text-center">{razonsocial} - {sucursal_direccion}</h6>
        <Table borderless responsive size="sm">
            <thead>
                <tr>
                    <th>Año</th>
                    <th>Mes</th>
                    <th>N° Boletas</th>
                    <th>Acción</th>
                </tr>
            </thead>
            <tbody>
                {resumenesBajas.map(resumenBaja => {
                    return (
                        <tr
                            key={resumenBaja.anio + "-" + resumenBaja.mes}
                            className="reportRow"
                        >
                            <td>{resumenBaja.anio}</td>
                            <td>{resumenBaja.mes.toString().padStart(2, "0")}</td>
                            <td>{resumenBaja.boletas}</td>
                            <td>
                                <DownloadReport
                                    sucursalPk={resumenBaja.sucursal}
                                    empresa={razonsocial}
                                    year={resumenBaja.anio}
                                    month={resumenBaja.mes}
                                    boletas={resumenBaja.boletas}
                                />
                            </td>
                        </tr>
                    )
                })}
            </tbody>
            <style>
                {`
                .reportRow {
                    transition: all 0.2.2s ease-in-out;
                }
                .reportRow:hover {
                    background-color: #dfe3e6;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
                    z-index: 1;
                }
                .reportRow td {
                    vertical-align: middle;
                }
            `}
            </style>
        </Table>
    </div>
}

const DownloadReport = ({ sucursalPk, empresa, year, month, boletas }) => {
    const [isLoading, setIsLoading] = useState(false)
    const endPoint = `finanzas/fe/${sucursalPk}/${year}/${month}/`

    const startDownloadPorcess = async () => {
        if (boletas == 0) return null;
        setIsLoading(true)

        const data = await downloadFileService(endPoint);

        if (data === null) {
            setIsLoading(false)
            alert("Error inesperado")
            return;
        }

        const empresaName = empresa.replaceAll(" ", "_")
        const monthFormated = month.toString().padStart(2, "0")
    
        const url = URL.createObjectURL(new Blob([data]));
        let a = document.createElement('a');
        a.href = url;
        a.download = `Comprobantes-${empresaName}-${year}_${monthFormated}.xlsx`;
        a.click();
        setIsLoading(false)
    }

    return (<div style={{
        "display": "flex",
        "justifyContent": "center",
    }}>
        <Button
            color="primary"
            size="sm"
            onClick={() => startDownloadPorcess()}
        >
            {(isLoading) ? "Descargando ..." : "Descargar"}
        </Button>
    </div>)
}

export default SucursalesReportMonth;
