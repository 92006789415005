import React from 'react'
import CheckSVG from '../svgs/CheckSVG'
import XSVG from '../svgs/XSVG'
import LoaderSVG from '../svgs/LoaderSVG'

function MessageContentCard({message}) {
  const MESSAGE_STATUS_LIST = [
    {id: "0", icon: <CheckSVG/>},
    {id: "1", icon: <XSVG/>},
    {id: "2", icon: <LoaderSVG/>},
  ]

  return (
    <article key={message.id} className={`card p-2 mb-1 rounded text-white ${message.is_sender ? " bg-primary align-self-end" : "bg-secondary align-self-start" }`} style={{width: "fit-content", maxWidth:"70%", justifyContent: "end"}}>
      <p className='m-0'>{message.content}</p>
      <small className={`${message.is_sender ? "align-self-end":"align-self-start"}`}>{message.created_at_as_lima_tz}
        <span className='ml-1'>
          {MESSAGE_STATUS_LIST.find(status => status.id === message.status)?.icon}
        </span>
      </small>
    </article>
  )
}

export default MessageContentCard
