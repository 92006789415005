import React from "react";
import { ProcedureContextProvider } from "./ProcedimientoContext";
import { ProcedureProductContextProvider } from "./ProcedimientoProductoContext";
import { ProductContextProvider } from "../../inventario/context/ProductoContext";
import { ProductPresentationContextProvider } from "../../inventario/context/ProductoPresentacionContext";

function AdminProvider({children}){
  return(
    <ProductPresentationContextProvider>
      <ProcedureProductContextProvider>
        <ProcedureContextProvider>
          <ProductContextProvider>
            {children}
          </ProductContextProvider>
        </ProcedureContextProvider>
      </ProcedureProductContextProvider>
    </ProductPresentationContextProvider>
  )
}

export default AdminProvider
