import React, { createContext, useContext, useState } from "react";
import { getDataByPK, handleErrorResponse, simpleGet } from "../../../functions";

export const ContactContext = createContext();

export const useContact = () => {
  const context = useContext(ContactContext);
  if (!context) {
      throw new Error("useContact must be used within a ContactContext");
    }
  return context;
};

let defaultContact = {
  id: "",
  webhook: "",
  name: "",
  phone: 0,
  last_message: "",
}

export const ContactContextProvider = ({ children }) => {
  const [contact, setContact] = useState(defaultContact)
  const [contacts, setContacts] = useState(false)

  const getListContacts = () => {
    simpleGet("wabachat/contact/")
    .then(setContacts)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const getContact = (id) => {
    getDataByPK("inventario/almacen/contact", id)
    .then(setContact)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  return (
    <ContactContext.Provider
      value={{
        contact,
        contacts,
        getListContacts,
        getContact,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};
