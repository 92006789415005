import React, { createContext, useContext, useState } from "react";
import { getDataByPK, handleErrorResponse } from "../../../functions";

export const ProcedureContext = createContext();

export const useProcedure = () => {
  const context = useContext(ProcedureContext);
  if (!context) {
      throw new Error("useProcedure must be used within a ProcedureContext");
  }
  return context;
};

export const ProcedureContextProvider = ({ children }) => {
  let defaultProcedure = {
    id: "",
    nombre: "",
    descripcion: "",
  }
  const [procedure, setProcedure] = useState(defaultProcedure)

  const getProcedure = (id) => {
    getDataByPK("maestro/procedimiento/sucursal/detalle", id)
    .then(setProcedure)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  return (
    <ProcedureContext.Provider
      value={{
        defaultProcedure,
        procedure,
        getProcedure, 
      }}
    >
      {children}
    </ProcedureContext.Provider>
  );
};
