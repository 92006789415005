import React from 'react'
import { useCompra } from "../context/CompraContext";

function CardDetalleCompra() {
  const {compra} = useCompra()

  return (
    <div className="card col-12" style={{padding: "0px"}}>
      <div className="card-header">
        <div className="card-title">
          Detalle Compra
        </div>
      </div>
      <div className="card-body">
        <div className="row pt-1">
          <div className="col-sm-6 col-md-3 mb-1">
            <h6>Proveedor</h6>
            <p className="text-muted">{compra.proveedor_descripcion}</p>
          </div>
          <div className="col-sm-6 col-md-3 mb-1">
            <h6>Total</h6>
            <p className="text-muted">{compra.total_final}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardDetalleCompra
