import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  simpleGet,
  getDataByPK,
  capitalizeFirstLetter as cFL
} from '../../functions';
import { Icon, PageTitle } from '../bits';
import { PatientDataList } from '../admision/Admision';
import { NavigationContext } from '../Navigation'
import { ModalContextProvider } from '../../context/modalContext';
import SimpleModal from '../layout/SimpleModal';
import useModal from '../../hooks/useModal';
import ProcedimientoObservacion from './ProcedimientoObservacion';
import Datatable from '../datatable/Datatable';


const HistoriaClinica = () => {
  const {redirectTo, current_sucursal} = useContext(NavigationContext)
  const {patient_pk} = useParams()
  const [listKey, setListKey] = useState("1")

  const renderList = useMemo(() => {
    switch(listKey){
      case "1": return <HistoriaCitaList redirectTo={redirectTo} patient_pk={patient_pk} />;
      case "2": return <HistoriaProcedimientoslist redirectTo={redirectTo} patient_pk={patient_pk} />;
      default: return <div>No hay datos</div>;
    }
  }, [listKey])

  const showCitas = () => setListKey("1")
  const showProcedimientos = () => setListKey("2")

  const handlePrint = () => window.open(process.env.REACT_APP_PROJECT_API+`atencion/viewdoc/historia_clinica/${current_sucursal}/${patient_pk}/`, '_blank')

  return (
    <>
      <ModalContextProvider>
        <PageTitle title={"Historia Clinica"} />
        <div className='mb-6'>
          {listKey !== "1" && <button className='btn btn-info' onClick={showCitas}>Ver Citas</button>}
          {listKey !== "2" && <button className='btn btn-info' onClick={showProcedimientos}>Ver Procedimientos</button>}
          <button
            className='btn btn-dark ml-3'
            onClick={handlePrint}
          >
              <i className="fal fa-print fa-1x mr-1"></i>
              Imprimir Historia Clínica
          </button>
        </div>

        <h5 style={{paddingBottom: "15px"}}>Fecha de generación de documento: {new Date().toDateInputValue()}</h5>
        {/*
          <HistoriaPatientData patient_pk={patient_pk} />
          */}
        {renderList}
        <SimpleModal body={<ProcedimientoObservacion />}/>
      </ModalContextProvider>
    </>
  )
}
const HistoriaPatientData = ({patient_pk}) => {
  const [patient, setPatient] = useState(false);

  const getPatientByID = _id => getDataByPK('atencion/paciente', _id).then(setPatient)

  useEffect(() => {
    getPatientByID(patient_pk);
  }, []);

  return (
    <div>
      <h3>Datos del Paciente</h3>
      {!patient
        ? (<div className="card"><div className="card-body">loading</div></div>)
        : <PatientDataList patient={patient} />
      }
    </div>
  )
}
const HistoriaCitaList = ({patient_pk, redirectTo}) => {
  const [citaList, setCitaList] = useState(false);
  const {current_sucursal} = useContext(NavigationContext)

  const getCitas = pac_pk => {
    simpleGet(`atencion/cita/?filtro={"paciente":"${pac_pk}", "estado":"5", "sort":"true"}`)
    .then(res => {
      // Remove duplicated attention
      let _tmp = res;
      let _tmp1 = [];  // Store attention's id
      if(_tmp.length>0){
        _tmp = res.filter(i => {
          if(_tmp1.includes(i.atencion)){  // If attention already in _tmp1
            return false;  // Remove
          }
          _tmp1.push(i.atencion);  // Save attention in _tmp1 array
          return true;
        });
      }

      setCitaList(_tmp);
    })
  }

  useEffect(() => {
    getCitas(patient_pk);
  }, []);

  const columnas = [
    {
      id: "fecha",
      columnHeaderLabel: "Fecha",
      cellRender: item => new Date(item.fecha),
      columnType: "date",
      isSortable: true,
    },
    {
      id: "hora",
      columnHeaderLabel: "Hora",
      cellRender: item => `${item.hora} - ${item.hora_fin}`,
      isSortable: true,
    },
    {
      id: "personal",
      columnHeaderLabel: "Personal",
      cellRender: item => `${item.personal.nombre_principal} ${item.personal.ape_paterno} ${item.personal.ape_materno}`,
      isSortable: true,
    },
    {
      id: "ver",
      columnHeaderLabel: "Ver detalle",
      cellRender: item => (
        <Icon
          type="attention"
          onClick={() =>
            redirectTo(`/nav/atencion/${item.pk}/detalle`, {cita: item})
          }
        />
      ),
      isSortable: false,
    }
  ]

  return (
    <div>
      <h3>Atenciones</h3>
      {!citaList
        ? (<div className="card"><div className="card-body">loading</div></div>)
        : (
          <Datatable columns={columnas} data={citaList} />
        )
      }
    </div>
  )
}
const HistoriaProcedimientoslist = ({patient_pk, redirectTo}) => {
  const [procedimientosList, setProcedimientosList] = useState(false);
  const {current_sucursal} = useContext(NavigationContext)
  const {toggle, setModalData} = useModal()

  const openModal = (procedimiento) => {
    setModalData({procedimiento})
    toggle()
  }

  const getProcedimientos = () => {
    simpleGet(`atencion/procedimiento/?paciente=${patient_pk}&sucursal=${current_sucursal}`)
    .then(setProcedimientosList)
  }

  useEffect(() => {
    getProcedimientos();
  },[])

  const columnas = [
    {
      id: "fecha",
      columnHeaderLabel: "Fecha",
      cellRender: item => new Date(item.fecha),
      columnType: "date",
      isSortable: true,
    },
    {
      id: "hora",
      columnHeaderLabel: "Hora",
      cellRender: item => item.hora.split(":").slice(0, 2).join(":"),
      isSortable: true,
    },
    {
      id: "personal",
      columnHeaderLabel: "Personal",
      cellRender: item => item.personal,
      isSortable: true,
    },
    {
      id: "procedimientos",
      columnHeaderLabel: "Procedimientos",
      cellRender: item => item.procedimiento,
      isSortable: true,
    },
    {
      id: "ver",
      columnHeaderLabel: "Ir a Cita",
      cellRender: item => (
        <Icon
          type="attention"
          onClick={() => redirectTo(`/nav/atencion/${item.cita}/detalle`)}
        />
      ),
      isSortable: false,
    }
  ]

  return (
    <div>
      <h3>Atenciones</h3>
      {!procedimientosList
        ? (<div className="card"><div className="card-body">loading</div></div>)
        : (
          <Datatable columns={columnas} data={procedimientosList} />
        )
      }
    </div>
  )
}
  
export default HistoriaClinica;
