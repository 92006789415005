import React, { useEffect } from 'react'
import Loader from '../../loader/Loader'
import { useKardex } from "../context/KardexContext"
import Datatable from "../../datatable/Datatable";

const colFechaCreacion = "fecha_creacion";

function KardexList() {
  const {kardexList, getListKardex} = useKardex();

  useEffect(()=>{
    getListKardex()
  },[])

  const columnas = [
    {
      id: 'id',
      columnHeaderLabel: 'ID',
      cellRender: item => item.id,
      isSortable: true
    },
    {
      id: 'almacen',
      columnHeaderLabel: 'Almacén',
      cellRender: item => item.almacen_nombre,
      isSortable: true
    },
    {
      id: colFechaCreacion,
      columnHeaderLabel: 'Fecha Hora Creación',
      cellRender: item => new Date(item.created_at),
      columnType: "datetime",
      isSortable: true,
    },
    {
      id: 'tipo_movimiento',
      columnHeaderLabel: 'Tipo',
      cellRender: item => item.tipo_movimiento_display,
      isSortable: true
    },
    {
      id: 'tipo_detalle',
      columnHeaderLabel: 'Motivo',
      cellRender: item => item.tipo_detalle_display,
      isSortable: true
    },
    {
      id: 'producto',
      columnHeaderLabel: 'Producto',
      cellRender: item => item.producto_descripcion,
      isSortable: true
    },
    {
      id: 'presentacion',
      columnHeaderLabel: 'Presentación',
      cellRender: item => item.pp_descripcion,
      isSortable: true
    },
    {
      id: 'unidades_antes',
      columnHeaderLabel: 'Unidad Antes',
      cellRender: item => item.cantidad_unidad_antes,
      isSortable: true
    },
    {
      id: 'unidad_entrada',
      columnHeaderLabel: 'Unidad Entrada',
      cellRender: item => item.cantidad_unidad_entrada,
      isSortable: true
    },
    {
      id: 'unidad_salida',
      columnHeaderLabel: 'Unidad Salida',
      cellRender: item => item.cantidad_unidad_salida,
      isSortable: true
    },
    {
      id: 'unidades_despues',
      columnHeaderLabel: 'Unidad Despues',
      cellRender: item => item.cantidad_unidad_despues,
      isSortable: true
    },
  ]

  return (
    !kardexList
    ? <Loader scale={2} />
    : (
      <div>
        <div className="datatable-container col-12">
          <Datatable
            data={kardexList}
            columns={columnas}
            defaultSortField={colFechaCreacion}
            defaultSortOrder="desc"
          />
        </div>
      </div>
    )
  )
}

export default KardexList
