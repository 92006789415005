import React, { useContext } from "react";
import { useEffect } from "react";
import Loader from "../loader/Loader";
import Datatable from "../datatable/Datatable";
import { VL_NOTA_ESTADO_COMPLETADO } from "./params";
import { NotaContext } from "./context/NotaContext";

const colFechahoraProgramada = "fechahora_programada";

const NotaList = ({ filterNotas }) => {
  const {
    isOpenNotaForm,
    handleToggleNotaForm,
    notas,
    setNotas,
    setNota,
    updateNota,
    deleteNota,
  } = useContext(NotaContext);

  useEffect(() => {
    if (!isOpenNotaForm) {
      filterNotas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenNotaForm]);

  const columns = [
    {
      id: "texto",
      columnHeaderLabel: "Texto",
      cellRender: (item) => {
        return (
          <span
            className="d-inline-block text-truncate"
            style={{ maxWidth: "160px" }}
            data-toggle="tooltip"
            data-placement="right"
            title={item.texto}>
            {item.texto}
          </span>
        );
      },
      isSortable: false,
    },
    {
      id: "tiponota",
      columnHeaderLabel: "Tipo",
      cellRender: (item) => item.tiponota_descripcion,
      isSortable: true,
    },
    {
      id: colFechahoraProgramada,
      columnHeaderLabel: "Programada",
      cellRender: (item) => {
        return item.fechahora_programada.replace("T", " ");
      },
      isSortable: true,
    },
    {
      id: "actions",
      columnHeaderLabel: "Acciones",
      cellRender: (item) => {
        return (
          <div className="d-inline">
            <button
              className="btn btn-xs btn-info btn-icon waves-effect mr-2"
              onClick={() => {
                let newNotas = notas.filter((i) => i.id !== item.id);
                updateNota(item.id, {
                  ...item,
                  estado: VL_NOTA_ESTADO_COMPLETADO,
                });
                setNotas(newNotas);
              }}>
              <i className="fas fa-check-circle"></i>
            </button>
            <button
              className="btn btn-xs btn-warning btn-icon waves-effect mr-2"
              onClick={() => {
                setNota(item);
                handleToggleNotaForm();
              }}>
              <i className="fas fa-pen"></i>
            </button>
            <button
              className="btn btn-xs btn-danger btn-icon waves-effect"
              onClick={() => {
                deleteNota(item.id, notas, setNotas);
              }}>
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        );
      },
      isSortable: false,
    },
  ];

  return !notas ? (
    <Loader scale={2} />
  ) : (
    <div>
      <div className="datatable-contaoner col-12">
        <Datatable
          data={notas}
          columns={columns}
          defaultSortField={colFechahoraProgramada}
          defaultSortOrder="desc"
        />
      </div>
    </div>
  );
};

export default NotaList;
