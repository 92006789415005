import React, { useEffect, useState, useContext, useRef } from "react"
import ReactDOM from 'react-dom';
import { NavigationContext } from '../Navigation'
import Loader from '../loader/Loader'
import { simpleDelete, simpleGet, simplePostData } from "../../functions"
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap"
import Datatable from "../datatable/Datatable";

const EGRESO_TIPO = {
  0: "Otros",
  1: "Compra de articulos de oficina",
  2: "Compra de insumos",
  3: "Pago profesional",
}
const EGRESO_MODO = {
  1: "Efectivo",
  2: "Transferencia"
}


const Egresos = () => { 
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [egresos_list, setEgresosList] = useState(false)
  const searchDate = useRef(false)

  function getEgresosList(_date=false){
    if(!_date) _date = (new Date().toDateInputValue())
    else searchDate.current = _date
    simpleGet(`finanzas/egreso/?date=${_date}`)
    .then(setEgresosList)
  }
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => {
    setIsModalOpen(false)
    getEgresosList()
    searchDate.current = new Date().toDateInputValue()
  }

  return (
    <div>
      <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "10px"}}>
        <button className="btn btn-primary" onClick={openModal}>Crear Egreso</button>
      </div>

      <EgresosList egresos_list={egresos_list} getEgresosList={getEgresosList} searchDate={searchDate} />

      <ModalFormEgresos isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  )
}

const EgresosList = ({egresos_list, getEgresosList, searchDate}) => {

  const deleteEgreso = (egreso_pk) => simpleDelete(`finanzas/egreso/${egreso_pk}/`).then(getEgresosList)

  useEffect(() => {
    searchDate.current = new Date().toDateInputValue()
    getEgresosList()
  }, [])

  const getTipo = (tipo) => EGRESO_TIPO[tipo]

  const getPago = (modo) => EGRESO_MODO[modo]

  const getDate = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

    return formattedDate;
  }

  const columnas = [
    {
      id: 'registrado',
      columnHeaderLabel: 'Registrado',
      cellRender: item => getDate(item.created),
    },
    {
      id: 'tipo',
      columnHeaderLabel: 'Tipo',
      cellRender: item => getTipo(item.tipo),
    },
    {
      id: 'modo',
      columnHeaderLabel: 'Modo de Pago',
      cellRender: item => getPago(item.modo),
    },
    {
      id: 'monto',
      columnHeaderLabel: 'Monto',
      cellRender: item => item.monto,
    },
    {
      id: "view",
      columnHeaderLabel: "Eliminar",
      cellRender: item =>
          <button
            className="btn"
            style={{paddingTop: "0", paddingBottom: "0", fontSize: "15px"}}
            onClick={() =>{
              deleteEgreso(item.pk)
            }}
          >
            <i className="fal fa-trash-alt"></i>
          </button>
    }
  ]

  return (
    !egresos_list
    ? <Loader scale={2} />
    : (
      <div>
        <div className="datatable-container col-12">
          <Datatable data={egresos_list} columns={columnas} isSearching={false}>
            <Input
              type="date"
              placeholder="Buscar..."
              value={searchDate.current}
              onChange={(event) => {
                searchDate.current = event.target.value
                getEgresosList(event.target.value)
              }}
              style={{
                width: '20%',
              }}
            />
          </Datatable>
        </div>
      </div>
    )
  )
}

const ModalFormEgresos = ({isOpen, closeModal}) => {
  const style_ = {
    mb: {marginBottom: "10px"}
  }

  const createNewEgreso = () => {
    const payload = {
      detalle: window.document.getElementById("egreso-new-detalle").value,
      tipo: window.document.getElementById("egreso-new-tipo").value,
      modo: window.document.getElementById("egreso-new-modo").value,
      monto: window.document.getElementById("egreso-new-monto").value,
    }

    simplePostData("finanzas/egreso/", payload)
    .catch(() => alert("Ha ocurrido un error, por favor contacte al administrador"))
    .finally(closeModal)
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <h3>Registrar Egreso</h3>
      </ModalHeader>
      <ModalBody>
        <div>
          <div style={style_.mb}>
            <label className="form-label" htmlFor="egreso-new-detalle">Detalle &nbsp;</label>
            <input className="form-control" id="egreso-new-detalle" type="text"/>
          </div>
          <div style={style_.mb}>
            <label className="form-label" htmlFor="egreso-new-tipo">Tipo &nbsp;</label>
            <select className="custom-select form-control custom-select-lg" id="egreso-new-tipo" defaultValue="1">
              <option value="1">Compra de articulos de oficina</option>
              <option value="2">Compra de insumos</option>
              <option value="3">Pago profesional</option>
              <option value="0">Otros</option>
            </select>
          </div>
          <div style={style_.mb}>
            <label className="form-label" htmlFor="egreso-new-modo">Modo de pago &nbsp;</label>
            <select className="custom-select form-control custom-select-lg" id="egreso-new-modo" defaultValue="1">
              <option value="1">Efectivo</option>
              <option value="2">Transferencia</option>
            </select>
          </div>
          <div style={style_.mb}>
            <label className="form-label" htmlFor="egreso-new-monto">Monto &nbsp;</label>
            <input type="number" className="form-control custom-select-lg" id="egreso-new-monto" min="0" />
          </div>

          <div style={{paddingTop: "10px", display: "flex", gap: "10px"}}>
            <button className="btn btn-primary" onClick={createNewEgreso}>Guardar</button>
            <button className="btn btn-secondary" onClick={closeModal}>Cancelar</button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default Egresos;