import { useState, useEffect } from 'react'
import { handleErrorResponse, simplePostData } from '../../../functions';
import { useWebsocketConnection } from '../context/WebsocketConnection';

export function useWebsocket() {
  const [websocket, setWebsocket] = useState(null);
  const [WSResponse, setWSResponse] = useState([]);
  const {isConnected, setIsConnected} = useWebsocketConnection();

  const updateWSResponse = (data) => {
    setWSResponse([...WSResponse, data]);
  };

  const get_websocket_instance = () => {
    const apiURL = process.env.REACT_APP_PROJECT_API
    const apiURLFormatted = "muelitas.pe";
    const websocketProtocol = apiURL.startsWith('https') ? 'wss' : 'ws';

    const websocket = new WebSocket(`${websocketProtocol}://${apiURLFormatted}/ws/wabachat/websocket/chat/`);

    return websocket;
  }

  useEffect(() => {
    const websocket = get_websocket_instance();

    websocket.onopen = () => {
      console.log('WebSocket connection established.');
      setWebsocket(websocket);
    };

    websocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      updateWSResponse(data);
    };

    websocket.onerror = () => {
      setIsConnected(false);
    };

    websocket.onclose = () => {
      console.log('WebSocket connection closed.');
    };

    return () => {
        if (websocket) websocket.close()
    };
  }, []);

  useEffect(() => {
    if (!isConnected) handleErrorResponse('custom', "Error", "No se pudo conectar con WhatsApp, inténtelo nuevamente o contácte con el administrador", 'danger');

    if (WSResponse.length === 0 ) return

    WSResponse.forEach((data) => {
      if (data.mensaje == null){
        simplePostData("wabachat/message/update/", data, "PUT")
        .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
      }
    }, [WSResponse]);

  }, [WSResponse, isConnected]);


  return {
    websocket,
    WSResponse,
  };
}
