export function getCurrentCenterPaths(tooth_data) {
  // Get the center tooth parts from the given tooth Class
  return [
    tooth_data.tooth_center_tl,  // 1005
    tooth_data.tooth_center_tr,  // 1006
    tooth_data.tooth_center_br,  // 1007
    tooth_data.tooth_center_bl,  // 1008
  ];
}

export function getCenterToothKeys(build_data) {
  // Get premolar and molar keys which have 4 center parts.
  let build_data_values = Object.values(build_data).flat();
  let root_key = "root";

  let center_tooth_keys = build_data_values.reduce((array, item) => {
      if (item.hasOwnProperty(root_key)) array.push(item.key);
      return array;
  }, []);

  return center_tooth_keys;
}

export function getExtraCenterIncPaths(currentToothDataset, inc_paths) {
  // Get extra incidence paths data based on the center tooth parts
  let tooth_data = currentToothDataset.tooth;
  let current_center_paths = getCurrentCenterPaths(tooth_data);

  let included_inc_paths_keys = inc_paths.reduce((array, inc_path) => {
    let {path, key} = inc_path;
    if (current_center_paths.includes(path)) array.push(key);
    return array;
  }, []);
  let center_inc_paths = current_center_paths.reduce((array, path, index) => {
    let key = String(1000+index+5)
    if (!included_inc_paths_keys.includes(key)) {
      let inc_path = { path: path, key: key, tooth: tooth_data };
      array.push(inc_path);
    };
    return array;
  }, []);

  return center_inc_paths;
}
