import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import useModal from '../../hooks/useModal'

const SimpleModal = ({ title="", body }) => {
    const { modal, toggle, modalData } = useModal()
    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader><h1>{modalData?.procedimiento?.procedimiento ?? title}</h1></ModalHeader>
            <ModalBody>
                {body}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default SimpleModal
