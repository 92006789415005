import React, { useContext } from "react"
import { NavigationContext } from "../Navigation"

function CustomCard({children, redirect_to, onClick}) {
  const {redirectTo} = useContext(NavigationContext)

  const handleClick = () => {
    if (redirect_to) redirectTo(redirect_to)
    else onClick()
  }

  return (
    <div className="card text-center d-flex justify-content-center" style={{"width":"200px", "height":"120px", "borderRadius": "10px", "cursor": "pointer", "margin": "0 20px"}} onClick={() => handleClick()}>
        {children}
    </div>
  )
}

export default CustomCard
