import React, { useEffect } from 'react'
import Loader from '../../loader/Loader'
import Datatable from "../../datatable/Datatable";
import { useDetalleCompra } from '../context/DetalleCompraContext';
import { useParams } from 'react-router-dom';
import { useCompra } from '../context/CompraContext';

function DCProductoList({openModal}) {
  const {compra_id}= useParams()
  const {compra} = useCompra()
  const {setDetalleCompra, detalleCompras, getListDetalleCompra, deleteDetalleCompra} = useDetalleCompra()

  const filteredColumns = () => compra.estado === "1" ? columnas : columnas.filter(columna => columna.id !== "acciones")

  useEffect(()=>{
    if(compra_id) getListDetalleCompra(compra_id)
  }, [])

  const columnas = [
    {
      id: 'producto',
      columnHeaderLabel: 'Producto',
      cellRender: item => item.producto_descripcion,
      isSortable: true
    },
    {
      id: 'presentacion',
      columnHeaderLabel: 'Presentación',
      cellRender: item => item.producto_presentacion_descripcion,
      isSortable: true
    },
    {
        id: 'cantidad_presentacion_pedido',
        columnHeaderLabel: 'Cantidad de Presentaciones',
        cellRender: item => item.cantidad_presentacion_pedido,
        isSortable: true
    },
    {
        id: 'cantidad_unidad_pedido',
        columnHeaderLabel: 'Cantidad de Unidades',
        cellRender: item => item.cantidad_unidad_pedido,
        isSortable: true
    },
    {
      id: 'precio_presentacion',
      columnHeaderLabel: 'Precio por Presentación',
      cellRender: item => item.precio_presentacion,
      isSortable: true
    },
    {
      id: 'total_final',
      columnHeaderLabel: 'Total',
      cellRender: item => item.total_final,
      isSortable: true
    },
    {
      id: "acciones",
      columnHeaderLabel: "Acciones",
      cellRender:(item) => {
        return (
          <>
            <button
              className="btn btn-sm btn-warning btn-pills waves-effect mr-2"
              onClick={() => {
                setDetalleCompra(item);
                openModal();
              }}
            >
              Editar
            </button>
            <button
              className="btn btn-sm btn-danger btn-pills waves-effect mr-2"
              onClick={() => {
                deleteDetalleCompra(item.id)
              }}
            >
              <i className="fas fa-trash"></i>
            </button>
          </>
        )
      },
      isSortable: false,
    }
  ]

  return (
    <div className="w-100" style={{display: "inline-block", textAlign: "center"}}>
      {!detalleCompras
      ? <Loader scale={2} />
      : (
        <div className="datatable-container col-12">
          <Datatable data={detalleCompras} columns={filteredColumns()} />
        </div>
      )}
    </div>
  )
}

export default DCProductoList
