import React from 'react';
import Loader from '../../loader/Loader';
import Datatable from '../../datatable/Datatable';
import { useProductPresentation } from '../context/ProductoPresentacionContext';
import { usePresentation } from '../context/PresentacionContext';

const isBaseColumnId = 'isBase';

function ProductoPresentacionList({ openModal, productPresentations }) {
  const { setProductPresentation, changeisBaseProductPresentation } =
    useProductPresentation();
  const { presentations } = usePresentation();

  const columnas = [
    {
      id: 'presentacion',
      columnHeaderLabel: 'Presentación',
      cellRender: (item) => item.presentacion_descripcion,
      isSortable: true,
    },
    {
      id: 'cantidadUnidad',
      columnHeaderLabel: 'Cantidad de Unidades',
      cellRender: (item) => item.cantidad_unidad,
      isSortable: true,
    },
    {
      id: 'precioCompraPresentacion',
      columnHeaderLabel: 'Precio Compra Presentación',
      cellRender: (item) => item.precio_compra_presentacion,
      isSortable: true,
    },
    {
      id: 'precioCompraUnidad',
      columnHeaderLabel: 'Precio Compra Unidad',
      cellRender: (item) => item.precio_compra_unidad,
      isSortable: true,
    },
    {
      id: isBaseColumnId,
      columnHeaderLabel: 'Es Base?',
      cellRender: (item) => {
        return (
          <input
            key={item.id}
            type="radio"
            name="radio-is-base"
            onChange={({ target }) =>
              changeisBaseProductPresentation(item.id, item, target.checked)
            }
            defaultChecked={item.is_base}
          />
        );
      },
      isSortable: false,
    },
    {
      id: 'views',
      columnHeaderLabel: 'Acciones',
      cellRender: (item) => {
        return (
          <>
            <button
              className="btn btn-sm btn-light btn-pills waves-effect mr-2"
              onClick={() => {
                setProductPresentation(item);
                openModal();
              }}
            >
              Editar
            </button>
            {/* TODO: Add deactivate functionality */}
          </>
        );
      },
      isSortable: false,
    },
  ];

  return !productPresentations || !presentations ? (
    <Loader scale={2} />
  ) : (
    <div>
      <div className="datatable-container col-12">
        <Datatable
          data={productPresentations}
          columns={columnas}
          defaultSortField={isBaseColumnId}
        />
      </div>
    </div>
  );
}

export default ProductoPresentacionList;
