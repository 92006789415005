import React from 'react'
import { Spinner } from "reactstrap";


const SimpleSpinner = ({ msg = "" }) => {
    return (
        <div style={simpleSpinnerStyle}>
            <img src="/img/logo_muelitas_image.png" style={{
                width: "58px", height: "58px"
            }}
            />
            <Spinner type='border' role="status" color='primary'>
                {msg}
            </Spinner>
        </div>
    );
}

const simpleSpinnerStyle = {
    "position": "absolute",
    "display": "flex",
    "width": "100%",
    "height": "100%",
    "justifyContent": "center",
    "alignItems": "center",
    "flexDirection": "column"
}

export default SimpleSpinner;
