import React from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';

const DetailView = () => {
  return (
    <Card>
      <CardBody>
        <CardTitle>Detalle del Formulario</CardTitle>
        <CardText>
          <strong>Nombre Principal:</strong> <br />
          <strong>Nombre Secundario:</strong> <br />
          <strong>Apellido Paterno:</strong> <br />
          <strong>Apellido Materno:</strong> <br />
          <strong>DNI:</strong> <br />
          <strong>Fecha de Nacimiento:</strong> <br />
          <strong>Sexo:</strong> <br />
          <strong>Especialidad:</strong> <br />
          <strong>Color:</strong> <br />
          <strong>Código de Colegio:</strong> <br />
          <strong>Personal de Atención:</strong>  ? 'Sí' : 'No'<br />
          <strong>Usuario:</strong> <br />
          <strong>Administrador:</strong>  ? 'Sí' : 'No'<br />
        </CardText>
      </CardBody>
    </Card>
  );
};

export default DetailView;
