import React from "react";
import { PresentationContextProvider } from "./PresentacionContext";
import { ProductContextProvider } from "./ProductoContext";
import { ProductPresentationContextProvider } from "./ProductoPresentacionContext";
import { ProveedorContextProvider } from "./ProveedorContext";
import { CompraContextProvider } from "./CompraContext";
import { DetalleCompraContextProvider } from "./DetalleCompraContext";
import { StockContextProvider } from "./StockContext";
import { KardexContextProvider } from "./KardexContext";

function InventarioProvider({children}){
    return(
      <KardexContextProvider>
        <StockContextProvider>
          <DetalleCompraContextProvider>
            <CompraContextProvider>
              <ProveedorContextProvider>
                <ProductPresentationContextProvider>
                  <PresentationContextProvider>
                      <ProductContextProvider>
                        {children}
                      </ProductContextProvider>
                  </PresentationContextProvider>
                </ProductPresentationContextProvider>
              </ProveedorContextProvider>
            </CompraContextProvider>
          </DetalleCompraContextProvider>
        </StockContextProvider>
      </KardexContextProvider>
    )
}

export default InventarioProvider
