import { handleErrorResponse, simplePostData } from "../../../functions";

const updateUserCustomUser = (user_id, data, setup) => {
  simplePostData(`maestro/user/${user_id}/sucursal/`, data, "PUT")
    .then((data) => setup(data))
    .catch(() =>
      handleErrorResponse("custom", "Error", "Ha ocurrido un error", "danger")
    );
};

export default updateUserCustomUser;
