import React, { useState, useEffect } from "react"
import { PageTitle } from "../../bits";
import CardDetalleCompra from "./CardDetalleCompra"
import DetalleCompraActions from "./DetalleCompraActions"
import ModalTerminarCompra from "./ModalTerminarCompra"
import { useParams } from "react-router-dom";
import { useCompra } from "../context/CompraContext";
import DCProducto from "./DCProducto";

function DetalleCompra() {
  const {compra_id} = useParams()
  const {compra, getCompra} = useCompra()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => {
    setIsModalOpen(false)
  }

  useEffect(()=>{
    if(compra_id) getCompra(compra_id)
  },[compra_id])

  return (
  <>
    <PageTitle title={"Detalle Compra"} />
      <div className="row">
        <div className={`${compra.estado === "1" ? "col-lg-9" : "col-lg-12"}`}>
          <div style={{marginBottom: "25px"}}>
            <CardDetalleCompra/>
          </div>
        </div>
        {compra.estado === "1" && (
        <div className="col-lg-3">
          <div className="panel">
            <DetalleCompraActions openModal={openModal}/>
          </div>
        </div>
        )}
      </div>
      <DCProducto/>
      <ModalTerminarCompra isOpen={isModalOpen} closeModal={closeModal}/>
    </>
  )
}
export default DetalleCompra
