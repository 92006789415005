import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ModalCloseButton from "../../layout/ModalCloseButton";
import DetalleAtencionProductoList from "./DetalleAtencionProductoList";

const DetalleAtencionProductoModal = ({
  cita,
  da,
  isOpenDAPModal,
  handleToggleDAPModal,
}) => {
  ////---- Components ---------------------------------------------------------
  const modalCloseButton = (
    <ModalCloseButton handleClick={handleToggleDAPModal} />
  );

  ////---- Helpers ------------------------------------------------------------
  const composeModalTitle = (_da) => `Productos - ${da.pxs_data.nombre}`;

  ////---- Return -------------------------------------------------------------
  if (!da) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpenDAPModal}
      toggle={handleToggleDAPModal}
      size="lg"
      returnFocusAfterClose={false}
    >
      <ModalHeader
        tag="h2"
        close={modalCloseButton}
        style={{ color: "#666666" }}
      >
        <span className="text-wrap">
          {composeModalTitle(da)}
        </span>
      </ModalHeader>

      <ModalBody>
        <DetalleAtencionProductoList cita={cita} da={da} />
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={handleToggleDAPModal}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DetalleAtencionProductoModal;
