import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import Loader from '../../loader/Loader'
import Datatable from "../../datatable/Datatable";
import { useProduct } from "../context/ProductoContext";

function ProductosList({openModal}) {
  const {setProduct, products, getListProducts, changeIsActiveProduct, changeValidarStockProduct} = useProduct();
  
  useEffect(() => {
    getListProducts();
  }, [])
  
  const columnas = [
    {
      id: 'descripcion',
      columnHeaderLabel: 'Descripción',
      cellRender: item => item.descripcion,
      isSortable: true
    },
    {
      id: 'stockMinimo',
      columnHeaderLabel: 'Stock Mínimo',
      cellRender: item => item.stock_minimo,
      isSortable: true
    },
    {
      id: "switchButton",
      columnHeaderLabel: "Habilitado",
      cellRender: (item) => {
        return(
          <div className="form-check form-switch">
            <input
              key={item.id}
              type="checkbox"
              className="form-check-input"
              onChange={
                ({target}) => {
                  changeIsActiveProduct(item.id, target.checked)
                }
              }
              defaultChecked={item.is_active}
            />
          </div>
        )
      },
      isSortable: true,
    },
    {
      id: "views",
      columnHeaderLabel: "Acciones",
      cellRender:(item) => {
        return (
          <>
            <button
              className="btn btn-sm btn-light btn-pills waves-effect mr-2"
              onClick={() => {
                setProduct(item);
                openModal();
              }}
            >
              Editar
            </button>
            <Link
              to={`/nav/inventario/producto/${item.id}/presentacion/`}
              className="btn btn-sm btn-primary btn-pills waves-effect"
              data-bs-toggle="tooltip"
              title="Asignar Presentaciones"
            >
              <i className="fal fa-pills"></i>
            </Link>
          </>
        );
      },
      isSortable: false,
    }
  ]  

  return (
    !products
    ? <Loader scale={2} />
    : (
      <div>
        <div className="datatable-container col-12">
          <Datatable data={products} columns={columnas} />
        </div>
      </div>
    )
  )
  }

  export default ProductosList
