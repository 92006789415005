import React, { useState, createContext } from 'react'
import { useContext } from 'react';

const WebsocketConnectionContext = createContext();

export const useWebsocketConnection = () => {
  const context = useContext(WebsocketConnectionContext);
  if (!context) {
      throw new Error("useWebsocket must be used within a WebsocketConnectionContext");
    }
  return context;
}

export function WebsocketConnectionProvider({ children }) {
  const [isConnected, setIsConnected] = useState(true);

  return (
    <WebsocketConnectionContext.Provider value={{ isConnected, setIsConnected }}>
      {children}
    </WebsocketConnectionContext.Provider>
  )

}
