import { handleErrorResponse, simpleGet } from "../../../../functions";

const listDetalleAtencionProducto = (setup, queryParams = null) => {
  simpleGet(`inventario/detalleatencionproducto/?${queryParams}`)
    .then(setup)
    .catch(() =>
      handleErrorResponse(
        "custom",
        "Error",
        "Ha ocurrido un error al recuperar los datos",
        "danger"
      )
    );
};

export default listDetalleAtencionProducto;
