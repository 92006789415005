import { handleErrorResponse, simplePostData } from "../../../functions";

const updateNota = (id, nota) => {
  simplePostData(`atencion/nota/${id}/`, nota, "PUT")
    .then(() =>
      handleErrorResponse(
        "custom",
        "Exito",
        "Nota editada exitosamente",
        "success"
      )
    )
    .catch(() =>
      handleErrorResponse(
        "custom",
        "Error",
        "Ha ocurrido un error al editar la nota",
        "danger"
      )
    );
};

export default updateNota;
