import React from 'react'

function ChatInformation({selectedContact}) {
  return (
    <div className="h-30 px-3 py-3 card mb-2">
    {selectedContact &&
      <div className='d-flex flex-wrap justify-content-between' style={{fontWeight: "bold"}}>
        <p className='m-0'>{selectedContact.name}</p>
        <p className='m-0 text-primary'>+{selectedContact.phone} </p>
      </div>}
    </div>
  )
}

export default ChatInformation
