import React, { createContext, useContext, useState } from "react";
import { getDataByPK, handleErrorResponse, simpleGet, simplePostData } from "../../../functions";

export const ProductContext = createContext();

export const useProduct = () => {
  const context = useContext(ProductContext);
  if (!context) {
      throw new Error("useProduct must be used within a ProductContext");
  }
  return context;
};

export const ProductContextProvider = ({ children }) => {
  let defaultProduct = {
      id: "",
      descripcion: "",
      stock_minimo: 0,
    }
  const [product, setProduct] = useState(defaultProduct)
  const [products, setProducts] = useState(false)

  const createProduct = (product, closeModal) => {
    simplePostData("maestro/empresa/producto/", product)
    .then(() => handleErrorResponse('custom', "Exito", "Producto creado exitosamente", 'success'))
    .catch(() => {
      handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger');
    })
    .finally(closeModal)
  }

  const getListProducts = () => {
    simpleGet("maestro/empresa/producto/")
    .then(setProducts)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const getProduct = (id) => {
    getDataByPK("maestro/empresa/producto", id)
    .then(setProduct)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const updateProduct = (id, product, closeModal) => {
    simplePostData(`maestro/empresa/producto/${id}/`, product, "PUT")
    .then(() => handleErrorResponse('custom', "Exito", "Producto editado exitosamente", 'success'))
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
    .finally(closeModal)
  }

  const changeIsActiveProduct = (id, is_active) => {
    simplePostData(`maestro/empresa/producto/${id}/`, {is_active: is_active}, "PATCH")
    .then(() => handleErrorResponse('custom', "Exito", "Producto editado exitosamente", 'success'))
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const changeValidarStockProduct = (id, validar_stock) => {
    simplePostData(`maestro/empresa/producto/${id}/`, {validar_stock: validar_stock}, "PATCH")
    .then(() => handleErrorResponse('custom', "Exito", "Producto editado exitosamente", 'success'))
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  return (
      <ProductContext.Provider
          value={{
              defaultProduct,
              product,
              setProduct,
              products,
              createProduct, 
              getListProducts, 
              getProduct, 
              updateProduct,
              changeIsActiveProduct,
              changeValidarStockProduct,
          }}
      >
          {children}
      </ProductContext.Provider>
  );
};
