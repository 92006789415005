import { handleErrorResponse, simpleGet } from "../../../functions";

const listUserSucursal = (setup) => {
  simpleGet(`maestro/empresa/sucursal/user/`)
    .then((data) => setup(data))
    .catch(() =>
      handleErrorResponse(
        "custom",
        "Error",
        "Ha ocurrido un error al recuperar los datos",
        "danger"
      )
    );
};

export default listUserSucursal;
