import React, { createContext, useContext, useState } from "react";
import { getDataByPK, handleErrorResponse, simpleDelete, simpleGet, simplePostData } from "../../../functions";
import { composeNonFieldErrors } from "../../../utils";

export const DetalleCompraContext = createContext();

export const useDetalleCompra = () => {
  const context = useContext(DetalleCompraContext);
  if (!context) {
      throw new Error("useDetalleCompra must be used within a DetalleCompraContext");
  }
  return context;
};

export const DetalleCompraContextProvider = ({ children }) => {
  let defaultDetalleCompra = {
    id: "",
    compra: "",
    producto: "",
    producto_presentacion: "",
    cantidad_presentacion_pedido: 0,
    precio_presentacion: 0,
    total_final: 0
  }
  const [detalleCompra, setDetalleCompra] = useState(defaultDetalleCompra)
  const [detalleCompras, setDetalleCompras] = useState([])

  const createDetalleCompra = (compra_id, detalle_compra, closeModal) => {
    simplePostData(`inventario/almacen/compra/${compra_id}/detallecompra/`, detalle_compra)
    .then(() => handleErrorResponse('custom', "Exito", "Producto añadido exitosamente", 'success'))
    .catch((response) => {
      response.json().then((data) => {
        const error_message = composeNonFieldErrors(data?.non_field_errors)
        handleErrorResponse('custom', "Error", error_message, 'danger');
      })
    })
    .finally(closeModal)
  }

  const getListDetalleCompra = (compra_id) => {
    simpleGet(`inventario/almacen/compra/${compra_id}/detallecompra/`)
    .then(setDetalleCompras)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const getDetalleCompra = (id) => {
    getDataByPK("inventario/almacen/compra/detallecompra", id)
    .then(setDetalleCompra)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const updateDetalleCompra = (id, detalle_compra, closeModal) => {
    simplePostData(`inventario/almacen/compra/detallecompra/${id}/`, detalle_compra, "PUT")
    .then(() => handleErrorResponse('custom', "Exito", "Producto editado exitosamente", 'success'))
    .catch((response) => {
      response.json().then((data) => {
        const error_message = composeNonFieldErrors(data?.non_field_errors)
        handleErrorResponse('custom', "Error", error_message, 'danger');
      })
    })
    .finally(closeModal)
  }

  const deleteDetalleCompra = (id) => {
    simpleDelete(`inventario/almacen/compra/detallecompra/${id}/`)
    .then(() => setDetalleCompras(detalleCompras.filter((dc) => dc.id !== id)))
    .then(() => handleErrorResponse('custom', "Exito", "Producto eliminado exitosamente", 'success'))
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  return (
    <DetalleCompraContext.Provider
      value={{
        defaultDetalleCompra,
        detalleCompra,
        setDetalleCompra,
        detalleCompras,
        createDetalleCompra, 
        getListDetalleCompra, 
        getDetalleCompra, 
        updateDetalleCompra,
        deleteDetalleCompra
      }}
    >
      {children}
    </DetalleCompraContext.Provider>
  );
};
