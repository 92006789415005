import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import useModal from '../../hooks/useModal'

export const ModalPayment = ({ body }) => {
  const { modal, toggle } = useModal()
  return (
    <Modal isOpen={modal} toggle={toggle} size="lg" className='modal-payment' style={{ maxWidth: "1100px" }}>
      <ModalHeader tag="h2" style={{ color: "#666666" }}>
        Registar pagos libres y de planes de trabajo
      </ModalHeader>  
      <ModalBody className='pt-0'>
        {body}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  )
}
