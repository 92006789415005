import React, { forwardRef, useContext, useEffect, useState } from "react";
import Loader from "../../loader/Loader";
import { NavigationContext } from "../../Navigation";
import listMedioPagos from "../api/listMedioPagos";

const MedioPagoSelect = forwardRef((_, ref) => {
  const { current_sucursal } = useContext(NavigationContext);
  const [medioPagos, setMedioPagos] = useState(null);
  const [medioPago, setMedioPago] = useState(null);

  ////---- Hooks --------------------------------------------------------------
  useEffect(() => {
    let filter = `filtro={"sucursal": "${current_sucursal}"}`;
    listMedioPagos(filter, setMedioPagos);
  }, [current_sucursal]);

  useEffect(() => {
    setMedioPago(() => (medioPagos ? medioPagos[0].pk : ""));
  }, [medioPagos]);

  ////---- Components ---------------------------------------------------------
  const getOptionList = () => {
    let options = null;

    if (!medioPagos) {
      return options;
    }

    options = medioPagos.map((item) => {
      return (
        <option key={item.pk} value={item.pk}>
          {item.descripcion}
        </option>
      );
    });

    return options;
  };

  ////---- Return -------------------------------------------------------------
  return !medioPagos || !medioPago ? (
    <Loader scale={2} />
  ) : (
    <div className="col-sm pb-1">
      <div className="form-group">
        <label className="form-label" htmlFor="id-mediopago">
          Medio Pago:
        </label>
        <select
          className="form-control"
          ref={ref}
          id="id-mediopago"
          value={medioPago}
          onChange={({ target }) => setMedioPago(target.value)}
        >
          {getOptionList() || "Loading..."}
        </select>
      </div>
    </div>
  );
});

export default MedioPagoSelect;
