import React, { useState } from "react";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import { Icon } from "../bits";
import useModal from "../../hooks/useModal";
import RegisterPayment from "./RegisterPayment";
import { ModalPayment } from "./ModalPayment";

export const CardCobranzaActions = ({ patientPk }) => {
  const { toggle, setModalData } = useModal();
  const [modalContent, setModalContent] = useState(null);

  const openModalResisterPayment = (content) => {
    setModalContent(content)
    setModalData({patientPk: patientPk})
    toggle()
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>
            Cobranza
          </CardTitle>
        </CardHeader>
        <CardBody className="fs-xl d-flex align-items-center flex-wrap">
          <div className="px-3 text-center col-4 pb-3">
            <Icon type="finance" onClick={() => openModalResisterPayment(<RegisterPayment />)} />
            <span>Registrar Pago</span>
          </div>
        </CardBody>
        <ModalPayment body={modalContent} />
      </Card>
    </>
  )
}
