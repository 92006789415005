import React from "react";
import { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useParams } from "react-router-dom";
import { useProcedureProduct } from "../context/ProcedimientoProductoContext";
import { useProduct } from "../../inventario/context/ProductoContext";
import { useProductPresentation } from "../../inventario/context/ProductoPresentacionContext";

function ModalFormProcedimientoProducto({ isOpen, closeModal }) {
  const { procedure_id } = useParams();
  const {
    procedureProducts,
    procedureProduct,
    setProcedureProduct,
    createProcedureProduct,
    getProcedureProduct,
    updateProcedureProduct,
  } = useProcedureProduct()
  const { products } = useProduct();
  const { productPresentations, getListProductPresentations } = useProductPresentation();

  const isValidForm = (procedureProduct)  => {
    let isValid = false

    if (!procedureProduct.producto || !procedureProduct.producto_presentacion){
      return isValid
    } 
    isValid = true

    return isValid
  }
  
  const handleSubmit = async () => {
    let is_valid_form = isValidForm(procedureProduct)

    if (!is_valid_form) return

    if (procedureProduct.id) {
      updateProcedureProduct(
        procedureProduct.id,
        procedureProduct,
        closeModal
      );
      return;
    }
    createProcedureProduct(procedure_id, procedureProduct, closeModal);
  };

  const getActiveProducts = (products) => {
    return products.filter((product) => product.is_active === true)
  }

  const getProductOptions = (products) => {
    let target_products = getActiveProducts(products) 
    let options = null

    if(!procedureProducts) return

    if (procedureProduct.id) {
      target_products = target_products.filter((product) => product.id === procedureProduct.producto)
    } else {
      let createdProducts = procedureProducts?.map((item) => item.producto)
      target_products = target_products.filter((product) => !createdProducts.includes(product.id))
    }

    options = target_products.map((product) =>
      <option key={product.id} value={product.id}>{product.descripcion}</option>
    )

    return options
  }

  useEffect(() => {
    if(procedureProduct.producto) getListProductPresentations(procedureProduct.producto)
  }, [procedureProduct.producto])

  useEffect(() => {
    if (procedureProduct.id) getProcedureProduct(procedureProduct.id);
  }, [procedureProduct.id]);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <span className="h3">
          {procedureProduct.id
            ? "Editar Producto"
            : "Añadir Producto"}
        </span>
      </ModalHeader>
      <ModalBody>
        <div>
          {/* productos */}
          <div className="mb-3">
            <label className="form-label" htmlFor="producto">
              Producto:
            </label>
            <select
              className="custom-select form-control"
              id="producto"
              value={procedureProduct.producto}
              onChange={({ target }) =>
                setProcedureProduct({
                  ...procedureProduct,
                  producto: target.value,
                })
              }>
              <option value="" disabled>
                Seleccione un producto...
              </option>
              {products && getProductOptions(products)}
            </select>
          </div>
          {/* producto presentación */}
          <div className="mb-3">
            <label className="form-label" htmlFor="producto_presentacion">
              Presentación:
            </label>
            <select
              className="custom-select form-control"
              id="producto_presentacion"
              value={procedureProduct.producto_presentacion}
              onChange={({ target }) =>
                setProcedureProduct({
                  ...procedureProduct,
                  producto_presentacion: target.value,
                })
              }>
            <option value="" disabled>Seleccione una Presentación...</option>
            {productPresentations && productPresentations.map((pp)=>
              <option key={pp.id} value={pp.id}>{pp.presentacion_descripcion}</option>
            )}
            </select>
          </div>
          {/* cantidad presentacion */}
          <div className="mb-3">
            <label className="form-label" htmlFor="cantidad_presentacion">
              Cantidad de Presentaciones:
            </label>
            <input
              type="number"
              className="form-control"
              id="cantidad_presentacion"
              value={procedureProduct.cantidad_presentacion}
              min="1"
              onChange={({ target }) =>
                setProcedureProduct({
                  ...procedureProduct,
                  cantidad_presentacion: target.value,
                })
              }
            />
          </div>
          <div style={{ paddingTop: "10px", display: "flex", gap: "10px" }}>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Guardar
            </button>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={closeModal}>
              Cancelar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalFormProcedimientoProducto;
