import React, { useMemo, useState, useEffect } from "react";
import { Button, Nav, Navbar, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";

const Sidebar = ({empresas, stateForm, handleStateCreate, handleEmpresa, handleSucursal}) => {

  const [activeEmpresa, setActiveEmpresa] = useState(0);
  const [activeSucursalPk, setActiveSucursalPk] = useState(0);

  const buttonSaveLabel = stateForm === 'create' ? 'Cancelar' : 'Crear Nueva Empresa';

  useEffect(() => {
    if (empresas.length > 0) {
      const empresa = empresas[0];
      setActiveEmpresa(empresa);
      if (empresa.sucursales.length > 0) {
        const sucursal = empresa.sucursales[0];
        setActiveSucursalPk(sucursal.pk);
        handleEmpresa(empresa);
        handleSucursal(sucursal);
        
      }
    }
  }, [empresas])

  const handleSelectItem = (empresaRuc, sucursalPk) => {
    const empresa = empresas.find((empresa) => empresa.ruc === empresaRuc);
    setActiveEmpresa(empresa);
    if (sucursalPk) {
      const sucursal = empresa.sucursales.find((sucursal) => sucursal.pk === sucursalPk);
      setActiveSucursalPk(sucursal.pk);
      handleEmpresa(empresa);
      handleSucursal(sucursal);
    } else{
      setActiveSucursalPk(null);
      handleEmpresa(empresa);
      handleSucursal(null);
    }
  };

  const empresaLinks = useMemo(() => (
    empresas.map((empresa, index) => {
      const sucursalesLength = empresa.sucursales.length;
      return (
      <NavItem key={index} className={classnames({ active: activeEmpresa === empresa })}>
        <div
          className={classnames('nav-title-item' ,{ active: activeEmpresa === empresa })}
          onClick={() => handleSelectItem(empresa.ruc, empresa.sucursales[0] ? empresa.sucursales[0].pk : null)}
        >
          {empresa.razon_social}
        </div>
        { sucursalesLength > 0 ?
            empresa.sucursales.map((sucursal) => (
              <NavLink
                href="#"
                onClick={() => handleSelectItem(empresa.ruc, sucursal.pk)}
                className={classnames({ active: activeSucursalPk === sucursal.pk })}
                key={sucursal.pk}
              >
                <li style={{listStyle: "disc"}}>{sucursal.direccion}</li>
              </NavLink>
            ))
          :
            <NavLink
              href="#"
              key={0}
              disabled
            >
              <p>Sin sucursales</p>
            </NavLink>
        }
      </NavItem>
      )
    })
  ), [empresas, activeSucursalPk, activeEmpresa]);

  return(
    <Nav vertical style={{ padding: '0px', justifyContent: 'start' }}>
      <Button
        color={stateForm === 'create' ? 'danger' : 'primary'}
        onClick={handleStateCreate}
        style={{ marginTop: '20px', marginBottom: '10px', marginLeft: '15px', marginRight: '15px' }}
      >
        {buttonSaveLabel}
      </Button>
      {empresaLinks}
    </Nav>
  );
}

export default Sidebar;