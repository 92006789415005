import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader, FormGroup, Label, Input } from "reactstrap"
import { useProduct } from "../context/ProductoContext";
import { useProductPresentation } from "../context/ProductoPresentacionContext";
import { useDetalleCompra } from "../context/DetalleCompraContext";
import { useParams } from "react-router-dom";
import { useCompra } from "../context/CompraContext";

function ModalFormDCProducto ({isOpen, closeModal}){
  const {compra_id} = useParams();
  const {compra, setCompra} = useCompra();
  const {
    detalleCompras,
    detalleCompra,
    setDetalleCompra,
    createDetalleCompra,
    getListDetalleCompra,
    getDetalleCompra,
    updateDetalleCompra
  } = useDetalleCompra()
  const {products, getListProducts} = useProduct();
  const {productPresentations, getListProductPresentations} = useProductPresentation();

  const isValidDetalleCompra = (detalleCompra) => {
    let isValid = false

    if (!detalleCompra.producto) return isValid
    if (!detalleCompra.producto_presentacion) return isValid
    isValid = true
    
    return isValid
  }

  const handleSubmit = async () => {
    let is_valid_detallecompra = isValidDetalleCompra(detalleCompra)

    if(!is_valid_detallecompra) return
    if (detalleCompra.id){
      updateDetalleCompra(detalleCompra.id, detalleCompra, closeModal); return
    } 
    createDetalleCompra(compra_id, detalleCompra, closeModal);
  }

  const getSubtotal = (cantidad_presentacion_pedido, precio_presentacion) =>
    cantidad_presentacion_pedido * precio_presentacion

  useEffect(()=>{
    getListDetalleCompra(compra_id);
    getListProducts()
  }, [])
  
  useEffect(() => {
    if(detalleCompra.producto) getListProductPresentations(detalleCompra.producto)
  },[detalleCompra.producto])

  useEffect(() => {
    if (detalleCompra.id) getDetalleCompra(detalleCompra.id);
  }, [detalleCompra.id])
  
  useEffect(() => {
    setDetalleCompra({
      ...detalleCompra,
      sub_total: getSubtotal(detalleCompra.cantidad_presentacion_pedido, detalleCompra.precio_presentacion),
      total_final: getSubtotal(detalleCompra.cantidad_presentacion_pedido, detalleCompra.precio_presentacion)
    })
  }, [detalleCompra.cantidad_presentacion_pedido, detalleCompra.precio_presentacion])

  useEffect(() => {
    let total_compra = detalleCompras.reduce((total, detalleCompra) => total + parseFloat(detalleCompra.total_final), 0)
    let total_compra_rounded = total_compra.toFixed(4)
    setCompra({...compra, total_final: total_compra_rounded})
  }, [detalleCompras])

  return(
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <span className="h3">{detalleCompra.id ? "Editar Producto" : "Agregar Producto"}</span>
      </ModalHeader>
      <ModalBody>
        {/* producto  */}
        <FormGroup className="mb-3">
          <Label className="form-label" for="producto">Producto:</Label>
          <Input className="form-control" type="select" name="producto" id="producto" value={detalleCompra.producto} onChange={({target}) => {
            setDetalleCompra({...detalleCompra, producto: target.value})}}>
            <option value="" disabled>Seleccione un producto...</option>
            {products && products
            .filter((product) => product.is_active === true)
            .map((product)=>
              <option key={product.id} value={product.id}>{product.descripcion}</option>
            )}
          </Input>
        </FormGroup>
        {/* presentación */}
        <FormGroup className="mb-3">
        <Label className="form-label" for="presentacion">Presentación:</Label>
          <Input className="form-control" type="select" name="presentacion" id="presentacion" value={detalleCompra.producto_presentacion} onChange={({target}) => {
            setDetalleCompra({...detalleCompra, producto_presentacion: target.value})}}>
            <option value="" disabled>Seleccione una Presentación...</option>
            {productPresentations && productPresentations.map((pp)=>
              <option key={pp.id} value={pp.id}>{pp.presentacion_descripcion}</option>
            )}
          </Input>
        </FormGroup>    
        {/* cantidad presentación pedido */}
        <FormGroup className="mb-3">
            <Label className="form-label" for="cantidad_presentacion_pedido">Cantidad de Presentaciones:</Label>
            <Input type="number" className="form-control" id="cantidad_presentacion_pedido" min="0" value={detalleCompra.cantidad_presentacion_pedido} onChange={({target}) => {
              setDetalleCompra({...detalleCompra, cantidad_presentacion_pedido: target.value})
            }}/>
        </FormGroup>
        {/* precio presentación */}
        <FormGroup className="mb-3">
          <Label className="form-label" for="precio_presentacion">Precio por Presentación:</Label>
          <Input type="number" className="form-control" id="precio_presentacion" min="0" value={detalleCompra.precio_presentacion} onChange={({target}) => {
            setDetalleCompra({...detalleCompra, precio_presentacion: target.value})
          }}/>
        </FormGroup>
        {/* total */}
        <FormGroup className="mb-3">
          <Label className="form-label" for="total">Total</Label>
          <Input type="number" className="form-control" id="total" disabled value={detalleCompra.total_final}/>
        </FormGroup>
        <FormGroup style={{paddingTop: "10px", display: "flex", gap: "10px"}}>
          <button className="btn btn-primary" onClick={handleSubmit}>Guardar</button>
          <button className="btn btn-secondary" type="button" onClick={closeModal}>Cancelar</button>
        </FormGroup>
      </ModalBody>
    </Modal>
  )
}

export default ModalFormDCProducto
