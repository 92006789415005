import React from 'react'
import { useContact } from '../context/ContactContext';
import ContactCard from './ContactCard';
import { useSearch } from '../context/SearchContex';

function ContactList({ handleContactSelected, selectedContact }) {
  const { contacts } = useContact();
  const { searchTerm } = useSearch();

  const filteredContacts = contacts && contacts.filter(contact =>
    contact.name.toLowerCase().includes(searchTerm) ||
    contact.phone.includes(searchTerm)
  );

  return (
    <section className="px-3">
      <div style={{maxHeight: "62vh", overflowY: "auto"}}>
        {filteredContacts && filteredContacts.map((contact) => (
            <ContactCard key={contact.id} contact={contact} handleContactSelected={handleContactSelected} selectedContact={selectedContact} />
          ))
        }
      </div>
    </section>
  )
}

export default ContactList
