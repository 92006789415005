import React, { useEffect, useRef, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ModalComponent from '../../utils/ModalComponent';
import { simplePostData } from '../../../functions';

const EmpresaForm = ({setAlertData, selectedEmpresa = null, setSelectedEmpresa, stateForm, setStateForm, getEmpresas}) => {

  const [showExtendedFields, setShowExtendedFields] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const razonSocialRef = useRef();
  const rucRef = useRef();
  const userSolRef = useRef();
  const passSolRef = useRef();
  const passCertRef = useRef();
  const certificadoRef = useRef();
  const logoRef = useRef();
  const feActivoRef = useRef();
  const historiaClinicaRef = useRef();
  const wabaIdRef = useRef();
  const wabaTokenRef = useRef();
  const wabaAccounIdRef = useRef();

  useEffect( () => {
    razonSocialRef.current.value = selectedEmpresa?.razon_social || null;
    rucRef.current.value = selectedEmpresa?.ruc || null;
    userSolRef.current.value = selectedEmpresa?.user_sol || null;
    passSolRef.current.value = selectedEmpresa?.pass_sol || null;
    passCertRef.current.value = selectedEmpresa?.pass_cert || null;
    certificadoRef.current.src = selectedEmpresa?.certificado || null;
    logoRef.current.src = selectedEmpresa?.logo || null;
    feActivoRef.current.checked = selectedEmpresa?.fe_activo || false;
    historiaClinicaRef.current.src = selectedEmpresa?.historia_clinica || null;
    wabaIdRef.current.value = selectedEmpresa?.waba_id || null;
    wabaTokenRef.current.value = selectedEmpresa?.waba_token || null;
    wabaAccounIdRef.current.value = selectedEmpresa?.waba_account_id || null;
  }, [selectedEmpresa])

  const toggleShowExtendedFields = (id) => {
    if (showExtendedFields === id) {
      setShowExtendedFields('');
    } else {
      setShowExtendedFields(id);
    }
  };

  const getDatatoSubmit = () => {
    return {
      razon_social: razonSocialRef.current?.value || null,
      ruc: rucRef.current?.value || null,
      user_sol: userSolRef.current?.value || null,
      pass_sol: passSolRef.current?.value || null,
      pass_cert: passCertRef.current?.value || null,
      certificado: certificadoRef.current?.value || null,
      logo: logoRef.current?.value || null,
      fe_activo: feActivoRef.current?.checked || false,
      historia_clinica: historiaClinicaRef.current?.value || null,
      firma_notificacion: rucRef.current?.value ? rucRef.current?.value.toUpperCase() : "",
      waba_id: wabaIdRef.current?.value || null,
      waba_token: wabaTokenRef.current?.value || null,
      waba_account_id: wabaAccounIdRef.current?.value || null
    }
  }

  const showAlert = (response, httpMethod) => {
    if(response){
        setAlertData({
          color: 'success',
          message: httpMethod === 'POST' ? 'Empresa creada correctamente' : 'Empresa actualizada correctamente'
        })
        setSelectedEmpresa(response);
        getEmpresas();
    } else {
      setAlertData({
        color: 'danger',
        message: httpMethod === 'POST' ? 'Error al crear la empresa' : 'Error al actualizar la empresa'
      })
      setSelectedEmpresa(selectedEmpresa);
    }
  }

  const handleCreateEmpresa = (event) => {
    if(event) event.preventDefault();

    const formData = getDatatoSubmit();

    simplePostData('maestro/empresa/', formData)
      .then(data => {
        showAlert(data, 'POST');
      })
      .catch(error => {
        showAlert(null, 'POST');
      })
  }

  const handleUpdateEmpresa = (event) => {
    if(event) event.preventDefault();

    const formData = getDatatoSubmit();

    simplePostData(`maestro/empresa/${selectedEmpresa.pk}/`, formData, 'PUT')
      .then(data => {
        showAlert(data, 'PUT');
        setStateForm('view');
      })
      .catch(error => {
        showAlert(null, 'PUT');
      })
  }

  const handleFormEdit = () => {
    if(stateForm == 'view'){
      setStateForm('edit');
    }else{
      handleUpdateEmpresa();
    }
  }

  return (
    <Container className='pl-0 mt-3' >
      <Row>
        <Col >
          <h4>Empresa {selectedEmpresa?.razon_social}</h4>
        </Col>
        <Col style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        { stateForm !== 'create' &&
          <Button
            color={stateForm === 'view' ? 'warning' : 'success'}
            className='mr-2'
            onClick={() => handleFormEdit()}
          >
            {stateForm === 'view' ? 'Editar' : 'Guardar'}
          </Button>
        }
          <Button
            className='mr-2'
            color='danger'
            onClick={toggleModal}
          >
            Eliminar
          </Button>
          <ModalComponent onSubmit={() => alert('Método no disponible')} isOpen={isModalOpen} toggle={toggleModal} title="Confirmación" body="¿Desea eliminar la empresa?" />
        </Col>
      </Row>
      <Form className='pl-2'>
        {/* Required fields */}
        <Row>
          <Col md={6} lg={6}>
            <FormGroup>
              <Label for="razon_social">Razón Social*</Label>
              <Input
                id="razon_social"
                name="razon_social"
                type="text"
                required
                innerRef={razonSocialRef}
                disabled={stateForm == "view" ? true : false}
              />
            </FormGroup>
          </Col>
          <Col md={6} lg={6}>
            <FormGroup>
              <Label for="ruc">RUC*</Label>
              <Input
                id="ruc"
                name="ruc"
                type="text"
                required
                innerRef={rucRef}
                disabled={stateForm == 'view'}
              />
            </FormGroup>
          </Col>
        </Row>
        {/* Optional fields */}
        <Accordion open={showExtendedFields} toggle={toggleShowExtendedFields}>
          <AccordionItem>
            <AccordionHeader targetId='1'>
              Mostrar campos adicionales
            </AccordionHeader>
            <AccordionBody accordionId='1'>
            {/* SUNAT fields */}
              <Row>
                <Col md={6} lg={6}>
                  <FormGroup>
                    <Label for="user_sol">Usuario SOL</Label>
                    <Input
                      id="user_sol"
                      name="user_sol"
                      type="text"
                      innerRef={userSolRef}
                      disabled={stateForm == 'view'}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} lg={6}>
                  <FormGroup>
                    <Label for="pass_sol">Clave SOL</Label>
                    <Input
                      id="pass_sol"
                      name="pass_sol"
                      type="text"
                      innerRef={passSolRef}
                      disabled={stateForm == 'view'}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* Llama.pe fields */}
              <Row>
                <Col md={6} lg={6}>
                  <FormGroup>
                    <Label for="pass_cert">Contraseña CertificadoRef Llama.pe</Label>
                    <Input
                      id="pass_cert"
                      name="pass_cert"
                      type="text"
                      innerRef={passCertRef}
                      disabled={stateForm == 'view'}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} lg={6}>
                  <FormGroup>
                    <Label for="certificadoRef">CertificadoRef Llama.pe</Label>
                    <Input
                      id="certificadoRef"
                      name="certificadoRef"
                      type="file"
                      innerRef={certificadoRef}
                      disabled={stateForm == 'view'}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='align-items-center'>
                <Col md={6} lg={6}>
                  <FormGroup>
                    <Label for="logoRef">Logo</Label>
                    <Input
                      id="logoRef"
                      name="logoRef"
                      type="file"
                      innerRef={logoRef}
                      disabled={stateForm == 'view'}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} lg={6}>
                  <FormGroup check>
                    <Input
                      id="fe_activoRef"
                      name="fe_activoRef"
                      type="checkbox"
                      innerRef={feActivoRef}
                      disabled={stateForm == 'view'}
                    />
                    <Label check>
                      ¿Facturación Electrónica?
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              {/* Other fields */}
              <Row>
                <Col md={6} lg={6}>
                  <FormGroup>
                    <Label for="historiaclinicaRef">Historia Clínica</Label>
                    <Input
                      id="historiaclinicaRef"
                      name="historiaclinicaRef"
                      type="file"
                      innerRef={historiaClinicaRef}
                      disabled={stateForm == 'view'}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} lg={6}>
                  <FormGroup>
                    <Label for="firma_notificacion">Notificación firma</Label>
                    <Input
                      id="firma_notificacion"
                      name="firma_notificacion"
                      type="text"
                      disabled={stateForm == 'view'}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
              <Col md={6} lg={6}>
                  <FormGroup>
                    <Label for="waba_id">WABA ID</Label>
                    <Input
                      id="waba_id"
                      name="waba_id"
                      type="text"
                      innerRef={wabaIdRef}
                      disabled={stateForm == 'view'}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} lg={6}>
                  <FormGroup>
                    <Label for="waba_token">WABA Token</Label>
                    <Input
                      id="waba_token"
                      name="waba_token"
                      type="text"
                      innerRef={wabaTokenRef}
                      disabled={stateForm == 'view'}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
              <Col md={6} lg={6}>
                  <FormGroup>
                    <Label for="waba_account_id">WABA Account ID</Label>
                    <Input
                      id="waba_account_id"
                      name="waba_account_id"
                      type="text"
                      innerRef={wabaAccounIdRef}
                      disabled={stateForm == 'view'}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </AccordionBody>
          </AccordionItem>
        </Accordion>
        {stateForm === 'create' && (
          <FormGroup className='justify-content-end mt-2'>
            <Button
              type='submit'
              color='primary'
              onClick={handleCreateEmpresa}
            >
              Guardar
            </Button>
          </FormGroup>
        )}
      </Form>
    </Container>
  )
}

export default EmpresaForm;
