import React, { createContext, useContext, useState } from "react";
import { getDataByPK, handleErrorResponse, simpleDelete, simpleGet, simplePostData } from "../../../functions";
import { composeNonFieldErrors } from "../../../utils";

export const ProcedureProductContext = createContext();

export const useProcedureProduct = () => {
  const context = useContext(ProcedureProductContext);
  if (!context) {
    throw new Error("useProcedureProduct must be used within a ProcedureProductContext");
  }
  return context;
};

export const ProcedureProductContextProvider = ({ children }) => {
  let defaultProcedureProduct = {
    id: "",
    procedimiento: "",
    producto: "",
    producto_presentacion: "",
    cantidad_presentacion: 1,
  }
  const [procedureProduct, setProcedureProduct] = useState(defaultProcedureProduct)
  const [procedureProducts, setProcedureProducts] = useState(false)

  const createProcedureProduct = (procedure_id, procedure_product, closeModal) => {
    simplePostData(`maestro/procedimiento/${procedure_id}/producto/`, procedure_product)
    .then(() => handleErrorResponse('custom', "Exito", "Producto añadido exitosamente", 'success'))
    .catch((response) => {
      response.json().then((data) => {
        const error_message = composeNonFieldErrors(data?.non_field_errors)
        handleErrorResponse('custom', "Error", error_message, 'danger');
      })
    })
    .finally(closeModal)
  }

  const getListProcedureProducts = (procedure_id) => {
    simpleGet(`maestro/procedimiento/${procedure_id}/producto/`)
    .then(setProcedureProducts)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const getProcedureProduct = (id) => {
    getDataByPK("maestro/procedimiento/producto", id)
    .then(setProcedureProduct)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const updateProcedureProduct = (id, procedure_product, closeModal) => {
    simplePostData(`maestro/procedimiento/producto/${id}/`, procedure_product, "PUT")
    .then(() => handleErrorResponse('custom', "Exito", "Producto editado exitosamente", 'success'))
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
    .finally(closeModal)
  }

  const deleteProcedureProduct = (id) => {
    simpleDelete(`maestro/procedimiento/producto/${id}/`)
    .then(() => setProcedureProducts(procedureProducts.filter((pp) => pp.id !== id)))
    .then(() => handleErrorResponse('custom', "Exito", "Producto eliminado exitosamente", 'success'))
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const changeValidarStockProcedureProduct = (id, procedure_product, validar_stock) => {
    simplePostData(`maestro/procedimiento/producto/${id}/`, {...procedure_product, validar_stock: validar_stock}, "PUT")
    .then(() => handleErrorResponse('custom', "Exito", "Producto editado exitosamente", 'success'))
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  return (
    <ProcedureProductContext.Provider
      value={{
        defaultProcedureProduct,
        procedureProduct,
        setProcedureProduct,
        procedureProducts,
        createProcedureProduct, 
        getListProcedureProducts, 
        getProcedureProduct, 
        updateProcedureProduct,
        deleteProcedureProduct,
        changeValidarStockProcedureProduct,
      }}
    >
      {children}
    </ProcedureProductContext.Provider>
  );
};
