import React from "react";

const ModalCloseButton = ({ handleClick }) => {
  return (
    <button className="close" onClick={handleClick} type="button">
      <i className="fal fa-times"></i>
    </button>
  );
};

export default ModalCloseButton;
