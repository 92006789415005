import React, { createContext, useContext, useState } from "react";
import { handleErrorResponse, simpleGet } from "../../../functions";

export const PresentationContext = createContext();

export const usePresentation = () => {
  const context = useContext(PresentationContext);
  if (!context) {
    throw new Error("usePresentation must be used within a PresentationContext");
  }
  return context;
};

export const PresentationContextProvider = ({ children }) => {

  const [presentations, setPresentations] = useState(false)

  const getListPresentations = () => {
    simpleGet("maestro/presentacion/")
    .then(setPresentations)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  return (
    <PresentationContext.Provider
      value={{
        presentations,
        getListPresentations, 
      }}
    >
      {children}
    </PresentationContext.Provider>
  );
};
