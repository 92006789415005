import { VL_NOTA_IU_DIA, VL_NOTA_IU_MES, VL_NOTA_IU_SEMANA } from "./params"

export const zeroPadStart = (num, length) => String(num).padStart(length, "0")

export const formatDatetimeInputValue = (dt) => {
  let year = dt.getFullYear()
  let month = dt.getMonth() + 1
  let day = dt.getDate()
  let hour = dt.getHours()
  let minute = dt.getMinutes()

  month = zeroPadStart(month, 2)
  day = zeroPadStart(day, 2)
  hour = zeroPadStart(hour, 2)
  minute = zeroPadStart(minute, 2)

  return `${year}-${month}-${day}T${hour}:${minute}`
}

export const calculateNow = (cantidad, unidad) => {
  let intervalo = 0
  let now = null

  if (!cantidad || !unidad) {
    return now
  }
  
  now = new Date()
  cantidad = parseInt(cantidad)
  
  if (unidad === VL_NOTA_IU_DIA) {
    intervalo = now.getDate() + cantidad
    now.setDate(intervalo)
  } else if (unidad === VL_NOTA_IU_SEMANA) {
    intervalo = now.getDate() + (cantidad * 7)
    now.setDate(intervalo)
  } else if (unidad === VL_NOTA_IU_MES) {
    intervalo = now.getMonth() + cantidad
    now.setMonth(intervalo)
  }

  return now
}
