import React, { useState, useMemo, useEffect } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import './VerticalTabNavigation.css'
import { useMediaQuery } from 'react-responsive';
import SucursalesReport from "../superusernavigation/sucursales/SucursalesReport";
import { Link, Redirect, Route, Switch, useHistory, withRouter} from "react-router-dom";

const tabs = [
  {
    title: "Reportes",
    route: "/navsuperuser/sucursalreports/",
  },
  {
    title: "Title 2",
    route: "/navsuperuser/hola1/",
  },
  {
    title: "Title 3",
    route: "/navsuperuser/hola2/",
  },
];

const Routes = () => {
  return (
    <Switch>
      <Route
        exact path='/navsuperuser/' 
        component={() => <h1>Dashboard</h1>}
      />
      <Route path='/navsuperuser/sucursalreports/' component={SucursalesReport} />
      <Route path='/navsuperuser/hola1/' component={() => <h1>Hola 1</h1>} />
      <Route path='/navsuperuser/hola2/' component={() => <h1>Hola 2</h1>} />
      <Route path='*' component={() => <h1>Página no encontrada</h1>} />
    </Switch>
  )
}

const VerticalTabNavigation = () => {
  const [activeTab, setActiveTab] = useState(0);
  const history = useHistory();
  const location = history.location

  useEffect(() => {
    const savedActiveTab = JSON.parse(localStorage.getItem("activeTab"));
    if (savedActiveTab !== null) {
      setActiveTab(savedActiveTab);
    }
  }, []);

  const changeActiveTab = (newIndex) => {
    localStorage.setItem("activeTab", JSON.stringify(newIndex));
    setActiveTab(newIndex)
  }

  useEffect(() => {
    const activeTab = tabs.findIndex(tab => tab.route === location.pathname)
    changeActiveTab(activeTab)
  }, [location]);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });

  const handleSelect = (event) => {
    const tab = tabs[event.target.value]
    history.push(tab.route)
  }

  const navItems = useMemo(() => {
    return tabs.map((tab, index) => (
      <NavItem key={index}>
        <NavLink
          className={classnames({ active: activeTab === index })}
          style={{
            fontSize: "1rem",
          }}
          tag={Link}
          to={tab.route}
        >
          {tab.title}
        </NavLink>
      </NavItem>
    ));
  }, [activeTab, tabs]);

  const selectOptions = useMemo(() => {
    return tabs.map((tab, index) => (
      <option value={index} key={index}>{tab.title}</option>
    ));
  }, [tabs]);

  return (
    <Row style={{
      height: '100%',
    }}>
      {isMobile ? (
        <Col xs="12" sm="12" md="12" className="mobile-select" >
          <select onChange={handleSelect} value={activeTab}>
            {selectOptions}
          </select>
        </Col>
      ) : (
        <Col xs="6" sm="4" md="2" className="vertical-menu" >
          <Nav tabs vertical >
            {navItems}
          </Nav>
        </Col>
      )}
      <Col xs="12" sm="8" md="10" className="content" style={{ padding: "10px 0px 0px 10px" }}>
        <Routes />
      </Col>
    </Row>
  );
}


export default withRouter(VerticalTabNavigation);
