import React from 'react'

function HeaderAside() {
  return (
    <section className="h-30 card py-2 px-3">
      <div className='d-flex flex-wrap justify-content-between align-items-center'>
          <img src="/img/logo_muelitas_image.png" alt="icon" width={"36x"} height={"36px"}/>
          <img src="/img/logo_whatsapp.png" alt="icon" width={"36px"} height={"36px"}/>
      </div>
    </section>
  )
}

export default HeaderAside
