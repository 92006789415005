import React, { useState, useEffect } from 'react';
import { useContact } from '../context/ContactContext';
import { useMessage } from '../context/MessageContext';
import { useWebsocket } from '../hooks/useWebsocket';
import ContactList from '../components/ContactList';
import ChatMessageList from '../components/ChatMessageList';
import ChatInformation from '../components/ChatInformation';
import ChatSendForm from '../components/ChatSendForm';
import ChatDefaultContent from '../components/ChatDefaultContent';
import HeaderAside from '../components/HeaderAside';
import SearchInput from '../components/SearchInput';

function Chat() {
    const { WSResponse } = useWebsocket();
    const { getListContacts } = useContact();
    const [selectedContact, setSelectedContact] = useState(null);
    const { getListMessages} = useMessage();

    const handleContactSelected = (contact) => setSelectedContact(contact);

    useEffect(() => {
        getListContacts();
    }, [WSResponse]);

    useEffect(() => {
        if (selectedContact) getListMessages(selectedContact.id)
    }, [selectedContact,  WSResponse]);

    return (
        <>
            {/* // <!-- Alert --> */}
            <div id="alert-permission" className="alert bg-primary-200 text-white fade" role="alert" style={{display:'none'}}>
                <strong>Ups!</strong> Parece que no posees permisos para realizar esta acción.
            </div>
            <div id="alert-custom" className="alert bg-warning-700 fade" role="alert" style={{display: "none"}}>
                <strong id="alert-custom-headline">Error!</strong> <span id="alert-custom-text">Algo salió mal</span>.
            </div>

            {/* <!-- Main Content --> */}
            <div className="container-fluid">
                <div className="row" style={{minHeight: "80vh"}}>
                    {/* <!-- Aside --> */}
                    <aside className="col-md-3 bg-light p-0 card">
                        <HeaderAside/>
                        <SearchInput/>
                        <ContactList handleContactSelected={handleContactSelected} selectedContact={selectedContact}/>
                    </aside>
                    {/* Chat */}
                    <section className="col-md-9 p-0 bg-light card">
                        {selectedContact ?
                        <>
                            <ChatInformation selectedContact={selectedContact}/>
                            <ChatMessageList selectedContact={selectedContact}/>
                            <ChatSendForm selectedContact={selectedContact}/>
                        </>
                        :<ChatDefaultContent/>
                        }
                    </section>
                </div>
            </div>
        </>
    );
}

export default Chat;
