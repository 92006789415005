import React, { useState, useMemo } from 'react';
import { Table, Pagination, PaginationItem, PaginationLink, Input, FormGroup, Label } from 'reactstrap';
import HeaderColumnDatatable from './HeaderColumnDatatable';
import { format, compareAsc, compareDesc } from 'date-fns'
import { convertToComparableString } from './utils';

/*
  Structure of columns prop:
  const columns1 = [
  {
      id: 'dni',
      columnHeaderLabel: 'DNI',
      cellRender: item => item.dni,
      isSortable: true,
    },
    {
      id: 'view',
      columnHeaderLabel: 'Acciones',
      cellRender: item => (
        <Button
            style={{
              width: '100%',
            }}
            className='btn btn-light btn-pills waves-effect'
            color="primary"
            size="sm"
            onClick={() => {
              redirectTo(`/nav/admision/${item.pk}/detalle`)
            }}>
              Ver
          </Button>
      ),
      isSortable:false,
    }
] */

const itemsPerPageDefaultOptions = [10, 15, 50];

const infoTextToShowNoData = 'No hay datos para mostrar';

const Datatable = ({
  data,
  columns,
  children,
  isSearching=true,
  isItemsPerPage=true,
  itemsPerPageOptions = itemsPerPageDefaultOptions,
  emptyMessage = infoTextToShowNoData,
  defaultSortField = null,
  defaultSortOrder = null
}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [sortField, setSortField] = useState(defaultSortField ? defaultSortField : columns[0].id);
  const [searchTerm, setSearchTerm] = useState('');

  const columnToSort = columns.find(column => column.id === sortField);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder || columnToSort.columnType === "date" ? "desc" : "asc");

  const handleSortClick = (id) => {
    if (id === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(id);
      /* setSortOrder('asc'); */
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  }

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  let sortedData = [...data];
  if(columnToSort){
    if(["date", "datetime"].includes(columnToSort.columnType)){
      sortedData = sortedData.sort((a, b) => {
        const callback = sortOrder === 'asc' ? compareAsc: compareDesc;
        return callback(columnToSort.cellRender(a), columnToSort.cellRender(b));
      });
    }else{
      sortedData = sortedData.slice().sort((a,b) => {
        const aRender = columnToSort.cellRender(a);
        const bRender = columnToSort.cellRender(b);
        const aComparable = convertToComparableString(aRender);
        const bComparable = convertToComparableString(bRender);

        if (aComparable && bComparable) {
          const aIsNumber = typeof aComparable === 'number';
          const bIsNumber = typeof bComparable === 'number';

          if (sortOrder === 'asc') {
            return aIsNumber && bIsNumber
              ? aComparable - bComparable
              : aComparable.localeCompare(bComparable);
          } else {
            return aIsNumber && bIsNumber
              ? bComparable - aComparable
              : bComparable.localeCompare(aComparable);
          }
        }
      })
    }
  }
  sortedData = sortedData.filter((item) => {
    const searchTermLowerCase = searchTerm.toLowerCase().trim();
    if (!searchTermLowerCase) {
      return true;
    }
    const itemValues = Object.values(item);
    const isMatch = itemValues.some((value) => {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(searchTermLowerCase);
      }
      if (typeof value === 'number') {
        return value.toString().includes(searchTermLowerCase);
      }
      return false;
    });
    return isMatch;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  const getPageNumbers = useMemo(() => {
    let pageNumbers = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage <= 3) {
      pageNumbers = [1, 2, 3, 4, 5, '...', totalPages];
    } else if (currentPage >= totalPages - 2) {
      pageNumbers = [1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    } else {
      pageNumbers = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
    }
    return pageNumbers;
  }, [currentPage, totalPages]);

  /* Memo functions */

  const OptionsItemsPerPage = useMemo(() => {
    return itemsPerPageOptions.map((option) => {
      return (
        <option key={option} value={option}>
          {option}
        </option>
      );
    });
  }, [itemsPerPageOptions])

  const ColumnHeadersDatatable = useMemo(() => {
    return (
      columns && columns.map((column) => {
        return (
          <HeaderColumnDatatable
            column={column}
            handleSortClick={handleSortClick}
            sortOrder={sortOrder}
            key={column.id}
            sortField={sortField}
          />
        );
      })
    )
  })

  const RowsDatatable = useMemo(() => {
    return currentItems.map((item, index) => (
      <tr key={index}>
        {columns && columns.map((column, index) => {
          let content = column.cellRender(item);
          if(column.columnType === "date"){
            content = format(content, "dd-MM-yyyy")
          }else if(column.columnType === "datetime"){
            content = format(content, "dd-MM-yyyy p")
          }
          return (
            <td key={index}>
              {content}
            </td>
          )
        })
        }
      </tr>
    ))
  }, [currentItems])

  return (
    <div>
    {/* Inputs and Filters for data */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {
          isSearching ? (
            <Input
              type="text"
              placeholder="Buscar..."
              value={searchTerm}
              onChange={handleSearch}
              style={{
                width: '70%',
              }}
              autoFocus
            />
          ) : (
            children
          )
        }
        {
          isItemsPerPage && (
            <FormGroup
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Label for='itemsPerPage'>
                Mostrar por página:
              </Label>
                <Input
                  name="itemsPerPage"
                  type="select"
                  className='form-control'
                  value={itemsPerPage}
                  onChange={(event) => setItemsPerPage(event.target.value)}
                  style={{
                    width: '60%',
                  }}
                >
                  {
                    OptionsItemsPerPage
                  }
                </Input>
            </FormGroup>
          )
        }
      </div>
      {/* Table */}
      <Table className='table-sm table-borderless ' responsive>
      {/* Columns header */}
        <thead>
          <tr>
            {ColumnHeadersDatatable}
          </tr>
        </thead>
        <tbody>
        {
          data.length > 0 ? (
            RowsDatatable
          ) : (
            <tr>
              <td style={{
                textAlign: 'center',
                fontSize: '1.8rem',
                color: '#6c757d',
              }} colSpan="12">
                {emptyMessage}
              </td>
            </tr>
          )
        }
        </tbody>
      </Table>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}>
        {
          totalPages > 1 && (
            <Pagination aria-label="Page navigation example" size='sm'>
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink onClick={() => handlePageClick(currentPage - 1)}>
                  Anterior
                </PaginationLink>
              </PaginationItem>
              {getPageNumbers.map((number, index) => (
                <PaginationItem key={index} active={currentPage === number} disabled={number==='...'}>
                  <PaginationLink onClick={() => handlePageClick(number)}>
                    {number}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={(currentPage === totalPages)}>
                <PaginationLink onClick={() => handlePageClick(currentPage + 1)}>
                  Siguiente
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          )
        }
        <div style={{
        }}>
          Se estan mostrando {currentItems.length} de {data.length} registros
        </div>
      </div>
    </div>
  );
};

export default Datatable;
