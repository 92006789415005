import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useProduct } from '../context/ProductoContext';
import { PageTitle } from '../../bits';
import ProductoPresentacionList from './ProductoPresentacionList';
import ModalFormProductoPresentacion from './ModalFormProductoPresentacion';
import ProductoPresentacionActions from './ProductoPresentacionActions';
import { useProductPresentation } from '../context/ProductoPresentacionContext';
import { usePresentation } from '../context/PresentacionContext';

function ProductoPresentacion() {
  const { product_id } = useParams();
  const { product, getProduct } = useProduct();
  const {
    defaultProductPresentation,
    setProductPresentation,
    productPresentations,
    getListProductPresentations,
  } = useProductPresentation();
  const { getListPresentations } = usePresentation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  ////---- Helpers ------------------------------------------------------------
  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {
    setIsModalOpen(false);
    getListProductPresentations(product_id);
    setProductPresentation(defaultProductPresentation);
  };

  const shouldCreateBasePP = (_productPresentations) => {
    return (
      Array.isArray(_productPresentations) && _productPresentations.length === 0
    );
  };

  ////---- Hooks --------------------------------------------------------------
  useEffect(() => {
    if (product_id) getProduct(product_id);
  }, [product_id]);

  useEffect(() => {
    if (product_id) getListProductPresentations(product_id);
  }, [product_id]);

  useEffect(() => {
    getListPresentations();
  }, []);

  ////---- Return -------------------------------------------------------------
  return (
    <>
      <PageTitle title={`${product.descripcion} - Presentaciones`} />
      <div className="row">
        <div className="col-lg-9">
          <div style={{ marginBottom: '25px' }}>
            <ProductoPresentacionList
              openModal={openModal}
              productPresentations={productPresentations}
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="panel">
            <ProductoPresentacionActions openModal={openModal} />
          </div>
        </div>
        <ModalFormProductoPresentacion
          isOpen={isModalOpen}
          closeModal={closeModal}
          shouldCreateBasePP={shouldCreateBasePP(productPresentations)}
        />
      </div>
    </>
  );
}

export default ProductoPresentacion;
