import React, { useContext, useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TipoNotasSelect from "./TipoNotasSelect";
import { calculateNow, formatDatetimeInputValue } from "./utils";
import NotaEstadoSelect from "./NotaEstadoSelect";
import NotaIntervaloUnidadSelect from "./NotaIntervaloUnidadSelect";
import { NotaContext } from "./context/NotaContext";
import CloseBtn from "./components/CloseBtn";

/**
 * @notes
 * - `initialTipoNotaKey`: It gets the first value of TipoNotas without
 *    considering the initial value of TipoNotasSelect.
 */
const NotaForm = () => {
  const {
    current_sucursal,
    isOpenNotaForm,
    handleToggleNotaForm,
    nota,
    setNota,
    getNota,
    createNota,
    updateNota,
    tipoNotas,
  } = useContext(NotaContext);
  const selectIntervaloUnidadRef = useRef(null);
  const inputIntervaloCantidadRef = useRef(null);

  ////---- variables ----------------------------------------------------------
  let now = new Date();
  let minFechaHoraProgramada = formatDatetimeInputValue(now);
  let defaultFechaHoraProgramada = formatDatetimeInputValue(now);
  let initialTipoNotaKey = tipoNotas && tipoNotas[0].id;

  ////---- Hooks --------------------------------------------------------------
  useEffect(() => {
    let ignore = false;

    if (!ignore && nota.id) {
      getNota(nota.id, setNota);
    }

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nota.id]);

  ////---- Components ---------------------------------------------------------
  const closeBtn = <CloseBtn onClick={handleToggleNotaForm} />;

  ////---- Handlers -----------------------------------------------------------
  const fixForm = (nota) => {
    let tiponota = nota.tiponota || initialTipoNotaKey;
    let sucursal = nota.sucursal || current_sucursal;
    let paciente = nota.value || null;
    let intervalo_unidad = nota.intervalo_unidad || null;
    let fechahora_programada =
      nota.fechahora_programada || defaultFechaHoraProgramada;
    let intervalo_cantidad = nota.intervalo_cantidad || null;

    return {
      ...nota,
      tiponota: tiponota,
      sucursal: sucursal,
      paciente: paciente,
      intervalo_unidad: intervalo_unidad,
      fechahora_programada: fechahora_programada,
      intervalo_cantidad: intervalo_cantidad,
    };
  };

  const handleSubmit = () => {
    if (nota.id) {
      updateNota(nota.id, nota);
    } else {
      createNota(fixForm(nota));
    }
    handleToggleNotaForm();
  };

  const handleChangeIntervaloCantidad = (intervalo_cantidad) => {
    let intervalo_unidad = selectIntervaloUnidadRef.current.value;
    let calculated_now = calculateNow(intervalo_cantidad, intervalo_unidad);

    let newData = {
      intervalo_cantidad: intervalo_cantidad,
    };
    if (calculated_now) {
      newData.fechahora_programada = formatDatetimeInputValue(calculated_now);
    }

    setNota({ ...nota, ...newData });
  };

  const handleChangeIntervaloUnidad = (intervalo_unidad) => {
    let intervalo_cantidad = inputIntervaloCantidadRef.current.value;
    let calculated_now = calculateNow(intervalo_cantidad, intervalo_unidad);

    let newData = {
      intervalo_unidad: intervalo_unidad,
    };
    if (calculated_now) {
      newData.fechahora_programada = formatDatetimeInputValue(calculated_now);
    }

    setNota({ ...nota, ...newData });
  };

  ////---- Return -------------------------------------------------------------
  return (
    <>
      <Modal
        isOpen={isOpenNotaForm}
        toggle={handleToggleNotaForm}
        returnFocusAfterClose={false}>
        <ModalHeader tag="h2" close={closeBtn} style={{ color: "#666666" }}>
          {nota.id ? "Editar nota" : "Crear nota"}
        </ModalHeader>

        <ModalBody>
          <div className="form-group">
            <label className="form-label" htmlFor="id-texto">
              Texto:
            </label>
            <textarea
              id="id-texto"
              className="form-control"
              rows={4}
              value={nota.texto || ""}
              onChange={({ target }) => {
                setNota({ ...nota, texto: target.value });
              }}></textarea>
          </div>
          {nota.id && <NotaEstadoSelect nota={nota} setNota={setNota} />}
          <TipoNotasSelect
            tipoNotas={tipoNotas}
            nota={nota}
            setNota={setNota}
          />
          <div className="form-group">
            <label className="form-label" htmlFor="id-intervalo_cantidad">
              Intervalo programado:
            </label>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="number"
                  id="id-intervalo_cantidad"
                  ref={inputIntervaloCantidadRef}
                  className="form-control"
                  min={0}
                  value={nota.intervalo_cantidad || ""}
                  onChange={({ target }) =>
                    handleChangeIntervaloCantidad(target.value)
                  }
                />
              </div>
              <div className="col-md-6">
                <NotaIntervaloUnidadSelect
                  nota={nota}
                  ref={selectIntervaloUnidadRef}
                  handleChange={handleChangeIntervaloUnidad}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="id-fechahora_programada">
              Fecha y hora programada:
            </label>
            <input
              type="datetime-local"
              id="id-fechahora_programada"
              className="form-control"
              min={minFechaHoraProgramada}
              value={nota.fechahora_programada || defaultFechaHoraProgramada}
              onChange={({ target }) => {
                setNota({ ...nota, fechahora_programada: target.value });
              }}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            color={nota.id ? "warning" : "primary"}
            onClick={handleSubmit}>
            Guardar
          </Button>
          <Button color="secondary" onClick={handleToggleNotaForm}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NotaForm;
