import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import useModal from '../../hooks/useModal'

const SimpleModal2 = ({ title = "", body, size = "auto", width }) => {
    const { modal, toggle, modalData } = useModal()
    return (
        <Modal isOpen={modal} toggle={toggle} size={size}>
            <div className="modal-header" style={{ paddingBottom: "0px" }}>
                <h1 className="modal-title">{title}</h1>
            </div>
            <button
                type="button"
                className="close p-sm-2 p-md-4 text-white fs-xxl position-absolute pos-right mr-sm-2 mt-sm-1 z-index-space"
                onClick={toggle}
            >
                <span aria-hidden="true">
                    <i className="fal fa-times" style={{ color: "black" }}></i>
                </span>
            </button>
            {/* <ModalHeader style={{ paddingBottom: "0px" }}>
                {title}
            </ModalHeader> */}
            <ModalBody>
                {body}
            </ModalBody>
        </Modal>
    )
}

export default SimpleModal2;
