import { handleErrorResponse, simplePostData } from "../../../../functions";

const changeDAPValidarStock = (id, validar_stock) => {
  simplePostData(
    `inventario/detalleatencionproducto/${id}/`,
    { validar_stock: validar_stock },
    "PATCH"
  )
    .then(() =>
      handleErrorResponse(
        "custom",
        "Exito",
        "Producto editado exitosamente",
        "success"
      )
    )
    .catch(() =>
      handleErrorResponse("custom", "Error", "Ha ocurrido un error", "danger")
    );
};

export default changeDAPValidarStock;
