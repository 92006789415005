import React, { useState } from "react"
import { PageTitle } from "../../bits";
import ProductosList from "./ProductoList";
import ProductosActions from "./ProductoActions";
import ModalFormProductos from "./ModalFormProducto";
import { useProduct } from "../context/ProductoContext";

function Producto() {
  const {defaultProduct, setProduct,  getListProducts} = useProduct();
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => {
    setIsModalOpen(false)
    getListProducts();
    setProduct(defaultProduct)
  }

  return (
    <>
      <PageTitle title={"Productos"} />
      <div className="row">
        <div className="col-lg-9">
          <div style={{marginBottom: "25px"}}>
            <ProductosList openModal={openModal}/>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="panel">
            <ProductosActions openModal={openModal}/>
          </div>
        </div>
        <ModalFormProductos isOpen={isModalOpen} closeModal={closeModal}/>
      </div>
    </>
  )
}

export default Producto
