import React from "react"
import { PageTitle } from "../../bits";
import KardexList from "./KardexList";

function Kardex() {

  return (
    <>
      <PageTitle title={"Kardex"}/>
      <div className="row">
        <div className="col-lg-12">
          <KardexList />
        </div>
      </div>
    </>
  )
}

export default Kardex
