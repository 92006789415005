import React from 'react'
import MessageContentCard from './MessageContentCard'
import { useScroll } from '../hooks/useScroll';
import { useMessage } from '../context/MessageContext';

function ChatMessageList ({selectedContact}) {
  const { messagesEndRef } = useScroll();
  const { messages } = useMessage();

  return (
    <div className='p-3 mb-2 d-flex flex-column' style={{maxHeight: "62vh", overflowY: "auto"}}>
    {selectedContact && messages && messages.map((message) => <MessageContentCard key={message.id || crypto.randomUUID()}   message={message} />)}
    <span ref={messagesEndRef} />
</div>
  )
}

export default ChatMessageList
