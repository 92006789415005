import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import Loader from '../../loader/Loader'
import Datatable from "../../datatable/Datatable";
import { useProcedureProduct } from "../context/ProcedimientoProductoContext";
import { useProduct } from "../../inventario/context/ProductoContext";

const validarStockColumnId = "validarStock"

function ProcedimientoProductoList({openModal}) {
  const {procedure_id} = useParams()
  const {
    setProcedureProduct,
    procedureProducts,
    getListProcedureProducts,
    deleteProcedureProduct,
    changeValidarStockProcedureProduct
} = useProcedureProduct();
  const {products} = useProduct();

  useEffect(() => {
    if(procedure_id) getListProcedureProducts(procedure_id);
  }, [procedure_id])

  const columnas = [
    {
      id: 'producto',
      columnHeaderLabel: 'Producto',
      cellRender: item => item.producto_descripcion,
      isSortable: true
    },
    {
      id: 'cantidadPresentacion',
      columnHeaderLabel: 'Cantidad de Presentaciones',
      cellRender: item => item.cantidad_presentacion,
      isSortable: true
    },
    {
      id: 'cantidadUnidad',
      columnHeaderLabel: 'Cantidad de Unidades',
      cellRender: item => item.cantidad_unidad,
      isSortable: true
    },
    {
      id: validarStockColumnId,
      columnHeaderLabel: "Validar Stock?",
      cellRender: (item) => {
        return(
          <input
            key={item.id}
            type="checkbox"
            onChange={
              ({target}) => {
                changeValidarStockProcedureProduct(item.id, item, target.checked)
              }
            }
            defaultChecked={item.validar_stock}
          />
        )
      },
      isSortable: false,
    },
    {
      id: "views",
      columnHeaderLabel: "Acciones",
      cellRender:(item) => {
        return (
          <>
            <button
              className="btn btn-sm btn-light btn-pills waves-effect mr-2"
              onClick={() => {
                setProcedureProduct(item);
                openModal();
              }}
            >
              Editar
            </button>
            <button
              className="btn btn-sm btn-danger btn-pills waves-effect mr-2"
              onClick={() => {
                deleteProcedureProduct(item.id)
              }}
            >
              <i className="fas fa-trash"></i>
            </button>
          </>
        );
      },
      isSortable: false,
    }
  ]
  
  return (
    !procedureProducts || !products
    ? <Loader scale={2} />
    : (
      <div>
        <div className="datatable-container col-12">
          <Datatable
            data={procedureProducts}
            columns={columnas}
            defaultSortField={validarStockColumnId}
          />
        </div>
      </div>
    )
  )
}

export default ProcedimientoProductoList
