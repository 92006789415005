import React, { useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap"
import usePatientData from "../../hooks/usePatiendData"
import { simpleGet } from "../../functions"

const deudasModel = {
  deudas_atencion_libre: 0,
  deudas_plan_trabajo: 0,
  total: 0
}

export const CardCobranzacharacteristics = ({ patientPk }) => {
  const { patientData } = usePatientData(patientPk)
  const [deudas, setDeudas] = useState(deudasModel)

  useEffect(() => {
    const getDeudas = async () => {
      const url = `finanzas/cuentacorriente/atencionplantrabajo/deudas/paciente/${patientPk}`
      const deudas = await simpleGet(url)
      deudas.total = parseInt(deudas.deudas_atencion_libre) + parseInt(deudas.deudas_plan_trabajo)
      setDeudas(deudas)
    }
    getDeudas()
  }, [])

  return (
    <Card>
      <CardBody className="fs-xl">
        <table>
          <tbody>
            <tr>
              <td><b>Paciente</b></td>
              <td className="pl-3">{patientData?.fullname}</td>
            </tr>
            <tr>
              <td><b>Deudas</b></td>
              <td className="pl-3"></td>
            </tr>
            <tr>
              <td><b>Atenciones Libres</b></td>
              <td className="pl-3">{deudas?.deudas_atencion_libre}</td>
            </tr>
            <tr>
              <td><b>Atenciones Plan de Trabajo</b></td>
              <td className="pl-3">{deudas?.deudas_plan_trabajo}</td>
            </tr>
            <tr>
              <td><b>Total</b></td>
              <td className="pl-3">{deudas?.total}</td>
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
}
