import { handleErrorResponse, simpleGet } from "../../../functions";

const listTipoNotas = (setup) => {
  simpleGet("atencion/tiponota/")
    .then(setup)
    .catch(() =>
      handleErrorResponse(
        "custom",
        "Error",
        "Ha ocurrido un error al recuperar los datos",
        "danger"
      )
    );
};

export default listTipoNotas;
