import React, { createContext, useContext, useState } from "react";
import { NavigationContext } from "../../Navigation";
import { INITIAL_NOTA, INITIAL_NOTAS } from "../params";
import listNotas from "../api/listNotas";
import createNota from "../api/createNota";
import getNota from "../api/getNota";
import updateNota from "../api/updateNota";
import deleteNota from "../api/deleteNota";
import listTipoNotas from "../api/listTipoNotas";
export const NotaContext = createContext(null);

export const useNotaContext = () => {
  const context = useContext(NotaContext);
  if (!context) {
    throw new Error("useNotaContext must be used within NotaContext");
  }
  return context;
};

const NotaContextProvider = ({ children }) => {
  const { current_sucursal } = useContext(NavigationContext);
  const [isOpenNotaForm, setIsOpenNotaForm] = useState(false);
  const [notas, setNotas] = useState(INITIAL_NOTAS);
  const [nota, setNota] = useState(INITIAL_NOTA);
  const [tipoNotas, setTipoNotas] = useState(null);

  ////---- Handlers -----------------------------------------------------------
  const handleToggleNotaForm = () => {
    let keepOpen = !isOpenNotaForm;
    setIsOpenNotaForm(keepOpen);

    if (!keepOpen) {
      setNota(INITIAL_NOTA);
    }
  };

  ////---- Return -------------------------------------------------------------
  return (
    <NotaContext.Provider
      value={{
        current_sucursal,
        isOpenNotaForm,
        handleToggleNotaForm,
        notas,
        setNotas,
        nota,
        setNota,
        tipoNotas,
        setTipoNotas,
        listNotas,
        createNota,
        getNota,
        updateNota,
        deleteNota,
        listTipoNotas,
      }}>
      {children}
    </NotaContext.Provider>
  );
};

export default NotaContextProvider;
