import React, { useEffect } from "react"
import Loader from '../../loader/Loader'
import Datatable from "../../datatable/Datatable";
import { useCompra } from "../context/CompraContext";
import { Link } from "react-router-dom";

function CompraList() {
  const {compras, getListCompras} = useCompra();

  const show_estado_display = (estado, estado_display) => 
    <span className={`badge rounded-pill ${estado === "1" ? "bg-warning" : "bg-primary text-white"}`}>{estado_display}</span>

  useEffect(() => {
    getListCompras()
  }, [])

  const columnas = [
    {
      id: 'proveedor',
      columnHeaderLabel: 'Proveedor',
      cellRender: item => item.proveedor_descripcion,
      isSortable: true
    },
    {
      id: 'estado',
      columnHeaderLabel: 'Estado',
      cellRender: item => show_estado_display(item.estado, item.estado_display),
      isSortable: true
    },
    {
      id: 'fecha_creacion',
      columnHeaderLabel: 'Fecha Hora Creación',
      cellRender: item => item.created_at,
      isSortable: true
    },
    {
      id: 'total',
      columnHeaderLabel: 'Monto',
      cellRender: item => item.total_final,
      isSortable: true
    },
    {
      id: "views",
      columnHeaderLabel: "Acciones",
      cellRender:(item) => {
        return(
          <Link
            to={`/nav/inventario/compra/${item.id}/detallecompra/`}
            className="btn btn-sm btn-light btn-pills waves-effect"
          >
            Detalles
          </Link>
        )
      },
      isSortable: false,
    }
  ]

  return (
    !compras
    ? <Loader scale={2} />
    : (
      <div>
        <div className="datatable-container col-12">
          <Datatable data={compras} columns={columnas} />
        </div>
      </div>
    )
  )
}

  export default CompraList
