import React, {useEffect, useState} from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { handleErrorResponse, simpleGet } from '../../../functions';

function WebhookModalForm({isOpen, closeModal}) {
  const [webhook, setWebhook] = useState({
    url: "",
    verify_token: ""
  })

  const getWebhook = () => {
    simpleGet("wabachat/webhook/retrieve/")
    .then(setWebhook)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  useEffect(() => {
    if (isOpen) getWebhook()
  }, [isOpen])

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <span className="h3">Webhook</span>
      </ModalHeader>
      <ModalBody>
        <div>
          {/* url */}
          <div className="mb-3">
            <label className="form-label" htmlFor="url">URL: </label>
            <input type="text" className="form-control" id="url" value={webhook.url} disabled/>
          </div>
          {/* verify_token */}
          <div className="mb-3">
            <label className="form-label" htmlFor="verify_token">Token de Verificación: </label>
            <input type="text" className="form-control" id="verify_token" value={webhook.verify_token} disabled/>
          </div>
          <div style={{paddingTop: "10px", display: "flex", gap: "10px"}}>
            <button className="btn btn-secondary" type="button" onClick={closeModal}>Salir</button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default WebhookModalForm
