import React, { useState, useEffect, useRef, useContext } from 'react'
/* Calendar components
* FullCalendar required @fullcalendar/react to provide the FullCalendar component
* additionally it required a package for each plugin we want to include in the calendar
* we included @fullcalendar/core package to use esLocale spanish language injection
* Further changes were made right into the package's code
  route: node_modules/@fullcalendar/bootstrap/main.js
  * change the following prototype property BootstrapTheme.prototype.baseIconClass = 'fal'  // Changed 'fa' -> 'fal'
  * in BootstrapTheme.prototype.classes we added the following property: ` button: 'btn btn-default' `
*/
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from "@fullcalendar/interaction"
import bootstrapPlugin from "@fullcalendar/bootstrap"
import esLocale from '@fullcalendar/core/locales/es'

import {
  Icon,
  PageTitle,
  RegularModalCentered
} from '../bits'
import {
  handleErrorResponse,
  capitalizeFirstLetter as cFL,
  simpleGet,
  getDataByPK,
  simplePostData,
} from '../../functions'
import { NavigationContext } from '../Navigation'
import { tipo_documento } from '../admision/Admision'
import { personaFromReniec, xhtmlDecode } from '../admision/NewEditPatient'
import Loader from '../loader/Loader'
import format from 'date-fns/format'
import es from 'date-fns/locale/es'
import { AddIcon, EyeIcon, RefreshIcon, ShowNoteIcon } from './icons'
import NotaModal from '../nota/NotaModal'

// Static
const __debug__ = process.env.REACT_APP_DEBUG == "true"


// constant values
const CITA_ESTADO_PENDIENTE = "1"
const CITA_ESTADO_ANULADO_POR_CLIENTE = "2"
const CITA_ESTADO_ANULADO_POR_CLINICA = "3"
const CITA_ESTADO_PACIENTE_NO_SE_PRESENTO = "4"
const CITA_ESTADO_ATENDIDO = "5"
const CITA_ESTADO_NOMBRE = {
  [CITA_ESTADO_PENDIENTE]: "Pendiente",
  [CITA_ESTADO_ANULADO_POR_CLIENTE]: "Anulado por cliente",
  [CITA_ESTADO_ANULADO_POR_CLINICA]: "Anulado por clinica",
  [CITA_ESTADO_PACIENTE_NO_SE_PRESENTO]: "Paciente no se presento",
  [CITA_ESTADO_ATENDIDO]: "Atendido",
}
const html_cita_form = "modal-crear_cita"
const html_cita_detail = "modal-ver_cita"
const html_cita_annul = "modal-anular_cita"
const html_cita_repro = "modal-repro_cita"

const Cita = () => {
  const dniOtroCodeFilled = useRef(false)
  const events_response_data = useRef([])  // Used to preserve events values after filters
  const [events, setEvents] = useState([])  // FullCalendar events
  const [personal, setPersonal] = useState(false)  // Sucursal's personal
  const [pxss, setPXS] = useState(false)  // Sucursal's procedures
  const [cita_selected, selectCita] = useState(false)  // Cita selected to get info
  const [show_past_citas, setShowPastCitas] = useState(false)  // Show past citas
  const [loading, setLoader] = useState(true)
  /* fake_redirect_data: Workaround to fake redirect_data empty
  * when it has already been fully used to create a Cita
  * and therefore it is not longer needed
  */
  const [fake_redirect_data, setFakeRedirectData] = useState(false)
  const fullname_validado = useRef(false)  // Keeps a track if unknown patient's data came from reniec's service
  // const usageHint = useRef(false)  // Allow to change dynamically the behaviour

  // Redirect data
  const {current_sucursal, redirectTo, redirect_data} = useContext(NavigationContext)
  let _redirect_data = fake_redirect_data ? false : redirect_data
  let [isOpenNotaModal, setIsOpenNotaModal] = useState(false)

  // initial requests
  const getPersonal = () => simpleGet(`maestro/empresa/personal/?filtro={"atencion":"true"}`).then(setPersonal)
  const getPXS = () => simpleGet(`maestro/procedimiento/sucursal/?filtro={"active":"1"}`).then(setPXS)
  const getCitas = () => {
    if(__debug__) console.log("Cita getCitas", show_past_citas)
    setLoader(true)

    let dt = new Date()
    dt.setTime(dt.getTime() - (14*24*60*60*1000))  // get back time to 2 weeks ago
    let filter = show_past_citas
      ? `"fecha_desde":"${dt.toDateInputValue()}","estado":"-1"`
      : `"estado":"1"`
    simpleGet(`atencion/cita/?filtro={"sucursal":"${current_sucursal}","programado":"1",${filter}}`)
    .then(handleCitaResponse)
  }
  const handleCitaResponse = _citas => {
    if(__debug__) console.log("Cita handleCitaResponse")

    // Format cita as event
    let _events = _citas.map(c => {
      let color = `#${c.personal.color}`;
      let textColor = "white";
      if(c.estado === CITA_ESTADO_PACIENTE_NO_SE_PRESENTO){
        color = "#CDCDCD";
        textColor = "#343434";
      }
      return {
        cita: c,
        title: c.paciente_data.fullname.toUpperCase()+" - "+c.programado,
        start: c.fecha+"T"+c.hora,
        end: c.fecha+"T"+c.hora_fin,
        color,
        textColor,
      }
    })
    setEvents(_events)
    events_response_data.current = _events  // Save response in useRef variable (to use in filters)
  }
  // helpers
  const fixFCStyles = () => {
    if(__debug__) console.log("Cita fixFCStyles")

    // CSS changes for react's fullcalendar
    let style = document.head.appendChild(document.createElement("style"))
    style.innerHTML = `
      .fc-highlight {background-color: #c2c7cc!important}
      div.fc-scroller table.fc-col-header {background-image: linear-gradient(0deg,#f2f2f2 0,#fafafa)}
      div.fc-scroller {overflow: hidden}
      td.fc-day-today div.fc-timegrid-now-indicator-container {background-color: #bbddf5}
    `
  }   
  const pacienteDataInputs = (_pk="", _n1="", _n2="", _a1="", _a2="", _c="", _notify="1", block=false) => {
    window.document.getElementById("pac_pk").value = _pk
    window.document.getElementById("newpat-name-pric").value = _n1
    window.document.getElementById("newpat-name-sec").value = _n2
    window.document.getElementById("newpat-ape-p").value = _a1
    window.document.getElementById("newpat-ape-m").value = _a2
    window.document.getElementById("newpat-phone").value = _c
    window.document.getElementById("newpat-notification_type").value = _notify
    // Enable/Disable
    fullname_validado.current = block
    window.document.getElementById("newpat-name-pric").disabled = block
    window.document.getElementById("newpat-name-sec").disabled = block
    window.document.getElementById("newpat-ape-p").disabled = block
    window.document.getElementById("newpat-ape-m").disabled = block
  }
  const fillPatienteByDNI = () => {
    const tipo_documento = window.document.getElementById("dni_tipo").value
    const documento = window.document.getElementById("pac_dni").value
    if(__debug__) console.log("fillPatienteByDNI", tipo_documento, documento)

    if(tipo_documento === "0" && documento.length === 0 && !dniOtroCodeFilled.current){
      const date = new Date();
      let codigo = `${date.getDate()}${date.getMonth()+1}${date.getYear()-100}`;
      const nombre_principal = window.document.getElementById("newpat-name-pric").value
      if(nombre_principal) codigo = `${codigo}${nombre_principal[0].toUpperCase()}`
      const nombre_secundario = window.document.getElementById("newpat-name-sec").value
      if(nombre_secundario) codigo = `${codigo}${nombre_secundario[0].toUpperCase()}`
      window.document.getElementById('pac_dni').value = codigo
      dniOtroCodeFilled.current = true
    }

    // Reset values when dni changes
    pacienteDataInputs()
    if(documento.length < 4) return

    // Generate promise
    getDataByPK(`atencion/paciente/${tipo_documento}/doc`, documento)
    .then(pac => {
      // Set paciente data
      pacienteDataInputs(pac.pk, pac.nombre_principal, pac.nombre_secundario, pac.ape_paterno, pac.ape_materno, pac.celular, pac.tipo_notificacion, pac.fullname_validado)
    })
    .catch(() => {
      if(documento.length === 8 && tipo_documento === "1"){
        pacienteDataInputs()
        personaFromReniec(documento)
        .then(res => {
          if(__debug__) console.log("personaFromReniec res", res);
          if(res.hasOwnProperty('error')) return
          else handleErrorResponse('pac_form', "", "Se ha encontrado información relacionada al dni en el servicio de reniec", 'info')
          // Fill data from reniec
          let primer_nombre = res.nombres.split(" ")[0]
          pacienteDataInputs("", xhtmlDecode(primer_nombre),
            xhtmlDecode( res.nombres.replace(primer_nombre, "").trim() ),
            xhtmlDecode(res.apellido_paterno), xhtmlDecode(res.apellido_materno),
            "", true, true)
        })
      }
    })
  }
  const cancelCitaForm = () => {
    if(_redirect_data) return
    window.document.getElementById("pac_dni").value = ""
    pacienteDataInputs()
    window.document.getElementById("cita-date").value = new Date().toDateInputValue()
    window.document.getElementById("cita-hour").value = "08"
    window.document.getElementById("cita-minute").value = "00"
    window.document.getElementById("cita-duracion").value = "15"

    window.$("#select-filtro_personal").val(null).trigger('change')
    window.$("#select-procedimiento_programado").val([]).trigger("change")
  }
  const errorForm = log => {
    document.querySelector('div#alert-cita-form span').innerText = log
    document.getElementById('alert-cita-form').style.display = "block"
    document.getElementById('alert-cita-form').classList.remove("fade")
    setTimeout(function(){
      if(document.getElementById('alert-cita-form'))
        document.getElementById('alert-cita-form').classList.add("fade")
    }, 2500)
    setTimeout(function(){
      if(document.getElementById('alert-cita-form'))
        document.getElementById('alert-cita-form').style.display = "none"
    }, 2700)
  }
  const fillDataFromRedirect = () => {
    if(__debug__) console.log("Cita fillDataFromRedirect")
    /* _redirect_data.selected
    * procedures are added through the SelectProcedure component
    * select patient are setted directly
    */
    // Set paciente data
    window.document.getElementById("pac_dni").value = _redirect_data.patient.dni ? _redirect_data.patient.dni : _redirect_data.patient.dni_otro
    pacienteDataInputs(_redirect_data.patient.pk,
      _redirect_data.patient.nombre_principal,
      _redirect_data.patient.nombre_secundario||"",
      _redirect_data.patient.ape_paterno,
      _redirect_data.patient.ape_materno,
      _redirect_data.patient.celular,
      _redirect_data.patient.tipo_notificacion,
      _redirect_data.patient.fullname_validado)
    // Open modal
    window.$('#'+html_cita_form).modal('show')
  }
  const redirectDataFinal = cita => {
    // Compare procs sended and procs received
    // _redirect_data.selected = [{pxs_pk, dpdt}]
    let sended_ar = window.$("#select-procedimiento_programado").select2('data').map(i => Number(i.id))
    simplePostData('atencion/plantrabajo/cita/relacion/', {
      cita: cita.pk,
      dpdt_pks: _redirect_data.selected.filter(i => sended_ar.indexOf(i.pxs_pk)!=-1 ).map(i => i.dpdt)
    })
    .finally(() => setFakeRedirectData(true))
  }
  const createDACita = cita => {
    // Create DA for every programado
    let ar_programado = window.$("#select-procedimiento_programado").select2('data').map(i => Number(i.id))
    ar_programado.reduce(
      (promise_chain, pxs_pk) => promise_chain.then(  // Create DA
        () => simplePostData(`atencion/detalle/`, {atencion: cita.atencion, pxs: pxs_pk})
      ), Promise.resolve()
    )
  }
  const getValidatedCitaFormData = () => {
    // VALIDATIONS
    // Validate date
    let _fecha = document.getElementById("cita-date").value

    // Validate hour
    let _hora = Number(document.getElementById("cita-hour").value)
    // Get PERSONAL
    let personal_ = window.$("#select-filtro_personal").select2('data')
    if(personal_.length==0){
      errorForm("Debe seleccionar al menos un personal de atención")
      return
    }
    let _personal = []
    let _personal_atencion = ""
    personal_.forEach((personal, inx) => {
      _personal.push(personal.id)

      // Personal name to programado
      let _personal_name = personal.text
      if(inx==personal_.length-1) _personal_atencion+=" y "
      else if(inx>0) _personal_atencion+=", "
      _personal_atencion += _personal_name
    })

    // Get patient
    let _paciente = {}
    let _tmp = document.getElementById("pac_pk").value
    if(_tmp != "") _paciente.pk = _tmp
    if(_tmp == ""){
      // Validate newpat values
      // tipo documento
      _tmp = window.document.getElementById("dni_tipo").value
      _paciente.tipo_documento = _tmp
      // dni or dni_otro
      _tmp = window.document.getElementById("pac_dni").value
      if(_paciente.tipo_documento === "1"){ // DNI
        if(_tmp.trim().length!=8){
          errorForm("El DNI debe tener 8 digitos")
          return
        }
        _paciente.dni = _tmp
        _paciente.dni_otro = null
      }else{
        _paciente.dni_otro = _tmp
        _paciente.dni = null
      }
      // nombre_principal
      _tmp = window.document.getElementById("newpat-name-pric").value
      if(_tmp.trim() == ""){
        errorForm("Debe especificar un nombre principal")
        return
      }
      if(!/^[a-zA-ZñÑáéíóúÁÉÍÓÚ]+$/.test(_tmp)){
        errorForm("Los nombres solo pueden contener letras")
        return false
      }
      _paciente.nombre_principal = _tmp
      // nombre_secundario
      _tmp = window.document.getElementById("newpat-name-sec").value
      if(_tmp.trim() != ""){
        if(!/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/.test(_tmp)){
          errorForm("Los nombres solo pueden contener letras")
          return false
        }else _paciente.nombre_secundario = _tmp
      }
      // ape_paterno
      _tmp = window.document.getElementById("newpat-ape-p").value
      if(_tmp.trim() == ""){
        errorForm("Debe especificar un apellido paterno")
        return
      }
      if(!/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/.test(_tmp)){
        errorForm("Los apellidos solo pueden contener letras")
        return false
      }
      _paciente.ape_paterno = _tmp
      // ape_materno
      _tmp = window.document.getElementById("newpat-ape-m").value
      if(_tmp.trim() == ""){
        errorForm("Debe especificar un apellido materno")
        return
      }
      if(!/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/.test(_tmp)){
        errorForm("Los apellidos solo pueden contener letras")
        return false
      }
      _paciente.ape_materno = _tmp
      // fullname_validado
      _paciente.fullname_validado = fullname_validado.current
    }
    // celular
    _paciente.celular = window.document.getElementById("newpat-phone").value
    // Notificacion
    _paciente.tipo_notificacion = window.document.getElementById("newpat-notification_type").value

    // Get META
    let _minutos = document.getElementById("cita-minute").value
    let _sucursal = current_sucursal
    let _origen_cita = "3"  // Origen Web
    let _programado = window.$("#select-procedimiento_programado").select2('data').map(i => i.text).join(", ")

    // Add personal to 'programado'
    if(_personal.length>1) _programado += `\nAtendido por ${_personal_atencion}`
    let _duracion = document.getElementById("cita-duracion").value
    let _hora_fin = (()=>{
      let _minres = parseInt(_duracion)+parseInt(_minutos)
      let _temp_hora = parseInt(_hora)+parseInt(_minres/60)
      let _temp_min = _minres%60
      // Handle more than 24hrs time
      if(_temp_hora>=22 && _temp_min>0) return -1
      // Fix left zeros
      _temp_hora = String(_temp_hora).length === 1 ? '0'+_temp_hora:_temp_hora
      _temp_min = String(_temp_min).length === 1 ? '0'+_temp_min:_temp_min
      return _temp_hora+":"+_temp_min
    })()
    // Handle hora_fin out of work time
    if(_hora_fin==-1){
      alert("La hora de finalización no debe exceder el horario de trabajo")
      return
    }

    // Generate data object
    let data = {
      sucursal: _sucursal,
      paciente: _paciente,
      personal_array: String(_personal),
      fecha: _fecha,
      hora: _hora+":"+_minutos,
      hora_fin: _hora_fin,
      origen_cita: _origen_cita,
    }
    if(_programado.length != 0) data.programado = _programado

    return data
  }
  // events
  const getCitaInfo = ev => {
    if(ev.event.extendedProps.cita == cita_selected) openCitaDetail()
    else selectCita(ev.event.extendedProps.cita)
  }
  const selectRangoFecha = ev => {
    if(__debug__) console.log("Cita selectRangoFecha")
    // if(!usageHint.current){
    //   alert("Ahora puedes crear una cita seleccionando un rango de tiempo en el calendario")
    //   usageHint.current = true
    //   return
    // }

    // Assure selected range doesn't cross days
    if(ev.start.toDateInputValue() != ev.end.toDateInputValue()){
      handleErrorResponse("custom", "", 'Por favor seleccione un rango de tiempo perteneciente al mismo día', 'info')
      return
    }

    // Calc cita duration
    document.getElementById("cita-date").value = ev.start.toDateInputValue()
    document.getElementById("cita-hour").value = String(ev.start.getHours()).padStart(2, '0')
    document.getElementById("cita-minute").value = String(ev.start.getMinutes()).padStart(2, '0')
    // Get selected range's duration
    let _duration = Math.floor((ev.end.getTime() - ev.start.getTime())/60000)
    // Add custom option if there is none for the duration value
    let html_input_duration = document.getElementById("cita-duracion")
    if(![...html_input_duration.options].find(inp => inp.value==_duration)){
      let _html_custom_duration_input = window.document.createElement("option")
      _html_custom_duration_input.value = _duration
      _html_custom_duration_input.text = _duration+" minutos"
      html_input_duration.options.add(_html_custom_duration_input)
    }
    document.getElementById("cita-duracion").value = _duration

    window.$('#'+html_cita_form).modal("show")
  }
  const filterByPersonal = _filter => {
    if(_filter.length == 0) setEvents(events_response_data.current)
    else setEvents(events_response_data.current.filter(e => _filter.indexOf(e.cita.personal.pk) != -1 ))
  }
  const saveCita = () => {
    if(__debug__) console.log("Cita saveCita")

    let data = getValidatedCitaFormData()
    if(!data) return

    // Generate promise
    simplePostData('atencion/cita/', data)
    .then(cita => {
      (_redirect_data?.selected ? redirectDataFinal : createDACita)(cita)  // If there was data from redirect
      handleErrorResponse("custom", "Exito", "La cita fue creada exitosamente", "info")
      fakeCrearCita(cita)
    })
    .catch(res => {
      // We expect an error bc of CRUCE_DE_CITAS
      // the simpleFetch functions are standar in the system and when error it returns the response itself
      // er = Response || we need to use er.json() to acces the info inside the response
      res.text().then(er => {
        if(er == '["CRUCE_DE_CITAS"]')
          handleErrorResponse("custom", "", "Ya hay una cita programada para el personal en la hora indicada, por favor escoja otro horario", "warning")
        else handleErrorResponse("custom", "", "Ha ocurrido un error", "warning")
      })
    })
    .finally( () => document.getElementById("cita-cancel").click() )
  }
  const openCitaForm = () => window.$('#'+html_cita_form).modal('show')
  const openCitaDetail = () => window.$('#'+html_cita_detail).modal('show')
  const confirmAnnul = _state => {
    if(__debug__) console.log("Cita confirmAnnul", _state)

    window.$('#'+html_cita_detail).modal('hide')
    window.$('#'+html_cita_annul).data({estado: _state}).modal('show')
  }
  const openReprogramarCitaModal = () => {
    window.$('#'+html_cita_detail).modal('hide')
    window.$('#'+html_cita_repro).modal('show')
  }
  const annulCita = () => {
    let _state = window.$('#'+html_cita_annul).data('estado')
    if(!_state) return

    simplePostData(`atencion/cita/anular/${cita_selected.pk}/`, {estado: _state}, 'PUT')
    .then(() => fakeAnnulCita(cita_selected))
    .catch(() => handleErrorResponse("custom", "Error", "Ha ocurrido un error", "danger"))
  }
  const fakeAnnulCita = _cita => {
    let f_events = events.filter(e => e.cita.pk != _cita.pk)
    setEvents(f_events)
    events_response_data.current = f_events  // Save response in useRef variable (to use in filters)
  }
  const fakeCrearCita = _cita => {
    if(!_cita) return

    let f_events = events

    // New cita fake event
    let f_cita = {
      cita: _cita,
      title: _cita.paciente_data.fullname.toUpperCase()+" - "+_cita.programado,
      start: _cita.fecha+"T"+_cita.hora,
      end: _cita.fecha+"T"+_cita.hora_fin,
      color: "#"+_cita.personal.color,
    }

    /* refer: codeStructure.js: state change: Array state change not firing */
    f_events.push(f_cita)
    setEvents([...f_events])
    events_response_data.current = f_events  // Save response in useRef variable (to use in filters)
  }
  const showPastCitas = () => setShowPastCitas(!show_past_citas)
  const handleToggleNotaModal = () => setIsOpenNotaModal(!isOpenNotaModal)


  useEffect(() => {
    // Select2 for personal choose in Cita
    // CSS
    if(!document.getElementById('select2_link')){
      const select2_link = document.createElement("link")
      select2_link.rel = "stylesheet"
      select2_link.id = "select2_link"
      select2_link.media = "screen, print"
      select2_link.href = "/css/formplugins/select2/select2.bundle.css"
      document.head.appendChild(select2_link)
    }
    // JS
    if(!document.getElementById('select2_script')){
      const select2_script = document.createElement("script")
      select2_script.async = false
      select2_script.id = "select2_script"
      select2_script.onload = ()=>{
        // Set select2 for personal
        window.$("#select-filtro_personal").select2({
          dropdownParent: window.$("#select-filtro_personal").parent()
        })
        // Set select2 for programado
        window.$("#select-procedimiento_programado").select2({
          dropdownParent: window.$("#select-procedimiento_programado").parent()
        })
      }
      select2_script.src = "/js/formplugins/select2/select2.bundle.js"
      document.body.appendChild(select2_script)
    }else{
      // Set select2 for personal
      window.$("#select-filtro_personal").select2({
        dropdownParent: window.$("#select-filtro_personal").parent()
      })
      // Set select2 for programado
      window.$("#select-procedimiento_programado").select2({
        dropdownParent: window.$("#select-procedimiento_programado").parent()
      })
    }

    // Fill data from _redirect_data
    if(_redirect_data) fillDataFromRedirect()

    // Will unmount
    return () => {
      if(__debug__) console.log("Cita unmount")
      window.$('#'+html_cita_form).modal('hide')
      window.$('#'+html_cita_detail).modal('hide')
      window.$('#'+html_cita_annul).modal('hide')
      window.$('#'+html_cita_repro).modal('hide')
    }
  }, [])
  useEffect(() => {
    if(!personal || personal.length==0) return

    getCitas()
  }, [personal])
  useEffect(() => {
    setLoader(false)
    if(!events || events.length==0) return

    fixFCStyles()
  }, [events])
  useEffect(() => {
    if(!cita_selected) return

    openCitaDetail()
  }, [cita_selected])
  useEffect(() => {
    // Get data everytime sucursal changes
    getPersonal()
    getPXS()
  }, [current_sucursal])
  useEffect(() => {
    if(events.length == 0) return  // Do not call getCitas before events is set (it might break bc required data is async loading )

    getCitas()
  }, [show_past_citas])

  const calculateBirthdayTime = (patient) => {
    if (!patient.fecha_nacimiento) return false

    const today = new Date()
    const birthday_split = patient.fecha_nacimiento.split("/")
    const invertedDate = `${birthday_split[2]}-${birthday_split[1]}-${birthday_split[0]}`;
    const birthday = new Date(invertedDate)

    // Calcula el primer día de la semana actual (domingo)
    const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));

    // Calcula el último día de la semana actual (sábado)
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    return (
      birthday.getMonth() === today.getMonth() &&
      birthday.getDate() >= firstDayOfWeek.getDate() &&
      birthday.getDate() <= lastDayOfWeek.getDate()
    );
  }

  // Event in the calendar
  const eventContent = (eventInfo) => {
    const {cita} = eventInfo.event.extendedProps
    const patient = cita.paciente_data
    const isBirthdayTime = calculateBirthdayTime(patient)
    let formattedBirthdayDate = ''
    if(isBirthdayTime) {
      const birthday_split = patient.fecha_nacimiento.split("/")
      const birthday = `${birthday_split[2]}-${birthday_split[1]}-${birthday_split[0]}`;
      const birthdayDate = new Date(birthday)
      formattedBirthdayDate = format(birthdayDate, "'cumpleaños' d 'de' MMMM", { locale: es });
    }

    return (
      <div className='fc-event-main-frame'>
        {isBirthdayTime && <div title={formattedBirthdayDate} style={{marginRight: "3px"}}>🎂</div> } 
        {/* <i className="fa fa-birthday-cake fa-lg" aria-hidden="true" style={{color: 'yellow', marginRight: "3px"}}></i> */}
        <div className='fc-event-title-container'>
          <div className={`fc-event-title fc-sticky ${isBirthdayTime ? 'birthday-event': ''}`}>
            {eventInfo.event.title}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <PageTitle />

      {/* HEADER */}
      <div className="subheader">
        <h1 className="subheader-title">
          <i className="subheader-icon fal fa-chart-area"></i> Cita
        </h1>
        <FilterPersonal
          personal={personal}
          filterByPersonal={filterByPersonal}
          />
      </div>

      {/* FULLCALENDAR */}
      <FullCalendar
        plugins={[timeGridPlugin, listPlugin, interactionPlugin, bootstrapPlugin]}
        initialView="timeGridWeek"
        themeSystem='bootstrap'
        nowIndicator={true}
        slotMinTime="08:00:00"
        slotMaxTime="21:00:00"
        slotDuration='00:15:00'
        headerToolbar={{
          start: 'prev,today,next addCita,refreshCita,showPastCitas showNota',
          center: 'title',
          end: 'timeGridWeek,timeGridDay,listWeek'
        }}
        navLinks={true}
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: true,
          hour12: true,
        }}
        editable={false}
        eventClick={getCitaInfo}
        customButtons={{
          addCita: { click: openCitaForm, text: <AddIcon /> },
          refreshCita: { click: getCitas, text: <RefreshIcon /> },
          showPastCitas: { click: showPastCitas, text: <EyeIcon state={show_past_citas} /> },
          showNota: { click: handleToggleNotaModal, text: <ShowNoteIcon /> },
        }}
        locale={esLocale}
        allDaySlot={false}
        displayEventTime={false}
        selectable={true}
        select={selectRangoFecha}
        events={events}
        eventContent={eventContent}
        eventColor='gray'
        />
      {loading && <Loader scale={2} />}

      {/* MODAL CITA FORM */}
      <ModalCitaForm
        id={html_cita_form}
        saveCita={saveCita}
        cancelCitaForm={cancelCitaForm}
        fillPatienteByDNI={fillPatienteByDNI}
        personal={personal}
        pxss={pxss}
        redirect_data={_redirect_data}
        />

      {/* INFO CITA (modal) */}
      <InfoCita
        id={html_cita_detail}
        cita={cita_selected}
        confirmAnnul={confirmAnnul}
        openReprogramarCitaModal={openReprogramarCitaModal}
        redirectTo={redirectTo} />
      <AnnulCita
        id={html_cita_annul}
        annulCita={annulCita}
        />

      {/* REPROGRAMAR CITA (modal) */}
      <ReprogramarCita
        id={html_cita_repro}
        cita={cita_selected}
        onSuccess={getCitas}
        />

      {/* MODAL NOTA */}
      <NotaModal isOpen={isOpenNotaModal} handleToggle={handleToggleNotaModal}/>
    </div>
  )
}

/*** COMPONENTS ***/
const FilterPersonal = ({personal, filterByPersonal}) => {
  const getFilterByPersonalValues = () => {
    let html_inputs = []
    personal.map(p => window.document.getElementById('filter-personal_'+p.pk).checked && html_inputs.push(p.pk) )
    filterByPersonal(html_inputs)
  }

  return (
    <div className="btn-group btn-group-toggle" data-toggle="buttons">
      {personal && personal.map(p =>
        <label key={p.pk} className="btn btn-light waves-effect waves-themed"
        onClick={getFilterByPersonalValues}>
          <input key={"filter-personal_"+p.pk} type="checkbox" id={"filter-personal_"+p.pk} />
          <span style={{
            fontSize:"1.1em", filter:"contrast(2)",
            color: "#"+p.color,
          }}>
            <b>{(p.nombre_principal+" "+p.ape_paterno[0]+"."+p.ape_materno[0]+".").toUpperCase()}</b>
          </span>
        </label>
      ) || "loading"}
    </div>
  )
}
const ModalCitaForm = ({id, saveCita, cancelCitaForm, fillPatienteByDNI, pxss, personal, redirect_data}) => {
  // Current sucursal
  const ctx_nv = useContext(NavigationContext)
  let cur_suc = ctx_nv.sucursales.find(s => s.pk==ctx_nv.current_sucursal)
  let empresa = cur_suc?.empresa_data

  return(
    <div className="modal fade" id={id} tabIndex="-1" role="dialog" style={{display: "none"}} aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title">
              Programar Cita
            </h2>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i className="fal fa-times"></i></span>
            </button>
          </div>
          <div className="modal-body" id="cita-form">
            <div id="alert-pac_form" className="alert bg-info-700 fade" role="alert" style={{display: "none"}}>
              <strong id="alert-pac_form-headline"></strong> <span id="alert-pac_form-text"></span>.
            </div>
            <SelectPersonal personal={personal} />
            {/* Paciente */}
            <input type="text" id="pac_pk" style={{display:'none'}} disable="true" />
            <div className="form-group col-md-6" style={{display: "inline-block"}}>
              <label className="form-label" htmlFor="dni_tipo">Tipo documento: </label>
              <select id="dni_tipo" className="custom-select form-control" defaultValue="1" onChange={fillPatienteByDNI}>
                <option value="1">DNI</option>
                <option value="2">CARNET DE EXTRANJERIA</option>
                <option value="3">PASAPORTE</option>
                <option value="0">OTRO</option>
              </select>
            </div>
            <div className="form-group col-md-6" style={{display: "inline-block"}}>
              <input type="text" id="pac_dni" placeholder="Dni del paciente" maxLength="30"
              className="form-control form-control-lg" onChange={fillPatienteByDNI} />
            </div>
  
            <div style={{display: "block"}}>
              {/* New Patient Form */}
              <div className="form-group col-md-6" style={{display:'inline-block'}}>
                <label className="form-label" htmlFor="newpat-name-pric">Nombre principal: </label>
                <input type="text" id="newpat-name-pric" className="form-control" />
              </div>
              <div className="form-group col-md-6" style={{display:'inline-block'}}>
                <label className="form-label" htmlFor="newpat-name-sec">Nombre secundario: </label>
                <input type="text" id="newpat-name-sec" className="form-control" />
              </div>
              <div className="form-group col-md-6" style={{display:'inline-block'}}>
                <label className="form-label" htmlFor="newpat-ape-p">Apellido paterno: </label>
                <input type="text" id="newpat-ape-p" className="form-control" />
              </div>
              <div className="form-group col-md-6" style={{display:'inline-block'}}>
                <label className="form-label" htmlFor="newpat-ape-m">Apellido materno: </label>
                <input type="text" id="newpat-ape-m" className="form-control" />
              </div>
              <div className="form-group col-md-6" style={{display:'inline-block'}}>
                <label className="form-label" htmlFor="newpat-phone">Celular: </label>
                <span style={{fontSize: ".6875rem", marginLeft: "1em", color: "blue"}}>(Mantener actualizado)</span>
                <input type="text" id="newpat-phone" className="form-control" maxLength="9" />
              </div>
              <div className="form-group col-md-6" style={{display:'inline-block'}}>
              <label className="form-label" htmlFor="newpat-notification_type">Tipo de Notificación:</label>
                  <select id="newpat-notification_type" className="custom-select form-control" defaultValue="1">
                    <option value="1">SMS</option>
                    {/* {empresa && empresa.waba_id && empresa.waba_token && empresa.waba_account_id && (
                      <option value="2">WhatsApp</option>
                    )} */}
                    <option value="0">Desactivado</option>
                  </select>
              </div>
            </div>
  
            {/* Fin Paciente */}
            <SelectProcedure
              pxss={pxss}
              selected={redirect_data?.selected}
              />
            <div className="form-group col-md-6" style={{display:'inline-block'}}>
              <label className="form-label" htmlFor="cita-date">Fecha: </label>
              <input type="date" id="cita-date" className="form-control form-control-lg" defaultValue={(new Date().toDateInputValue())} required />
            </div>
            <div className="form-group col-md-3" style={{display:'inline-block'}}>
              <label className="form-label" htmlFor="cita-hour" style={{display:'block'}}>Hora: </label>
              <select id="cita-hour" className="custom-select col-lg-6">
                <option value="08" defaultValue>8 AM</option>
                <option value="09">9 AM</option>
                <option value="10">10 AM</option>
                <option value="11">11 AM</option>
                <option value="12">12 PM</option>
                <option value="13">1 PM</option>
                <option value="14">2 PM</option>
                <option value="15">3 PM</option>
                <option value="16">4 PM</option>
                <option value="17">5 PM</option>
                <option value="18">6 PM</option>
                <option value="19">7 PM</option>
                <option value="20">8 PM</option>
                <option value="21">9 PM</option>
              </select>
              <select id="cita-minute" className="custom-select col-lg-6">
                <option value="00" defaultValue>00</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
              </select>
            </div>
            <div className="form-group col-md-3" style={{display:'inline-block'}}>
              <label className="form-label" htmlFor="cita-duracion" style={{display:'block'}}>Duración aproximada: </label>
              <select id="cita-duracion" className="custom-select form-control">
                <option value="15" defaultValue>15 minutos</option>
                <option value="30">30 minutos</option>
                <option value="45">45 minutos</option>
                <option value="60">60 minutos</option>
                <option value="90">90 minutos</option>
                <option value="120">2 horas</option>
                <option value="180">3 horas</option>
                <option value="240">4 horas</option>
              </select>
            </div>
            <div id="alert-cita-form" className="alert bg-danger-400 text-white fade" role="alert" style={{display:'none'}}>
              <strong>Ups!</strong> <span>Parece que los datos introducidos no son correctos.</span>
            </div>
          </div>
          {/* FIN FORMULARIO CITA */}
          <div className="modal-footer">
            <button type="button" onClick={saveCita} className="btn btn-primary waves-effect waves-themed">Guardar</button>
            <button type="button" data-dismiss="modal" onClick={cancelCitaForm} className="btn btn-secondary waves-effect waves-themed" id="cita-cancel">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  )
}
const InfoCita = ({id, cita, confirmAnnul, openReprogramarCitaModal, redirectTo}) => {
  if(!cita) return ""

  const addAttention = () => redirectTo(`/nav/atencion/${cita.pk}/detalle`)
  const addOdontograma = () => redirectTo(`/nav/odontograma/${cita.pk}`)
  const addProcedure = () => redirectTo(`/nav/procedimiento/${cita.pk}/agregar/`)

  let estadoCita = <span>{CITA_ESTADO_NOMBRE[cita.estado]}</span>
  if(cita.estado === CITA_ESTADO_PACIENTE_NO_SE_PRESENTO){
    estadoCita = <u>{CITA_ESTADO_NOMBRE[CITA_ESTADO_PACIENTE_NO_SE_PRESENTO]}</u>
  }

  return (
    <div className="modal fade" id={id} tabIndex="-1" role="dialog" style={{display: "none"}} aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-title fw-500" style={{fontSize:'2em'}}>
              Información Cita
            </span>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i className="fal fa-times"></i></span>
            </button>
          </div>
          <div className="modal-body">
            <h6>
              <b>Paciente: </b>
              <span style={{color: "blue", cursor: "pointer"}}
              onClick={() => redirectTo(`/nav/admision/${cita.paciente_data.pk}/detalle`)}
              >{cita.paciente_data.fullname}</span>
            </h6>
            <h6><b>Estado: </b>{estadoCita}</h6>
            <h6><b>{tipo_documento[cita.paciente_data.tipo_documento]}: </b>{cita.paciente_data.dni?cita.paciente_data.dni:cita.paciente_data.dni_otro}</h6>
            <h6><b>Fecha: </b>{cita.fecha.split("-").reverse().join("/")}</h6>
            <h6><b>Hora: </b>{cita.hora} - {cita.hora_fin}</h6>
            <h6><b>Celular: </b>{cita.paciente_data.celular}</h6>
            <h6><b>Direccion: </b>{cita.paciente_data.direccion}</h6>
            <h6><b>Personal: </b>{cita.personal.fullname} - {cita.personal.especialidad_descripcion}</h6>
            <h6><b>Procedimientos: </b>{cita.programado}</h6>
          </div>
          <div className="modal-footer">
            {/* Icons */}
            <div className="btn-group">
              <Icon type="patient-didnt-show" onClick={()=>confirmAnnul(4)} data_dismiss="modal" style={{"color": "#fd3995"}} />
              <Icon type="attention" onClick={addAttention} data_dismiss="modal" />
              <Icon type="odontogram" onClick={addOdontograma} data_dismiss="modal" />
              <Icon type="procedure" onClick={addProcedure} data_dismiss="modal" />
            </div>
            {/* Reprogramar cita */}
            <button className="btn btn-info" onClick={openReprogramarCitaModal}>Reprogramar</button>
            {/* Estado cita */}
            <div className="btn-group">
              <button className="btn btn-primary waves-effect waves-themed"
                onClick={()=>confirmAnnul(3)}>Anular cita</button>
              <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-themed" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              </button>
              <div className="dropdown-menu">
                <button className="dropdown-item" data-dismiss="modal"
                  onClick={()=>confirmAnnul(2)}>Anulado por cliente</button>
                <button className="dropdown-item" data-dismiss="modal"
                  onClick={()=>confirmAnnul(4)}>Paciente no se presento</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const AnnulCita = ({id, annulCita}) => (
  <div className="modal modal-alert fade" id={id} tabIndex="-1" role="dialog" style={{display: "none", paddingRight: "15px"}} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Anular cita</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i className="fal fa-times"></i></span>
          </button>
        </div>
        <div className="modal-body">
          Esta seguro que quiere anular la cita?
        </div>
        <div className="modal-footer">
          <button type="button" data-dismiss="modal"
            className="btn btn-secondary waves-effect waves-themed">Cancelar</button>
          <button type="button" data-dismiss="modal"
            className="btn btn-danger waves-effect waves-themed"
            onClick={annulCita}>Anular</button>
        </div>
      </div>
    </div>
  </div>
)
const SelectPersonal = ({personal}) => (
  <div className="form-group col-md-12">
    <label className="form-label" htmlFor="select-filtro_personal">Personal de atención: </label>
    <select id="select-filtro_personal" className="custom-select form-control custom-select-lg" multiple>
      {personal && personal.map(p =>
        <option key={p.pk} value={p.pk}>
          {cFL(p.nombre_principal)+" "+cFL(p.ape_paterno)}
        </option>
      ) || "loading"}
    </select>
  </div>
)
const SelectProcedure = ({pxss, selected}) => {
  /* Selected comes from _redirect_data which is loaded at the very begining and never changes */
  const html_select_programed_procedure = "select-procedimiento_programado"

  useEffect(() => {
    if(!pxss || !selected) return

    // Select values when selected is setted
    window.$('#'+html_select_programed_procedure).val( selected.map(i => i.pxs_pk) )
    // window.$('#'+html_select_programed_procedure).trigger('change')
  }, [pxss])

  return (
    <div className="form-group col-md-12">
      <label className="form-label" htmlFor={html_select_programed_procedure}>Programado: </label>
      <select id={html_select_programed_procedure} className="custom-select form-control custom-select-lg" multiple>
        {pxss && pxss.map(pxs =>
          <option key={"select_proc_"+pxs.pk} value={pxs.pk}>
            {pxs.nombre.toUpperCase()}
          </option>
        ) || "loading"}
      </select>
    </div>
  )
}
const ReprogramarCita = ({id, cita, onSuccess}) => {
  const reprogramarCita = () => {
    if(!cita){
      window.$('#'+id).modal("hide")
      handleErrorResponse('Error', 'No se selecciono una cita a programar. Por favor contacte con el administrador', 'warning')
      return
    }

    // Obtener valores
    let fecha = window.document.getElementById('reprogram-cita-fecha').value
    let hora = window.document.getElementById('reprogram-cita-hour').value
    let minutos = window.document.getElementById('reprogram-cita-minute').value

    let data = {
      fecha: fecha,
      hora: `${hora}:${minutos}`,
    }

    simplePostData(`atencion/cita/${cita.pk}/reprogramar/`, data)
    .then(() => handleErrorResponse('custom', 'Exito!', "Cita reprogramada exitosamente", 'info'))
    .then(() => onSuccess(cita, data))
    .catch(res => {
      res.text().then(er => {
        if(er == '["CRUCE_DE_CITAS"]')
          handleErrorResponse("custom", "", "Ya hay una cita programada para el personal en la hora indicada, por favor escoja otro horario", "warning")
        else handleErrorResponse("custom", "", "Ha ocurrido un error", "warning")
      })
    })
    .finally( () => window.$('#'+id).modal("hide") )
  }

  return (
    <RegularModalCentered
      _id={id}
      _title={"Reprogramar Cita"}
      _body={
        <div>
          <div className="form-group">
            <label className="form-label" htmlFor="reprogram-cita-fecha">Fecha</label>
            <input type="date" id="reprogram-cita-fecha" className="form-control" defaultValue={(new Date().toDateInputValue())} />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="reprogram-cita-hour" style={{display:'block'}}>Hora: </label>
            <select id="reprogram-cita-hour" className="custom-select col-lg-6">
              <option value="08" defaultValue>8 AM</option>
              <option value="09">9 AM</option>
              <option value="10">10 AM</option>
              <option value="11">11 AM</option>
              <option value="12">12 PM</option>
              <option value="13">1 PM</option>
              <option value="14">2 PM</option>
              <option value="15">3 PM</option>
              <option value="16">4 PM</option>
              <option value="17">5 PM</option>
              <option value="18">6 PM</option>
              <option value="19">7 PM</option>
              <option value="20">8 PM</option>
              <option value="21">9 PM</option>
            </select>
            <select id="reprogram-cita-minute" className="custom-select col-lg-6">
              <option value="00" defaultValue>00</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="45">45</option>
            </select>
          </div>
          <button className="btn btn-primary" onClick={reprogramarCita}>Reprogramar</button>
        </div>
      } />
  )
}

export default Cita
// eslint-disable-next-line react-hooks/exhaustive-deps
