import React from 'react'
import { VL_NOTA_ESTADO_CHOICES } from './params'

const NotaEstadoSelect = ({nota, setNota}) => {
  const options = VL_NOTA_ESTADO_CHOICES.map((item) => {
    return (
      <option key={item.key} value={item.key}>
        {item.value}
      </option>
    )
  })

  return (
    <div className="form-group">
      <label className="form-label" htmlFor="id-estado">Estado:</label>
      <select
        className="form-control"
        id="id-estado"
        value={nota.estado}
        onChange={({target}) => {
          setNota({...nota, estado: target.value})
        }}
      >
        {options}
      </select>
    </div>
  )
}

export default NotaEstadoSelect
