import React, { useEffect, useState } from "react";
import Loader from "../../loader/Loader";
import Datatable from "../../datatable/Datatable";
import listDetalleAtencionProducto from "./api/listDetalleAtencionProducto";
import changeDAPValidarStock from "./api/changeDAPValidarStock";

const validarStockColumnId = "validarStock";

const DetalleAtencionProductoList = ({ cita, da }) => {
  const [dapList, setDapList] = useState(null);

  ////---- Variables ----------------------------------------------------------
  const columnas = [
    {
      id: "producto",
      columnHeaderLabel: "Producto",
      cellRender: (item) => item.producto_descripcion,
      isSortable: false,
    },
    {
      id: "productoPresentacion",
      columnHeaderLabel: "Presentación",
      cellRender: (item) => item.producto_presentacion_descripcion,
      isSortable: false,
    },
    {
      id: "cantidadPresentacion",
      columnHeaderLabel: "Cantidad de Presentaciones",
      cellRender: (item) => item.cantidad_presentacion,
      isSortable: false,
    },
    {
      id: "cantidadUnidad",
      columnHeaderLabel: "Cantidad de Unidades",
      cellRender: (item) => item.cantidad_unidad,
      isSortable: false,
    },
    {
      id: validarStockColumnId,
      columnHeaderLabel: "Validar Stock?",
      cellRender: (item) => {
        return (
          <input
            key={item.id}
            type="checkbox"
            disabled={!shouldUpdateDAP(cita, item)}
            onChange={
              shouldUpdateDAP(cita, item)
                ? ({ target }) => changeDAPValidarStock(item.id, target.checked)
                : undefined
            }
            defaultChecked={item.validar_stock}
          />
        );
      },
      isSortable: false,
    },
  ];

  ////---- Helpers ------------------------------------------------------------
  const shouldUpdateDAP = (_cita, _dap) => {
    return _cita.estado === "1" && !_dap.base_validar_stock;
  };

  ////---- Hooks --------------------------------------------------------------
  useEffect(() => {
    const queryParams = `filtro={"detalleatencion": "${da.pk}"}`;
    listDetalleAtencionProducto(setDapList, queryParams);
  }, [da.pk]);

  ////---- Return -------------------------------------------------------------
  if (!dapList) {
    return <Loader scale={2} />;
  }

  return (
    <div className="datatable-container col-12">
      <Datatable
        data={dapList}
        columns={columnas}
        defaultSortField={validarStockColumnId}
        defaultSortOrder={"desc"}
      />
    </div>
  );
};

export default DetalleAtencionProductoList;
