import React from "react";
import { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useProduct } from "../context/ProductoContext";

function ModalFormProductos({isOpen, closeModal}) {
  const {product, setProduct, createProduct, getProduct, updateProduct} = useProduct();
  
  const handleSubmit = async () => {
    if (product.id){
      updateProduct(product.id, product, closeModal); return
    } 
    createProduct(product, closeModal);
  }

  useEffect(() => {
    if (product.id) getProduct(product.id);
  }, [product.id])
  
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <span className="h3">{product.id ? "Editar Producto": "Crear Producto"}</span>
      </ModalHeader>
      <ModalBody>
        <div>
          {/* descripcion */}
          <div className="mb-3">
            <label className="form-label" htmlFor="descripcion">Descripción: </label>
            <input type="text" className="form-control" id="descripcion" value={product.descripcion} onChange={({target}) =>
              setProduct({...product, descripcion: target.value})
            }/>
          </div>
          {/* stock minimo */}
          <div className="mb-3">
            <label className="form-label" htmlFor="stock_minimo">Stock Mínimo: </label>
            <input type="number" className="form-control" id="stock_minimo" value={product.stock_minimo} min="0" onChange={({target}) => 
              setProduct({...product, stock_minimo: target.value})
            }/>
          </div>
          <div style={{paddingTop: "10px", display: "flex", gap: "10px"}}>
            <button className="btn btn-primary" onClick={handleSubmit}>Guardar</button>
            <button className="btn btn-secondary" type="button" onClick={closeModal}>Cancelar</button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalFormProductos
