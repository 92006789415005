import React, { useState } from 'react'
import DCProductoList from "./DCProductoList"
import ModalFormDCProducto from './ModalFormDCProducto';
import DCProductoActions from './DCProductoActions';
import { useDetalleCompra } from '../context/DetalleCompraContext';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function DCProducto () {
  const {compra_id} = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false) 
  const {defaultDetalleCompra, setDetalleCompra, getListDetalleCompra} = useDetalleCompra()

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => {
    setIsModalOpen(false);
    getListDetalleCompra(compra_id);
    setDetalleCompra(defaultDetalleCompra);
  } 

  return (
    <div className="card col-12" style={{padding: "0px"}}>
      <div className="card-header p-2">
        <DCProductoActions openModal={openModal}/>
      </div>
      <div className="card-body">
        <DCProductoList openModal={openModal} />
      </div>
        <ModalFormDCProducto isOpen={isModalOpen} closeModal={closeModal}/>
    </div>
  )
}
export default DCProducto
