import React, { useState } from 'react'

function ContactCard({ contact, handleContactSelected, selectedContact }) {
  const [isHovered, setIsHovered] = useState(false);
  const is_contact_selected = selectedContact && selectedContact.id === contact.id;

  return (
    <article
      id="contact-card"
      className={`card cursor-pointer mb-1 ${(isHovered || is_contact_selected) && "bg-primary text-white"}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => handleContactSelected(contact)}>
      <div className="card-body">
        <h5 className="card-title m-0" style={{fontWeight: "bold"}}>{contact.name}</h5>
        <small className={`badge rounded ${(isHovered || is_contact_selected) ? "bg-light text-dark" : "bg-primary text-white"}`}>+{contact.phone}</small>
        <p className="card-text mt-1">{contact?.last_message}</p>
      </div>
    </article>
  )
}

export default ContactCard
