import React from "react"
import { Icon, PageTitle } from "../bits"
import CustomCard from "../utils/CustomCard"
import { Switch, Route } from "react-router-dom"
import Producto from "./producto/Producto"
import Kardex from "./kardex/Kardex"
import ProductoPresentacion from "./producto_presentacion/ProductoPresentacion"
import InventarioProvider from "./context/InventarioContext"
import Compra from "./compra/Compra"
import DetalleCompra from "./detalle_compra/DetalleCompra"
import Stock from "./stock/Stock"

function Inventario() {
  return (
    <InventarioProvider>
      <Switch>
        <Route exact path="/nav/inventario/">
          <InventarioNavigation/>
        </Route>
        <Route exact path="/nav/inventario/producto/">
          <Producto/>
        </Route>
        <Route exact path="/nav/inventario/producto/:product_id/presentacion/">
          <ProductoPresentacion/>
        </Route>
        <Route exact path="/nav/inventario/stock/">
          <Stock/>
        </Route>
        <Route exact path="/nav/inventario/kardex/">
          <Kardex/>
        </Route>
        <Route exact path="/nav/inventario/compra/">
          <Compra/>
        </Route>
        <Route exact path="/nav/inventario/compra/:compra_id/detallecompra/">
          <DetalleCompra/>
        </Route>
      </Switch>
    </InventarioProvider>
  )
}

function InventarioNavigation(){
  return(
    <>
      <PageTitle title={"Inventario"} />
      <div className="row">
        {/* Productos */}
        <CustomCard redirect_to="/nav/inventario/producto/">
          <Icon type="products" />
          <span style={{fontSize: "0.9rem"}}>Productos</span>
        </CustomCard >
        {/* Stock */}
        <CustomCard redirect_to="/nav/inventario/stock/">
          <Icon type="stock" />
          <span style={{fontSize: "0.9rem"}}>Stock</span>
        </CustomCard>
        {/* Kardex */}
        <CustomCard redirect_to="/nav/inventario/kardex/">
          <Icon type="kardex" />
          <span style={{fontSize: "0.9rem"}}>Kardex</span>
        </CustomCard>
        {/* Compras */}
        <CustomCard redirect_to="/nav/inventario/compra/">
          <Icon type="shopping" />
          <span style={{fontSize: "0.9rem"}}>Compras</span>
        </CustomCard>        
      </div>    
    </>
  );
}

export default Inventario
