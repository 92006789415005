import React, { useState, useEffect, useRef, useMemo, useContext } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardHeader, CardTitle, CardBody, Button,
} from 'reactstrap'
import ReactDOM from 'react-dom'
import {
  Link,
  Switch,
  Route,
  useParams,
  useHistory,
} from "react-router-dom"
import {
  indexOfInObjectArray,
  handleErrorResponse,
  getDataByPK,
  simplePostData,
  simpleGet,
  simpleDelete,
  capitalizeFirstLetter as cFL,
  isArray,
} from '../../functions'
import {
  PageTitle,
  ModalCancel,
  Icon,
  RegularModalCentered,
} from '../bits'
import { NavigationContext } from '../Navigation'
import { PaymentForm } from '../finanzas/Cobranza'
import Loader from '../loader/Loader'
import Select from 'react-select'
import Datatable from '../datatable/Datatable'
import MedioPagoSelect from '../finanzas/components/MedioPagoSelect'


// Constant
const __debug__ = process.env.REACT_APP_DEBUG == "true"


const PlanDeTrabajo = () => (
  <Switch>
    {/* PDT List by Patient */}
    <Route exact path="/nav/plandetrabajo/paciente/:patient_pk">
      <PageTitle title="Planes de Trabajo" />
      <PDTList />
    </Route>

    {/* PDT Detail */}
    <Route exact path="/nav/plandetrabajo/:pdt_pk">
      <PDTDetail />
    </Route>
  </Switch>
)

// PDT List
const PDTList = () => {
  /* Get Patient's PDT
  * allow to create, update and delete
  * links to PDT's detail view (DPDT table)
  */
  const [pdts, setPDTs] = useState(false)
  const [show_modal_newpdt, setShowModalNewPDT] = useState(false)
  const [show_modal_editpdt, setShowModalEditPDT] = useState(false) // false || {pdt}
  const [show_alert_delpdt, setShowAlertDelPDT] = useState(false) // false || {pdt}
  const { current_sucursal, redirectTo } = useContext(NavigationContext)
  const { patient_pk } = useParams()

  const getPdts = () => {
    simpleGet(`atencion/plantrabajo/?filtro={"paciente":"${patient_pk}", "sucursal":"${current_sucursal}"}`)
    .then(setPDTs)
  }
  const openModalNewPDT = () => setShowModalNewPDT(true)
  const closeModalNewPDT = () => setShowModalNewPDT(false)
  const onPDTCreated = () => {
    getPdts()
    closeModalNewPDT()
  }
  const getBack = () => redirectTo(`/nav/admision/${patient_pk}/detalle`)

  const openModalEditPDT = _pdt => setShowModalEditPDT(_pdt)
  const closeModalEditPDT = () => setShowModalEditPDT(false)
  const onPDTEdited = () => {
    getPdts()
    closeModalEditPDT()
  }
  const openAlertDelPDT = _pdt => setShowAlertDelPDT(_pdt)
  const closeAlertDelPDT = () => setShowAlertDelPDT(false)
  const deletePDT = () => {
    simpleDelete(`atencion/plantrabajo/${show_alert_delpdt.pk}/`)
    .then(getPdts)
    .finally(closeAlertDelPDT)
  }
  const renderPDTBtns = pdt => (
    <div className="float-right mr-3 fs-xxl">
      <div className="px-2 cursor-pointer" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i className="fal fa-ellipsis-v" />
      </div>

      <div className="dropdown-menu">
        <div className="dropdown-item" onClick={() => openModalEditPDT(pdt)}>Editar</div>
        {/* Disabled Delete PDT */}
        <div className="dropdown-item disabled" onClick={() => openAlertDelPDT(pdt)}>Eliminar</div>
      </div>
    </div>
  )

  useEffect(getPdts, [])

  return !pdts
    ? <Loader scale={2} />
    : pdts.length == 0
    ? (
      <React.Fragment>
        <h3>El paciente no tiene ningún Plan de trabajo creado</h3>
        <div>
          <button className="btn btn-primary mr-3" onClick={openModalNewPDT}>
            <i className="fal fa-plus"/> Nuevo Plan de trabajo
            <ModalNewPDT
              show_modal={show_modal_newpdt}
              closeModal={closeModalNewPDT}
              onSubmit={onPDTCreated}
              suc_pk={current_sucursal}
              pac_pk={patient_pk}
              />
          </button>
          <button className="btn btn-secondary mr-3" onClick={getBack}>
            Regresar
          </button>
        </div>
      </React.Fragment>
    )
    : (
      <React.Fragment>
        <div>
          <button className="btn btn-primary mr-3" onClick={openModalNewPDT}>
            <i className="fal fa-plus"/> Nuevo Plan de trabajo
            <ModalNewPDT
              show_modal={show_modal_newpdt}
              closeModal={closeModalNewPDT}
              onSubmit={onPDTCreated}
              suc_pk={current_sucursal}
              pac_pk={patient_pk}
              />
          </button>
          <button className="btn btn-secondary mr-3" onClick={getBack}>
            Regresar
          </button>
        </div>

        <div className="row">
          {pdts.map(pdt => (
            <div className="col-md-4 mt-4" key={"pac_pdts_list_"+pdt.pk}>
              <PDTCard pdt={pdt} right_side_btns={renderPDTBtns} />
            </div>
          ))}
        </div>

        <ModalEditPDT
          show_modal={!!show_modal_editpdt}
          closeModal={closeModalEditPDT}
          onSubmit={onPDTEdited}
          pdt={show_modal_editpdt}
          />
        <AlertDelPDT
          show_modal={!!show_alert_delpdt}
          closeModal={closeAlertDelPDT}
          onSubmit={deletePDT}
          pdt={show_alert_delpdt}
          />
      </React.Fragment>
    )
}
const PDTCard = ({pdt, right_side_btns}) => (
  <Card className="col-md-12 rounded-plus">
    <CardTitle className="text-center mb-0 mt-4 border-bottom">
      <h1>
        <Link to={"/nav/plandetrabajo/"+pdt.pk}>
          {pdt.nombre}
        </Link>

        {right_side_btns(pdt)}
      </h1>
    </CardTitle>
    <CardBody className="fs-xl px-4">

      {/* PDT Detail table */}
      <PDTInfoTable pdt={pdt} />

      {/* Link to PDT detail */}
      <div className="text-right cursor-pointer">
        <Link to={"/nav/plandetrabajo/"+pdt.pk}>
          <i className="fal fa-sign-in-alt h1"/>
        </Link>
      </div>
    </CardBody>
  </Card>
)
const PDTInfoTable = ({pdt}) => (
  <table>
    <tbody>
    {/*
      <tr>
        <td><b>Nombre</b></td>
        <td className="pl-3">{pdt.nombre}</td>
      </tr>
      */}
      <tr>
        <td><b>Paciente</b></td>
        <td className="pl-3">{pdt.paciente_data.fullname}</td>
      </tr>
      <tr>
        <td><b>Observaciones</b></td>
        <td className="pl-3">{pdt.observaciones}</td>
      </tr>
      <tr>
        <td><b>Presupuesto</b></td>
        <td className="pl-3">
          {pdt.precio_total}&nbsp;
          {pdt.monto_descuento ? (
            <s style={{color: "darkgray", paddingRight: "2px"}}>{pdt.precio_total + pdt.monto_descuento}</s>
          ) : ""}
        </td>
      </tr>
      <tr>
        <td><b>Descuento</b></td>
        <td className="pl-3">{pdt.monto_descuento}</td>
      </tr>
      <tr>
        <td><b>Pagado</b></td>
        <td className="pl-3">{pdt.monto_pagado}</td>
      </tr>
      <tr>
        <td><b>Resta</b></td>
        <td className="pl-3">{(pdt.precio_total - pdt.monto_pagado).toFixed(1)}</td>
      </tr>
    </tbody>
  </table>
)
const ModalNewPDT = ({show_modal, closeModal, onSubmit, suc_pk, pac_pk}) => {
  const inp_name = useRef()
  const inp_obs = useRef()

  const validatePayload = payload => {
    let has_error = false

    if(!payload.nombre){
      has_error = true;
      alert("Nombre invalido")
    }

    return !has_error
  }
  const generatePayload = () => {
    let payload = {
      nombre: inp_name.current.value.trim(),
      observaciones: inp_obs.current.value.trim(),
      paciente: pac_pk,
      sucursal: suc_pk,
    }

    // Validate payload
    if(!validatePayload(payload)) return;

    simplePostData("atencion/plantrabajo/", payload)
    .then(onSubmit)
  }

  return (
    <Modal isOpen={show_modal} toggle={closeModal}>
      <ModalHeader tag="h3">
        Crear Nuevo Plan de Trabajo
      </ModalHeader>
      <ModalBody className="row">

        <div className="col-md-12 form-group">
          <label className="form-label">Nombre</label>
          <input className="form-control" ref={inp_name} />
        </div>

        <div className="col-md-12 form-group">
          <label className="form-label">Observaciones</label>
          <input className="form-control" ref={inp_obs} />
        </div>

      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" onClick={generatePayload}>Crear</button>
        <button className="btn btn-danger" onClick={closeModal}>Cancelar</button>
      </ModalFooter>
    </Modal>
  )
}
const ModalEditPDT = ({show_modal, closeModal, onSubmit, pdt}) => {
  const [fake_state, setFakeState] = useState(0)
  const inp_name = useRef()
  const inp_obs = useRef()

  const fillPDTData = () => {
    inp_name.current.value = pdt.nombre
    inp_obs.current.value = pdt.observaciones

    setFakeState(2)
  }
  const validatePayload = payload => {
    let has_error = false

    if(!payload.nombre){
      has_error = true;
      alert("Nombre invalido")
    }

    return !has_error
  }
  const generatePayload = () => {
    let payload = {
      nombre: inp_name.current.value.trim(),
      observaciones: inp_obs.current.value.trim(),
    }

    // Validate payload
    if(!validatePayload(payload)) return;

    simplePostData(`atencion/plantrabajo/${pdt.pk}/`, payload, "PATCH")
    .then(onSubmit)
  }

  // Handle prefill PDT data into form
  useEffect(() => {
    if(!show_modal){
      setFakeState(0)
     return;
    }

    if(fake_state == 0) setFakeState(1)
    else if(fake_state == 1) fillPDTData()
  }, [fake_state, show_modal])

  return (
    <Modal isOpen={show_modal} toggle={closeModal}>
      <ModalHeader tag="h3">
        Editar Plan de Trabajo
      </ModalHeader>
      <ModalBody className="row">

        <div className="col-md-12 form-group">
          <label className="form-label">Nombre</label>
          <input className="form-control" ref={inp_name} />
        </div>

        <div className="col-md-12 form-group">
          <label className="form-label">Observaciones</label>
          <input className="form-control" ref={inp_obs} />
        </div>

      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" onClick={generatePayload}>Guardar</button>
        <button className="btn btn-danger" onClick={closeModal}>Cancelar</button>
      </ModalFooter>
    </Modal>
  )
}
const AlertDelPDT = ({show_modal, closeModal, onSubmit, pdt}) => (
  <Modal isOpen={show_modal} toggle={closeModal}>
    <ModalHeader tag="h3">
      Estas seguro que quieres eliminar el Plan de trabajo {pdt.nombre}
    </ModalHeader>
    <ModalFooter>
      <button className="btn btn-danger" onClick={onSubmit}>Eliminar</button>
      <button className="btn btn-default" onClick={closeModal}>Cancelar</button>
    </ModalFooter>
  </Modal>
)

// PDT Detail
const PDTDetail = () => {
  const [pdt, setPDT] = useState(false)
  const [ar_proc_selected, setSelectPDT] = useState([])
  const [show_modal_addpdt, setShowModalAddDPDT] = useState(false)
  const [dpdt_to_edit, setDPDTToEdit] = useState(false)
  const [dpdts, setDPDTs] = useState(false)
  const [pxss, setPXS] = useState(false)
  /* 'selected_list' is used as a reference variable
  * it keeps the previous value of 'ar_proc_selected'
  * ar_proc_selected's update is not tracked by addOrRemoveFromList function (even with useCallback)
  * this value is only a reference, the actual value is ar_proc_selected
  */
  const selected_list = useRef([])
  const btn_loading = useRef(false)
  const { pdt_pk } = useParams()
  const history = useHistory()
  const { current_sucursal, redirectTo } = useContext(NavigationContext)

  const getPDT = () => {
    simpleGet(`atencion/plantrabajo/${pdt_pk}/`)
    .then(setPDT)
  }
  const getDPDTs = () => {
    simpleGet(`atencion/plantrabajo/detalle/?pt=${pdt_pk}`)
    .then(setDPDTs)
  }
  const getProcedures = () => {
    simpleGet(`maestro/procedimiento/sucursal/?filtro={"active":"1"}`)
    .then(setPXS)
  }
  const addOrRemoveFromList = _obj => {
    // BUG: ar_proc_selected's value is not tracking the value update from using setSelectPDT
    // FIX: use a useRef to update the value
    let ar_copy = [...selected_list.current]  // Copy array with no memory reference
    // Search if _obj is already in the array if so find its index
    let _inx = ar_copy.findIndex(i => i.pk == _obj.pk)
    if(_inx == -1) ar_copy.push(_obj)
    else ar_copy.splice(_inx, 1)
    // Set new array
    setSelectPDT(ar_copy)
  }
  const redirectToList = () => history.push("/nav/plandetrabajo/paciente/"+pdt.paciente)
  const alertDeletePDT = () => window.$("#modal_eliminar_pdt").modal('show')
  const deletePDT = () => {
    simpleDelete(`atencion/plantrabajo/${pdt.pk}/`)
    .then(redirectToList)
  }
  const openModalAddDPDT = () => setShowModalAddDPDT(true)
  const closeModalAddDPDT = () => setShowModalAddDPDT(false)
  const closeModalEditDPDT = () => setDPDTToEdit(false)
  const onDPDTAdded = () => {
    getPDT()
    getDPDTs()
    closeModalAddDPDT()
  }
  const onDPDTEdited = () => {
    getPDT()
    getDPDTs()
    closeModalEditDPDT()
  }
  const deleteDPDT = () => {
    if(ar_proc_selected.length == 0) return;
    if(btn_loading.current) return;

    btn_loading.current = true
    // If any selected dpdt is partially/fully paid, has a related attention or DienteIncidencia
    let dpdt_er = ar_proc_selected.filter(
      i => i.cita_relacionada || i.updated_estado!="1" || i.dcc //|| i.incidencias.length > 0
    )
    if(dpdt_er.length > 0){
      dpdt_er.map(alertInputRedSpark)
      btn_loading.current = false
      return;
    }

    /* Reduce array with selected dpdts
    * start with Promise.resolve()
    * and concatenate promises one after another in the "chain"
    * finally update DPDTs
    */
    ar_proc_selected.reduce(
      (chain, dpdt) => chain.then(() => simpleDelete(`atencion/plantrabajo/detalle/${dpdt.pk}/`))
      , Promise.resolve()
    )
    .finally(() => {
      setTimeout(() => {btn_loading.current = false}, 1000)
      getPDT()
      getDPDTs()
      setSelectPDT([])
    })
  }

  useEffect(getPDT, [])
  useEffect(getDPDTs, [])
  useEffect(getProcedures, [])
  useEffect(() => {
    selected_list.current = ar_proc_selected
  }, [ar_proc_selected])

  return !pdt
    ? <Loader scale={2} />
    : (
      <React.Fragment>
        <PageTitle title={"Plan de Trabajo: "+pdt.nombre} />
        <div className="row">

          <div className="col-lg-8">
          {!dpdts
            ? <Loader scale={2} />
            : (
              <DPDTTable
                pdt={pdt}
                dpdts={dpdts}
                current_sucursal={current_sucursal}
                redirectTo={redirectTo}
                selectProc={addOrRemoveFromList}
                setDPDTToEdit={setDPDTToEdit}
                selected={ar_proc_selected}
                />
          )}
          </div>

          <div className="col-lg-4">
            {/* Actions */}
            <div className="panel">
              <PDTActions
                pdt={pdt}
                selected={ar_proc_selected}
                redirectTo={redirectTo}
                editDPDT={openModalAddDPDT}
                deleteDPDT={deleteDPDT}
                />
            </div>

            {/* PDT Characteristics */}
            <Card>
              <CardBody className="fs-xl">
                <PDTInfoTable pdt={pdt} />
              </CardBody>
            </Card>
          </div>

          <div className="col-md-12 mt-5">
            <button className="btn btn-primary fs-xl" onClick={redirectToList}>Regresar</button>
            <button className="btn btn-danger fs-xl ml-5" onClick={alertDeletePDT}>Eliminar</button>
          </div>
        </div>

        <ModalAddDPDT
          show_modal={!!show_modal_addpdt}
          closeModal={closeModalAddDPDT}
          onSubmit={onDPDTAdded}
          pdt={pdt}
          pxss={pxss ? pxss : []}
          />
        <ModalEditDPDT
          show_modal={!!dpdt_to_edit}
          closeModal={closeModalEditDPDT}
          onSubmit={onDPDTEdited}
          dpdt={dpdt_to_edit}
          />
        <ModalPagos
          pdt={pdt}
          selected={ar_proc_selected}
          refreshPDT={getPDT}
          refreshDPDTs={getDPDTs}
          setSelectPDT={setSelectPDT}
          />
        <ModalCitaRelacion
          pdt={pdt}
          selected={ar_proc_selected}
          refreshDPDTs={getDPDTs}
          />
        <ModalCancel
          _id={"modal_eliminar_pdt"}
          _title={"Eliminar Plan de trabajo"}
          _action_text={"Eliminar"}
          _action_func={deletePDT}
          _body_text={"Esta seguro que quiere eliminar este Plan de trabajo"}
          />
      </React.Fragment>
    )
}
const DPDTTable = ({pdt, dpdts, current_sucursal, redirectTo, selectProc, setDPDTToEdit, selected}) => {
  const paidDPDTStatus = ["2", "3"]

  const isChecked = (item) => {
    let dpdt_selected = selected.some(i => i.pk === item.pk)
    return dpdt_selected
  }

  const columnas = [
    {
      id: "procedimiento",
      columnHeaderLabel: "Procedimiento",
      cellRender: item => (
        <span>
          {item.pxs_data.nombre.toUpperCase()}

          {item.incidencias.map((inc, inx) => (
            <span
              key={"dpdt-inc-"+inx}
              className={`badge badge-pill ml-2 ${item.dcc?.cita?.estado == '5' ? 'badge-success' : 'badge-secondary'}`}
            >
              {inc.start_tooth_key && inc.start_tooth_key+" - "}
              {inc.diente}
            </span>
          ))}
        </span>
      ),
      isSortable: true,
    },
    {
      id: "estado",
      columnHeaderLabel: "Estado",
      cellRender: item => item.estado=='1'?'Planificado':item.estado=='2'?'Cita':'Concluido',
      isSortable: true,
    },
    {
      id: "orden",
      columnHeaderLabel: "Orden",
      cellRender: item => item.orden,
      isSortable: true,
    },
    {
      id: "pago",
      columnHeaderLabel: "Pago",
      cellRender: (item) => {
        const { dcc } = item;
        let badge_color = dcc
              ? dcc.estado_pago=='1'
                ? "danger"
                : dcc.estado_pago=='2'
                  ? "warning"
                  : "info"
              : "secondary"
            let precio = dcc ? dcc.precio : item.precio;
            let descuento = "";
            if(!dcc && item.descuento){
              descuento = item.precio + item.descuento
            }
        return (
          <span>
            <span className={`badge badge-${badge_color} badge-pill`}>
              {dcc?dcc.monto_pagado:0}/{precio}
            </span>
            &nbsp;
            <s>{descuento}</s>
          </span>
        )
      },
      isSortable: false,
    },
    {
      id: "acciones",
      columnHeaderLabel: "Acciones",
      cellRender: item =>
        {
          return (
            item.cita_relacionada ? (
                <Icon
                  type="attention"
                  onClick={() => redirectTo(`/nav/atencion/${item.cita_relacionada}/detalle`)}
                  style={{
                    width: "25px",
                    display: "flex",
                  }}/>
            )
              : !item.dcc || !paidDPDTStatus.includes(item.dcc.estado_pago) ? (
              // Edit
                <Icon
                type="edit"
                onClick={() => setDPDTToEdit(item)}
                style={{
                  width: "20px",
                  display: "flex",
                }}/>
              ) : null
            )
          },
      isSortable: false,
    },
    {
      id: "checkbox",
      columnHeaderLabel: "",
      cellRender: item => {
        let finished = item.cita_relacionada && item.dcc && item.dcc.estado_pago == '3'
        return (
          !finished ? <input type='checkbox' id={"html_input_checkbox_dpdt_"+item.pk} checked={isChecked(item)} onChange={() => selectProc(item)} /> : ""
        )
      }
    }
  ]

  const ItemsPerPage = [15, 25, 40, 50]

  return (
    <div className="datatable-container col-12">
      {/* <table className="w-100" ref={inp_dt}></table> */}
      <Datatable columns={columnas} data={dpdts}  itemsPerPageOptions={ItemsPerPage} defaultSortField="orden" />
    </div>
  )
}
const PDTActions = ({pdt, selected, redirectTo, editDPDT, deleteDPDT}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const redirectToCita = () => {
    // Verify selected not empty
    if(selected.length == 0){
      alert("Debe seleccionar al menos un procedimiento");
      return;
    }
    // If any selected dpdt have a related cita already
    let dpdt_er = selected.find(i => i.cita_relacionada)
    if(dpdt_er){
      alertInputRedSpark(dpdt_er)
      return
    }

    redirectTo('/nav/cita/', {
      pdt: pdt.pk,
      patient: pdt.paciente_data,
      selected: selected.map(i => ({dpdt: i.pk, pxs_pk: i.pxs})),
    })
  }
  const openPagosModal = () => {
    // Verify selected not empty
    if(selected.length == 0){
      alert("Debe seleccionar al menos un procedimiento");
      return;
    }
    // If any selected dpdt have been fully paid already
    let dpdt_er = selected.find(i => i.dcc && i.dcc.estado_pago == "3");
    if(dpdt_er){
      alertInputRedSpark(dpdt_er);
      return;
    }

    window.$("#pdtpay_modal").modal('show')
  }
  const openCitaRelModal = () => {
    // Verify selected not empty
    if(selected.length == 0){
      alert("Debe seleccionar al menos un procedimiento")
      return;
    }
    // If any selected dpdt have a cita related already
    let dpdt_er = selected.find(i => i.cita_relacionada);
    if(dpdt_er){
      alertInputRedSpark(dpdt_er);
      return;
    }

    window.$("#citarel_modal").modal('show')
  }
  const openImprimirModal = () => setIsModalOpen(true)
  const closeImprimirModal = () => setIsModalOpen(false)
  const openPDTDoc = () => {
    window.open(
      process.env.REACT_APP_PROJECT_API+
      `atencion/viewdoc/pdt/${pdt.sucursal}/${pdt.paciente}/${pdt.pk}/`
    , '_blank')
  }
  const openPagosDoc = () => {
    window.open(
      process.env.REACT_APP_PROJECT_API+
      `atencion/${pdt.sucursal}/plantrabajo/${pdt.pk}/pagos/doc/`
    , '_blank')
  }

  let btn_style = {
    filter: `opacity(${selected.length ? 1:.5})`
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          Acciones del Plan de Trabajo
        </CardTitle>
      </CardHeader>
      <CardBody className="fs-xl d-flex align-items-center flex-wrap">

        <div className="px-3 text-center col-4 pb-3">
          <Icon type="edit" onClick={editDPDT} />
          <span>Agregar</span>
        </div>

        <div className="px-3 text-center col-4 pb-3" style={btn_style}>
          <Icon type="finance" onClick={openPagosModal} />
          <span>Pagos</span>
        </div>

        <div className="px-3 text-center col-4 pb-3" style={btn_style}>
          <Icon type="add" onClick={redirectToCita} />
          <span>Crear Cita</span>
        </div>

        <div className="px-3 text-center col-4 pb-3" style={btn_style}>
          <Icon type="list" onClick={openCitaRelModal} />
          <span>Enlazar Cita</span>
        </div>

        <div className="px-3 text-center col-4 pb-3" style={btn_style}>
          <div onClick={deleteDPDT}>
            <i className="fs-xxl cursor-pointer fal fa-trash-alt"></i>
          </div>
          <span>Eliminar</span>
        </div>

        <div className="px-3 text-center col-4 pb-3">
          <div onClick={openImprimirModal}>
            <i className="fs-xxl cursor-pointer fal fa-print"></i>
          </div>
          <span>Imprimir</span>
        </div>

      </CardBody>

      <Modal isOpen={isModalOpen}>
        <ModalBody>
          <h3>Imprimir</h3>
          <hr/>
          <div style={{margin: "5px 0", display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px"}}>
            <button className='btn btn-primary' onClick={openPDTDoc}>Plan de Trabajo</button>
            <button disabled className='btn btn-primary' onClick={openPagosDoc}>Lista de Pagos</button>
          </div>
          <hr/>
          <button className='btn btn-secondary' onClick={closeImprimirModal}>Cerrar</button>
        </ModalBody>
      </Modal>
    </Card>
  )
}
const ModalAddDPDT = ({show_modal, closeModal, onSubmit, pdt, pxss}) => {
  // PXS
  const inp_precio = useRef()
  const inp_descuento = useRef()
  const opt_pxss = useMemo(() => pxss.map(pxs => ({
    value: pxs.pk,
    label: pxs.nombre.toUpperCase(),
    precio: pxs.precio,
  })), [pxss])
  const [selected_pxs, setSelectedPXS] = useState(false)
  // Otros
  const inp_cantidad = useRef()

  const style = {
    form_div: {
      justifyContent: "space-between",
      paddingLeft: "20px",
    },
    header_inc_list: {
      backgroundColor: "lightblue",
    }
  }

  const validatePayload = payload => {
    let has_error = false;

    if(!payload.pxs){
      alert("Debes seleccionar un procedimiento");
      has_error = true;
    }
    if(isNaN(Number(payload.precio)) || Number(payload.precio) < 0){
      alert("Precio invalido");
      has_error = true;
    }
    if(isNaN(Number(payload.descuento)) || Number(payload.descuento) < 0){
      alert("Descuento invalido");
      has_error = true;
    }
    if(isNaN(Number(payload.cantidad)) || Number(payload.cantidad) < 1){
      alert("Cantidad invalida");
      has_error = true;
    }

    return !has_error;
  }
  const generatePayload = () => {
    let payload = {
      plantrabajo: pdt.pk,
      pxs: selected_pxs?.value,
      precio: inp_precio.current.value.trim(),
      descuento: inp_descuento.current.value.trim(),
      cantidad: inp_cantidad.current.value.trim(),
    }

    if(!validatePayload(payload)) return;

    simplePostData(`atencion/plantrabajo/detalle/`, payload)
    .then(onSubmit)
    .finally(closeModal)
  }
  const updatePriceByProcedure = () => {
    if(!show_modal || !selected_pxs) return;

    inp_precio.current.value = selected_pxs.precio
  }
  const resetSelectedPXS = () => {
    if(!show_modal) setSelectedPXS(false)
  }

  useEffect(updatePriceByProcedure, [selected_pxs, show_modal])
  useEffect(resetSelectedPXS, [show_modal])

  return (
    <Modal isOpen={show_modal} toggle={closeModal}>
      <ModalHeader>
        Editar Procedimientos del Plan de Trabajo
      </ModalHeader>
      <ModalBody>

        {/* Procedimiento */}
        <div className="form-group row" style={style.form_div}>
          <label className="form-label">Procedimiento</label>
          <Select className="col-8 p-0"
            value={selected_pxs}
            onChange={setSelectedPXS}
            options={opt_pxss} />
        </div>

        {/* Precio */}
        <div className="form-group row" style={style.form_div}>
          <label className="form-label">Precio</label>
          <input type="number" className="form-control col-8"
          placeholder="Precio" min="0" ref={inp_precio} />
        </div>

        {/* Descuento */}
        <div className="form-group row" style={style.form_div}>
          <label className="form-label">Descuento</label>
          <input type="number" className="form-control col-8"
          placeholder="Descuento" min="0" defaultValue={0} ref={inp_descuento} />
        </div>

        {/* Cantidad */}
        <div className="form-group row" style={style.form_div}>
          <label className="form-label">Cantidad</label>
          <input type="number" className="form-control col-8"
          min="1" defaultValue="1" ref={inp_cantidad} />
        </div>

      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" onClick={generatePayload}>Crear</button>
        <button className="btn btn-danger" onClick={closeModal}>Cancelar</button>
      </ModalFooter>
    </Modal>
  )
}
const ModalEditDPDT = ({show_modal, closeModal, onSubmit, dpdt}) => {
  // PXS
  const inp_precio = useRef()
  const inp_descuento = useRef()

  const style = {
    form_div: {
      justifyContent: "space-between",
      paddingLeft: "20px",
    },
    header_inc_list: {
      backgroundColor: "lightblue",
    }
  }

  const validatePayload = payload => {
    let has_error = false;
    if(!payload.precio || isNaN(Number(payload.precio)) || Number(payload.precio) < 0){
      alert("Precio invalido");
      has_error = true;
    }
    if(!payload.descuento || isNaN(Number(payload.descuento)) || Number(payload.descuento) < 0){
      alert("Descuento invalido");
      has_error = true;
    }
    return !has_error;
  }
  const generatePayload = () => {
    let payload = {
      precio: inp_precio.current.value.trim(),
      descuento: inp_descuento.current.value.trim(),
    }

    if(!validatePayload(payload)) return;

    simplePostData(`atencion/plantrabajo/detalle/${dpdt.pk}/`, payload, "PATCH")
    .then(onSubmit)
    .finally(closeModal)
  }

  return (
    <Modal isOpen={show_modal} toggle={closeModal}>
      <ModalHeader>
        Editar Procedimiento
      </ModalHeader>
      <ModalBody>

        {/* Precio */}
        <div className="form-group row" style={style.form_div}>
          <label className="form-label">Precio</label>
          <input type="number" className="form-control col-8"
          placeholder="Precio" min="0" defaultValue={dpdt.precio + dpdt.descuento} ref={inp_precio} />
        </div>

        {/* Descuento */}
        <div className="form-group row" style={style.form_div}>
          <label className="form-label">Descuento</label>
          <input type="number" className="form-control col-8"
          placeholder="Descuento" min="0" defaultValue={dpdt.descuento} ref={inp_descuento} />
        </div>

      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" onClick={generatePayload}>Guardar</button>
        <button className="btn btn-danger" onClick={closeModal}>Cancelar</button>
      </ModalFooter>
    </Modal>
  )
}
const ModalPagos = ({pdt, selected, refreshPDT, refreshDPDTs, setSelectPDT}) => {
  return pdt ? (
    <RegularModalCentered _title={"Pagos del Plan de trabajo"}
      _id={"pdtpay_modal"}
      _body={
        <PagoPDT pdt={pdt} selected={selected} refreshPDT={refreshPDT} refreshDPDTs={refreshDPDTs} setSelectPDT={setSelectPDT}/>
      }
      _min_width={"600"} />
  ) : ""
}
const PagoPDT = ({pdt, selected, refreshPDT, refreshDPDTs, setSelectPDT}) => {
  const {current_sucursal} = useContext(NavigationContext)
  let clicked = false
  let deuda = selected.reduce((sum, i) => sum+(i.dcc?i.dcc.deuda:i.precio), 0)

  const sendData = (_client, _mediopago, _tipo_pago) => {
    if(clicked) return
    else clicked = true

    let monto_pagado = window.document.getElementById('pdtpay-monto').value
    let dcc_list = []
    let monto_remain = Number(monto_pagado)
    selected.every(i => {
      if(monto_remain == 0) return false  // End loop
      // Calcular monto del dpdt que se pagara
      let monto = 0
      let _precio = i.dcc ? i.dcc.deuda : i.precio
      if(_precio > monto_remain){
        monto = monto_remain
        monto_remain = 0
      }else{
        monto = _precio
        monto_remain -= monto
      }
      // Agregar al array
      dcc_list.push({dcc: i.dcc?.pk, dpdt: i.pk, monto: monto})
      return true
    })
    // Validations
    if(dcc_list.length == 0){
      // Validate dcc_list is not empty
      handleErrorResponse("paymentform", "Error", "No se puede pagar 0 soles", "warning")
      return
    }

    simplePostData(`finanzas/pago/create/`, {
      paciente: pdt.paciente,
      cliente: _client,
      sucursal: current_sucursal,
      plantrabajo: pdt.pk,
      tipo_pago: _tipo_pago,
      mediopago: _mediopago,
      monto_pagado: monto_pagado,
      dcc_list: dcc_list,
    })
    // Call EP to show comprobante in screen
    .then(res => res.comprobante && window.open(process.env.REACT_APP_PROJECT_API+`fe/comprobante/view/${res.comprobante}/`, "_blank"))
    .then(() => handleErrorResponse('custom', "Exito", "Pago realizado correctamente", 'info'))
    .then(() => refreshPDT(true))
    .then(() => refreshDPDTs())
    .then(() => setSelectPDT([]))
    .finally(() => {
      window.$("#pdtpay_modal").modal('hide')
      clicked = false
    })
  }
  const handleSubmit = (getFormClient, getMedioPago, tipo_pago) => {
    let _client  = getFormClient()
    let _mediopago = getMedioPago()

    if(!_client) return
    if (!_mediopago) {
      handleErrorResponse("paymentform", "Error", "Medio de pago inválido", "warning")
      return
    }

    sendData(_client, _mediopago, tipo_pago)
  }
  const footer_fn = (getFormClient, getMedioPago, tipo_pago) => {
    const checkPayMontoValue = ev => {
      let _monto = Number(ev.target.value)
      if(_monto < 0) ev.target.value = 0
      if(_monto > deuda) ev.target.value = deuda
    }

    return (
      <div>
        <div className="col-sm pb-1">
          <div className="form-group">
            <label className="form-label" htmlFor="pdtpay-monto">Monto a pagar:</label>
            <input type="number" className="form-control" id="pdtpay-monto" min="0" max={deuda} onChange={checkPayMontoValue}/>
          </div>
        </div>
        <button className="btn btn-primary mt-4" onClick={() => handleSubmit(getFormClient, getMedioPago, tipo_pago)}>
          Pagar
        </button>
      </div>
    )
  }

  useEffect(() => {
    window.document.getElementById('pdtpay-monto').value = deuda
  }, [selected])

  return (
    <PaymentForm
      hideButtons={true}
      current_sucursal={current_sucursal}
      patient={pdt.paciente_data}
      footer_fn={footer_fn} />
  )
}
const ModalCitaRelacion = ({pdt, selected, refreshDPDTs}) => (
  <RegularModalCentered _title={"Enlazar procedimiento a Cita"}
    _id={"citarel_modal"}
    _body={
      <CitaRel pdt={pdt} selected={selected} refreshPDT={refreshDPDTs} />
    }
    _min_width={"600"} />
)
const CitaRel = ({pdt, selected, refreshDPDTs}) => {
  const {current_sucursal} = useContext(NavigationContext)
  const [latest_citas, setLatestPacAte] = useState(false)

  const getLastAttendedPatients = (_sucursal_pk, _pac_pk) => {
    simpleGet(`atencion/cita/?filtro={"sucursal":"${_sucursal_pk}", "estado":"-1", "sort":"true", "last": "10", "paciente": "${_pac_pk}"}`)
    .then(setLatestPacAte)
  }
  const enlazarCita = () => {
    let related_cita = window.document.getElementById('cita-to-relate').value
    if(!related_cita) return

    simplePostData('atencion/plantrabajo/cita/relacion/', {
      cita: related_cita,
      dpdt_pks: selected.map(i => i.pk)
    })
    .then(refreshDPDTs)
    .finally(() => window.$("#citarel_modal").modal('hide'))
  }

  useEffect(() => {
    getLastAttendedPatients(current_sucursal, pdt.paciente)
  }, [])

  return !latest_citas
    ? <Loader scale={2} />
    : (
    <div>
      <p>Enlazar los procedimientos seleccionados ({selected.length}) a la Cita seleccionada</p>
      <select className="custom-select form-control" id="cita-to-relate">
        {latest_citas.length == 0 && (
          <p>El paciente no tiene atenciones a las cuales relacionar</p>
        )}
        {latest_citas.map(c => (
          <option key={"latest_citas_"+c.pk} value={c.pk}>
            {`${c.personal.fullname.toUpperCase()} | ${c.hora} - ${c.hora_fin}
            ${c.fecha.split("-").reverse().join('/')} | ${c.programado.length<25?c.programado:c.programado.slice(0, 25)+".."}`}
          </option>
        ))}
      </select>
      <br/><br/>
      <button className="btn btn-primary" onClick={enlazarCita}>Enlazar</button>
      <br/>
      <br/>
      <p style={{color: "orange"}}>* Para desvincular el procedimiento entre a la atención y elimine los procedimientos manualmente</p>
    </div>
  )
}
// General helper functions
const alertInputRedSpark = dpdt => {
  let html_input = window.document.getElementById("html_input_checkbox_dpdt_"+dpdt.pk)
  if(!html_input) return
  html_input.style.filter = "hue-rotate(140deg)"
  setTimeout(a => a.style.filter = "hue-rotate(00deg)", 400, html_input)
  setTimeout(a => a.style.filter = "hue-rotate(140deg)", 800, html_input)
  setTimeout(a => a.style.filter = "hue-rotate(00deg)", 1200, html_input)
  setTimeout(a => a.style.filter = "hue-rotate(140deg)", 1600, html_input)
  setTimeout(a => a.style.filter = "hue-rotate(00deg)", 2000, html_input)
}


export default PlanDeTrabajo
