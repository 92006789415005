/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import {
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
} from "react-router-dom";
import {
  simpleGet,
  getDataByPK,
  simplePostData,
  handleErrorResponse,
  capitalizeFirstLetter as cFL
} from '../../functions';
import { PageTitle, RegularModalCentered } from '../bits';
import { NavigationContext } from '../Navigation'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardHeader, CardTitle, CardBody,
} from 'reactstrap'
import Select from 'react-select'
import Loader from '../loader/Loader'
import OdontogramHistory from './OdontogramHistory';
import useModal from '../../hooks/useModal';
import SimpleModal2 from '../layout/SimpleModal2';
import { ModalContextProvider } from '../../context/modalContext';
import jsPDF from 'jspdf';
import {
  getCurrentCenterPaths,
  getExtraCenterIncPaths,
  getCenterToothKeys
} from './utils';

// Constant
const __debug__ = process.env.REACT_APP_DEBUG == "true"

// General properties
let teeth;
let teeth_a=[], teeth_k=[];
let ctx;  // Context 2d
let select_type = 4;  // Tooth select
let currentTooth = {tooth: null, path: null, preserve: false};
// Objects properties
const scale = 0.9;
const preview_scale = 2.5;
const preview_margin = 3;
const preview_select_margin = 2;
// General settings
const settings = {
  strokeColor: "black",
  hovercolor: "orange",
  toothhovercolor: "#0002",
  tooth_spacing: 7*scale,
  width: 30*scale,
  height: 65*scale,
  root_height: 32*scale,
  data_height: 35*scale,
  data_space: 80*scale,
}
// Odontogram constant data
let build_data = {
  build_adult_top: [
    {key: 18, orientation: 'U', type: 0, root: 3},
    {key: 17, orientation: 'U', type: 0, root: 3},
    {key: 16, orientation: 'U', type: 0, root: 3},
    {key: 15, orientation: 'U', type: 1, root: 1},
    {key: 14, orientation: 'U', type: 2, root: 2},
    {key: 13, orientation: 'U', type: 3},
    {key: 12, orientation: 'U', type: 3},
    {key: 11, orientation: 'U', type: 3},
    {key: 21, orientation: 'U', type: 3},
    {key: 22, orientation: 'U', type: 3},
    {key: 23, orientation: 'U', type: 3},
    {key: 24, orientation: 'U', type: 1, root: 2},
    {key: 25, orientation: 'U', type: 1, root: 1},
    {key: 26, orientation: 'U', type: 0, root: 3},
    {key: 27, orientation: 'U', type: 0, root: 3},
    {key: 28, orientation: 'U', type: 0, root: 3},
  ],
  build_kid_top: [
    {key: 55, orientation: 'U', type: 0, root: 3},
    {key: 54, orientation: 'U', type: 0, root: 3},
    {key: 53, orientation: 'U', type: 3},
    {key: 52, orientation: 'U', type: 3},
    {key: 51, orientation: 'U', type: 3},
    {key: 61, orientation: 'U', type: 3},
    {key: 62, orientation: 'U', type: 3},
    {key: 63, orientation: 'U', type: 3},
    {key: 64, orientation: 'U', type: 0, root: 3},
    {key: 65, orientation: 'U', type: 0, root: 3},
  ],
  build_adult_bottom: [
    {key: 48, orientation: 'D', type: 0, root: 2},
    {key: 47, orientation: 'D', type: 0, root: 2},
    {key: 46, orientation: 'D', type: 0, root: 2},
    {key: 45, orientation: 'D', type: 1, root: 1},
    {key: 44, orientation: 'D', type: 1, root: 1},
    {key: 43, orientation: 'D', type: 3},
    {key: 42, orientation: 'D', type: 3},
    {key: 41, orientation: 'D', type: 3},
    {key: 31, orientation: 'D', type: 3},
    {key: 32, orientation: 'D', type: 3},
    {key: 33, orientation: 'D', type: 3},
    {key: 34, orientation: 'D', type: 1, root: 1},
    {key: 35, orientation: 'D', type: 1, root: 1},
    {key: 36, orientation: 'D', type: 0, root: 2},
    {key: 37, orientation: 'D', type: 0, root: 2},
    {key: 38, orientation: 'D', type: 0, root: 2},
  ],
  build_kid_bottom: [
    {key: 85, orientation: 'D', type: 0, root: 2},
    {key: 84, orientation: 'D', type: 0, root: 2},
    {key: 83, orientation: 'D', type: 3},
    {key: 82, orientation: 'D', type: 3},
    {key: 81, orientation: 'D', type: 3},
    {key: 71, orientation: 'D', type: 3},
    {key: 72, orientation: 'D', type: 3},
    {key: 73, orientation: 'D', type: 3},
    {key: 74, orientation: 'D', type: 0, root: 2},
    {key: 75, orientation: 'D', type: 0, root: 2},
  ]
}
let odontogram_squares = {square_top: null, square_bottom: null};
// Classes
class Tooth {
  constructor(ctx, x, y, key, orientation, body, incidents){
    this.teeth = false;  // To be setted later on
    this.ctx = ctx;
    this.key = key;
    this.orientation = orientation;
    this.x = x;
    this.y = y;
    this.body = body;
    this.incidents = incidents ? incidents : [];
    // Generate general elements
    this.drawBase();
    this.drawToothComponents();
  }
  drawBase(){
    let x = this.x;
    let y = this.y;
    let width = this.body===2 ? settings.width*1.3 : settings.width;  // Fix width by body
    let height = settings.height;
    let root_height = settings.root_height;
    let data_height = settings.data_height;
    let data_space = settings.data_space;
    let _y;

    // Data area
    let _data_area = new Path2D();
    _y = this.orientation==='U' ? y-(data_space+data_height) : y-data_height+height;
    _data_area.rect(x, _y, width, data_height);
    this.data_area = _data_area;

    // General area
    let _area = new Path2D();
    _y = this.orientation==='U' ? y : y-(data_space+data_height);
    _area.rect(x, _y, width, height);
    this.area = _area;
    // Root area
    let _root_area = new Path2D();
    _y = this.orientation==='U' ? y : y-data_space-2;  // Fix pixel
    _root_area.rect(x, _y, width, root_height);
    this.root_area = _root_area;
    // Tooth area
    let _tooth = new Path2D();
    _y = this.orientation==='U' ? y+root_height : y-(data_space+data_height);
    _tooth.rect(x, _y, width, height-root_height);
    this.tooth = _tooth;
  }
  drawToothComponents(){
    let x = this.x;
    let y = this.y;
    let data_height = settings.data_height;
    let data_space = settings.data_space;
    y = this.orientation==='U' ? y+settings.root_height : y-(data_space+data_height);
    let width = this.body===2 ? settings.width*1.3 : settings.width;  // Fix width by body
    let height = settings.height-settings.root_height;
    // line into tooth
    let tooth_line_up = 0;
    let tooth_line_down = 0;
    let tooth_line_right = 0;
    let tooth_line_left = 0;
    if(this.body===2){
      tooth_line_up = 1/4;
      tooth_line_down = 3/4;
      tooth_line_left = 1/4;
      tooth_line_right = 3/4;
    }else if(this.body===1){
      tooth_line_up = 1/2;
      tooth_line_down = 1/2;
      tooth_line_left = 1/3;
      tooth_line_right = 2/3;
    }

    // Top part
    let tooth_top = new Path2D();
    tooth_top.moveTo(x, y);
    tooth_top.lineTo(x+width*tooth_line_left, y+height*tooth_line_up);
    tooth_top.lineTo(x+width*tooth_line_right, y+height*tooth_line_up);
    tooth_top.lineTo(x+width, y);
    tooth_top.moveTo(x+width-1, y);  // Fix pixel
    tooth_top.lineTo(x, y);
    this.tooth_top = tooth_top;
    // Bottom part
    let tooth_bottom = new Path2D();
    tooth_bottom.moveTo(x, y+height);
    tooth_bottom.lineTo(x+width*tooth_line_left, y+height*tooth_line_down);
    tooth_bottom.lineTo(x+width*tooth_line_right, y+height*tooth_line_down);
    tooth_bottom.lineTo(x+width, y+height);
    tooth_bottom.moveTo(x+width-1, y+height);  // Fix pixel
    tooth_bottom.lineTo(x, y+height);
    this.tooth_bottom = tooth_bottom;
    // Left part
    let tooth_left = new Path2D();
    tooth_left.moveTo(x, y);
    tooth_left.lineTo(x+width*tooth_line_left, y+height*tooth_line_up);
    tooth_left.lineTo(x+width*tooth_line_left, y+height*tooth_line_down);
    tooth_left.lineTo(x, y+height);
    tooth_left.moveTo(x, y+height);  // Fix pixel
    tooth_left.lineTo(x, y);
    this.tooth_left = tooth_left;
    // Right part
    let tooth_right = new Path2D();
    tooth_right.moveTo(x+width, y);
    tooth_right.lineTo(x+width*tooth_line_right, y+height*tooth_line_up);
    tooth_right.lineTo(x+width*tooth_line_right, y+height*tooth_line_down);
    tooth_right.lineTo(x+width, y+height);
    tooth_right.moveTo(x+width, y+height);  // Fix pixel
    tooth_right.lineTo(x+width, y);
    this.tooth_right = tooth_right;
    // Center part
    let tooth_center = new Path2D();
    let tooth_center_tl = new Path2D();
    let tooth_center_tr = new Path2D();
    let tooth_center_bl = new Path2D();
    let tooth_center_br = new Path2D();
    if(this.body===2){
      // Center area
      tooth_center.moveTo(x+width*tooth_line_left, y+height*tooth_line_up);
      tooth_center.lineTo(x+width*tooth_line_right, y+height*tooth_line_up);
      tooth_center.lineTo(x+width*tooth_line_right, y+height*tooth_line_down);
      tooth_center.lineTo(x+width*tooth_line_left, y+height*tooth_line_down);
      tooth_center.lineTo(x+width*tooth_line_left, y+height*tooth_line_up);  // Return
      // Center top left
      tooth_center_tl.moveTo(x+width*tooth_line_left, y+height*tooth_line_up);
      tooth_center_tl.lineTo(x+width/2, y+height*tooth_line_up);
      tooth_center_tl.lineTo(x+width/2, y+height/2);
      tooth_center_tl.lineTo(x+width*tooth_line_left, y+height/2);
      tooth_center_tl.lineTo(x+width*tooth_line_left, y+height*tooth_line_up);
      // Center top right
      tooth_center_tr.moveTo(x+width/2, y+height*tooth_line_up);
      tooth_center_tr.lineTo(x+width*tooth_line_right, y+height*tooth_line_up);
      tooth_center_tr.lineTo(x+width*tooth_line_right, y+height/2);
      tooth_center_tr.lineTo(x+width/2, y+height/2);
      tooth_center_tr.lineTo(x+width/2, y+height*tooth_line_up);
      // Center bottom right
      tooth_center_br.moveTo(x+width/2, y+height/2);
      tooth_center_br.lineTo(x+width*tooth_line_right, y+height/2);
      tooth_center_br.lineTo(x+width*tooth_line_right, y+height*tooth_line_down);
      tooth_center_br.lineTo(x+width/2, y+height*tooth_line_down);
      tooth_center_br.lineTo(x+width/2, y+height/2);
      // Center bottom left
      tooth_center_bl.moveTo(x+width*tooth_line_left, y+height/2);
      tooth_center_bl.lineTo(x+width/2, y+height/2);
      tooth_center_bl.lineTo(x+width/2, y+height*tooth_line_down);
      tooth_center_bl.lineTo(x+width*tooth_line_left, y+height*tooth_line_down);
      tooth_center_bl.lineTo(x+width*tooth_line_left, y+height/2);
    }
    this.tooth_center = tooth_center;
    this.tooth_center_tl = tooth_center_tl;
    this.tooth_center_tr = tooth_center_tr;
    this.tooth_center_bl = tooth_center_bl;
    this.tooth_center_br = tooth_center_br;

    // Lines
    // IMPORTANT: Do not change the order of the following variables, it'd cause bugs at saving incidence
    this.tooth_lines = [];

    let line_t = new Path2D();  // Top
    line_t.moveTo(x, y);
    line_t.lineTo(x+width, y);
    this.tooth_lines.push(line_t);

    let line_r = new Path2D();  // Right
    line_r.moveTo(x+width, y);
    line_r.lineTo(x+width, y+height);
    this.tooth_lines.push(line_r);

    let line_b = new Path2D();  // Bottom
    line_b.moveTo(x, y+height);
    line_b.lineTo(x+width, y+height);
    this.tooth_lines.push(line_b);

    let line_l = new Path2D();  // Left
    line_l.moveTo(x, y);
    line_l.lineTo(x, y+height);
    this.tooth_lines.push(line_l);

    let line_tl = new Path2D();  // Top left
    line_tl.moveTo(x, y);
    line_tl.lineTo(x+width*tooth_line_left, y+height*tooth_line_up);
    this.tooth_lines.push(line_tl);

    let line_tr = new Path2D();  // Top right
    line_tr.moveTo(x+width, y);
    line_tr.lineTo(x+width*tooth_line_right, y+height*tooth_line_up);
    this.tooth_lines.push(line_tr);

    let line_br = new Path2D();  // Bottom right
    line_br.moveTo(x+width, y+height);
    line_br.lineTo(x+width*tooth_line_right, y+height*tooth_line_down);
    this.tooth_lines.push(line_br);

    let line_bl = new Path2D();  // Bottom left
    line_bl.moveTo(x, y+height);
    line_bl.lineTo(x+width*tooth_line_left, y+height*tooth_line_down);
    this.tooth_lines.push(line_bl);

    let line_ct1 = new Path2D();  // Center top 1
    let line_ct2 = new Path2D();  // Center top 2
    let line_cr1 = new Path2D();  // Center right 1
    let line_cr2 = new Path2D();  // Center right 2
    let line_cb1 = new Path2D();  // Center bottom 1
    let line_cb2 = new Path2D();  // Center bottom 2
    let line_cl1 = new Path2D();  // Center left 1
    let line_cl2 = new Path2D();  // Center left 2
    let line_cct = new Path2D();  // Center center top
    let line_ccr = new Path2D();  // Center center right
    let line_ccb = new Path2D();  // Center center bottom
    let line_ccl = new Path2D();  // Center center left
    let line_ccc = new Path2D();  // Center center line
    if(this.body===2){
      // Center top 1
      line_ct1.moveTo(x+width*tooth_line_left, y+height*tooth_line_up);
      line_ct1.lineTo(x+width/2, y+height*tooth_line_up);
      // Center center top
      line_cct.moveTo(x+width/2, y+height*tooth_line_up);
      line_cct.lineTo(x+width/2, y+height/2);
      // Center center left
      line_ccl.moveTo(x+width/2, y+height/2);
      line_ccl.lineTo(x+width*tooth_line_left, y+height/2);
      // Center left 2
      line_cl2.moveTo(x+width*tooth_line_left, y+height/2);
      line_cl2.lineTo(x+width*tooth_line_left, y+height*tooth_line_up);
      // Center top 2
      line_ct2.moveTo(x+width/2, y+height*tooth_line_up);
      line_ct2.lineTo(x+width*tooth_line_right, y+height*tooth_line_up);
      // Center right 1
      line_cr1.moveTo(x+width*tooth_line_right, y+height*tooth_line_up);
      line_cr1.lineTo(x+width*tooth_line_right, y+height/2);
      // Center center right
      line_ccr.moveTo(x+width*tooth_line_right, y+height/2);
      line_ccr.lineTo(x+width/2, y+height/2);
      // Center right 2
      line_cr2.moveTo(x+width*tooth_line_right, y+height/2);
      line_cr2.lineTo(x+width*tooth_line_right, y+height*tooth_line_down);
      // Center bottom 1
      line_cb1.moveTo(x+width*tooth_line_right, y+height*tooth_line_down);
      line_cb1.lineTo(x+width/2, y+height*tooth_line_down);
      // Center center bottom
      line_ccb.moveTo(x+width/2, y+height*tooth_line_down);
      line_ccb.lineTo(x+width/2, y+height/2);
      // Center bottom 2
      line_cb2.moveTo(x+width/2, y+height*tooth_line_down);
      line_cb2.lineTo(x+width*tooth_line_left, y+height*tooth_line_down);
      // Center left 1
      line_cl1.moveTo(x+width*tooth_line_left, y+height*tooth_line_down);
      line_cl1.lineTo(x+width*tooth_line_left, y+height/2);
    }else{
      // Center area
      line_ccc.moveTo(x+width*tooth_line_left, y+height*tooth_line_up);
      line_ccc.lineTo(x+width*tooth_line_right, y+height*tooth_line_up);
    }
    this.tooth_lines.push(line_ct1);
    this.tooth_lines.push(line_ct2);
    this.tooth_lines.push(line_cr1);
    this.tooth_lines.push(line_cr2);
    this.tooth_lines.push(line_cb1);
    this.tooth_lines.push(line_cb2);
    this.tooth_lines.push(line_cl1);
    this.tooth_lines.push(line_cl2);
    this.tooth_lines.push(line_cct);
    this.tooth_lines.push(line_ccr);
    this.tooth_lines.push(line_ccb);
    this.tooth_lines.push(line_ccl);
    this.tooth_lines.push(line_ccc);
  }
  drawKey(){
    let x = this.x;
    let y = this.y;
    let width = this.body===2 ? settings.width*1.3 : settings.width;  // Fix width by body
    let height = settings.height;
    let data_height = settings.data_height;
    let data_space = settings.data_space;
    // Tooth key
    this.ctx.strokeStyle = settings.strokeColor;
    if(this.orientation==='U') this.ctx.strokeText(this.key, x-6+width/2, y-data_space+18);
    else if(this.orientation==='D') this.ctx.strokeText(this.key, x-6+width/2, y-(data_space+data_height)+height+data_space-10);
  }
  strokeBold(e){
    this.ctx.beginPath(); this.ctx.lineWidth = 1.5;  // Bold line width
    this.ctx.stroke(e);
    this.ctx.beginPath(); this.ctx.lineWidth = 1;  // Restore line width
  }
  // Setter
  setTeeth(_teeth){
    this.teeth = _teeth
  }

  // Incidents from Manual_Odontograma_Electronico
  selectComponent(key){
    if(typeof(key)==="undefined") return null;  // If component is not specified
    if(key === null) return null;  // If component is not specified
    // Select tooth part
    if(typeof(key)==="number") key = [key];  // Fake array
    // Array of components
    let component = [];
    key.forEach((k) => {
      // IMPORTANT: Do not change the keys of the following cases, it'd cause bugs at saving incidence
      switch(k){
        case 1001: component.push(this.tooth_top); break;
        case 1002: component.push(this.tooth_right); break;
        case 1003: component.push(this.tooth_bottom); break;
        case 1004: component.push(this.tooth_left); break;
        case 1005: component.push(this.tooth_center_tl); break;
        case 1006: component.push(this.tooth_center_tr); break;
        case 1007: component.push(this.tooth_center_br); break;
        case 1008: component.push(this.tooth_center_bl); break;
        // Lines
        case 1100: component.push(this.tooth_lines[0]); break;  // line_t
        case 1101: component.push(this.tooth_lines[1]); break;  // line_r
        case 1102: component.push(this.tooth_lines[2]); break;  // line_b
        case 1103: component.push(this.tooth_lines[3]); break;  // line_l
        case 1104: component.push(this.tooth_lines[4]); break;  // line_tl
        case 1105: component.push(this.tooth_lines[5]); break;  // line_tr
        case 1106: component.push(this.tooth_lines[6]); break;  // line_br
        case 1107: component.push(this.tooth_lines[7]); break;  // line_bl
        case 1108: component.push(this.tooth_lines[8]); break;  // line_ct1
        case 1109: component.push(this.tooth_lines[9]); break;  // line_ct2
        case 1110: component.push(this.tooth_lines[10]); break;  // line_cr1
        case 1111: component.push(this.tooth_lines[11]); break;  // line_cr2
        case 1112: component.push(this.tooth_lines[12]); break;  // line_cb1
        case 1113: component.push(this.tooth_lines[13]); break;  // line_cb2
        case 1114: component.push(this.tooth_lines[14]); break;  // line_cl1
        case 1115: component.push(this.tooth_lines[15]); break;  // line_cl2
        case 1116: component.push(this.tooth_lines[16]); break;  // line_cct
        case 1117: component.push(this.tooth_lines[17]); break;  // line_ccr
        case 1118: component.push(this.tooth_lines[18]); break;  // line_ccb
        case 1119: component.push(this.tooth_lines[19]); break;  // line_ccl
        case 1120: component.push(this.tooth_lines[20]); break;  // line_ccc
        // default: component.push(k); break;
        default: break;
      }
    });

    return component;
  }
  drawIncidents(){
    if(!this.teeth) return  // this.teeth is not setted
    if(!this.incidents) return;  // There is no incidents
    let data = [];  // Array to store tooth incident data
    // Print incident
    this.incidents.forEach((v) => {
      // Select tooth part
      let component = this.selectComponent(v.component);
      // Select incident function
      let _txt;
      switch(v.type){
        case 1: _txt = this.inc_LCD(component, v.value); break;
        case 2: _txt = this.inc_DDE(component, v.value); break;
        case 3: _txt = this.inc_Sellante(component, v.value); break;
        case 4: _txt = this.inc_Fractura(component, v.value); break;
        case 5: _txt = this.inc_FFP(component, v.value); break;
        case 6: _txt = this.inc_PDAusente(component, v.value); break;
        case 7: _txt = this.inc_PDErupcion(component, v.value); break;
        case 8: _txt = this.inc_RestDef(component, v.value); break;
        case 9: _txt = this.inc_RestTemp(component, v.value); break;
        case 10: _txt = this.inc_EdentuloTotal(component, v.value); break;
        case 11: _txt = this.inc_PDSupernumeraria(component, v.value); break;
        case 12: _txt = this.inc_PDExtruida(component, v.value); break;
        case 13: _txt = this.inc_PDIntruida(component, v.value); break;
        case 14: _txt = this.inc_Diastema(component, v.value); break;
        case 15: _txt = this.inc_Giroversion(component, v.value); break;
        case 16: _txt = this.inc_PosDent(component, v.value); break;
        case 17: _txt = this.inc_PDClavija(component, v.value); break;
        case 18: _txt = this.inc_PDEctopica(component, v.value); break;
        case 19: _txt = this.inc_Macrodoncia(component, v.value); break;
        case 20: _txt = this.inc_Microdoncia(component, v.value); break;
        case 21: _txt = this.inc_Fusion(component, v.value); break;
        case 22: _txt = this.inc_Geminacion(component, v.value); break;
        case 23: _txt = this.inc_Impactacion(component, v.value); break;
        case 24: _txt = this.inc_SupDesg(component, v.value); break;
        case 25: _txt = this.inc_RemRad(component, v.value); break;
        case 26: _txt = this.inc_MovPat(component, v.value); break;
        case 27: _txt = this.inc_CoronaTemp(component, v.value); break;
        case 28: _txt = this.inc_Corona(component, v.value); break;
        case 29: _txt = this.inc_EM(component, v.value); break;
        case 30: _txt = this.inc_ImplanteDental(component, v.value); break;
        case 31: _txt = this.inc_AOF(component, v.value); break;
        case 32: _txt = this.inc_AOR(component, v.value); break;
        case 33: _txt = this.inc_PF(component, v.value); break;
        case 34: _txt = this.inc_PR(component, v.value); break;
        case 35: _txt = this.inc_PT(component, v.value); break;
        case 36: _txt = this.inc_TP(component, v.value); break;
        case 37: _txt = this.inc_Transposicion(component, v.value); break;
        case 38: _txt = this.inc_Diente_para_extraer(component, v.value); break;
        case 39: _txt = this.inc_Calculo_dental(component, v.value); break;
        case 40: _txt = this.inc_Curacion_en_buen_estado(component, v.value); break;
        //* Ungrouped incidents
        //Caries
        case 41: _txt = this.inc_LCD(component, v.value); break;
        case 42: _txt = this.inc_LCD(component, v.value); break;
        case 43: _txt = this.inc_LCD(component, v.value); break;
        //Restauracion
        case 44: _txt = this.inc_RestDef(component, v.value); break;
        case 45: _txt = this.inc_RestDef(component, v.value); break;
        case 46: _txt = this.inc_RestDef(component, v.value); break;
        //Endodoncia
        case 47: _txt = this.inc_TP(component, v.value); break;
        //Ausencia dental
        case 48: _txt = this.inc_PDAusente(component, v.value); break;
        //Exodoncia Remanente Radicular
        case 49: _txt = this.inc_RemRad(component, v.value); break;
        //Corona ungrouped incidents
        case 50: _txt = this.inc_Corona(component, v.value); break;
        case 51: _txt = this.inc_Corona(component, v.value); break;
        case 52: _txt = this.inc_Corona(component, v.value); break;
        case 53: _txt = this.inc_Corona(component, v.value); break;
        case 54: _txt = this.inc_TP(component, v.value); break;
        case 55: _txt = this.inc_TP(component, v.value); break;
        //Rayos x
        case 56: _txt = this.inc_RayosX(component, v.value); break;
        //Tratamiento
        case 57: _txt = this.inc_Tratamiento(component, v.value); break;
        //Calculo dental
        case 58: _txt = this.inc_Calculo_dental_All(component, v.value); break;
        default: alert(`ERROR IN TEETH BUILD DATA: incident type ${v.type} not found in key ${v.key}`); break;
      }
      if(_txt) data.push(_txt);  // Add to tooth incident data when method returned
    });
    // Print tooth incident data
    this.ctx.lineWidth = 1;
    data.forEach((d, inx) => {
      let x = this.x + 4;
      let y = this.y + 12*(inx+1);
      let height = settings.height;
      let data_height = settings.data_height;
      let data_space = settings.data_space;
      y = this.orientation==='U' ? y-(data_space+data_height) : y-data_height+height;
      this.ctx.strokeStyle = d.color;
      this.ctx.strokeText(d.log, x, y);
    });
  }
  inc_LCD(c, v){  // 5.3.1  Lesión de caries dental
    // c is array
    this.ctx.fillStyle = "red";
    c.forEach((path) => {
      this.ctx.fill(path);
    });
    return {log: v.log, color: "red"};
  }
  inc_DDE(c, v){  // 5.3.2  Defectos de desarrollo de esmalte
    this.ctx.fillStyle = "red";
    c.forEach((path) => {
      this.ctx.fill(path);
    });
    return {log: v.log, color: "black"};
  }
  inc_Sellante(c, v){  // 5.3.3  Sellantes
    let color = v.state ? "blue" : "red";
    this.ctx.strokeStyle = color;
    this.ctx.lineWidth = 3;
    // c is array
    c.forEach((path) => {
      this.ctx.stroke(path)
    });
    return {log: "S", color: color};
  }
  inc_Fractura(c, v){  // 5.3.4  Fractura
    let y = this.y;
    let width = settings.width*(this.body===2?1.3:1);
    if(this.orientation==='D')
      y += settings.height - (settings.data_space+settings.data_height);

    let vxo;
    let vyo;
    let vxf;
    let vyf;
    switch(v.fractura){
      case 1: vxo=0;vyo=(settings.root_height+settings.height)/2;vxf=width;vyf=vyo; break;  // Crown middle horizontal
      case 2: vxo=width/2;vyo=settings.root_height;vxf=vxo;vyf=settings.height; break;  // Crown middle vertical
      case 3: vxo=width;vyo=settings.root_height;vxf=0;vyf=settings.height; break;  // Diag left
      case 4: vxo=0;vyo=settings.root_height;vxf=width;vyf=settings.height; break;  // Diag right
      case 5: vxo=width/2;vyo=0;vxf=0;vyf=settings.height; break;  // All left
      case 6: vxo=width/2;vyo=0;vxf=width;vyf=settings.height; break;  // All right
      case 7: vxo=width/2;vyo=0;vxf=vxo;vyf=settings.height; break;  // All middle
      default: alert("ERROR FRACTURE CODE NOT FOUND, code: "+v.fractura); return;
    }

    if(this.orientation==='D'){
      vyo *= -1;
      vyf *= -1;
    }

    let xo = this.x + vxo;
    let yo = y + vyo;
    let xf = this.x + vxf;
    let yf = y + vyf;

    this.ctx.strokeStyle = "red";
    this.ctx.lineWidth = 3;
    this.ctx.beginPath();
    this.ctx.moveTo(xo, yo);
    this.ctx.lineTo(xf, yf);
    this.ctx.stroke();
  }
  inc_FFP(c, v){  // 5.3.5  Fosas y fisuras profundas
    return {log: "FFP", color: "blue"};
  }
  inc_PDAusente(c, v){  // 5.3.6  Pieza dentaria ausente
    let width = settings.width*(this.body===2?1.3:1);
    let height = settings.height;
    let y = this.y;
    if(this.orientation==='D'){
      height *= -1;
      y += settings.height - (settings.data_height+settings.data_space);
    }

    this.ctx.strokeStyle = "blue";
    this.ctx.lineWidth = 3;
    this.ctx.beginPath();
    this.ctx.moveTo(this.x, y);
    this.ctx.lineTo(this.x+width, y+height);
    this.ctx.moveTo(this.x+width, y);
    this.ctx.lineTo(this.x, y+height);
    this.ctx.stroke();
  }
  inc_PDErupcion(c, v){  // 5.3.7  Pieza dentaria en erupción
    let height = settings.height
    let width = settings.width*(this.body===2?1.3:1);
    let y = this.y;
    let x = this.x;
    if(this.orientation==='D'){
      height *= -1;
      y += settings.height - (settings.data_height+settings.data_space) - 3;
    }
    let eruptionX_0 = 1/3;
    let eruptionY_0 = 0/5;
    let eruptionX_1 = 2/3;
    let eruptionY_1 = 1/5;
    let eruptionX_2 = 1/3;
    let eruptionY_2 = 2/5;
    let eruptionX_3 = 1/2;
    let eruptionY_3 = 3/5;
    let eruptionX_4 = 1/3;
    let eruptionY_4 = 4/5;

    // Draw a blue x mark
    this.ctx.strokeStyle = "blue";
    this.ctx.lineWidth = 5;
    this.ctx.beginPath();
    this.ctx.moveTo(x+width*eruptionX_0, y+height*eruptionY_0);
    this.ctx.lineTo(x+width*eruptionX_1, y+height*eruptionY_1);
    this.ctx.lineTo(x+width*eruptionX_2, y+height*eruptionY_2);
    this.ctx.lineTo(x+width*eruptionX_3, y+height*eruptionY_3);
    this.ctx.lineTo(x+width*eruptionX_4, y+height*eruptionY_4);
    // Arrow
    this.ctx.moveTo(x+width*eruptionX_4, y+height*eruptionY_4);
    this.ctx.lineTo(
      x+width*eruptionX_4-5,
      y+height*eruptionY_4-10*Math.sign(height)
    );
    this.ctx.moveTo(x+width*eruptionX_4-2, y+height*eruptionY_4+2);
    this.ctx.lineTo(
      x+width*eruptionX_4+13,
      y+height*eruptionY_4-5*Math.sign(height)
    );

    this.ctx.stroke();
  }
  inc_RestDef(c, v){  // 5.3.8  Restauración definitiva
    let color = v.state ? "blue" : "red";
    this.ctx.fillStyle = color;
    // c is array
    c.forEach((path) => {
      this.ctx.fill(path)
    });
    return {log: v.log, color: color};
  }
  inc_RestTemp(c, v){  // 5.3.9  Restauración temporal
    this.ctx.strokeStyle = "red";
    this.ctx.lineWidth = 3;
    // c is array
    c.forEach((path) => {
      this.ctx.stroke(path)
    });
  }
  inc_EdentuloTotal(c, v){  // 5.3.10  Edéntulo Total
    /* This function must be called by the last tooth of teeths
      otherwise another incident may overdraw this draw
    */
    let direction = ["1", "2", "5", "6"].includes(String(this.key)[0]);
    let _teeth;
    if(direction) _teeth = this.teeth.upper_teeth;
    else _teeth = this.teeth.lower_teeth;
    let last_index = _teeth.length-1;
    let last_width = settings.width;
    if(_teeth[last_index].body===2) last_width*=1.3;
    let y = this.y;
    if(this.orientation==='D'){
      y -= settings.data_space+settings.root_height+settings.data_height;
    }

    this.ctx.strokeStyle = "blue";
    this.ctx.lineWidth = 4;
    this.ctx.beginPath();
    this.ctx.moveTo(_teeth[0].x, y+(settings.height+settings.root_height)/2);
    this.ctx.lineTo(_teeth[last_index].x+last_width, y+(settings.height+settings.root_height)/2);
    this.ctx.stroke();
  }
  inc_PDSupernumeraria(c, v){  // 5.3.11  Pieza dentaria supernumeraria
    let x = this.x + (v.orientation==='R' ? settings.width : 0);
    let y = this.y;
    if(this.orientation==='D'){
      y += settings.height - (settings.data_height+settings.data_space);
    }

    this.ctx.strokeStyle = "blue";
    this.ctx.lineWidth = 2;
    this.ctx.beginPath();
    this.ctx.arc(x, y, 8, 0, 2*Math.PI);
    this.ctx.stroke();
    this.ctx.lineWidth = 1;
    this.ctx.strokeText("S", x-3, y+3);
  }
  inc_PDExtruida(c, v){  // 5.3.12  Pieza dentaria extruida
    let y = this.y + settings.height+30;
    let x = this.x + (this.body===2?1.3:1)*settings.width/2;
    let height = 25;
    if(this.orientation==='D'){
      height *= -1;
      y -= settings.height*2+settings.data_space+settings.data_height;
    }

    this.ctx.lineWidth = 3;
    this.ctx.strokeStyle = "blue";
    this.ctx.beginPath();
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x, y-height);
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x-8, y-height/2);
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x+8, y-height/2);

    this.ctx.stroke();
  }
  inc_PDIntruida(c, v){  // 5.3.13  Pieza dentaria intruida
    let y = this.y + settings.height+5;
    let x = this.x + (this.body===2?1.3:1)*settings.width/2;
    let height = 25;
    if(this.orientation==='D'){
      height *= -1;
      y -= settings.height+settings.data_space+settings.data_height+10;
    }

    this.ctx.lineWidth = 3;
    this.ctx.strokeStyle = "blue";
    this.ctx.beginPath();
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x, y+height);
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x-8, y+height/2);
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x+8, y+height/2);

    this.ctx.stroke();
  }
  inc_Diastema(c, v){  // 5.3.14  Diastema
    let diastema_space = 2;
    let width = settings.width;
    let radius = settings.width;
    let x = this.x;
    let y = this.y + settings.root_height + (settings.height - settings.root_height)/2;
    let left = x + width - diastema_space;
    let right = x + diastema_space;
    if(this.orientation==='D'){
      y -= settings.root_height+settings.data_space+settings.data_height;
    }

    this.ctx.lineWidth = 3;
    this.ctx.strokeStyle = "blue";
    this.ctx.beginPath();
    if(v.orientation==='R'){
      right += this.body===2 ? width*.3 : 0;
      this.ctx.arc(right, y, radius, -40*Math.PI/180, 40*Math.PI/180);
    }else{
      this.ctx.arc(left, y, radius, 140*Math.PI/180, 220*Math.PI/180);
    }
    this.ctx.stroke();
  }
  inc_Giroversion(c, v){  // 5.3.15  Giroversión
    let cy = this.y;
    let y = this.y + settings.height + (this.body===2 ? 8 : 9);
    let width = this.body===2 ? settings.width*1.3/2 : settings.width/2;
    let x = this.x + width - (v.orientation==='R' ? this.body===2 ? -18 : -14 : this.body===2 ? 19 : 15);
    let angel_o = this.body===2 ? 65 : 70;
    let angel_f = this.body===2 ? 115 : 110;
    let _sign = v.orientation==='R' ? 1 : -1;
    if(this.orientation==='D'){
      y -= settings.data_space + settings.data_height + settings.height + 19;
      cy -= settings.data_space + (this.body===2 ? 6 : 3);
      angel_o += 180;
      angel_f += 180;
    }else if(this.orientation==='U'){
      cy += settings.root_height;
    }

    this.ctx.lineWidth = 3;
    this.ctx.strokeStyle = "blue";
    this.ctx.beginPath();
    this.ctx.arc(this.x+width, cy, 40, angel_o*Math.PI/180, angel_f*Math.PI/180);
    this.ctx.moveTo(x, y-2);
    this.ctx.lineTo(x-6*_sign, y+7);
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x-6*_sign, y-7);
    this.ctx.stroke();
  }
  inc_PosDent(c, v){  // 5.3.16  Posición dentaria
    return {log: v.log, color: "blue"};
  }
  inc_PDClavija(c, v){  // 5.3.17  Pieza dentaria en clavija
    let data_space = settings.data_space;
    let x = this.x;
    let y = this.y - data_space;
    let width = this.body===2 ? settings.width*1.3 : settings.width;
    if(this.orientation==='D'){
      y += data_space + settings.root_height - 8;
    }else if(this.orientation==='U'){
      y += 22;
    }

    this.ctx.strokeStyle = "blue";
    this.ctx.lineWidth = 3;
    this.ctx.beginPath();
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x+width/2, y-20);
    this.ctx.lineTo(x+width, y);
    this.ctx.lineTo(x, y);
    this.ctx.stroke();
  }
  inc_PDEctopica(c, v){  // 5.3.18  Pieza dentaria ectópica
    return {log: "E", color: "blue"};
  }
  inc_Macrodoncia(c, v){  // 5.3.19  Macrodoncia
    return {log: "MAC", color: "blue"};
  }
  inc_Microdoncia(c, v){  // 5.3.20  Microdoncia
    return {log: "MIC", color: "blue"};
  }
  inc_Fusion(c, v){  // 5.3.21  Fusión
    let data_space = settings.data_space;
    let width = this.body===2 ? settings.width*1.3 : settings.width;
    let x = this.x + width/2;
    let y = this.y - data_space - 7;
    if(this.orientation==='D'){
      y += data_space + settings.root_height - 8;
    }else if(this.orientation==='U'){
      y += 21;
    }
    let sign1 = 1;
    let sign2 = -1;
    if(v.orientation==='L'){
      sign1 = -1;
      sign2 = 1;
    }

    this.ctx.strokeStyle = "blue";
    this.ctx.lineWidth = 3;
    this.ctx.beginPath();
    this.ctx.arc(x, y, 10, sign1*Math.PI/2, sign2*Math.PI/2);
    this.ctx.ellipse(x, y, width*3/4, 10, 0, sign2*Math.PI/2, sign1*Math.PI/2)
    this.ctx.stroke();
  }
  inc_Geminacion(c, v){  // 5.3.22  Geminación
    let data_space = settings.data_space;
    let width = this.body===2 ? settings.width*1.3 : settings.width;
    let x = this.x + width/2;
    let y = this.y - data_space - 7;
    if(this.orientation==='D'){
      y += data_space + settings.root_height - 8;
    }else if(this.orientation==='U'){
      y += 21;
    }

    this.ctx.strokeStyle = "blue";
    this.ctx.lineWidth = 3;
    this.ctx.beginPath();
    this.ctx.arc(x, y, 10, 0, 2*Math.PI);
    this.ctx.stroke();
  }
  inc_Impactacion(c, v){  // 5.3.23  Impactación
    return {log: "I", color: "blue"};
  }
  inc_SupDesg(c, v){  // 5.3.24  Superficie desgastada
    this.ctx.strokeStyle = "red";
    this.ctx.lineWidth = 3;
    // c is array
    c.forEach((path) => {
      this.ctx.stroke(path)
    });
    return {log: "DES", color: "red"};
  }
  inc_RemRad(c, v){  // 5.3.25  Remanente radicular
    return {log: "RR", color: "red"};
  }
  inc_MovPat(c, v){  // 5.3.26  Movilidad Patológica
    v.log = isFinite(v.log) ? v.log : '';
    return {log: "M"+v.log, color: "red"};
  }
  inc_CoronaTemp(c, v){  // 5.3.27  Corona temporal
    this.ctx.lineWidth = 3;
    this.ctx.strokeStyle = "red";
    this.ctx.stroke(this.tooth);
    this.ctx.lineWidth = 1;
    return {log: "CT", color: "red"};
  }
  inc_Corona(c, v){  // 5.3.28  Corona
    this.ctx.lineWidth = 3;
    this.ctx.strokeStyle = v.state ? "blue" : "red";
    this.ctx.stroke(this.tooth);
    this.ctx.lineWidth = 1;

    return {log: v.log, color: "blue"};
  }
  inc_EM(c, v){  // 5.3.29  Espigo muñon
    let y = this.y;
    let width = settings.width*(this.body===2 ? 1.3 : 1);
    let height = settings.height;
    let sign = 1;
    if(this.orientation==='D'){
      height *= -1;
      y -= settings.data_space+settings.data_height+height;
      sign = -1;
    }

    this.ctx.strokeStyle = v.state ? "blue" : "red";
    // Draw vertical line
    this.ctx.lineWidth = 4;
    this.ctx.beginPath();
    this.ctx.moveTo(this.x+width/2, y);
    this.ctx.lineTo(this.x+width/2, y+height/2+sign*settings.root_height/2);
    this.ctx.stroke();
    // Draw square
    this.ctx.lineWidth = 3;
    if(this.body===2){
      this.ctx.stroke(this.tooth_center);
    }else{
      let long = 12
      this.ctx.rect(this.x+width/2-long/2, y+height/2+sign*settings.root_height/2-long/2, long, long);
      this.ctx.stroke();
    }
  }
  inc_ImplanteDental(c, v){  // 5.3.30  Implante dental
    let color = v.state ? "blue" : "red";
    return {log: "IMP", color: color};
  }
  inc_AOF(c, v){  // 5.3.31  Aparato ortodóntico fijo
    /* This function must be called by the last tooth of the affected teeth */
    let direction = ["1", "2", "5", "6"].includes(String(this.key)[0]);
    let _teeth;
    if(direction) _teeth = this.teeth.upper_teeth;
    else if(!direction) _teeth = this.teeth.lower_teeth;
    let start_index;
    let _inx = _teeth.some((tooth, inx) => {
      if(tooth.key==v.start_tooth_key){
        start_index = inx;
        return true;
      }
      return false;
    });
    if(!_inx){
      console.error("SOMETHING WENT WRONG, START_TOOTH_KEY VALUE WASN'T FOUND")
      return;
    }
    let y = this.y - 25;
    let height = 15;  // Size of the square face

    this.ctx.strokeStyle = v.state ? "blue" : "red";
    this.ctx.lineWidth = 3;
    this.ctx.beginPath();
    let width = settings.width*(_teeth[start_index].body===2 ? 1.3 : 1);
    // Rectangle
    this.ctx.rect(_teeth[start_index].x, y-height/2, height, height)
    this.ctx.moveTo(_teeth[start_index].x+height, y);
    this.ctx.lineTo(this.x+width-height, y);
    // Rectangle
    this.ctx.rect(this.x+width-height, y-height/2, height, height)
    this.ctx.stroke();
    this.ctx.beginPath();
    this.ctx.lineWidth = 2;
    this.ctx.strokeText("+", _teeth[start_index].x+height/3, y+height/4);
    this.ctx.strokeText("+", this.x+width-height*11/16, y+height/4)
  }
  inc_AOR(c, v){  // 5.3.32  Aparato ortodóntico removible
    /* This function must be called by the last tooth of teeths
      otherwise another incident may overdraw this draw
    */
    let direction = ["1", "2", "5", "6"].includes(String(this.key)[0]);
    let _teeth = [];
    if(direction) _teeth = this.teeth.upper_teeth;
    else if(!direction) _teeth = this.teeth.lower_teeth;
    let y = this.y - 10;
    let height = 15;
    if(this.orientation==='D'){
      height *= -1;
      y -= 20;
    }

    this.ctx.strokeStyle = v.state ? "blue" : "red";
    this.ctx.lineWidth = 4;
    this.ctx.beginPath();
    this.ctx.moveTo(_teeth[0].x, y);
    _teeth.forEach((_tooth) => {
      let width = settings.width*(_tooth.body===2 ? 1.3 : 1);
      this.ctx.lineTo(_tooth.x, y);
      this.ctx.lineTo(_tooth.x+width/2, y-height);
      this.ctx.lineTo(_tooth.x+width+settings.tooth_spacing/2, y);
    });
    this.ctx.stroke();
  }
  inc_PF(c, v){  // 5.3.33  Prótesis fija
    /* This function must be called by the last tooth of teeths
      otherwise another incident may overdraw this draw
    */
    let direction = ["1", "2", "5", "6"].includes(String(this.key)[0]);
    let _teeth = direction ? this.teeth.upper_teeth : this.teeth.lower_teeth
    let start_index;
    let _inx = _teeth.some((tooth, inx) => {
      if(tooth.key==v.start_tooth_key){
        start_index = inx;
        return true;
      }
      return false;
    });
    if(!_inx){
      alert("SOMETHING WENT WRONG, START_TOOTH_KEY VALUE WASN'T FOUND")
      return;
    }
    let y = this.y - 25;
    let height = 15;
    if(this.orientation==='D'){
      height *= -1;
    }

    this.ctx.strokeStyle = v.state ? "blue" : "red";
    this.ctx.lineWidth = 4;
    this.ctx.beginPath();
    // Left corner
    this.ctx.moveTo(_teeth[start_index].x, y+height);
    let width = settings.width*(_teeth[start_index].body===2 ? 1.3 : 1);
    this.ctx.lineTo(_teeth[start_index].x, y);
    // Right corner
    this.ctx.lineTo(this.x+width, y);
    this.ctx.lineTo(this.x+width, y+height);
    this.ctx.stroke();
  }
  inc_PR(c, v){  // 5.3.34  Prótesis removible
    /* This function must be called by the last tooth of the affected teeth */
    let direction = ["1", "2", "5", "6"].includes(String(this.key)[0]);
    let _teeth;
    if(direction) _teeth = this.teeth.upper_teeth;
    else if(!direction) _teeth = this.teeth.lower_teeth;
    let start_index;
    let _inx = _teeth.some((tooth, inx) => {
      if(tooth.key==v.start_tooth_key){
        start_index = inx;
        return true;
      }
      return false;
    });
    if(!_inx){
      alert("SOMETHING WENT WRONG, START_TOOTH_KEY VALUE WASN'T FOUND")
      return;
    }
    let y = this.y - 25;
    let height = 5;
    if(this.orientation==='D'){
      height *= -1;
    }

    this.ctx.strokeStyle = v.state ? "blue" : "red";
    this.ctx.lineWidth = 3;
    this.ctx.beginPath();
    let width = settings.width*(_teeth[start_index].body===2 ? 1.3 : 1);
    // Outter line
    this.ctx.moveTo(_teeth[start_index].x, y);
    this.ctx.lineTo(this.x+width, y);
    // Inner line
    this.ctx.moveTo(_teeth[start_index].x, y+height);
    this.ctx.lineTo(this.x+width, y+height);
    this.ctx.stroke();
  }
  inc_PT(c, v){  // 5.3.35  Prótesis total
    /* This function must be called by the last tooth of the affected teeth */
    let direction = ["1", "2", "5", "6"].includes(String(this.key)[0]);
    let _teeth = [];
    if(direction) _teeth = this.teeth.upper_teeth;
    else if(!direction) _teeth = this.teeth.lower_teeth;
    let start_index = 0;
    let y = this.y - 25;
    let height = 5;
    if(this.orientation==='D'){
      height *= -1;
    }

    this.ctx.strokeStyle = v.state ? "blue" : "red";
    this.ctx.lineWidth = 3;
    this.ctx.beginPath();
    let width = settings.width*(_teeth[start_index].body===2 ? 1.3 : 1);
    // Outter line
    this.ctx.moveTo(_teeth[start_index].x, y);
    this.ctx.lineTo(_teeth[start_index].x+width+settings.tooth_spacing/2, y);
    this.ctx.lineTo(this.x+width+settings.tooth_spacing/2, y);
    // Inner line
    this.ctx.moveTo(_teeth[start_index].x, y+height);
    this.ctx.lineTo(_teeth[start_index].x+width+settings.tooth_spacing/2, y+height);
    this.ctx.lineTo(this.x+width+settings.tooth_spacing/2, y+height);
    this.ctx.stroke();
  }
  inc_TP(c, v){  // 5.3.36  Tratamiento pulpar
    let y = this.y;
    let width = settings.width*(this.body===2 ? 1.3 : 1);
    let height = settings.height;
    let sign = 1;
    if(this.orientation==='D'){
      height *= -1;
      y -= settings.data_space+settings.data_height+height;
      sign = -1;
    }

    this.ctx.strokeStyle = v.state ? "blue" : "red";
    // Draw vertical line
    this.ctx.lineWidth = 4;
    this.ctx.beginPath();
    this.ctx.moveTo(this.x+width/2, y);
    this.ctx.lineTo(this.x+width/2, y+height/2+sign*settings.root_height/2);
    this.ctx.stroke();
    // Draw square
    this.ctx.beginPath();
    if(this.body===2){
      if(v.log===3) this.ctx.fill(this.tooth_center);
      else this.ctx.stroke(this.tooth_center);
    }else{
      let long = 12
      this.ctx.rect(this.x+width/2-long/2, y+height/2+sign*settings.root_height/2-long/2, long, long);
      if(v.log===3) this.ctx.fill();
      else this.ctx.stroke();
    }

    return {log: v.log, color: v.state?"blue":"red"};
  }
  inc_Transposicion(c, v){  // 5.3.37  Transposición
    let y = this.y + settings.height + (this.body===2 ? 10 : 9);
    let width = this.body===2 ? settings.width*1.3/2 : settings.width/2;
    let x = this.x + width - (v.orientation==='R' ? this.body===2 ? -18 : -14 : this.body===2 ? 19 : 15);
    let angel_o = this.body===2 ? 65 : 70;
    let angel_f = this.body===2 ? 115 : 110;
    let _sign = v.orientation==='R' ? 1 : -1;
    let rad_y = this.body===2 ? 180 : 300;
    let rad_x = this.body===2 ? 50 : 50;
    let cy = this.y;
    let _sign_y = 1;
    if(this.orientation==='D'){
      y -= settings.data_space + settings.data_height + settings.height + 19;
      cy -= settings.data_space + (this.body===2 ? 6 : 3);
      cy += rad_y*(this.body===2 ? 17/24 : 39/48);  // Fix cy by angle
      angel_o += 180;
      angel_f += 180;
      this.ctx.strokeStyle = "blue";
    }else if(this.orientation==='U'){
      this.ctx.strokeStyle = "green";
      cy += settings.root_height;
      cy -= rad_y*(this.body===2 ? 33/48 : 39/48);  // Fix cy by angle
      _sign_y *= -1;
    }
    let cx = this.x;
    if(v.orientation==='R'){
      cx += settings.width/(this.body===2?2:3);
      x += settings.width/(this.body===2?2:3) + 2;
    }else{
      cx -= settings.width/(this.body===2?2:3);
      x -= settings.width/(this.body===2?2:3) + 1;
    }
    this.ctx.lineWidth = 3;
    // this.ctx.strokeStyle = "blue";
    this.ctx.beginPath();
    this.ctx.ellipse(cx+width, cy, rad_x, rad_y, 0, angel_o*Math.PI/180, angel_f*Math.PI/180);
    if(v.orientation==='R') this.ctx.moveTo(x+2, y+_sign_y*2);
    if(v.orientation==='L') this.ctx.moveTo(x-2, y+_sign_y*2);
    if(this.body===2) this.ctx.lineTo(x-10*_sign, y-_sign_y*6);
    else this.ctx.lineTo(x-6*_sign, y-_sign_y*3);
    this.ctx.moveTo(x, y+_sign_y*2);
    if(this.body===2) this.ctx.lineTo(x+6*_sign, y-_sign_y*8);
    else this.ctx.lineTo(x+4*_sign, y-_sign_y*6);
    this.ctx.stroke();
  }
  inc_Diente_para_extraer(c, v){  // 5.3.38  Diente para extraer
    let width = settings.width*(this.body===2?1.3:1);
    let height = settings.height;
    let y = this.y;
    if(this.orientation==='D'){
      height *= -1;
      y += settings.height - (settings.data_height+settings.data_space);
    }
    this.ctx.strokeStyle = "red";
    this.ctx.lineWidth = 3;
    this.ctx.beginPath();
    this.ctx.moveTo(this.x, y);
    this.ctx.lineTo(this.x+width, y+height);
    this.ctx.moveTo(this.x+width, y);
    this.ctx.lineTo(this.x, y+height);
    this.ctx.stroke();
  }
  inc_Calculo_dental(c, v){  // 5.3.39  Cálculo dental
    let cy = this.y;
    let y = this.y + settings.height + (this.body===2 ? 8 : 9);
    let width = this.body===2 ? settings.width*1.3/2 : settings.width/2;
    let x = this.x + width - (v.orientation==='R' ? this.body===2 ? -18 : -14 : this.body===2 ? 19 : 15);
    let angel_o = this.body===2 ? 65 : 70;
    let angel_f = this.body===2 ? 115 : 110;
    if(this.orientation==='D'){
      y -= settings.data_space + settings.data_height + settings.height + 19;
      cy -= settings.data_space + (this.body===2 ? 6 : 3);
      angel_o += 180;
      angel_f += 180;
    }else if(this.orientation==='U'){
      cy += settings.root_height;
    }
    this.ctx.lineWidth = 3;
    this.ctx.strokeStyle = "red";
    this.ctx.beginPath();
    this.ctx.arc(this.x+width, cy, 40, angel_o*Math.PI/180, angel_f*Math.PI/180);
    this.ctx.moveTo(x, y-2);
    this.ctx.stroke();
  }
  inc_Calculo_dental_All(c,v){
    let _teethUpper = this.teeth.upper_teeth;
    let _teethLower = this.teeth.lower_teeth;
    /* Lower */
    _teethLower.forEach((tooth) => {
      this.orientation = 'D'
      tooth.inc_Calculo_dental(c, v);
    })
    /* Upper */
    _teethUpper.forEach((tooth) => {
      this.orientation = 'U'
      tooth.inc_Calculo_dental(c, v);
    })
  }
  inc_Curacion_en_buen_estado(c, v){  // 5.3.40  Curación en buen estado
    // c is array
    this.ctx.fillStyle = "blue";
    c.forEach((path) => {
      this.ctx.fill(path);
    });
  }
  inc_RayosX(c, v){  // Rayos X
    return {log: "RX", color: "blue"};
  }
  inc_Tratamiento(c, v){  // Tratamiento
    return {log: "T", color: "blue"};
  }
}
class Molar extends Tooth {
  constructor(ctx, x, y, key, orientation, root, incidents){
    // body: 2
    super(ctx, x, y, key, orientation, 2, incidents);
    this._root = root;
    this.genRoot();
  }
  molarBase(){
    let x = this.x;
    let y = this.y;
    let data_height = settings.data_height;
    let data_space = settings.data_space;
    y = this.orientation==='U' ? y+settings.root_height : y-(data_space+data_height);
    let width = settings.width*1.3;
    let height = settings.height-settings.root_height;

    // Chains
    let _line = new Path2D();
    _line.moveTo(x+width/4, y+height*4/10);
    _line.lineTo(x+width*3/4, y+height*4/10);
    _line.moveTo(x+width/4, y+height*6/10);
    _line.lineTo(x+width*3/4, y+height*6/10);
    _line.moveTo(x+width*3/8, y+height/4);
    _line.lineTo(x+width*3/8, y+height*3/4);
    _line.moveTo(x+width*5/8, y+height/4);
    _line.lineTo(x+width*5/8, y+height*3/4);
    this.strokeBold(_line)
  }
  genRoot(){
    let x = this.x;
    let y = this.y;
    let width = settings.width*1.3;
    let height = settings.root_height;
    let root = this._root;
    let data_height = settings.data_height;
    let data_space = settings.data_space;
    if(this.orientation==='D'){
      y = y-(data_space + data_height)+settings.height;  // Fix pixel
      height *= -1;
    }

    // Root area
    let _root = [];
    let _temp;
    if(root===3){
      // Left
      _temp = new Path2D();
      _temp.moveTo(x+width*0/8, y+height);
      _temp.lineTo(x+width*2/8, y);
      _temp.lineTo(x+width*3/8, y+height/2);
      _temp.lineTo(x+width*2/8, y+height);
      _root.push(_temp);

      // Right
      _temp = new Path2D();
      _temp.moveTo(x+width*2/8, y+height);
      _temp.lineTo(x+width*4/8, y);
      _temp.lineTo(x+width*6/8, y+height);
      _root.push(_temp);

      // Right
      _temp = new Path2D();
      _temp.moveTo(x+width*8/8, y+height);
      _temp.lineTo(x+width*6/8, y);
      _temp.lineTo(x+width*5/8, y+height/2);
      _temp.lineTo(x+width*6/8, y+height);
      _root.push(_temp);
    }else if(root===2){
      // Left
      _temp = new Path2D();
      _temp.moveTo(x+width*0/4, y+height);
      _temp.lineTo(x+width*1/4, y);
      _temp.lineTo(x+width*2/4, y+height);
      _root.push(_temp);

      // Right
      _temp = new Path2D();
      _temp.moveTo(x+width*2/4, y+height);
      _temp.lineTo(x+width*3/4, y);
      _temp.lineTo(x+width*4/4, y+height);
      _root.push(_temp);
    }
    this.root = _root;
  }
  drawRoot(){
    this.root.forEach((i) => {
      this.ctx.stroke(i);
    });
  }
  draw(color=settings.strokeColor, data=true){
    this.ctx.strokeStyle = color;
    this.strokeBold(this.tooth);
    this.strokeBold(this.tooth_top);
    this.strokeBold(this.tooth_bottom);
    this.strokeBold(this.tooth_left);
    this.strokeBold(this.tooth_right);
    this.drawRoot();
    this.molarBase();

    if(data){
      // Ugly at redraw
      this.ctx.stroke(this.data_area);
      this.drawKey();
    }
  }
}
class Premolar extends Tooth {
  constructor(ctx, x, y, key, orientation, root, incidents){
    // body: 2
    super(ctx, x, y, key, orientation, 2, incidents);
    this._root = root;
    this.genRoot();
  }
  premolarBase(){
    let x = this.x;
    let y = this.y+settings.root_height;  // Y coordinate of tooth
    let width = settings.width*1.3;
    let height = settings.height-settings.root_height;
    if(this.orientation==='D'){
      y = y+settings.height-(settings.data_space+settings.data_height+settings.root_height*2);  // Fix pixel
      height *= -1;
    }

    // horizontal line
    this.ctx.beginPath();
    this.ctx.moveTo(x+width/4, y+height/2);
    this.ctx.lineTo(x+width*3/4, y+height/2);
    this.ctx.lineWidth = 2;
    this.ctx.stroke();
    this.ctx.lineWidth = 1;
  }
  genRoot(){
    let x = this.x;
    let y = this.y;
    let width = settings.width*1.3;
    let height = settings.root_height;
    let root = this._root;
    let data_height = settings.data_height;
    let data_space = settings.data_space;
    let direction = ["1", "4", "5", "8"].includes(String(this.key)[0]);
    if(this.orientation==='D'){
      y = y-(data_space + data_height)+settings.height;  // Fix pixel
      height *= -1;
    }

    // Root area
    let _root = [];
    let _temp;
    if(root===1){
      _temp = new Path2D();
      _temp.moveTo(x+width*1/4, y+height);
      _temp.lineTo(x+width*2/4, y);
      _temp.lineTo(x+width*3/4, y+height);
      _root.push(_temp);
    }else if(root===2){
      // Behind
      _temp = new Path2D();
      _temp.moveTo(x+width*1/5, y+height);
      _temp.lineTo(x+width*2/5, y);
      if(direction){  // Direction
        _temp.lineTo(x+width*1/2, y+height/2);
        _temp.lineTo(x+width*2/5, y+height);
      }else{
        _temp.lineTo(x+width*3/5, y+height);
      }
      _root.push(_temp);

      // Main
      _temp = new Path2D();
      _temp.moveTo(x+width*4/5, y+height);
      _temp.lineTo(x+width*3/5, y);
      if(!direction){  // Direction
        _temp.lineTo(x+width*1/2, y+height/2);
        _temp.lineTo(x+width*3/5, y+height);
      }else{
        _temp.lineTo(x+width*2/5, y+height);
      }
      _root.push(_temp);
    }
    this.root = _root;
  }
  drawRoot(){
    this.root.forEach((i) => {
      this.ctx.stroke(i);
    });
  }
  draw(color=settings.strokeColor, data=true){
    this.ctx.strokeStyle = color;
    this.strokeBold(this.tooth);
    this.strokeBold(this.tooth_top);
    this.strokeBold(this.tooth_bottom);
    this.strokeBold(this.tooth_left);
    this.strokeBold(this.tooth_right);
    this.drawRoot();
    this.premolarBase();

    if(data){
      // Ugly at redraw
      this.ctx.stroke(this.data_area);
      this.drawKey();
    }
  }
}
class Canine extends Tooth {
  constructor(ctx, x, y, key, orientation, root, incidents){
    // body: 2
    super(ctx, x, y, key, orientation, 2, incidents);
    this._root = root;
    this.genRoot();
  }
  genRoot(){
    let x = this.x;
    let y = this.y;
    let width = settings.width*1.3;
    let height = settings.root_height;
    let root = this._root;
    let data_height = settings.data_height;
    let data_space = settings.data_space;
    let direction = ["1", "4", "5", "8"].includes(String(this.key)[0]);
    if(this.orientation==='D'){
      y = y-(data_space + data_height)+settings.height;  // Fix pixel
      height *= -1;
    }

    // Root area
    let _root = [];
    let _temp;
    if(root===1){
      _temp = new Path2D();
      _temp.moveTo(x+width*1/4, y+height);
      _temp.lineTo(x+width*2/4, y);
      _temp.lineTo(x+width*3/4, y+height);
      _root.push(_temp);
    }else if(root===2){
      // Behind
      _temp = new Path2D();
      _temp.moveTo(x+width*1/5, y+height);
      _temp.lineTo(x+width*2/5, y);
      if(direction){  // Direction
        _temp.lineTo(x+width*1/2, y+height/2);
        _temp.lineTo(x+width*2/5, y+height);
      }else{
        _temp.lineTo(x+width*3/5, y+height);
      }
      _root.push(_temp);

      // Main
      _temp = new Path2D();
      _temp.moveTo(x+width*4/5, y+height);
      _temp.lineTo(x+width*3/5, y);
      if(!direction){  // Direction
        _temp.lineTo(x+width*1/2, y+height/2);
        _temp.lineTo(x+width*3/5, y+height);
      }else{
        _temp.lineTo(x+width*2/5, y+height);
      }
      _root.push(_temp);
    }
    this.root = _root;
  }
  drawRoot(){
    this.root.forEach((i) => {
      this.ctx.stroke(i);
    });
  }
  draw(color=settings.strokeColor, data=true){
    this.ctx.strokeStyle = color;
    this.strokeBold(this.tooth);
    this.strokeBold(this.tooth_top);
    this.strokeBold(this.tooth_bottom);
    this.strokeBold(this.tooth_left);
    this.strokeBold(this.tooth_right);
    this.drawRoot();

    if(data){
      // Ugly at redraw
      this.ctx.stroke(this.data_area);
      this.drawKey();
    }
  }
}
class Incisor extends Tooth {
  constructor(ctx, x, y, key, orientation, incidents){
    // body: 1
    super(ctx, x, y, key, orientation, 1, incidents);
    this.drawRoot();
  }
  drawRoot(){
    let x = this.x;
    let y = this.y;
    let width = settings.width;
    let height = settings.root_height;
    let data_height = settings.data_height;
    let data_space = settings.data_space;
    if(this.orientation==='D'){
      y = y-(data_space + data_height)+settings.height;  // Fix pixel
      height *= -1;
    }

    // Root
    let _root = new Path2D();
    _root.moveTo(x, y+height);
    _root.lineTo(x+width/2, y);
    _root.lineTo(x+width, y+height);
    this.root = [_root];
  }
  draw(color=settings.strokeColor, data=true){
    this.ctx.strokeStyle = color;
    this.strokeBold(this.tooth);
    this.strokeBold(this.tooth_top);
    this.strokeBold(this.tooth_bottom);
    this.strokeBold(this.tooth_left);
    this.strokeBold(this.tooth_right);
    this.ctx.stroke(this.root[0]);

    if(data){
      // Ugly at redraw
      this.ctx.stroke(this.data_area);
      this.drawKey();
    }
  }
}
// Incident types
const incident_type = {
  component_tooth: [
    4, 5, 6, 7, 11, 12, 13, 15, 16, 17, 18, 19, 20, 22, 23, 25, 26, 27, 28, 29, 30, 36, 38, 39,
    //Endodoncia ungrouped
    47,
    //Rename Pieza dentaria ausente to Ausencia dental
    48,
    //Rename Remanente Radicular to Exodoncia Remanente Radicular
    49,
    // Corona ungrouped incidents
    50,51,52,
    // Tratamiento Pulpar
    54,55,
    //Rayos X
    56,
    //Tratamiento
    57
  ],  // tooth
  component_array: [
    1, 2, 8, 40,
    //* Ungrouped incidents
    //Caries
    41,42,43,
    //Restauracion definitivas
    44,45,46
  ],
    // component []
  component_line_array: [3, 9, 24],  // line []
  component_all: [10, 32, 35,58],  // all teeth block
  component_beside: [14, 21, 37],  // component,component (beside)
  component_range: [31, 33, 34],  // component && component
  component_all_mouth : [58], // all mouth
  form_elements: [
    {
      id: 0,
    },
    //* New Order 
    //Caries
    {
      id: 41,
      log: [
        {key: "CE", text: "Lesión de Caries dental a nivel de esmalte"},
      ],
    },
    {
      id: 42,
      log: [
        {key: "CD", text: "Lesión de Caries dental a nivel de dentina"},
      ],
    },
    {
      id: 1,
      log: [
        {key: "MB", text: "Mancha Blanca"},
        {key: "CE", text: "Lesión de Caries dental a nivel de esmalte"},
        {key: "CD", text: "Lesión de Caries dental a nivel de dentina"},
        {key: "CDP", text: "Lesión de Caries dental a nivel de dentina/compromiso de la pulpa"}
      ],
    },
    //Ungrouped Endodoncia
    {
      id: 54,
      log: [
        {key: "TC", text: "Tratamiento de conductos"},
      ],
      state: true,
    },
    {
      id: 55,
      log: [
        {key: "TC", text: "Tratamiento de conductos"},
      ],
      state: false,
    },
    //Restauraciones definitivas
    {
      id: 44,
      log: [
        {key: "RRBE", text: "Resina"},
      ],
      state: true,
    },
    {
      id: 45,
      log: [
        {key: "RRME", text: "Resina"},
      ],
      state: false,
    },
    {
      id: 46,
      log: [
        {key: "RAM", text: "Amalgama Dental"},
      ],
      state: true,
    },
    {
      id: 8,
      log: [
        {key: "AM", text: "Amalgama Dental"},
        {key: "R", text: "Resina"},
        {key: "IV", text: "Ionómetro de Vidrio"},
        {key: "IM", text: "Incrustación Metálica"},
        {key: "IE", text: "Incrustación Estética"},
        {key: "C", text: "Carilla Estética"}
      ],
      state: true,
    },
    //Rename Pieza dentaria ausente to Ausencia dental
    {
      id: 6
    },
    //Rename Remanente Radicular to Exodoncia Remanente Radicular
    {
      id: 25
    },
    // New Incident Rayos x
    {
      id: 56,
    },
    // Tratamiento
    {
      id: 57
    },
    {
      id: 50,
      log: [
        {key: "CMCBE", text: "Corona Metal Cerámica"},
      ],
      state: true,
    },
    {
      id: 51,
      log: [
        {key: "CMCME", text: "Corona Metal Cerámica"},

      ],
      state: false,
    },
    {
      id: 28,
      log: [
        {key: "CM", text: "Corona Metálica"}, {key: "CF", text: "Corona Fenestrada"},
        {key: "CMC", text: "Corona Metal Cerámica"}, {key: "CV", text: "Corona Venner"},
        {key: "CJ", text: "Corona Jacket"}
      ],
      state: true,
    },
    {
      id: 2,
      log: [
        {key: "HP", text: "Hipoplasia"},
        {key: "HM", text: "Hipo mineralización"},
        {key: "O", text: "Opacidades del Esmalte"},
        {key: "D", text: "Decoloración del Esmalte"},
        {key: "Fluorosis", text: "Fluorosis"}
      ],
    },
    {
      id: 3,
      state: true,
    },
    {
      id: 4,
      fractura: [
        {key: 1, text: "Fractura horizontal en la corona"},  // Crown middle vertical
        {key: 2, text: "Fractura vertical en la corona"},  // Crown middle horizontal
        {key: 3, text: "Fractura diagonal en la corona 1"},  // Diag left
        {key: 4, text: "Fractura diagonal en la corona 2"},  // Diag right
        {key: 5, text: "Fractura total 1"},  // All left
        {key: 6, text: "Fractura total 2"},  // All right
        {key: 7, text: "Fractura total 3"},  // All middle
      ],
    },
    {
      id: 5,
    },
    {
      id: 7,
    },
    {
      id: 9,
    },
    {
      id: 10,
    },
    {
      id: 11,
      orientation: true,
    },
    {
      id: 12,
    },
    {
      id: 13,
    },
    {
      id: 14,
      orientation: true,
    },
    {
      id: 15,
      orientation: true,
    },
    {
      id: 16,
      log: [
        {key: "M", text: "Mesializado"},
        {key: "D", text: "Distalizado"},
        {key: "V", text: "Vestibularizado"},
        {key: "P", text: "Palatinizado"},
        {key: "L", text: "Lingualizado"},
      ],
    },
    {
      id: 17,
    },
    {
      id: 18,
    },
    {
      id: 19,
    },
    {
      id: 20,
    },
    {
      id: 21,
      orientation: true,
    },
    {
      id: 22,
    },
    {
      id: 23,
    },
    {
      id: 24,
    },
    {
      id: 26,
      log: [
        {key: "1", text: "1"},
        {key: "2", text: "2"},
        {key: "3", text: "3"},
      ],
    },
    {
      id: 27,
    },
    {
      id: 29,
      state: true,
    },
    {
      id: 30,
      state: true,
    },
    {
      id: 31,
      state: true,
      amount: true,
    },
    {
      id: 32,
      state: true,
    },
    {
      id: 33,
      state: true,
      amount: true,
    },
    {
      id: 34,
      state: true,
      amount: true,
    },
    {
      id: 35,
      state: true,
    },
    {
      id: 36,
      log: [
        {key: "TC", text: "Tratamiento de conductos"},
        {key: "PC", text: "Pulpectomía"},
        {key: "PP", text: "Pulpotomía"},
      ],
      state: true,
    },
    {
      id: 37,
      orientation: true,
    },
    {
      id: 38,
    },
    // Calculo Dental
    {
      id: 39,
    },
    {
      id: 58,
    },
    {
      id: 40,
    },
  ]
}
const inc_functions = [

  // * New list order
  // Caries incidents
  {
    id: 41,
    nombre: "Caries por esmalte"
  },
  {
    id: 42,
    nombre: "Caries por dentina"
  },
  {
    id: 1,
    nombre: "Caries varios"
  },
  //Ungroup Tratamiento Pulpar incidents(Endodoncia)
  {
    id: 54,
    nombre: "Endodoncia Buen Estado",
  },
  {
    id: 55,
    nombre: "Endodoncia Mal Estado",
  },
  //Restauracion definitiva  incidents
  {
    id: 44,
    nombre: "Restauración con Resina buen estado"
  },
  {
    id: 45,
    nombre: "Restauración con Resina mal estado"
  },
  {
    id: 46,
    nombre: "Restauración con Amalgama"
  },
  {
    id: 8,
    nombre: "Restauración definitiva varios"
  },
  //Rename Pieza dentaria ausente to Ausencia dental
  {
    id: 6,
    nombre: "Ausencia dental",
  },
  //Rename Remanente Radicular to Exodoncia Remanente Radicular
  {
    id: 25,
    nombre: "Exodoncia Remanente radicular",
  },
  //Rayos X
  {
    id: 56,
    nombre: "Radiografía",
  },
  {
    id: 57,
    nombre: "Tratamiento"
  },
  // Ungroup Corona incidents
  {
    id: 50,
    nombre: "Corona Metal Cerámica Buen Estado",
  },
  {
    id: 51,
    nombre: "Corona Metal Cerámica Mal Estado",
  },
  {
    id: 28,
    nombre: "Corona varios",
  },
  {
    id: 2,
    nombre: "Defectos del desarrollo del esmalte",
  },
  {
    id: 3,
    nombre: "Sellantes",
  },
  {
    id: 4,
    nombre: "Fractura",
  },
  {
    id: 5,
    nombre: "Fosas y fisuras profundas",
  },
  {
    id: 6,
    nombre: "Pieza dentaria ausente",
  },
  {
    id: 7,
    nombre: "Pieza dentaria en erupción",
  },
  {
    id: 8,
    nombre: "Restauración definitiva",
  },
  {
    id: 9,
    nombre: "Restauración temporal",
  },
  {
    id: 10,
    nombre: "Edentulo total",
  },
  {
    id: 11,
    nombre: "Pieza dentaria supernumeraria",
  },
  {
    id: 12,
    nombre: "Pieza dentaria extruida",
  },
  {
    id: 13,
    nombre: "Pieza dentaria intruida",
  },
  {
    id: 14,
    nombre: "Diastema",
  },
  {
    id: 15,
    nombre: "Giroversión",
  },
  {
    id: 16,
    nombre: "Posición dentaria",
  },
  {
    id: 17,
    nombre: "Pieza dentaria en clavija",
  },
  {
    id: 18,
    nombre: "Pieza dentaria ectópica",
  },
  {
    id: 19,
    nombre: "Macrodoncia",
  },
  {
    id: 20,
    nombre: "Microdoncia",
  },
  {
    id: 21,
    nombre: "Fusión",
  },
  {
    id: 22,
    nombre: "Geminación",
  },
  {
    id: 23,
    nombre: "Impactación",
  },
  {
    id: 24,
    nombre: "Superficie desgastada",
  },
  {
    id: 25,
    nombre: "Remanente radicular",
  },
  {
    id: 26,
    nombre: "Movilidad patológica",
  },
  {
    id: 27,
    nombre: "Corona temporal",
  },
  {
    id: 28,
    nombre: "Corona",
  },
  {
    id: 29,
    nombre: "Espigon - Muñon",
  },
  {
    id: 30,
    nombre: "Implante dental",
  },
  {
    id: 31,
    nombre: "Aparato ortodóntico fijo",
  },
  {
    id: 32,
    nombre: "Aparato ortodóntico removible",
  },
  {
    id: 33,
    nombre: "Prótesis fija",
  },
  {
    id: 34,
    nombre: "Prótesis removible",
  },
  {
    id: 35,
    nombre: "Prótesis total",
  },
  {
    id: 36,
    nombre: "Tratamiento pulpar",
  },
  {
    id: 37,
    nombre: "Transposición"
  },
  {
    id: 38,
    nombre: "Diente para extraer"
  },
  {
    id: 39,
    nombre: "Cálculo dental"
  },
  {
    id: 58,
    nombre: "Cálculo dental para toda la boca"
  },
  {
    id: 40,
    nombre: "Curación en buen estado"
  },
];

export {inc_functions};

let inc_paths = [];

// Preview context
let pvw_ctx;

// Main component
function Odontograma({role, redirectTo, data, postCanva}){
  let __params__ = useParams()
  let {current_sucursal} = useContext(NavigationContext)
  if(data) {
      __params__ = {
        'cita_pk': data.cita_pk
      }
      current_sucursal = data.sucursal_pk
  }

  const {toggle} = useModal();

  let [cita, setCita] = useState(false)
  /* We want to keep these values even when any state change, so we declare 'em as Ref
    We initialize its value and reference it's 'current' attribute (which is the actual value)
    declaring to useRef().current directly only works on objects
  */
  // Main variables
  let [teethState, setTeeth] = useState(false);  // Tooth data, redraw incidents, teeth reference
  // Panel state
  let [incident, setIncident] = useState(false);
  // Incident list
  let [incident_list, setIncidentList] = useState([]);
  // DOM variables
  let odontogram_type = useRef('A');
  // Odontograma
  const [odontogram, setOdontogram] = useState(-1)  // -1 to difference when it becomes False after asking the api
  // Odontograma inicial
  const [init_od, setInitOd] = useState(-1)  // -1 to difference when it becomes False after asking the api
  // Array odontograma evolucion log
  const [ar_ev_od_log, setArrayEvolutionOdLog] = useState([])
  // Incidence counter
  let inc_count_a = useRef(0)
  let inc_count_k = useRef(0)

  // Generic rest function
  const getCita = cita_pk => {
    if(__debug__) console.log("getCita")

    getDataByPK('atencion/cita', cita_pk)
    .then(setCita)
  }

  /* We use useCallback to avoid re declaration of methods (has no return)
    makes a better performance
    source:
      https://reactjs.org/docs/hooks-reference.html#usecallback
      https://stackoverflow.com/a/57294726/12322283
    Also we declare empty dependencies..
    'cuz this functions do not need to be re declared after some variable change
    We make some of these functions to depent of incident
    'cuz we use that variable in those functions or in functions that depends on 'em
  */
  const genTeeth = useCallback(() => {
      if(__debug__) console.log("genTeeth");

      // Generate Adult Teeth
      genATeeth()
      // Generate Kid Teeth
      genKTeeth()
  }, []);
  const genATeeth = () => {
    if(__debug__) console.log("genATeeth");

    // General
    let _left = (
      // Odontogram element width
      ctx.canvas.width-
      // Tooth with plus tooth spacing
      (settings.width+settings.tooth_spacing)*(2+16)
    )/2;  // Half of the total space left
    let _top = 40;
    let _build_data;
    // Teeth container
    let upper_teeth = [];
    let lower_teeth = [];

    /*** Generate Adult Teeth ***/
    // Upper teeth
    upper_teeth = [];
    _top += settings.data_height+settings.data_space;
    _build_data = build_data.build_adult_top
    _build_data.forEach((v)=>{
      // Calc coordinates
      let _x = _left;
      if(upper_teeth.length){
        let last_tooth = upper_teeth[upper_teeth.length-1];
        _x = last_tooth.x + settings.tooth_spacing;
        _x += last_tooth.body===2 ? settings.width*1.3 : settings.width;
      }

      // Create object with properties
      let a;
      switch(v.type){
        case 0: a = new Molar(ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 1: a = new Premolar(ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 2: a = new Canine(ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 3: a = new Incisor(ctx, _x, _top, v.key, v.orientation, v.incidents); break;
        default: alert(`ERROR IN TEETH BUILD DATA: tooth type not found in key ${v.key}`); break;
      }
      a.setTeeth(teeth_a)  // Add teeth's memory reference
      upper_teeth.push(a);
    });
    teeth_a.upper_teeth = upper_teeth;
    // Lower teeth
    lower_teeth = [];
    _top *= 3;  // Dependant of canvas
    _build_data = build_data.build_adult_bottom
    _build_data.forEach((v)=>{
      // Calc coordinates
      let _x = _left;
      if(lower_teeth.length){
        let last_tooth = lower_teeth[lower_teeth.length-1];
        _x = last_tooth.x + settings.tooth_spacing;
        _x += last_tooth.body===2 ? settings.width*1.3 : settings.width;
      }

      // Create object with properties
      let a;
      switch(v.type){
        case 0: a = new Molar(ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 1: a = new Premolar(ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 2: a = new Canine(ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 3: a = new Incisor(ctx, _x, _top, v.key, v.orientation, v.incidents); break;
        default: alert(`ERROR IN TEETH BUILD DATA: tooth type not found in key ${v.key}`); break;
      }
      a.setTeeth(teeth_a)  // Add teeth's memory reference
      lower_teeth.push(a);
    });
    teeth_a.lower_teeth = lower_teeth;
  }
  const genKTeeth = () => {
    if(__debug__) console.log("genKTeeth");

    // General
    let _left = (
      // Odontogram element width
      ctx.canvas.width-
      // Tooth with plus tooth spacing
      (settings.width+settings.tooth_spacing)*(2+10)
    )/2;  // Half of the total space left
    let _top = 40;
    let _build_data;
    // Teeth container
    let upper_teeth = [];
    let lower_teeth = [];

    /*** Generate Kid Teeth ***/
    // Upper teeth
    upper_teeth = [];
    _top += settings.data_height+settings.data_space;
    _build_data = build_data.build_kid_top;
    _build_data.forEach((v)=>{
      // Calc coordinates
      let _x = _left;
      if(upper_teeth.length){
        let last_tooth = upper_teeth[upper_teeth.length-1];
        _x = last_tooth.x + settings.tooth_spacing;
        _x += last_tooth.body===2 ? settings.width*1.3 : settings.width;
      }

      // Create object with properties
      let a;
      switch(v.type){
        case 0: a = new Molar(ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 1: a = new Premolar(ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 2: a = new Canine(ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 3: a = new Incisor(ctx, _x, _top, v.key, v.orientation, v.incidents); break;
        default: alert(`ERROR IN TEETH BUILD DATA: tooth type not found in key ${v.key}`); break;
      }
      a.setTeeth(teeth_k)  // Add teeth's memory reference
      upper_teeth.push(a);
    });
    teeth_k.upper_teeth = upper_teeth;
    // Lower teeth
    lower_teeth = [];
    _top *= 3;  // Dependant of canvas
    _build_data = build_data.build_kid_bottom;
    _build_data.forEach((v)=>{
      // Calc coordinates
      let _x = _left;
      if(lower_teeth.length){
        let last_tooth = lower_teeth[lower_teeth.length-1];
        _x = last_tooth.x + settings.tooth_spacing;
        _x += last_tooth.body===2 ? settings.width*1.3 : settings.width;
      }

      // Create object with properties
      let a;
      switch(v.type){
        case 0: a = new Molar(ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 1: a = new Premolar(ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 2: a = new Canine(ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 3: a = new Incisor(ctx, _x, _top, v.key, v.orientation, v.incidents); break;
        default: alert(`ERROR IN TEETH BUILD DATA: tooth type not found in key ${v.key}`); break;
      }
      a.setTeeth(teeth_k)  // Add teeth's memory reference
      lower_teeth.push(a);
    });
    teeth_k.lower_teeth = lower_teeth;
  }
  const changeTeethType = (type) => {
    if(__debug__) console.log("changeTeethType")

    if(type!='A'&&type!='K') return  // Allow only A || K
    if(odontogram_type == type) return  // Prevent redundancy
    /* Set global teeth */
    teeth = type=='A'?teeth_a:teeth_k
    setTeeth(teeth)  // Update reference
    // Change global variables value when change odontogram type
    currentTooth.tooth = null;
    currentTooth.path = null;
    currentTooth.preserve = false;
    odontogram_type.current = type;  // DOM odontogram type indicator
    // Generate squares
    genOdontogramSquares()
    // Print odontogram
    printTeeth()
  }
  const genOdontogramSquares = () => {
    /*** Generate Teeth Squares ***/
    // General
    let _left = (
      // Odontogram element width
      ctx.canvas.width-
      // Tooth with plus tooth spacing
      (settings.width+settings.tooth_spacing)*(2+ (odontogram_type.current=='A'?16:10))
    )/2;  // Half of the total space left
    let _top = 0;
    // Genereate square_top
    _top = 143.5;
    odontogram_squares.square_top = new Path2D();
    let _last_tooth = teeth.upper_teeth[teeth.upper_teeth.length-1];
    odontogram_squares.square_top.moveTo(_left-15, _top-settings.data_space-settings.data_height-32);
    odontogram_squares.square_top.lineTo(_left-15, _top+settings.height+32);
    odontogram_squares.square_top.lineTo(_last_tooth.x+settings.width*(_last_tooth.body===2?1.3:1)+15, _top+settings.height+32);
    odontogram_squares.square_top.lineTo(_last_tooth.x+settings.width*(_last_tooth.body===2?1.3:1)+15, _top-settings.data_space-settings.data_height-32);
    odontogram_squares.square_top.closePath();
    // Genereate square_bottom
    _top = 430.5;
    odontogram_squares.square_bottom = new Path2D();
    _last_tooth = teeth.lower_teeth[teeth.lower_teeth.length-1];
    odontogram_squares.square_bottom.moveTo(_left-15, _top-settings.data_space-settings.data_height-32);
    odontogram_squares.square_bottom.lineTo(_left-15, _top+settings.height+32);
    odontogram_squares.square_bottom.lineTo(_last_tooth.x+settings.width*(_last_tooth.body===2?1.3:1)+15, _top+settings.height+32);
    odontogram_squares.square_bottom.lineTo(_last_tooth.x+settings.width*(_last_tooth.body===2?1.3:1)+15, _top-settings.data_space-settings.data_height-32);
    odontogram_squares.square_bottom.closePath();
  }
  const printTeeth = useCallback(() => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);  // Clear canvas
    [...teeth.upper_teeth, ...teeth.lower_teeth].forEach((tooth) => {
      tooth.draw();
    });
    drawAllIncidences();
  }, []);
  const mouseInCanvas = useCallback((e, check=false) => {
    if(select_type===0) return;  // All teeth incidence

    let x = e.offsetX;
    let y = e.offsetY;
    // Check if mouse is in big squares
    let top = ctx.isPointInPath(odontogram_squares.square_top, x, y);
    let bottom = ctx.isPointInPath(odontogram_squares.square_bottom, x, y);
    if(top || bottom){
      // Clear canvas area
      let check_in_teeth;
      if(top){  // Square TOP is hover
        check_in_teeth = teeth.upper_teeth;  // Check in upper_teeth
      }else if(bottom){  // Square BOTTOM is hover
        check_in_teeth = teeth.lower_teeth;  // Check in lower_teeth
      }

      let noevenone = check_in_teeth.some((e) => {
        let isIn = ctx.isPointInPath(e.area, x, y);  // Is point in tooth area?
        if(isIn){  // Point is in tooth area
          // If it's not the current tooth (point has moved to other tooth)
          if(currentTooth.tooth && e!==currentTooth.tooth && !check){
            // If it's not the same tooth and preserve is true -> cancel
            if(currentTooth.preserve) return true;

            clearTooth();  // Clear tooth
          }

          // Stroke tooth
          currentTooth.tooth = e;  // Save this as current tooth
          if(check) return true;  // Here ends the check functions, now return fake current tooth

          if(select_type===4){  // Select lvl to tooth?
            e.draw(settings.hovercolor, false);  // Fix clearTooth
            if(!currentTooth.preserve){
              currentTooth.preserve = true;
              clearTooth();  // Fix tooth hover color
              currentTooth.preserve = false;
            }else{
              clearTooth();  // Fix tooth hover color
            }

          }else if(select_type===3){  // Select lvl is line part
            // Keep tooth as current tooth when check
            if(check) currentTooth.preserve = true;

            // Check if mouse is over root or tooth
            let lines = e.tooth_lines;

            // Check what path is mouse over
            let _noevenone = lines.some((path, inx) => {
              let found = ctx.isPointInStroke(path, x, y);  // Is point in line stroke?
              if(found){
                let _key = String(1100+inx)
                // If it's not the current line (point has moved to other line component)
                if(currentTooth.path && _key!==currentTooth.path.key) clearTooth();  // Clear tooth
                ctx.lineWidth = 3;
                ctx.strokeStyle = settings.hovercolor;

                ctx.stroke(path);
                currentTooth.path = {path: path, key: _key};

                return true;
              }
              return false;
            });
            if(!_noevenone && currentTooth.tooth) clearTooth();  // Fix draw when no line is pointed
          }else{  // Select lvl is in tooth part [1, 2]
            // IMPORTANT: Do not change the order of the following array, it'd cause bugs at saving incidence
            let paths = [
              e.tooth_top,  // 1001
              e.tooth_right,  // 1002
              e.tooth_bottom,  // 1003
              e.tooth_left,  // 1004
              e.tooth_center_tl,  // 1005
              e.tooth_center_tr,  // 1006
              e.tooth_center_br,  // 1007
              e.tooth_center_bl,  // 1008
            ];

            // Check what path is mouse over
            let _noevenone = paths.some((path, inx) => {
              let found = ctx.isPointInPath(path, x, y);  // Is point in tooth area?
              if(found){
                let _key = String(1000+inx+1)
                // If it's not the current path (point has moved to other tooth component)
                if(currentTooth.path && _key!==currentTooth.path.key) clearTooth();  // Clear tooth
                ctx.fillStyle = settings.hovercolor;

                ctx.fill(path);
                currentTooth.path = {path: path, key: _key};

                return true;
              }
              return false;
            });
            if(!_noevenone && currentTooth.tooth) clearTooth();  // Fix draw when no path is pointed
          } /* fin select type */

          return true;  // End loop
        }
        return false;  // Continue loop
      });
      if(!noevenone){
        clearTooth();  // Fix draw when no tooth is pointed
        if(!currentTooth.preserve) currentTooth.tooth = null;
      }
      // if(!noevenone && !currentTooth.tooth) clearTooth();  // Fix draw when no tooth is pointed

    }else if(currentTooth.tooth) clearTooth();
    if(pvw_ctx){  // Preview is being shown
      drawPreview();  // Update preview
    }
  }, [incident]);
  const clearTooth = useCallback((all=false) => {
    // Erase all block
    ctx.fillStyle = ctx.canvas.style.background ? ctx.canvas.style.background : "white";
    let _teeth;
    if(teeth.upper_teeth.indexOf(currentTooth.tooth)!==-1){
      _teeth = teeth.upper_teeth;
      ctx.fill(odontogram_squares.square_top);
    }
    else if(teeth.lower_teeth.indexOf(currentTooth.tooth)!==-1){
      _teeth = teeth.lower_teeth;
      ctx.fill(odontogram_squares.square_bottom);
    }else{
      _teeth = [...teeth.upper_teeth, ...teeth.lower_teeth];
      ctx.fill(odontogram_squares.square_top);
      ctx.fill(odontogram_squares.square_bottom);
    }
    if(all){
      _teeth = [...teeth.upper_teeth, ...teeth.lower_teeth];
      ctx.fill(odontogram_squares.square_top);
      ctx.fill(odontogram_squares.square_bottom);
    }

    // Re draw all block
    _teeth.forEach((tooth) => {
      tooth.draw();
    });
    // Re draw incidents
    drawAllIncidences(_teeth);
    drawSelectedPaths();
    currentTooth.path = null;  // Fix path not updating

    if(currentTooth.preserve) return;  // Preserve selected tooth
    // Remove current tooth reference
    currentTooth.tooth = null;
  }, [incident]);
  const toothPartClickHandle = useCallback((e, safe=false, many=false) => {
    if(select_type===0) return;  // All teeth incidence

    let ct = Object.assign({}, currentTooth);  // Clone of currentTooth
    currentTooth.preserve = false;  // Allow to change tooth at click
    mouseInCanvas(e, true);
    if(!currentTooth.tooth){  // Skip when click in empty area
      if(safe){
        currentTooth = ct;  // Preverse currentTooth in preview
        return;
      }
      //inc_paths = [];  // Reset array -> Not needed anymore
      clearTooth();
      return;
    }
    if(ct.tooth && currentTooth.tooth.key==ct.tooth.key){  // Same tooth
      if(select_type!==4){  // Not select tooth, instead save tooth part
        currentTooth.preserve = true;
        let taked_off = []
        if(!currentTooth.path) return
        if( incident_type.component_array.includes(incident.id)) {
          taked_off = inc_paths.reduce((ar, path)=>{
            if(path.tooth.key!==currentTooth.tooth.key || path.key !== currentTooth.path.key) {
              ar.push(path);
            }
            return ar;
          }, []);
        } else {
          taked_off = inc_paths.reduce((ar, path)=>{if(path.key!==currentTooth.path.key) ar.push(path); return ar;}, []);
        }

        // Take out path object if it's already in array
        if(inc_paths.length === taked_off.length){  // If nothing was removed then currentPath wasn't in array so we added it
          inc_paths.push({...currentTooth.path, tooth: currentTooth.tooth });  // Add path to array
        }else{  // Something were removed in taken_off
          inc_paths = taked_off;  // Replace current array
        }
        // Include extra center tooth parts
        if (many) {
          let extra_center_inc_paths = getExtraCenterIncPaths(currentTooth, inc_paths);
          if (extra_center_inc_paths.length > 0) {
            inc_paths = [...inc_paths, ...extra_center_inc_paths];
          };
        }
      }else{  // Select tooth
        if(incident_type.component_tooth.includes(incident.id)){
          /* Validate if currentTooth.tooth.key exists, so delete, or push */
          let _inx = inc_paths.findIndex((e)=>e.key===currentTooth.tooth.key);
          if(_inx===-1)
            inc_paths.push({
              path: currentTooth.tooth.area,
              key: currentTooth.tooth.key,
              tooth: currentTooth.tooth
            });
          else
            inc_paths.splice(_inx, 1);
        } else {
          currentTooth.preserve = !ct.preserve;  // Switch preserve tooth
          inc_paths = [];  // Reset array
        }
      }
    }else{  // Other tooth clicked
      if (incident_type.component_array.includes(incident.id)) {
        inc_paths.push({path: currentTooth.tooth.path, key: currentTooth.tooth.key, tooth: currentTooth.tooth})
      }
      else {
        inc_paths = []
      }
    // inc_paths = [];  // Reset array
      currentTooth.preserve = true;  // Select this tooth
      if(incident_type.component_range.includes(incident.id) || incident_type.component_tooth.includes(incident.id)){  // If incident's component is range
        // Check if other tooth is in same square
        if(incident_type.component_range.includes(incident.id)){
          let direction = ["1", "2", "5", "6"].includes(String(ct.tooth.key)[0]);
          let _teeth;
          if(direction) _teeth = teeth.upper_teeth;
          else if(!direction) _teeth = teeth.lower_teeth;
          if(_teeth.indexOf(currentTooth.tooth)===-1){
            clearTooth(true);
            return;
          }
        }

        // Can only select one tooth at the time (apart of current tooth)
        // inc_paths = [];  // Already executed before
        inc_paths.push({path: currentTooth.tooth.area, key: currentTooth.tooth.key, tooth: currentTooth.tooth});
        currentTooth = ct;  // Preserve current tooth
      }
    }
    // Preview
    if(select_type===3 && currentTooth.tooth) initPreview();

    clearTooth(true);  // Clear and redraw all | fix click in other square's tooth
    if(pvw_ctx && currentTooth.tooth) drawPreview();  // Update preview
  }, [incident]);
  const toothRightClickHandle = useCallback((e, safe=false, many=true) => {
    // Verify that currentTooth has target properties
    if (!currentTooth.tooth || !currentTooth.path) return;
    
    let tooth_data = currentTooth.tooth;
    let path_data = currentTooth.path;
    let center_tooth_keys = getCenterToothKeys(build_data);
    let current_center_paths = getCurrentCenterPaths(tooth_data);
    
    // Validate that the right-click was triggered from certain teeth and tooth parts
    if (!center_tooth_keys.includes(tooth_data.key)) return;
    if (!current_center_paths.includes(path_data.path)) return;
    
    toothPartClickHandle(e, safe, many)
  }, [incident])
  const drawAllIncidences = useCallback((_teeth=false) => {
    _teeth = _teeth===false ? [...teeth.upper_teeth, ...teeth.lower_teeth] : _teeth;
    _teeth.forEach((tooth) => {
      tooth.drawIncidents();
    });
  }, []);
  const drawSelectedPaths = useCallback(() => {
    ctx.fillStyle = settings.toothhovercolor;
    if(currentTooth.tooth) ctx.fill(currentTooth.tooth.area);
    // All teeth incidence
    if(incident_type.component_range.includes(incident.id)  && inc_paths.length===1){
      ctx.fill(inc_paths[0].path);
      return;
    }
    //Select multiple tooth
    if(incident_type.component_tooth.includes(incident.id)){
      inc_paths.forEach((path) => {
        ctx.fill(path.path);
      });
      return;
    }


    if(select_type===3){
      ctx.lineWidth = 3;
      ctx.strokeStyle = settings.hovercolor;
      if(inc_paths.hasOwnProperty("length")) inc_paths.forEach((path) => {
        ctx.stroke(path.path)
      });
    }else{
      ctx.fillStyle = settings.hovercolor;
      inc_paths.forEach((path) => {
        ctx.fill(path.path)
      });
    }
  }, [incident]);
  const startRegularOd = () => {
    if(__debug__) console.log("startRegularOd");
    /* Initialize canvas */
    // Elements
    let odontogram_el = document.getElementById('odontogram');
    ctx = odontogram_el.getContext('2d');
    // Generate teeth objects
    genTeeth()
    // Init default
    changeTeethType('A')
    // Get evolution odontogram
    getEvolutionLog(cita.paciente)

    /* At this point odontogram is whether false or object */
    if(!odontogram){
      // Get last evolution state odontogram
      getEvolutionOdontogram(cita.paciente, cita.fecha)
      // If exists last evol od's get its incidences, if not initialize as regular od
      .then(res => res ? getIncidences(res.pk) : changeTeethType("A"))
      // Initialize brand new odontogram with last evol od's incidences

      if (incident_list.length == 0 && !postCanva) {
        //get lasted odontograma and drawn set incidences
        simpleGet(`atencion/paciente/${cita.paciente}/sucursal/${current_sucursal}/odontograma/latest/`)
        .then(res => {
          if (res) {
            getIncidences(res.pk)
          }
        })
        .catch(res => res.status == 404 && console.log(res))
      }
    }
    // If u don't want to get last evolution odontogram incidences
    // if(!odontogram) changeTeethType("A")
    else getIncidences(odontogram.pk)  // Get incidences
  }
  const startInitOd = () => {
    if(__debug__) console.log("startInitOd");
    /* Initialize canvas */
    // Elements
    let odontogram_el = document.getElementById('odontogram');
    ctx = odontogram_el.getContext('2d');
    // Generate teeth objects
    genTeeth()
    // Init default
    changeTeethType('A')

    // Obtener incidencias
    getIncidences(odontogram.pk)
    // Get evolution log
    getEvolutionLog(odontogram.paciente)
  }

  // Run after first render
  // __params__, genTeeth
  useEffect(() => {
    if(__debug__) console.log("useEffect __params__, genTeeth");
    if(!genTeeth) return

    // If cita is not setted
    if(!cita){
      // Behaviour from role
      switch(role){
        case "regular":  // Regular behaviour
          getCita(__params__.cita_pk);
          break;
        case "init":
          // Get initial odontogram
          getInitialOdontogram(__params__.pac_pk);
          // Set odontogram value of false
          setOdontogram(false)
          // Set odontogram value of initial odontogram
          break;
        case "evol":
          getEvolutionOdontogram(__params__.pac_pk)
          .then(res => res
            ? setOdontogram(res)
            // If there is no evolution odontogram redirect to initial odontogram
            : redirectTo(`/nav/odontograma/${__params__.pac_pk}/inicial/`)
          )
          // Get evolution odontogram
          getInitialOdontogram(__params__.pac_pk);
          break;
        default: break;
      }
    }
    // We need to re render each time our url params change
  }, [__params__, genTeeth])
  // Cita
  useEffect(() => {
    if(__debug__) console.log("useEffect cita")
    if(!cita) return

    if(role=="regular"){
      // Get odontogram
      getOdontogram()
      // Get initial odontogram
      getInitialOdontogram(cita.paciente)
    }
  }, [cita])

  // Run after teeth has changed
  useEffect(() => {
    if(__debug__) console.log("useEffect teethState");
    if(!teeth) return;

    ctx.canvas.onmousemove = (e)=>{mouseInCanvas(e)}  // Declare drawSelectedPaths listener
    ctx.canvas.onclick = (e)=>{toothPartClickHandle(e)}  // Declare toothPartClickHandle listener
    ctx.canvas.oncontextmenu = (e) => {toothRightClickHandle(e);};  // To re declare toothRightClickHandle listener
    printTeeth();  // Draw odontogram
  }, [teethState])
  // Click listener in canvas
  useEffect(() => {
    if(__debug__) console.log("useEffect incident");
    if(!teethState) return  // Prevent execution before teeth is setted
    if(!ctx) return;  // Ctx is not defined

    inc_paths = [];  // Reset array
    if(incident===false){
      printTeeth();  // Draw odontogram
      if(pvw_ctx){
        pvw_ctx.canvas.onmousemove = null;  // To re declare drawSelectedPaths
        pvw_ctx.canvas.onclick = null;  // To re declare toothPartClickHandle
        pvw_ctx = null;
      }
      currentTooth = {tooth: null, path: null, preserve: false};
    }
    // All teeth incident
    if(incident_type.component_all.includes(incident.id)){
      currentTooth = {tooth: null, path: null, preserve: false};
      clearTooth();
    }
    // Add odontogram listeners
    ctx.canvas.onmousemove = (e)=>{mouseInCanvas(e)}  // To re declare drawSelectedPaths
    ctx.canvas.onclick = (e)=>{toothPartClickHandle(e)}  // To re declare toothPartClickHandle
    ctx.canvas.oncontextmenu = (e) => {toothRightClickHandle(e);};  // To re declare toothRightClickHandle
    // Preview
    if(select_type===3 && currentTooth.tooth){
      initPreview();
    }
  }, [incident])
  // odontogram, init_od
  useEffect(() => {
    if(__debug__) console.log("useEffect odontogram, init_od", role)
    if(odontogram==-1 || init_od==-1) return

    if(role=="regular") startRegularOd()  // Initialize odontogram
    if(role=="init"){
      // 1: cuando odontograma sea falso y init_od este declarado
      if(!odontogram) setOdontogram(init_od)  // Pasar valor de init_od a odontograma
      // 2: cuando odontograma sea igual que init_od, iniciar odontograma
      if(odontogram) startInitOd()
    }
    if(role=="evol"){
      if(odontogram && init_od) startInitOd()
    }
  }, [odontogram, init_od])


  /* We declare this function as it is 'cuz we want it to be dinamically generated in every render
    that way it keeps updated with the newest state variables
  */
  // Initial odontogram
  function getOdontogram(){
    if(__debug__) console.log("getOdontogram");
    // Get odontograma
    let uri_for_superuser = 'odontograma'
    if (data && postCanva) uri_for_superuser = 'odontograma_superuser'
    simpleGet(`atencion/${cita.atencion}/sucursal/${current_sucursal}/${uri_for_superuser}/`)
    .then(setOdontogram)  // Save odontogram
    .catch(res => res.status == 404 && setOdontogram(false))
  }

  // Funcion for Odontogram cannvas
  const removeBackground = (canvas) => {
    // Obtener el canvas original
    const originalCanvas = canvas;
    const originalCtx = originalCanvas.getContext("2d");

    // Crear una nueva copia del canvas
    const copyCanvas = document.createElement("canvas");
    copyCanvas.width = originalCanvas.width;
    copyCanvas.height = originalCanvas.height;
    const copyCtx = copyCanvas.getContext("2d");

    // Dibujar en la copia
    copyCtx.drawImage(originalCanvas, 0, 0);

    // Obtener los píxeles del canvas original
    const imageData = originalCtx.getImageData(0, 0, originalCanvas.width, originalCanvas.height);
    const pixels = imageData.data;

    // Iterar a través de los píxeles y establecer el fondo blanco como transparente
    for (let i = 0; i < pixels.length; i += 4) {
        const red = pixels[i];
        const green = pixels[i + 1];
        const blue = pixels[i + 2];

        // Si el píxel es blanco, establecer la transparencia a 0
        if (red === 255 && green === 255 && blue === 255) {
            pixels[i + 3] = 0; // El cuarto valor es la transparencia (alpha)
        }
    }
    // Establecer los píxeles modificados en la copia
    copyCtx.putImageData(imageData, 0, 0);
    return copyCanvas;
  }

  function saveOdontogram(){
    // Save odontogram data to API
    let odontogram_data = {incidents: []};
    // Get teeth incidents' data
    teeth_a.lower_teeth.map((v) => {
      if(!v.incidents || v.incidents.length===0) return;
      // Fix support teeth
      if(v.incidents.filter(i => !i.support).length==0) return;
      // Save tooth with incidences
      odontogram_data.incidents.push({
        diente: v.key,
        incidents: v.incidents
      });
    });
    teeth_a.upper_teeth.map((v) => {
      if(!v.incidents || v.incidents.length===0) return;
      // Fix support teeth
      if(v.incidents.filter(i => !i.support).length==0) return;
      // Save tooth with incidences
      odontogram_data.incidents.push({
        diente: v.key,
        incidents: v.incidents
      });
    });
    teeth_k.lower_teeth.map((v) => {
      if(!v.incidents || v.incidents.length===0) return;
      // Fix support teeth
      if(v.incidents.filter(i => !i.support).length==0) return;
      // Save tooth with incidences
      odontogram_data.incidents.push({
        diente: v.key,
        incidents: v.incidents
      });
    });
    teeth_k.upper_teeth.map((v) => {
      if(!v.incidents || v.incidents.length===0) return;
      // Fix support teeth
      if(v.incidents.filter(i => !i.support).length==0) return;
      // Save tooth with incidences
      odontogram_data.incidents.push({
        diente: v.key,
        incidents: v.incidents
      });
    });
    /* Add general teeth incidences
    * the backend won't create a new incidence if we pass on DI.pk
    * backend will never create general incidences bc those are created directly by PDT relation
    */
    let general_incidences = incident_list.filter(inc => inc.type === 0)
    if(general_incidences.length !== 0){
      odontogram_data.incidents.push({
        diente: null,
        incidents: general_incidences.map(i => ({
          type: i.type,
          pk: i.pk,
          nueva_incidencia: i.nueva_incidencia
        }))
      });
    }

    // Add odontogram data
    odontogram_data.observaciones = document.getElementById('textarea_observaciones').value;

    const canvas = canvasRef.current;
    const canvas_clean = removeBackground(canvas);
    const img = canvas_clean.toDataURL("image/png");
    odontogram_data.image = img

    // Create or modify?
    if(!odontogram){  // Save to API
      odontogram_data.atencion = cita.atencion;
      odontogram_data.paciente = cita.paciente;

      simplePostData('atencion/odontograma/', odontogram_data)
      .then(res => setOdontogram(res) || res)
      .then(() => handleErrorResponse('custom', "Exito", "Odontograma guardado exitosamente", 'success'))
    }else{  // Modify
      simplePostData(`atencion/odontograma/${odontogram.pk}/`, odontogram_data, 'PUT')
      .then(res => {
        setOdontogram(res);
        getEvolutionLog(res.paciente);
      })
      .then(() => handleErrorResponse('custom', "Exito", "Odontograma actualizado exitosamente", 'success'))
      .catch(e => console.log("E", e.error) )
    }
  }
  function getIncidences(_od_pk){
    /* This function uses the 'odontogram' global object */
    /* This function should be called only once and only if odontogram exists */
    if(__debug__) console.log("getIncidences");
    // If _od_pk takes it's default value (odontogram.pk) and odontogram is false
    if(!_od_pk){
      console.error("getIncidences: can't get incidences from odontogram_pk=undefined");
      // Reset teeth incidences
      teeth_a.lower_teeth.map(v => v.incidents=[])
      teeth_a.upper_teeth.map(v => v.incidents=[])
      teeth_k.lower_teeth.map(v => v.incidents=[])
      teeth_k.upper_teeth.map(v => v.incidents=[])
      // If there is no incidences to insert
      changeTeethType('A')  // Set default odontogram type
      return
    }
    // Get incidents
    simpleGet(`atencion/odontograma/${_od_pk}/incidencia/`)
    // Set teeth incidence from db
    .then(insertIncidencesInTeeth)
    // Add to incident list
    .then(setIncidentList)
  }

  function insertIncidencesInTeeth(objs){
    /* This function require teethState to be already declared */
    if(__debug__) console.log("insertIncidencesInTeeth");

    let new_inc_list = [];
    let last_od_type = "1"
    // Reset teeth incidence counter
    inc_count_a.current = 0
    inc_count_k.current = 0

    // Reset teeth incidences
    teeth_a.lower_teeth.map(v => v.incidents=[])
    teeth_a.upper_teeth.map(v => v.incidents=[])
    teeth_k.lower_teeth.map(v => v.incidents=[])
    teeth_k.upper_teeth.map(v => v.incidents=[])
    // If there is no incidences to insert
    if(objs.length == 0){
      changeTeethType('A')  // Set default odontogram type
      data && uploadOdontograma()
      return []
    }

    // Iterate objs and add incidences to its respective tooth
    objs.forEach((inc) => {
      // Handle general tooth incidences
      if(inc.type === "0"){
        // Add to incidence list
        new_inc_list.push({
          nueva_incidencia: odontogram && inc.nueva_incidencia,
          type: Number(inc.type),
          pk: inc.pk,
          dpdts: inc.dpdts, // Related dpdts
          tipo_odontograma: inc.tipo_odontograma=="1"?'A':'K',
        });
        return;
      }

      /* getTooth from both A&K */
      let _teeth = inc.tipo_odontograma=="1"?teeth_a:teeth_k
      let tooth = getToothByKey(_teeth, inc.diente)
      if(!tooth){
        console.error("Tooth not found");
        console.error("inc:", inc);
        return
      }

      let inc_obj = {};
      inc_obj.type = parseInt(inc.type);
      inc_obj.value = {};
      inc_obj.value.log = inc.log;
      inc_obj.value.state = inc.state;
      inc_obj.value.orientation = inc.orientation;
      inc_obj.value.fractura = JSON.parse(inc.fractura);
      inc_obj.value.start_tooth_key = inc.start_tooth_key;
      inc_obj.component = JSON.parse(inc.component)
      inc_obj.odontogram_type = inc.tipo_odontograma;
      inc_obj.detalle = inc.detalle
      inc_obj.pk = inc.pk
      // Nueva_incidencia: si el odontograma es falso (odontograma no existe) -> todo falso
      // Nueva_incidencia: si el odontograma esta declarado (ya existe, se esta modificando) -> mantener los valores de .nueva_incidencia
      inc_obj.nueva_incidencia = odontogram && inc.nueva_incidencia
      tooth.incidents.push(inc_obj);  // Add to global teeth data

      // Add incidence list
      new_inc_list.push({
        diente_inicio: Number(inc.start_tooth_key),
        diente: tooth.key,
        type: Number(inc.type),
        detalle: inc.detalle.length!=0?cFL(inc.detalle):"-",
        inx: tooth.incidents.length-1,
        pk: inc.pk,
        dpdts: inc.dpdts, // Related dpdts
        tipo_odontograma: inc.tipo_odontograma=="1"?'A':'K',
      });

      // Set last odontogram type in incidence
      last_od_type = inc.tipo_odontograma

      // Add to teeth counter
      if(inc.tipo_odontograma=="1") inc_count_a.current++
      else inc_count_k.current++

      // Fix aside incidences
      if(incident_type.component_beside.includes(inc_obj.type))
        addBesideIncidence(teeth, tooth, inc_obj);
    });
    // Draw odontogram with last incidence's odontogram type
    changeTeethType(last_od_type=="1"?'A':'K')
    data && uploadOdontograma()
    return new_inc_list;
  }
  const getToothByKey = (_teeth_, key) => {
    /* Search for the tooth inside the given teeth object
    */
    // Get tooth by key
    let tooth
    let first_digit = parseInt(String(key)[0])
    let last_digit = parseInt(String(key)[1])
    let up = [1, 2, 5, 6].includes(first_digit)
    let _teeth = up ? _teeth_.upper_teeth : _teeth_.lower_teeth
    let middle_range = _teeth.length/2
    if(up){  // Upper side
      if([1, 5].includes(first_digit)){  // Left side
        tooth = _teeth[middle_range-last_digit]
      }else{  // Right side
        tooth = _teeth[middle_range+last_digit-1]
      }
    }else{  // Lower side
      if([4, 8].includes(first_digit)){  // Left side
        tooth = _teeth[middle_range-last_digit]
      }else{  // Right side
        tooth = _teeth[middle_range+last_digit-1]
      }
    }

    return tooth
  }

  // Preview
  function initPreview(){
    let debug = false;
    // Fake area of odontogram canvas
    if(!pvw_ctx){
      let el_preview = document.getElementById("preview");
      pvw_ctx = el_preview.getContext('2d');
    }
    pvw_ctx.canvas.onmousemove = (e)=>{
      if(!currentTooth.tooth) return;

      let _offsetX = currentTooth.tooth.x + e.offsetX/preview_scale - preview_margin;
      let _offsetY;
      if(currentTooth.tooth.orientation==="D"){
        _offsetY = currentTooth.tooth.y + e.offsetY/preview_scale - preview_margin;
        _offsetY -= settings.data_height+settings.data_space;
      }else{
        _offsetY = currentTooth.tooth.y + e.offsetY/preview_scale - preview_margin;
      }
      let _e = {
        offsetX: _offsetX,
        offsetY: _offsetY
      };

      for(let i=1; i<=preview_select_margin; i++){
        if(debug) console.log(i);
        // X
        _e.offsetX = _offsetX-i;
        mouseInCanvas(_e);
        if(currentTooth.path) break;
        if(debug) console.log("NOT FOUND IN "+_e.offsetX);
        _e.offsetX = _offsetX+i;
        mouseInCanvas(_e);
        if(currentTooth.path) break;
        if(debug) console.log("NOT FOUND IN "+_e.offsetX);
        // Y
        _e.offsetY = _offsetY-i;
        mouseInCanvas(_e);
        if(currentTooth.path) break;
        if(debug) console.log("NOT FOUND IN "+_e.offsetY);
        _e.offsetY = _offsetY+i;
        mouseInCanvas(_e);
        if(currentTooth.path) break;
        if(debug) console.log("NOT FOUND IN "+_e.offsetY);
      }

      if(debug){
        ctx.strokeStyle = "gray";
        ctx.lineWidth = "1";
        ctx.moveTo(0, 0);
        ctx.lineTo(_e.offsetX, _e.offsetY);
        ctx.stroke();
      }
    }
    pvw_ctx.canvas.onclick = (e)=>{
      if(!currentTooth.tooth) return;

      let _offsetX = currentTooth.tooth.x + e.offsetX/preview_scale - preview_margin;
      let _offsetY;
      if(currentTooth.tooth.orientation==="D"){
        _offsetY = currentTooth.tooth.y + e.offsetY/preview_scale - preview_margin;
        _offsetY -= settings.data_height+settings.data_space;
      }else{
        _offsetY = currentTooth.tooth.y + e.offsetY/preview_scale - preview_margin;
      }
      let _e = {
        offsetX: _offsetX,
        offsetY: _offsetY
      };
      toothPartClickHandle(_e, true);
    }
    pvw_ctx.canvas.onmouseleave = ()=>{
      currentTooth.path = null;  // Fix bad mouse leave event
      drawPreview();
    }
    drawPreview();
  }
  function drawPreview(){
    if(!currentTooth.tooth){  // Clear preview if there is no current tooth
      pvw_ctx.fillStyle = "white";
      pvw_ctx.rect(0, 0, pvw_ctx.canvas.width, pvw_ctx.canvas.height);
      pvw_ctx.fill();
      return;
    }
    // Draw tooth in preview
    pvw_ctx.setTransform(1, 0, 0, 1, 0, 0);  // Reset previous scale
    pvw_ctx.scale(preview_scale, preview_scale);
    if(currentTooth.tooth.orientation==="D"){
      pvw_ctx.drawImage(
        ctx.canvas,
        -currentTooth.tooth.x+preview_margin,
        -currentTooth.tooth.y+settings.data_height+settings.data_space+preview_margin
      );
    }else{
      pvw_ctx.drawImage(ctx.canvas, -currentTooth.tooth.x+preview_margin, -currentTooth.tooth.y+preview_margin);
    }
  }

  // Initial odontogram
  const getInitialOdontogram = _pac_pk => {
    simpleGet(`atencion/paciente/${_pac_pk}/sucursal/${current_sucursal}/odontograma/inicial/`)
    .then(response => {
      if(__debug__) console.log("getInitialOdontogram: response", response);
      // If response is false then there is no initial odontogram
      if(!response){
        if(__debug__) console.log("getInitialOdontogram: no initial odontogram");
        if(role == "init"){
          // NOTE: En desarrollo ***
          alert("Por favor ingrese al odontograma desde una cita")
          redirectTo(`/nav/admision/${_pac_pk}/detalle`)
        }
      }

      // Set response as initial odontogram
      setInitOd(response)
    })
  }
  const getEvolutionOdontogram = (_pac_pk, _fecha=false) => {
    const isSuperUserAction= data && postCanva ? 'odontograma_superuser': 'odontograma'
    return simpleGet(`atencion/paciente/${_pac_pk}/sucursal/${current_sucursal}/${isSuperUserAction}/evolucion/${_fecha?"?fecha="+_fecha:""}`)
  }
  // Evolution odontogram
  const getEvolutionLog = _pac_pk => {
    const isSuperUserAction= data && postCanva ? 'odontograma_superuser': 'odontograma'
    /* refer: codeStructure.js: simpleFetch functions: debug log structure */
    simpleGet(`atencion/paciente/${_pac_pk}/sucursal/${current_sucursal}/${isSuperUserAction}/evolucion/log/`)
    .then(res => (__debug__ && console.log("getEvolutionLog: response", res)) || res)
    // If response is an empty array then there is no evolution log
    .then(res => (__debug__ && res.length == 0 && console.log("getEvolutionLog: no evolution log") || res))
    // Set response as initial odontogram (it comes pre sorted by api)
    .then(setArrayEvolutionOdLog)
  }

  // Upload canvas to server
  const canvasRef = useRef(null);

  const isScript = data && postCanva;

  const uploadOdontograma = () => {
    const canvas = canvasRef.current;
    const img = canvas.toDataURL("image/png");
    postCanva(img)
    console.log("COMPONENTE: ",data)
    console.log(img)
  }

  const fetchSucursalData = async () => {
    try {
      const res = await simpleGet("maestro/empresa/sucursal/user/");
      return res[0];
    } catch (error) {
      console.log(error);
      return "";
    }
  }

  const descargarOdontograma = async () => {
    const canvas = canvasRef.current;
    const pdf = new jsPDF();

    const sucursalData = await fetchSucursalData();

    const title = `Odontograma - ${odontogram.paciente_data.fullname}`
    const companyName = `${sucursalData.empresa_data.razon_social} - ${sucursalData.direccion}`;

    // header
    pdf.setFont("Arial", "bold");
    pdf.setFontSize(16);
    const headerX = 20;
    const headerY = 20;
    pdf.text(companyName, headerX, headerY);

    // title
    const pageWidth = pdf.internal.pageSize.getWidth();
    const titleWidth = pdf.getStringUnitWidth(title) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
    // position and title style
    const titleX = (pageWidth - titleWidth) / 2;
    const titleY = headerY + 20;
    const titleFontSize = 16;
    pdf.setFont("Arial", "Regular");
    pdf.setFontSize(titleFontSize);
    pdf.text(title, titleX, titleY);

    // get de canvas image in base64
    const imgData = canvas.toDataURL('image/png');

    // Add Image to PDF
    // adjusting the position of the image
    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const imgX = 0;
    const imgY = titleY + 10; // space between title and image
    pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth, imgHeight);

    // footer
    const currentDate = new Date().toLocaleDateString('es-ES', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
    pdf.setFont("Arial", "italic");
    pdf.setFontSize(12);
    const footerX = pdf.internal.pageSize.getWidth() - 20;
    const footerY = pdf.internal.pageSize.getHeight() - 20;
    const footerWidth = pdf.internal.pageSize.getWidth() - 2 * footerX;
    pdf.text(currentDate, footerX, footerY, { align: 'right', width: footerWidth });

    // Save PDF
    pdf.save(`odontograma-${odontogram.paciente_data.fullname}.pdf`);
  }

  return odontogram==-1
    ? "loading"  // Still loading
    : (
    <>
      {/* HEADER */}
      {
        !isScript && (
          <div className="subheader">
              <h1 className="subheader-title">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                  data-prefix="fas" data-icon="tooth" className="svg-inline--fa fa-tooth fa-w-14"
                  role="img" viewBox="0 0 448 512" style={{width: "15px", height: "16px", verticalAlign: "baseline", filter: "opacity(.9)"}}>
                    <path fill="currentColor" d="M443.98 96.25c-11.01-45.22-47.11-82.06-92.01-93.72-32.19-8.36-63 5.1-89.14 24.33-3.25 2.39-6.96 3.73-10.5 5.48l28.32 18.21c7.42 4.77 9.58 14.67 4.8 22.11-4.46 6.95-14.27 9.86-22.11 4.8L162.83 12.84c-20.7-10.85-43.38-16.4-66.81-10.31-44.9 11.67-81 48.5-92.01 93.72-10.13 41.62-.42 80.81 21.5 110.43 23.36 31.57 32.68 68.66 36.29 107.35 4.4 47.16 10.33 94.16 20.94 140.32l7.8 33.95c3.19 13.87 15.49 23.7 29.67 23.7 13.97 0 26.15-9.55 29.54-23.16l34.47-138.42c4.56-18.32 20.96-31.16 39.76-31.16s35.2 12.85 39.76 31.16l34.47 138.42c3.39 13.61 15.57 23.16 29.54 23.16 14.18 0 26.48-9.83 29.67-23.7l7.8-33.95c10.61-46.15 16.53-93.16 20.94-140.32 3.61-38.7 12.93-75.78 36.29-107.35 21.95-29.61 31.66-68.8 21.53-110.43z"/>
                </svg> {init_od!=-1 && init_od && odontogram.pk != init_od.pk
                    ? "Odontograma de evolucion"
                    : "Odontograma inicial"
                  }
                <button className="btn btn-xs btn-primary pl-2 pr-2 pt-1 pb-1 ml-2"
              title="Descargar odontograma"
              onClick={descargarOdontograma}
            >
              <i className="fas fa-download fa-lg"></i>
            </button>
          </h1>
            <div className="row">
              {(role=="evol" || role=="regular") && init_od!=-1 && init_od && odontogram.pk != init_od.pk
                // Check that role is evol
                // Check that init_od is setted and that odontogram and init_od are not the same
                ? (
                  <div style={{marginRight: "8px"}}>
                    <button
                      type="button" className="btn btn-primary waves-effect waves-themed"
                      onClick={() => redirectTo(`/nav/odontograma/redirect/${__params__.pac_pk||cita.paciente}/inicial/`)}
                    >Ir a Inicial</button>
                  </div>
                ) : ""
              }
              {odontogram && (role=="init" || role=="regular")
                // Check that role is init and odontogram is setted (is not new)
                ? (
                  <div style={{marginRight: "8px"}}>
                    <button
                      type="button" className="btn btn-primary waves-effect waves-themed"
                      onClick={() => redirectTo(`/nav/odontograma/redirect/${__params__.pac_pk||cita.paciente}/evolucion/`)}
                    >Ir a Evolucion</button>
                  </div>
                ) : ""
              }
              {init_od!=-1 && init_od && odontogram.pk != init_od.pk
                // Check that init_od is setted
                // Check that odontogram and init_od are not the same
                ? (
                  <div style={{marginRight: "8px"}}>
                    <button type="button" className="btn btn-secondary waves-effect waves-themed" data-toggle="modal" data-target="#initialodontogram_modal">Ver Inicial</button>
                  </div>
                ) : ""
              }
              {ar_ev_od_log.length==0 ? ""
                // Check if odontogram and init_od are the same
                : (
                  <div style={{marginRight: "8px"}}>
                    <button type="button"
                    className="btn btn-secondary waves-effect waves-themed"
                    onClick={toggle}
                    >
                      Registro de evolucion
                    </button>
                  </div>
                )
              }
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label className={"btn btn-success waves-effect waves-themed "+(odontogram_type.current==='A'?'active':'')} onClick={()=>changeTeethType('A')}>
                  <input type="radio" name="odontogram_type" defaultChecked /> Adulto
                  <span className="badge border border-light rounded-pill bg-info-500 position-absolute pos-top pos-right">{inc_count_a.current}</span>
                </label>
                <label className={"btn btn-success waves-effect waves-themed "+(odontogram_type.current==='K'?'active':'')} onClick={()=>changeTeethType('K')}>
                  <input type="radio" name="odontogram_type" /> Infante
                  <span className="badge border border-light rounded-pill bg-info-500 position-absolute pos-top pos-right">{inc_count_k.current}</span>
                </label>
              </div>
            </div>
          </div>
        )
      }
      {/* Paciente */}
      {cita && (<div><h5>Paciente: <i>{cita.paciente_data.fullname}</i></h5></div>)}

      <div style={{whiteSpace: "nowrap"}}>
        <canvas id="odontogram" width="750" height="530" style={{background:"white", verticalAlign:"top"}} ref={canvasRef}></canvas>
        {
          !isScript &&
            <div style={{display: "inline-block"}} className="ml-4">
            {(()=>{
              if(incident)
                return <IncidentForm setTeeth={setTeeth} incident={incident}
                  setIncident={setIncident} inc_list={incident_list}
                  set_inc_list={setIncidentList} odontogram_type={odontogram_type.current} />
              else return <IncidentPanel setIncident={setIncident} />
            })()}
          </div>
        }
      </div>
      {/* Bottom Form */}
      {
        !isScript && (
          <div>
            <div  className="row" style={{paddingTop: "15px"}}>
              <div className="col-md-6">
                <label className="form-label" style={{fontSize: "1.3em"}} htmlFor="textarea_observaciones">Observaciones</label>
                <textarea className="form-control" id="textarea_observaciones" rows="5" defaultValue={odontogram.observaciones}></textarea>
              </div>
              <div className="col-md-6">
                <IncidentList
                  inc_list={incident_list}
                  set_inc_list={setIncidentList}
                  getToothByKey={getToothByKey}
                  clearTooth={clearTooth}
                  pac_pk={odontogram?.paciente || cita.paciente}
                  pdt_pk={odontogram?.pdt}
                  suc_pk={current_sucursal}
                  odontogram_pk={odontogram.pk}
                  updateIncidences={() => getIncidences(odontogram?.pk)}
                  />
              </div>
            </div><br/>
            <button type="button" className="btn btn-primary waves-effect waves-themed"
              onClick={saveOdontogram} title="Asegurate de escribir las observaciones que encuentres">
              {init_od!=-1 && (!init_od || init_od && odontogram.pk == init_od.pk)
                ? "Guardar Odontograma Inicial"
                : "Guardar"}
            </button>
            <button type="button" className="btn btn-secondary waves-effect waves-themed"
              style={{marginLeft: "15px"}} onClick={()=>window.history.back()}>Atras</button>
          </div>
        )
      }
      {/* ALERTS */}
      <br/>
      <PageTitle />
      {/* MODALS */}
      {init_od!=-1 && init_od && odontogram.pk != init_od.pk
        // Check if odontogram and init_od are the same
        ? <InitialOdontogramModal init_od={init_od} />
        : ""
      }
      {(ar_ev_od_log.length==0) ? ""
        // if ar_ev_od_log is empty -> do nothing
        : //<EvoLogOdModal evol_od={ar_ev_od_log} paciente={cita.paciente}/>
        <SimpleModal2
          body={<OdontogramHistory patientPk={cita.paciente || odontogram.paciente}/>}
          title={"Registro de Evolución"}
          size={"lg"}
          />
      }
    </>
  )
}

// Related components
const IncidentForm = ({setTeeth, incident, setIncident, inc_list, set_inc_list, odontogram_type}) => {
  let inc_form = incident_type.form_elements.find(i => i.id == incident.id);

  const s_select_helper = useMemo(() => {
    if(!incident) return [];

    if( incident_type.component_array.includes(incident.id) ){
      select_type = 2;
      return "Seleccione los elementos involucrados";
    }
    if( incident_type.component_line_array.includes(incident.id) ){
      select_type = 3;
      return "Seleccione los bordes de la incidencia";
    }
    if( incident_type.component_tooth.includes(incident.id) ){
      select_type = 4;
      return "Seleccione el diente";
    }
    if( incident_type.component_beside.includes(incident.id) ){
      select_type = 4;
      return "";
    }
    if( incident_type.component_range.includes(incident.id) ){
      select_type = 4;
      return "Seleccione el otro extremo del rango";
    }
    if( incident_type.component_all.includes(incident.id)){
      select_type = 0;
      return "Ubicación";
    }
  }, [incident])

  const form_elements = useMemo(() => {
    if(!incident) return [];

    let elements = [];

    // Preview canvas
    if( incident_type.component_line_array.includes(incident.id) ){
      elements.push(
        <div key="od-line_array-preview-canvas" className="form-group">
          <canvas id="preview"
            width={(settings.width*1.3+preview_margin*2)*preview_scale}
            height={(settings.height+preview_margin*2)*preview_scale}
            style={{background: settings.toothhovercolor, verticalAlign: "top"}}
            />
        </div>
      );
    }

    // Location
    if( incident_type.component_all.includes(incident.id) && ! incident_type.component_all_mouth.includes(incident.id)){
      elements.push(
        <div key={"od-all-location-"+incident.id} id="form-direction" className="form-group">
          <div className="custom-control custom-radio">
            <input type="radio" className="custom-control-input" id="all-up" name="all-direction" value="U" defaultChecked />
            <label className="custom-control-label" htmlFor="all-up">Arriba</label>
          </div>
          <div className="custom-control custom-radio">
            <input type="radio" className="custom-control-input" id="all-down" name="all-direction" value="D" />
            <label className="custom-control-label" htmlFor="all-down">Abajo</label>
          </div>
        </div>
      );
    }
    if( incident_type.component_all_mouth.includes(incident.id)){
      elements.push(
        <div key={"od-all-location-"+incident.id} id="form-direction" className="form-group" style={{ display: 'none'}}>
          <div className="custom-control custom-radio">
            <input type="radio" className="custom-control-input" id="all-up" name="all-direction" value="U" defaultChecked />
            <label className="custom-control-label" htmlFor="all-up">Arriba</label>
          </div>
        </div>
      );
    }

    // Log
    if(inc_form.hasOwnProperty("log")){
      elements.push(
        <div key={"od-inc_form-log-"+incident.id} id="form-log" className="form-group">
          <label className="form-label fs-xl" htmlFor="value-log">Identificador</label>
          <select className="form-control" id="value-log">
            {inc_form.log.map(opt => <option key={"log_option_"+opt.key} value={opt.key}>{opt.text}</option>)}
          </select>
        </div>
      );
    }

    // State
    if(inc_form.hasOwnProperty("state")){
      elements.push(
        <div key={"od-inc_form-state-"+incident.id} id="form-state" className="form-group">
          <span className="fs-xl">Estado</span>
          <div className="custom-control custom-radio">
            <input type="radio" className="custom-control-input" id="value-state-T" value="true" name="inc_state" defaultChecked={inc_form.state} />
            <label className="custom-control-label" htmlFor="value-state-T">Buen estado</label>
          </div>
          <div className="custom-control custom-radio">
            <input type="radio" className="custom-control-input" id="value-state-F" value="false" name="inc_state" defaultChecked={!inc_form.state}/>
            <label className="custom-control-label" htmlFor="value-state-F">Mal estado</label>
          </div>
        </div>
      );
    }

    // Orientation
    if(inc_form.hasOwnProperty("orientation")){
      elements.push(
        <div key={"od-inc_form-orientation-"+incident.id} id="form-orientation" className="form-group">
        <span className="fs-xl">Dirección</span>
          <div className="custom-control custom-radio">
            <input type="radio" className="custom-control-input" id="value-orientation-R" value="R" name="inc_orientation" defaultChecked />
            <label className="custom-control-label" htmlFor="value-orientation-R">Derecha</label>
          </div>
          <div className="custom-control custom-radio">
            <input type="radio" className="custom-control-input" id="value-orientation-L" value="L" name="inc_orientation" />
            <label className="custom-control-label" htmlFor="value-orientation-L">Izquierda</label>
          </div>
        </div>
      );
    }

    // Fractura
    if(inc_form.hasOwnProperty("fractura")){
      elements.push(
        <div key={"fractura_div"+incident.id} id="form-fractura" className="form-group">
          <span className="fs-xl">Caso</span>
          <label className="form-label" htmlFor="value-fractura"></label>
          <select className="form-control" id="value-fractura">
            {inc_form.fractura.map(opt => <option key={"fractura_option_"+opt.key} value={opt.key}>{opt.text}</option>)}
          </select>
        </div>
      );
    }

    // Detail
    elements.push(
      <div key={"od-inc-detail-"+inc_form.id} className="form-group form-detail">
        <label className="form-label" htmlFor="inc_detail">Detalle de la incidencia: </label>
        <input type="text" id="inc_detail" name="inc_detail" className="form-control" />
      </div>
    );

    return elements;
  }, [incident])

  // Save incidence function
  const saveIncidence = () => {
    if(select_type===0){  // When select type is 0 there is no condition
    }else if(!currentTooth.tooth && !incident_type.component_tooth.includes(incident.id)){  // When select type is other than 1 there should be a tooth selected
      alert("Debe seleccionar un diente");
      return;
    }else if(select_type!==4 && inc_paths.length<1){  // When select type is other than 4 there should be at least one component selected
      alert("Debe seleccionar al menos un componente");
      return;
    }

    const clavesVistas = {};

    // Filtrar el arreglo para obtener objetos con claves únicas
    const arregloFiltrado = inc_paths.filter((objeto) => {
      const key = objeto.tooth?.key;
      // Verificar si la clave ya ha sido vista
      if (!clavesVistas[key]) {
        // Si no ha sido vista, marcarla como vista y mantener el objeto
        clavesVistas[key] = true;
        return true;
      }
      // Si la clave ya ha sido vista, omitir el objeto
      return false;
    });

    // Tooth && component
    if((incident_type.component_array.includes(incident.id) ||incident_type.component_tooth.includes(incident.id) ) && arregloFiltrado.length > 0) {
      arregloFiltrado.forEach(incidente => {
         // Incident
        let _inc_obj = {};  // Incident object
        _inc_obj.odontogram_type = odontogram_type=='A'?"1":"2"  // Specify what odontogram type does this incident belong
        _inc_obj.type = Number(incident.id);  // Incident code
        _inc_obj.detalle = window.document.getElementById('inc_detail').value  // Incident detail
        _inc_obj.nueva_incidencia = true  // Nueva incidencia ingresada por el usuario

        // Get form data
        let _form_data = {};
        if(document.getElementById("form-log"))
          _form_data.log = document.getElementById("value-log").value;
        if(document.getElementById("form-state"))
          _form_data.state = document.getElementById('value-state-T').checked;
        if(document.getElementById("form-orientation"))
          _form_data.orientation = document.getElementById('value-orientation-R').checked ? 'R' : 'L';
        if(document.getElementById("form-fractura"))
          _form_data.fractura = parseInt(document.getElementById('value-fractura').value);
        _inc_obj.value = Object.assign({}, _form_data);  // Components of incident

        let _tooth = incidente.tooth
        _inc_obj.component = inc_paths.reduce((ar, v)=>{
          if(v.tooth.key === _tooth.key){
            ar.push(parseInt(v.key)); 
          }
          return ar
        }, []);
        _tooth.incidents.push(_inc_obj);  // Add incident object to tooth

        if(inc_list.filter(inc => (inc.diente === _tooth.key && inc.type === incident.id )).length === 0){
          inc_list.push({
            diente_inicio: 0,
            diente: _tooth.key,
            type: Number(incident.id),
            inx: _tooth.incidents.length-1,
            pk: -1, // Incidence yet to be saved in API
            dpdts: [], // No related dpdts
            tipo_odontograma: odontogram_type,
          })
        }
      })
      setTeeth(teeth);  // Save modified teeth

      set_inc_list(inc_list);
      // Return incident to false (finish the form)
      setIncident(false);
    }
    else {
        // Incident
      let _inc_obj = {};  // Incident object
      _inc_obj.odontogram_type = odontogram_type=='A'?"1":"2"  // Specify what odontogram type does this incident belong
      _inc_obj.type = Number(incident.id);  // Incident code
      _inc_obj.detalle = window.document.getElementById('inc_detail').value  // Incident detail
      _inc_obj.nueva_incidencia = true  // Nueva incidencia ingresada por el usuario

      // Get form data
      let _form_data = {};
      if(document.getElementById("form-log"))
        _form_data.log = document.getElementById("value-log").value;
      if(document.getElementById("form-state"))
        _form_data.state = document.getElementById('value-state-T').checked;
      if(document.getElementById("form-orientation"))
        _form_data.orientation = document.getElementById('value-orientation-R').checked ? 'R' : 'L';
      if(document.getElementById("form-fractura"))
        _form_data.fractura = parseInt(document.getElementById('value-fractura').value);
      _inc_obj.value = Object.assign({}, _form_data);  // Components of incident
          let _tooth = currentTooth.tooth;
      if(select_type===0){
        // Select the last tooth
        let _teeth = document.getElementById("all-up").checked ? teeth.upper_teeth : teeth.lower_teeth;
        _tooth = _teeth[_teeth.length-1];  // Select last tooth
      }else if(select_type===4){
        if(incident_type.component_beside.includes(incident.id)){  // Only two teeth
          if( !addBesideIncidence(teeth, _tooth, _inc_obj) ) return;
        }else if(incident_type.component_range.includes(incident.id)){  // *Range of teeth
          if(inc_paths.length!==1 || !inc_paths[0].key){
            alert("Debe seleccionar el otro extremo del rango")
            return;
          }

          // Start tooth key
          _inc_obj.value.start_tooth_key = inc_paths[0].key;

          // Get other tooth object
          let location = ["1", "2", "5", "6"].includes(String(inc_paths[0].key)[0]);
          let _teeth;  // Get teeth
          if(location) _teeth = teeth.upper_teeth;
          else if(!location) _teeth = teeth.lower_teeth;
          let _other_tooth;  // Other tooth object
          let _inx;  // Other tooth index
          let _other_right = _teeth.some((tooth, inx) => {
            if(tooth.key==inc_paths[0].key){
              _other_tooth = tooth;
              _inx = inx;
              return true;
            }
            return false;
          });
          // Get current tooth index
          let inx = _teeth.indexOf(_tooth);
          if(_other_right===false || inx===false){
            alert("SOMETHING WENT WRONG, TOOTH INDEX WASN'T FOUND IN TEETH ARRAY")
            return;
          }
          // Change teeth if order is not right
          if(_inx > inx){
            _inc_obj.value.start_tooth_key = _tooth.key;
            _tooth = Object.assign({}, _other_tooth);
          }
        }
      }else{
        // Get components of incident, get only key
        _inc_obj.component = inc_paths.reduce((ar, v)=>{ar.push(parseInt(v.key)); return ar}, []);
      }

      if(__debug__) console.log("_inc_obj", _inc_obj);
      _tooth.incidents.push(_inc_obj);  // Add incident object to tooth
      setTeeth(teeth);  // Save modified teeth
      // Add to incident list

      inc_list.push({
        diente_inicio: Number(_tooth.incidents[_tooth.incidents.length-1].value.start_tooth_key),
        diente: _tooth.key,
        type: Number(incident.id),
        inx: _tooth.incidents.length-1,
        pk: -1, // Incidence yet to be saved in API
        dpdts: [], // No related dpdts
        tipo_odontograma: odontogram_type,
      });
      setTeeth(teeth);  // Save modified teeth

      set_inc_list(inc_list);
      // Return incident to false (finish the form)
      setIncident(false);
    }
     
  }
  const resetIncidence = () => setIncident(false)

  return (
    <div className="pl-3" style={{whiteSpace: "break-spaces", width: "300px"}}>
      <h1>{incident.nombre}</h1>

      <div className="form-group">
        <i>{s_select_helper}</i>

        {form_elements}
      </div>

      <div className="pt-4 text-center">
        <button className="btn btn-primary mr-2" onClick={saveIncidence}>Guardar</button>
        <button className="btn btn-secondary" onClick={resetIncidence}>Cancelar</button>
      </div>
    </div>
  )
}
const IncidentPanel = ({setIncident}) => {
  const [search_text, setSearchText] = useState("");

  const incidence_list = useMemo(() => inc_functions
    .filter(i => i.nombre.toUpperCase().includes(search_text.toUpperCase()))
    .sort().map(v => (
      <button key={"inc_button_"+v.id} type="button"
      className="btn btn-light waves-effect waves-themed"
      onClick={() => setIncident(v)}>
        {v.nombre}
      </button>
    )
  ), [search_text]);

  const updateSearchText = ev => setSearchText(ev.target.value);

  useEffect(() => {
    if(window.$) window.$('#slimscroll').slimScroll({
      width: "280",
      height: "440",
      size: "4px",
      color: "rgba(0,0,0,0.6)",
      distance: "4px",
      railcolor: "#fafafa",
    });
  }, []);

  return (
    <Card>
      <CardHeader className="text-center">
        <CardTitle tag="h3" className="fw-700">
          LISTA DE INCIDENCIAS
        </CardTitle>
      </CardHeader>

      <CardBody className="p-0">
        {/* Search Bar */}
        <input className="form-control col-12" value={search_text}
        onChange={updateSearchText} placeholder="Buscar..." />

        <div id="slimscroll" className="custom-scroll">
          <div className="btn-group-vertical w-100">
            {incidence_list}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

const IncidentList = ({inc_list, set_inc_list, getToothByKey, clearTooth, pac_pk, pdt_pk, suc_pk, updateIncidences, odontogram_pk}) => {
  const [ar_selected, setSelected] = useState([])
  const [show_pdt_modal, setShowPDTModal] = useState(false)
  const [_pdt_pk, setPDTPK] = useState(pdt_pk)
  const [pdts, setPDTs] = useState([])
  const [pxss, setPXS] = useState([])

  const getPDTs = () => {
    simpleGet(`atencion/plantrabajo/?filtro={"paciente":"${pac_pk}", "sucursal":"${suc_pk}"}`)
    .then(setPDTs)
  }
  const getProcedures = () => simpleGet(`maestro/procedimiento/sucursal/?filtro={"active":"1"}`).then(setPXS)
  const switchInc = _inc => {
    /* switch the state of inc in ar_selected
    if inc in ar_selected -> removes it
    if not inc in ar_selected -> append it
    notes:
      [].concat returns a copy of the array but modifies the array itself
        so, if we were to setSelected the modified array
          the state won't change bc it's the same object
          and there won't be new render showing the status change
        instead, we setSelected the return of [].concat
          it being a copy (not the same memory address) the state will change and fire a render
    */
    if(ar_selected.includes(_inc))
      setSelected(ar_selected.filter(i => i != _inc))
    else setSelected(ar_selected.concat(_inc))
  }
  const execDelete = () => {
    let _fake_inc_list = Array.from(inc_list);

    ar_selected.map(inc => {
      // Handle general incidence
      if(inc.type == 0){
        _fake_inc_list = _fake_inc_list.filter(i => i != inc)
        return;
      }

      // Get tooth from incidence
      let _tooth = getToothByKey(
        inc.tipo_odontograma=="A"?teeth_a:teeth_k,
        inc.diente
      )
      // Fix beside teeth incidence before delete incidence
      if(incident_type.component_beside.includes(inc.type)){
        // Direction of aside tooth
        let _direction =
          (_tooth.incidents[inc.inx].value.orientation==="R")
          ? 1 : -1;
        // Get aside tooth
        let _tooth2 = (_tooth.orientation=="U"?teeth.upper_teeth:teeth.lower_teeth)[
          (_tooth.orientation=="U"?teeth.upper_teeth:teeth.lower_teeth)
          .indexOf(_tooth)+_direction
        ];
        // Remove incidence from aside tooth
        _tooth2.incidents = _tooth2.incidents.filter(i => {
          return !(
            i.type==inc.type ||
            i.value.orientation=="R"?1:-1 != _direction
          );
        });
      }
      // Delete incidence from its tooth
      _tooth.incidents.splice(inc.inx, 1);
      // Delete incidence from list
      _fake_inc_list = _fake_inc_list.filter(i => i != inc)
      // Fix index in other incidences of the same tooth
      _fake_inc_list.map((_other_inc) => {
        if(_other_inc.diente==_tooth.key){  // Same tooth
          // Index is upper than the one we're erasing
          if(_other_inc.inx>inc.inx) --_other_inc.inx;
        }
      });
    })

    clearTooth();  // Delete incidence drawing
    set_inc_list(_fake_inc_list);
    setSelected([]);
  }

  const openPDTModal = () => {
    // Allow to open modal with no selected incidence
    // Prevent from opening modal with selected general incidences
    if( ar_selected.some(inc => inc.type == 0) ){
      alert("Por favor deseleccione las incidencias generales")
      return;
    }
    // Prevent from opening modal with unsaved incidences
    if( inc_list.some(inc => inc.pk == -1) ){
      alert("Debes guardar los cambios en el odontograma antes")
      return;
    }

    setShowPDTModal(true)
  }
  const closePDTModal = () => setShowPDTModal(false)
  const onDPDTCreated = (new_pdt=false, _od=false) => {
    setSelected([])
    updateIncidences()

    // Select newly created PDT
    if(new_pdt == -1 && _od){
      getPDTs();
      setPDTPK(_od.pdt)
    }
  }

  useEffect(() => {
    if(window.$) window.$('#slimscroll-inc_list').slimScroll({
      height: "137",
      size: "4px",
      color: "rgba(0,0,0,0.6)",
      railVisible: true,
      alwaysVisible: true,
      railcolor: "#fafafa",
    });
    getProcedures()
  }, []);
  useEffect(() => {
    if(!pac_pk || !suc_pk) return;

    getPDTs()
  }, [pac_pk, suc_pk])
  useEffect(() => {
    setSelected([])
  }, [inc_list])

  return (
    <React.Fragment>
      <label className="form-label fs-xl">
        Incidencias en el odontograma

        <div style={{display: "inline", marginLeft: "10px"}}>
          <button className="btn btn-xs btn-primary pl-2 pr-2 pt-1 pb-1 ml-2" onClick={openPDTModal} title="Crear plan de trabajo">
            <i className="fas fa-file-medical-alt"></i>
          </button>

          <button className="btn btn-xs btn-danger pl-2 pr-2 pt-1 pb-1 ml-2" onClick={execDelete} title="Eliminar incidencias">
            <i className="fas fa-trash-alt"></i>
          </button>

        </div>
      </label><br/>
      <div id="slimscroll-inc_list" className="custom-scroll">
        <div style={{paddingLeft: "5px", fontSize: "1.2em"}}>
          {inc_list.length == 0
            ? "No hay ninguna incidencia"
            : inc_list.map((inc, inx) => (
              <div key={"inc_list_"+inx}>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" className="custom-control-input"
                    checked={ar_selected.includes(inc)} readOnly/>

                  <label className="custom-control-label" title={inc.detalle}
                  onClick={() => switchInc(inc)}>
                    {inc.type !== 0
                      ? (
                        <React.Fragment>
                          <span>Diente: </span>
                          <b>{inc.diente_inicio
                            ? `${inc.diente_inicio} - ${inc.diente}`
                            : inc.diente
                          }</b>
                          <span> &nbsp;{inc_functions.find(i => i.id == inc.type)?.nombre || ""}</span>
                        </React.Fragment>
                      )
                      : (
                        <span>General</span>
                      )}

                    {inc.dpdts.length > 0 && " --- "}
                    {inc.dpdts.map(dpdt =>
                      <Link to={`/nav/plandetrabajo/${dpdt.pdt}/?dpdt=`+dpdt.pk} key={"link_inc_to_pdt_"+dpdt.pk}
                      target="blank" className="mr-2">
                        {dpdt.procedimiento}
                      </Link>
                    )}
                  </label>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <IncidenceListPDTModal
        show_modal={show_pdt_modal}
        closeModal={closePDTModal}
        onSubmit={onDPDTCreated}
        inc_list={ar_selected}
        pdts={pdts}
        pdt_pk={_pdt_pk}
        pxss={pxss}
        odontogram_pk={odontogram_pk}
        />
    </React.Fragment>
  )
}
const IncidenceListPDTModal = ({show_modal, closeModal, onSubmit, inc_list, pdts, pxss, pdt_pk, odontogram_pk}) => {
  /* IncidenceListPDTModal
    modal to create procedures in PDT and link those procedures to the selected incidences
  * shows the selected incidences
  * allows to create a new PDT
  * shows selected PDT's procedures
  */
  // PDT
  const inp_pdtname = useRef()
  const opt_pdts = useMemo(() =>
    [{value: -1, label: "Nuevo Plan de trabajo"}]
    .concat(pdts.map(pdt => ({value: pdt.pk, label: pdt.nombre})))
  , [pdts])
  const [selected_pdt, setSelectedPDT] = useState(false)
  const [dpdts, setDPDTs] = useState(false)
  // PXS
  const inp_precio = useRef()
  const opt_pxss = useMemo(() => pxss.map(pxs => ({
    value: pxs.pk,
    label: pxs.nombre.toUpperCase(),
    precio: pxs.precio,
  })), [pxss])
  const [selected_pxs, setSelectedPXS] = useState(false)
  // Otros
  const [val_dpdtperdi, setDPDTperDI] = useState(false)
  const inp_cantidad = useRef()
  const btn_loading = useRef(false)

  const style = {
    form_div: {
      justifyContent: "space-between",
      paddingLeft: "20px",
    },
    header_inc_list: {
      backgroundColor: "lightblue",
    }
  }

  const setDefaultPDT = () => {
    if(show_modal) return;

    setSelectedPDT(opt_pdts.find(opt => opt.value == pdt_pk) || opt_pdts[0])
  }
  const getDPDT = () => {
    if(selected_pdt == false) return;
    if(selected_pdt.value == -1){
      // New PDT
      setDPDTs([]) // No dpdts yet
      return;
    }

    setDPDTs(false) // Show loading
    simpleGet(`atencion/plantrabajo/detalle/?pt=${selected_pdt.value}`)
    .then(setDPDTs)
  }
  const predictSelectedProcedure = () => {
    if(__debug__) console.log("predictSelectedProcedure")
    if(!show_modal || opt_pxss.length == 0) return;

    setSelectedPXS(opt_pxss[0])
  }
  const updatePriceByProcedure = () => {
    if(!show_modal || !selected_pxs) return;

    inp_precio.current.value = selected_pxs.precio
  }
  const updateDPDTSlimScroll = () => {
    if(!show_modal || !dpdts || dpdts.length == 0) return;

    window.$('#slimscroll-dpdt_list').slimScroll({
      height: "300",
      size: "4px",
      color: "rgba(0,0,0,0.6)",
      railVisible: true,
      alwaysVisible: true,
      railcolor: "#fafafa",
    });
  }
  const resetSelectedPXS = () => {
    if(!show_modal) setSelectedPXS(false)
  }
  const validatePayload = payload => {
    let has_error = false;

    if(payload.pdt == -1 && !payload.pdt_name){
      alert("El nombre del Plan de trabajo no puede estar vacio");
      has_error = true;
    }
    if(!payload.odontogram_pk){
      alert("Por favor primero guarde los cambios en el odontograma");
      has_error = true;
    }

    return !has_error;
  }
  const generatePayload = () => {
    if(btn_loading.current) return;

    let payload = {
      incidences: inc_list.map(inc => inc.pk),
      pdt: selected_pdt.value,
      pdt_name: inp_pdtname.current ? inp_pdtname.current.value.trim() : "",
      pxs: selected_pxs.value,
      precio: inp_precio.current.value.trim(),
      cantidad: inp_cantidad.current.value.trim(),
      dpdt_per_di: val_dpdtperdi,
      odontogram_pk: odontogram_pk,
    }

    if(!validatePayload(payload)) return;

    btn_loading.current = true
    simplePostData("atencion/odontograma/incidencia/dpdt/", payload)
    .then(_od => onSubmit(payload.pdt, _od))
    .catch(() => {
      alert("Ocurrio un error, por favor recargue la página y si el error persiste reportelo a los administradores")
    })
    .finally(() => {
      setTimeout(() => {btn_loading.current = false}, 1000);
      closeModal();
    })
  }

  useEffect(setDefaultPDT, [selected_pdt, pdts, pdt_pk])
  useEffect(getDPDT, [selected_pdt])
  useEffect(predictSelectedProcedure, [opt_pxss, show_modal])
  useEffect(updatePriceByProcedure, [selected_pxs, show_modal])
  useEffect(updateDPDTSlimScroll, [dpdts, show_modal, selected_pxs])
  useEffect(resetSelectedPXS, [show_modal])

  return (
    <Modal isOpen={show_modal} toggle={closeModal} size="lg">
      <ModalHeader tag="h3">
        Crear Procedimiento en Plan de Trabajo

        <div className="pl-3 pt-1" style={style.header_inc_list}>
        {inc_list.length === 0 ? (
          <li>
            <span>Toda la boca</span>
          </li>
        ) : inc_list.map(inc =>
            <li key={"dpdt_inc_"+inc.pk}>
              <span>Diente: </span>
              <b>{inc.diente_inicio?`${inc.diente_inicio} - ${inc.diente}`:inc.diente}</b>
              <span> &nbsp;{inc_functions.find(i => i.id == inc.type)?.nombre || ""}</span>
            </li>
          )}
        </div>
      </ModalHeader>
      <ModalBody>

        {/* DPDT Form */}
        <div className="row">
          <div className="col-md-7">
            {/* Select PDT */}
            <div className="form-group row" style={style.form_div}>
              <label className="form-label">Plan de Trabajo</label>
              <Select className="col-8 p-0"
                value={selected_pdt}
                onChange={setSelectedPDT}
                options={opt_pdts} />
            </div>

            {/* New PDT name */}
            {selected_pdt.value == -1 && (
              <div className="form-group row" style={style.form_div}>
                <label className="form-label">Nuevo Plan de Trabajo</label>
                <input className="form-control col-8" ref={inp_pdtname}
                placeholder="Nombre del plan de trabajo" />
              </div>
            )}

            {/* Procedimiento */}
            <div className="form-group row" style={style.form_div}>
              <label className="form-label">Procedimiento</label>
              <Select className="col-8 p-0"
                value={selected_pxs}
                onChange={setSelectedPXS}
                options={opt_pxss} />
            </div>

            {/* Precio */}
            <div className="form-group row" style={style.form_div}>
              <label className="form-label">Precio</label>
              <input type="number" className="form-control col-8"
              placeholder="Precio" min="0" ref={inp_precio} />
            </div>

            {/* Cantidad */}
            <div className={"form-group row"+(val_dpdtperdi?" disabled":"")} style={style.form_div}>
              <label className="form-label">Cantidad</label>
              <input type="number" className="form-control col-8" placeholder="Precio"
              min="1" defaultValue="1" ref={inp_cantidad} disabled={val_dpdtperdi} />
            </div>

            {/* BTN: 1 Proc per DI */}
            {inc_list.length > 0 ? (
              <div className="form-group row pt-2 justify-content-end" style={style.form_div}>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" className="custom-control-input" id="od-dpdt-chx-dpdtperdi"
                  checked={val_dpdtperdi} onChange={ev => setDPDTperDI(ev.target.checked)} />
                  <label className="custom-control-label" htmlFor="od-dpdt-chx-dpdtperdi">Crear 1 procedimiento por incidencia</label>
                </div>
              </div>
            ): ""}

          </div>

          {/* DPDT List */}
          <div className="col-md-5 pl-4">
            <div className="custom-scroll" id="slimscroll-dpdt_list">
              <h4>Procedimientos del Plan de trabajo seleccionado</h4>

              {dpdts === false
                ? <Loader scale={2} />
                : dpdts.length == 0
                  ? <h6>No hay procedimientos</h6>
                  : (
                    <table>
                      <thead>
                        <tr>
                          <th>N°</th>
                          <th>Procedimiento</th>
                          <th>Estado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dpdts.map(dpdt => (
                          <tr key={"od_inc_pdt_dpdt_"+dpdt.pk}>
                            <td className="pr-2">{dpdt.orden}</td>
                            <td className="pr-4">{dpdt.pxs_data.nombre}</td>
                            <td>
                              {dpdt.estado == '1' && "Planificado"}
                              {dpdt.estado == '2' && "Cita"}
                              {dpdt.estado == '3' && "Concluido"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )
                }
            </div>
          </div>
        </div>

      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" onClick={generatePayload}>Guardar</button>
        <button className="btn btn-warning" onClick={closeModal}>Cancelar</button>
      </ModalFooter>
    </Modal>
  )
}

// Initial odontogram
function InitialOdontogramModal({init_od}){
  return (
    <RegularModalCentered
      _title={"Odontograma inicial"}
      _id={"initialodontogram_modal"}
      _body={
        <OdontogramaInicial init_od={init_od}/>
      }
      _min_width={"1000"} />
  )
}
function OdontogramaInicial({init_od}){
  /* Prefijo de las variables de este contexto
  * o_: variable inicial
  * init_od: odontogram object (this component don't handle bad odontogram object, check it out before using this)
  */
  const [o_ctx, setOCtx] = useState(false)  // Canvas context
  const [o_teeth, setOTeeth] = useState([])  // Data current teeth
  const [o_teeth_a, setOTeethA] = useState([])  // Data teeth adult
  const [o_teeth_k, setOTeethK] = useState([])  // Data teeth kid
  const [o_inc_list, setOIncList] = useState([])  // Data teeth kid
  const [o_od_type, setOOdType] = useState(false)  // Current odontogram type
  // Incidence counter
  let o_inc_count_a = useRef(0)
  let o_inc_count_k = useRef(0)

  // Flujo incidencias
  const genTeeth = () => {
      if(__debug__) console.log("od_inicial: genTeeth")

      // Generate Adult Teeth
      genATeeth()
      // Generate Kid Teeth
      genKTeeth()
  }
  const genATeeth = () => {
    if(__debug__) console.log("od_inicial: genATeeth")

    // General
    let _left = (
      // Odontogram element width
      o_ctx.canvas.width-
      // Tooth with plus tooth spacing
      (settings.width+settings.tooth_spacing)*(2+16)
    )/2  // Half of the total space left
    let _top = 40
    let _build_data
    // Teeth container
    let upper_teeth = []
    let lower_teeth = []

    /*** Generate Adult Teeth ***/
    // Upper teeth
    let _teeth_a = []
    upper_teeth = []
    _top += settings.data_height+settings.data_space
    _build_data = build_data.build_adult_top
    _build_data.forEach((v)=>{
      // Calc coordinates
      let _x = _left
      if(upper_teeth.length){
        let last_tooth = upper_teeth[upper_teeth.length-1]
        _x = last_tooth.x + settings.tooth_spacing
        _x += last_tooth.body===2 ? settings.width*1.3 : settings.width
      }

      // Create object with properties
      let a
      switch(v.type){
        case 0: a = new Molar(o_ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 1: a = new Premolar(o_ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 2: a = new Canine(o_ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 3: a = new Incisor(o_ctx, _x, _top, v.key, v.orientation, v.incidents); break;
        default: alert(`ERROR IN TEETH BUILD DATA: tooth type not found in key ${v.key}`); break;
      }
      a.setTeeth(_teeth_a)  // Add teeth's memory reference
      upper_teeth.push(a)
    })
    _teeth_a.upper_teeth = upper_teeth
    // Lower teeth
    lower_teeth = []
    _top *= 3  // Dependant of canvas
    _build_data = build_data.build_adult_bottom
    _build_data.forEach((v)=>{
      // Calc coordinates
      let _x = _left
      if(lower_teeth.length){
        let last_tooth = lower_teeth[lower_teeth.length-1]
        _x = last_tooth.x + settings.tooth_spacing
        _x += last_tooth.body===2 ? settings.width*1.3 : settings.width
      }

      // Create object with properties
      let a
      switch(v.type){
        case 0: a = new Molar(o_ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 1: a = new Premolar(o_ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 2: a = new Canine(o_ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 3: a = new Incisor(o_ctx, _x, _top, v.key, v.orientation, v.incidents); break;
        default: alert(`ERROR IN TEETH BUILD DATA: tooth type not found in key ${v.key}`); break;
      }
      a.setTeeth(_teeth_a)  // Add teeth's memory reference
      lower_teeth.push(a)
    })
    _teeth_a.lower_teeth = lower_teeth
    setOTeethA(_teeth_a)
  }
  const genKTeeth = () => {
    if(__debug__) console.log("od_inicial: genKTeeth")

    // General
    let _left = (
      // Odontogram element width
      o_ctx.canvas.width-
      // Tooth with plus tooth spacing
      (settings.width+settings.tooth_spacing)*(2+10)
    )/2  // Half of the total space left
    let _top = 40
    let _build_data
    // Teeth container
    let upper_teeth = []
    let lower_teeth = []

    /*** Generate Kid Teeth ***/
    // Upper teeth
    let _teeth_k = []
    upper_teeth = []
    _top += settings.data_height+settings.data_space
    _build_data = build_data.build_kid_top
    _build_data.forEach((v)=>{
      // Calc coordinates
      let _x = _left
      if(upper_teeth.length){
        let last_tooth = upper_teeth[upper_teeth.length-1]
        _x = last_tooth.x + settings.tooth_spacing
        _x += last_tooth.body===2 ? settings.width*1.3 : settings.width
      }

      // Create object with properties
      let a
      switch(v.type){
        case 0: a = new Molar(o_ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 1: a = new Premolar(o_ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 2: a = new Canine(o_ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 3: a = new Incisor(o_ctx, _x, _top, v.key, v.orientation, v.incidents); break;
        default: alert(`ERROR IN TEETH BUILD DATA: tooth type not found in key ${v.key}`); break;
      }
      a.setTeeth(_teeth_k)  // Add teeth's memory reference
      upper_teeth.push(a)
    })
    _teeth_k.upper_teeth = upper_teeth
    // Lower teeth
    lower_teeth = []
    _top *= 3  // Dependant of canvas
    _build_data = build_data.build_kid_bottom
    _build_data.forEach((v)=>{
      // Calc coordinates
      let _x = _left
      if(lower_teeth.length){
        let last_tooth = lower_teeth[lower_teeth.length-1]
        _x = last_tooth.x + settings.tooth_spacing
        _x += last_tooth.body===2 ? settings.width*1.3 : settings.width
      }

      // Create object with properties
      let a
      switch(v.type){
        case 0: a = new Molar(o_ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 1: a = new Premolar(o_ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 2: a = new Canine(o_ctx, _x, _top, v.key, v.orientation, v.root, v.incidents); break;
        case 3: a = new Incisor(o_ctx, _x, _top, v.key, v.orientation, v.incidents); break;
        default: alert(`ERROR IN TEETH BUILD DATA: tooth type not found in key ${v.key}`); break;
      }
      a.setTeeth(_teeth_k)  // Add teeth's memory reference
      lower_teeth.push(a)
    })
    _teeth_k.lower_teeth = lower_teeth
    setOTeethK(_teeth_k)
  }
  const getIncidences = () => {
    /* This function uses the 'odontogram' global object */
    /* This function should be called only once and only if odontogram exists */
    if(__debug__) console.log("od_inicial: getIncidences")

    // Get incidents
    simpleGet(`atencion/odontograma/${init_od.pk}/incidencia/`)
    .then(insertIncidencesInTeeth)  // Add incidences to teeth
    .then(changeOdType)
  }
  const getToothByKey = (_teeth_, key) => {
    /* Search for the tooth inside the given teeth object
    */
    // Get tooth by key
    let tooth
    let first_digit = parseInt(String(key)[0])
    let last_digit = parseInt(String(key)[1])
    let up = [1, 2, 5, 6].includes(first_digit)
    let _teeth = up ? _teeth_.upper_teeth : _teeth_.lower_teeth
    let middle_range = _teeth.length/2
    if(up){  // Upper side
      if([1, 5].includes(first_digit)){  // Left side
        tooth = _teeth[middle_range-last_digit]
      }else{  // Right side
        tooth = _teeth[middle_range+last_digit-1]
      }
    }else{  // Lower side
      if([4, 8].includes(first_digit)){  // Left side
        tooth = _teeth[middle_range-last_digit]
      }else{  // Right side
        tooth = _teeth[middle_range+last_digit-1]
      }
    }

    return tooth
  }
  const insertIncidencesInTeeth = objs => {
    /* This function require teethState to be already declared
    * This function will return the last odontogram type in incidence
    */
    if(__debug__) console.log("od_inicial: insertIncidencesInTeeth")
    let last_od_type = "1"
    let _o_inc_list = []
    // Reset teeth incidence counter
    o_inc_count_a.current = 0
    o_inc_count_k.current = 0
    // If there is no incidences to insert
    if(objs.length == 0) changeOdType('A')  // Set default odontogram type

    objs.forEach(inc => {
      // Handle general tooth incidences
      if(inc.type === "0"){
        // Add to incidence list
        _o_inc_list.push({
          nueva_incidencia: inc.nueva_incidencia,
          type: Number(inc.type),
          pk: inc.pk,
          dpdts: inc.dpdts, // Related dpdts
          tipo_odontograma: inc.tipo_odontograma=="1"?'A':'K',
        });
        return;
      }

      /* getTooth from both A&K */
      let _teeth = inc.tipo_odontograma=="1"?o_teeth_a:o_teeth_k
      let tooth = getToothByKey(_teeth, inc.diente)
      if(!tooth){
        console.error("Tooth not found");
        console.error("inc:", inc);
        return
      }

      let inc_obj = {}
      inc_obj.type = parseInt(inc.type)
      inc_obj.value = {}
      inc_obj.value.log = inc.log
      inc_obj.value.state = inc.state
      inc_obj.value.orientation = inc.orientation
      inc_obj.value.fractura = JSON.parse(inc.fractura)
      inc_obj.value.start_tooth_key = inc.start_tooth_key
      inc_obj.component = JSON.parse(inc.component)
      inc_obj.odontogram_type = inc.tipo_odontograma
      inc_obj.detalle = inc.detalle
      inc_obj.nueva_incidencia = false
      tooth.incidents.push(inc_obj)  // Add to global teeth data

      // Set last odontogram type in incidence
      last_od_type = inc.tipo_odontograma

      // Add incidence to o_inc_list
      _o_inc_list.push({
        diente_inicio: Number(inc.start_tooth_key),
        diente: tooth.key,
        type: Number(inc.type),
        detalle: inc_obj.detalle,
      });

      // Add to teeth counter
      if(inc.tipo_odontograma=="1") o_inc_count_a.current++
      else o_inc_count_k.current++

      // Fix aside incidences
      if(incident_type.component_beside.includes(inc_obj.type))
        addBesideIncidence(_teeth, tooth, inc_obj)
    })

    // Set incidence list
    setOIncList(_o_inc_list)

    return last_od_type=="1"?'A':'K'
  }
  const printOdontogram = () => {
    if(__debug__) console.log("od_inicial: printOdontogram")

    o_ctx.clearRect(0, 0, o_ctx.canvas.width, o_ctx.canvas.height)  // Clear canvas
    // All teeth
    let all_teeth = [...o_teeth.upper_teeth, ...o_teeth.lower_teeth]
    all_teeth.forEach(tooth => tooth.draw())
    all_teeth.forEach(tooth => tooth.drawIncidents())
  }

  // Funciones de vista
  const changeOdType = type => {
    if(__debug__) console.log("od_inicial: changeOdType")

    if(type!='A'&&type!='K') return  // Allow only A || K
    if(o_od_type == type) return  // Prevent redundancy

    // Set teeth according to odontogram type
    setOTeeth(type=='A'?o_teeth_a:o_teeth_k)
    setOOdType(type)
  }

  // Only run after first render
  useEffect(() => {
    if(__debug__) console.log("od_inicial: useEffect genTeeth")
    // Elements
    let odontogram_el = document.getElementById('odontograma_inicial')

    // Save 2d context of odontogram
    setOCtx(odontogram_el.getContext('2d'))
  }, [])
  // After both teeth types are setted
  useEffect(() => {
    if(__debug__) console.log("od_inicial: useEffect o_teeth_a, o_teeth_k")

    if(!o_teeth_a.hasOwnProperty("upper_teeth") || !o_teeth_k.hasOwnProperty("upper_teeth")) return
    // Get incidences
    getIncidences()
  }, [o_teeth_a, o_teeth_k])
  // After odontogram type has changed
  useEffect(() => {
    if(!o_od_type) return

    printOdontogram()
  }, [o_od_type])
  // After ctx has changed
  useEffect(() => {
    if(!o_ctx) return

    genTeeth()  // Generate teeth objects
  }, [o_ctx])

  return (
    <div className="row">
      <canvas id="odontograma_inicial" width="750" height="530" style={{
        background:"white",
        width: "750px",
        height: "530px",
      }}></canvas>

      {/* SideBar */}
      <div style={{flex: "1 1 0%", paddingLeft: "15px"}}>
        {/* Odontogram type*/}
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          <label className={"btn btn-success waves-effect waves-themed "+(o_od_type=='A'?'active':'')} onClick={()=>changeOdType('A')}>
            <input type="radio" name="odontogram_type" /> Adulto
            <span className="badge border border-light rounded-pill bg-info-500 position-absolute pos-top pos-right">{o_inc_count_a.current}</span>
          </label>
          <label className={"btn btn-success waves-effect waves-themed "+(o_od_type=='K'?'active':'')} onClick={()=>changeOdType('K')}>
            <input type="radio" name="odontogram_type" /> Infante
            <span className="badge border border-light rounded-pill bg-info-500 position-absolute pos-top pos-right">{o_inc_count_k.current}</span>
          </label>
        </div>
        <hr style={{borderBottomWidth: "2px", borderBottomStyle: "solid"}}/> {/* Separator */}
        {/* Incidences list */}
        <b style={{fontSize: "1.4em"}}>Incidencias</b><br/>
        <div>
          {o_inc_list.length==0
            ? <span style={{fontSize: "1.2em"}}>No hay ninguna incidencia</span>
            : o_inc_list.map((inc, inx) => (
              <div key={"o-inc-"+inx} style={{fontSize: "1.2em", cursor: "pointer"}}  title={inc.detalle}>
                <span>Diente: </span>
                <b>{inc.diente_inicio?`${inc.diente_inicio} - ${inc.diente}`:inc.diente}</b>
                <span> &nbsp;{inc_functions.find(i => i.id == inc.type)?.nombre || ""}</span>
              </div>
            ))
          }
        </div>
        <hr style={{borderBottomWidth: "2px", borderBottomStyle: "solid"}}/> {/* Separator */}
        {/* Odontogram observations */}
        <b style={{fontSize: "1.4em"}}>Observaciones</b><br/>
        <span style={{fontSize: "1.2em"}}>{cFL(init_od.observaciones)}</span>
      </div>
    </div>
  )
}

// Evolution odontogram
function EvoLogOdModal({evol_od, paciente}){
  useEffect(() => {

  }, evol_od)

  return (
    <RegularModalCentered _title={"Registro de evolución"}
      _id={"evologod_modal"}
      _body={
        <EvolutionOdontogramLog evol_od={evol_od} />
      }
      _min_width={"900"} />
  )
}
function EvolutionOdontogramLog({evol_od}){
  useEffect(() => {
    if(__debug__) console.log("od_evolucion");

    if(window.$) window.$('#slimscroll-ev_od_inc_list').slimScroll({
      width: "100%",
      height: "450",
      size: "4px",
      color: "rgba(0,0,0,0.6)",
      distance: "4px",
      railcolor: "#fafafa",
    });
  }, [])


  return (
    <div className="row">
      <div id="slimscroll-ev_od_inc_list" className="custom-scroll" style={{width: "100%", height: "450px"}}>
        {/* Incidence log list */}
        {evol_od.length==0 ? <span style={{fontSize: "1.2em"}}>No hay registros de incidencias</span>
          : evol_od.map(inc => (
            <div key={"ev_od_inc-"+inc.pk}>
              <div className="row no-gutters row-grid align-items-stretch">
                <div className="col-md">
                  <div className="p-3">
                    <div className="d-flex">
                      <div className="d-inline-flex flex-column">
                        {/* Nombre */}
                        <span className="fs-xl text-truncate text-truncate-lg text-info" style={{fontWeight: "bold", whiteSpace: "initial"}}>
                          {inc_functions.find(i => i.id == inc.type)?.nombre || ""}
                        </span>
                        {/* Fecha */}
                        <span>Fecha: <i className="text-truncate text-truncate-xl">{inc.fecha}</i></span>
                        {/* Diente */}
                        <span>Diente: <span className="text-truncate text-truncate-xl">{inc.start_tooth_key?`${inc.start_tooth_key} - ${inc.diente}`:inc.diente}</span></span>
                        {/* Log */}
                        {!inc.log ? ""
                          : <span>Notacion: <i className="text-truncate text-truncate-xl">{inc.log}</i></span>
                        }
                        {/* Estado */}
                        {inc.state==undefined ? ""
                          : <span>Estado: <b className="text-truncate text-truncate-xl" style={{color: inc.state?"blue":"red"}}>{inc.state?"Bueno":"Malo"}</b></span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-7">
                  <div className="p-3">
                    <div className="d-flex">
                      <div className="d-inline-flex flex-column">
                        {/* <span>Detalle:</span> */}
                        {/* Detalle */}
                        {inc.detalle && inc.detalle.length!=0
                          ? (
                            <span className="mt-1 d-block fs-sm fw-400 text-dark" style={{paddingLeft: "10px",lineBreak: "auto"}}>
                              {inc.detalle}
                            </span>
                          ) : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{borderBottomStyle: "solid", marginTop: "5px", marginBottom: "5px"}}/>
            </div>
          ))
        }
      </div>
    </div>
  )
}

// Function
function addBesideIncidence(_teeth, _tooth, _inc){
  // console.log(_tooth, _inc)
  let _sign = _inc.value.orientation==='R'?1:-1;  // Aside tooth is left or right
  // Get _inx && side tooth
  let _inx = _teeth.upper_teeth.indexOf(_tooth);
  let _side_tooth;
  if(_inx!==-1){
    // Check if it's posible to add aside tooth incident
    if((_sign===1 && _inx===_teeth.upper_teeth.length-1) || (_sign===-1 && _inx===0)){
      alert("No es posible agregar la incidencia en esa dirección")
      return false;
    }
    _side_tooth = _teeth.upper_teeth[_inx+_sign];
  }else{
    _inx = _teeth.lower_teeth.indexOf(_tooth);
    if(_inx!==-1){
      // Check if it's posible to add aside tooth incident
      if((_sign===1 && _inx===_teeth.upper_teeth.length-1) || (_sign===-1 && _inx===0)){
        alert("No es posible agregar la incidencia en esa dirección")
        return false;
      }
      _side_tooth = _teeth.lower_teeth[_inx+_sign];
    }else{
      alert("Ha ocurrido un error, diente no encontrado")
      return false;
    }
  }

  // console.log(_sign, _inx, _side_tooth);
  // Fake incident object
  let _inc_obj_fake = {};
  _inc_obj_fake.support = true;  // This incidence is just to support
  _inc_obj_fake.type = _inc.type;  // Incident code
  _inc_obj_fake.odontogram_type = _inc.tipo_odontograma;
  _inc_obj_fake.value = Object.assign({}, _inc.value);  // Incident value
  _inc_obj_fake.value.orientation = _sign===1?'L':'R';  // Change orientation
  // console.log(_inc_obj_fake);

  // Add incident to aside tooth with other orientation
  _side_tooth.incidents.push(_inc_obj_fake);

  return true;
}

// Navigation
function OdontogramaNavigation({data, postCanva}){
  const {redirectTo} = useContext(NavigationContext)

  return(
    <ModalContextProvider>
      <Switch>
          <Route exact path="/nav/odontograma/:cita_pk/">
            <Odontograma redirectTo={redirectTo} role="regular" />
          </Route>
          <Route exact path="/nav/odontograma/:pac_pk/inicial/">
            {/* Agregar boton para ir al evolucion */}
            <Odontograma redirectTo={redirectTo} role="init" />
          </Route>
          <Route exact path="/nav/odontograma/:pac_pk/evolucion/">
            {/* Mostrar odontograma evolucion
              * Si se quiere modificar el odontograma: crear Atender y enlazarlo
            */}
            <Odontograma redirectTo={redirectTo} role="evol" />
          </Route>
          {/* Suport: redirect */}
          <Route exact path="/nav/odontograma/redirect/:pac_pk/inicial/"
            render={props => (
              <Redirect to={`/nav/odontograma/${props.match.params.pac_pk}/inicial/`} />
            )}>
          </Route>
          <Route exact path="/nav/odontograma/redirect/:pac_pk/evolucion/"
            render={props => (
              <Redirect to={`/nav/odontograma/${props.match.params.pac_pk}/evolucion/`} />
            )}>
          </Route>
          <Route exact path="/navsuperuser/utils/save-odontogramas/">
            <Odontograma redirectTo={redirectTo} role="regular" postCanva={postCanva} data={data} />
          </Route>

          <Route>
            <Redirect to="/nav/cita" />
          </Route>
      </Switch>
    </ModalContextProvider>
  )
}

export default OdontogramaNavigation
