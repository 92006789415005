import React, { useState } from "react"
import { PageTitle } from "../../bits";
import CompraList from "./CompraList"
import CompraActions from "./CompraActions";
import ModalFormCompra from "./ModalFormCompra";
import { useCompra } from "../context/CompraContext";
import { useEffect } from "react";

function Compra() {
  const {defaultCompra, setCompra, getListCompras} = useCompra();
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => {
    setIsModalOpen(false)
    getListCompras();
    setCompra(defaultCompra)
  }

  useEffect(() => {
    setCompra(defaultCompra)
  }, [])

  return (
    <>
      <PageTitle title={"Compras"} />
      <div className="row">
        <div className="col-lg-9">
          <div style={{marginBottom: "25px"}}>
            <CompraList openModal={openModal} />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="panel">
            <CompraActions openModal={openModal}/>
          </div>
        </div>
        <ModalFormCompra isOpen={isModalOpen} closeModal={closeModal}/>
      </div>
    </>
  )
}

export default Compra
