import { getDataByPK, handleErrorResponse } from "../../../functions";

const getNota = (id, setup) => {
  getDataByPK("atencion/nota", id)
    .then(setup)
    .catch(() =>
      handleErrorResponse(
        "custom",
        "Error",
        "Ha ocurrido un error al recuperar los datos",
        "danger"
      )
    );
};
export default getNota;
