import React from 'react'
import useFetchData from '../../../hooks/useFetchData';
import SimpleSpinner from '../../loader/SimpleSpinner';
import { PageTitle } from '../../bits';
import SucursalesTable from './SucursalesTable';
import { ModalContextProvider } from '../../../context/modalContext';
import SucursalesReportModal from './SucursalesReportModal';
import SucursalesReportMonth from './SucursalesReportMonth';
import { Container } from 'reactstrap';
import { Error } from '../../../Master'

const endPoint = "fe/resumenbajas/sucursal/all-data";

const containerStyle = { 
    width: '100%', 
    height: '100%', 
    paddingTop: "10px" 
}

const SucursalesReport = () => {
    const [listResumenesbajasSucursales, isLoading, error] = useFetchData(endPoint)

    if (isLoading) return (<div style={containerStyle}><Content> <SimpleSpinner /></Content></div>);

    if (error) return (
        <div style={containerStyle}>
            <div className="alert alert-danger text-center">
                <p>{error.message} - contacte con el soporte</p>
            </div>
        </div>
    );

    return (
        <Container fluid style={containerStyle}>
            <Content>
                <SucursalesTable data={listResumenesbajasSucursales} />
                <SucursalesReportModal Body={SucursalesReportMonth} />
            </Content>
        </Container>
    );
}

const Content = ({ children }) => {
    return (
        <ModalContextProvider>
            <PageTitle title={"Reportes"} />
            {children}
        </ModalContextProvider>
    )
}

export default SucursalesReport;
