import React, { useContext, useEffect, useRef } from 'react'
import {
  useParams
} from "react-router-dom";
import { Button, Col, Container, Row } from 'reactstrap';
import Datatable from '../../datatable/Datatable';
import ModalComponent from '../../utils/ModalComponent';
import { useState } from 'react';
import PersonalForm from './PersonalForm';
import { simpleGet, simplePostData } from '../../../functions';

const PersonalesSucursal = () => {

  const  {pk:sucursalPk} = useParams()

  const [isOpenModal, setIsOpenModal] = useState(false)

  const [personalSelected, setPersonalSelected] = useState(null)

  const [statusForm, setStatusForm] = useState('view')

  const [personal, setPersonal] = useState([])

  const getPersonal = async () => {
    simpleGet(`maestro/empresa/${sucursalPk}/personal/`)
      .then((res) => setPersonal(res))
  }

  useEffect(() => {
    getPersonal()
  }, [])

  const [sucursal, setSucursal] = useState()

  useEffect(() => {
    simpleGet(`maestro/empresa/sucursal/${sucursalPk}/`)
      .then((res) => setSucursal(res))
  }, [])

  const handleChangePassword = (e) => {
    alert('Función no disponible')
  }
  

  const columns = [
    {
      id: 'dni',
      columnHeaderLabel: 'DNI',
      cellRender: item => item.dni,
      isSortable: true,
    },
    {
      id: 'nombre_completo',
      columnHeaderLabel: 'Nombre Completo',
      cellRender: item => item.fullname,
      isSortable: true,
    },
    {
      id: 'personal_salud',
      columnHeaderLabel: 'Especialidad',
      cellRender: item => item.especialidad_descripcion,
      isSortable: true,
    },
    {
      id: 'admin',
      columnHeaderLabel: '¿Administrador?',
      cellRender: item => item.is_admin ? 'Si' : 'No',
      isSortable: true,
    },
    {
      id: 'Usuario',
      columnHeaderLabel: 'Usuario',
      cellRender: item => item.user?.username,
      isSortable: true,
    },
    {
      id: 'acciones',
      columnHeaderLabel: 'Acciones',
      cellRender: item => (
        <div className='d-flex justify-content-around'>
          <Button
            className='btn btn-light btn-pills waves-effect'
            color="primary"
            size="sm"
            onClick={() => {
              setPersonalSelected(item)
              setStatusForm('view')
              setIsOpenModal(true)
            }
            }>
              Ver
          </Button>
          <Button
            className='btn btn-light btn-pills waves-effect'
            color="primary"
            size="sm"
            onClick={() => {
              setPersonalSelected(item)
              setStatusForm('edit')
              setIsOpenModal(true)
            }}
          >
            Editar
          </Button>
          <Button
            className='btn btn-light btn-pills waves-effect'
            color="error"
            size="sm"
            onClick={() => alert('Función no disponible')}
          >
            Eliminar
          </Button>
          <Button
            className='btn btn-light btn-pills waves-effect'
            color="error"
            size="sm"
            onClick={handleChangePassword}
          >
            Cambiar contraseña
          </Button>
        </div>
      ),
    }
  ]

  /* Ref para escuchar el submit del modal y pasarlo al forms */
  const statusFormChangeRef = useRef()

  return (
    <div
      className='m-5'
      style={{
        width: '100%',

      }}
    >
      <h1>Personales de la sucursal {sucursal?.empresa_data?.razon_social}</h1>
      <ModalComponent
        body={
          <PersonalForm 
            submitRef={statusFormChangeRef}
            personal={personalSelected}
            status={statusForm}
            sucursalPk={sucursalPk}
          />}
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(!isOpenModal)}
        title={'Detalle Personal'}
        submitRef={statusFormChangeRef}
        onClosed={getPersonal}
      />
      <div>
        <Row>
          <Col className='col-10'>
            <Datatable
              columns={columns}
              data={personal}
            />
          </Col>
          <Col>
            <Button
              className='btn  btn-pills waves-effect'
              onClick={() => {
                setPersonalSelected(null)
                setStatusForm('create')
                setIsOpenModal(true)
              }}
            >
              Agregar Personal
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default PersonalesSucursal
