import React, { useEffect } from "react";
import { useState } from "react";
import { simpleGet } from "../../functions";
import { useContext } from "react";
import { NavigationContext } from "../Navigation";
import { inc_functions } from "./Odontograma"
import reformatDate from "../utils/helpers";


const fetchAllOdontogramsByPatient = async (patientPk) => {
    const url = `atencion/odontograma/all/paciente/${patientPk}`
    try {
        const res = await simpleGet(url);
        return res;
    } catch (e) {
        console.log(e);
        return [];
    }
}

const fetchAllIncidencesByOdontogram = async (odontogramPk) => {
    const url = `atencion/odontograma/${odontogramPk}/evolution`
    try {
        const res = await simpleGet(url);
        return res;
    } catch (e) {
        console.log(e);
        return [];
    }
}

const fetchCitaByAtencion = async (atencionPk) => {
    const url = `atencion/cita/${atencionPk}/`
    try {
        const res = await simpleGet(url);
        return res;
    } catch (e) {
        console.log(e)
        return null;
    }
}

const OdontogramHistory = ({ patientPk }) => {
    const { redirectTo } = useContext(NavigationContext)
    const [odontograms, setOdontograms] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [evolutionLog, setEvolutionLog] = useState([]);
    const [cita, setCita] = useState(null);

    const getOdontogramsByPatient = async (pac_pk) => {
        const res = await fetchAllOdontogramsByPatient(pac_pk);
        console.log("Odontogram: ", res);
        setCurrentIndex(res.length - 1);
        setOdontograms(res);
    }

    useEffect(() => {
        getOdontogramsByPatient(patientPk);
    }, [])

    const getIncidencesByOdontogram = async () => {
        if (odontograms.length == 0) return;
        const odontogram = odontograms[currentIndex];
        console.log("odontogram: ", odontogram);
        const res = await fetchAllIncidencesByOdontogram(odontogram.pk);
        let sortedIncidents = []
        if (res.length != 0) {
            sortedIncidents = res.sort((a, b) => a.diente - b.diente || a.id - b.id);
            sortedIncidents.reverse()
        }
        console.log("Incidences: ", sortedIncidents);
        setEvolutionLog(sortedIncidents);
    }

    const getCitaByAtencion = async () => {
        if (odontograms.length == 0) return;
        const odontogram = odontograms[currentIndex];
        const res = await fetchCitaByAtencion(odontogram.atencion);
        console.log("cita: ", res);
        setCita(res);
    }

    useEffect(() => {
        getIncidencesByOdontogram();
        getCitaByAtencion();
    }, [currentIndex, odontograms]);

    const handlePrevSlide = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex === 0 ? odontograms.length - 1 : prevIndex - 1)
        );
    };

    const handleNextSlide = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex === odontograms.length - 1 ? 0 : prevIndex + 1)
        );
    };

    return <div>
        <div className="row justify-content-center mb-2">
            {odontograms.length > 0 && (
                <img
                    src={odontograms[currentIndex].image} alt={`Odontograma ${currentIndex}`}
                    className="p-0 m-0"
                />)}
            <div className="w-100 d-flex justify-content-around">
                <button
                    className="btn btn-primary sm"
                    onClick={handlePrevSlide}
                    disabled={currentIndex == 0}>
                    <i className="fa fa-arrow-circle-left fa-2x" aria-hidden="true"></i>
                </button>
                {cita && (
                    <div className="w-30 d-flex align-items-center justify-content-center">
                        <h3 className="text-center">{currentIndex + 1}/{odontograms.length} - Cita: {reformatDate(cita.fecha)}</h3>
                    </div>
                )}
                <button
                    className="btn btn-primary sm"
                    onClick={handleNextSlide}
                    disabled={currentIndex == odontograms.length - 1}>
                    <i className="fa fa-arrow-circle-right fa-2x" aria-hidden="true"></i>
                </button>
            </div>
        </div>
        <OdontogramIncidencesDetail evolutionLog={evolutionLog} />
    </div >
}

const divScrollStyle = {
    width: '100%',
    //height: '200px',
    overflow: 'auto',
    border: '1px solid #ccc',
    padding: '10px',
};

function OdontogramIncidencesDetail({ evolutionLog }) {

    const DetalleIsDeleted = ({ incident }) => {
        return <span className="mt-1 d-block fs-sm fw-400 text-danger" style={{ paddingLeft: "10px", lineBreak: "auto" }}>
            {incident.is_deleted ? 'incidencia removida' : ''}
        </span>
    }

    return <div className="row">
        <div style={{ width: "100%", height: "450px", ...divScrollStyle }}>
            {/* Incidence log list */}
            {evolutionLog.length == 0 ? <span style={{ fontSize: "1.2em" }}>No hay registros de incidencias</span>
                : evolutionLog.map(inc => (
                    <div key={"ev_od_inc-" + inc.pk}>
                        <div className="row no-gutters row-grid align-items-stretch">
                            <div className="col-md">
                                <div className="p-1">
                                    <div className="d-flex">
                                        <div className="d-inline-flex flex-column">
                                            {/* Nombre */}
                                            <span className={`fs-xl text-truncate text-truncate-lg ${inc.is_deleted ? 'text-danger' : 'text-info'}`} style={{ fontWeight: "bold", whiteSpace: "initial", }}>
                                                {inc_functions.find(i => i.id == inc.type)?.nombre || ""}
                                            </span>
                                            {/* Fecha */}
                                            <span>Fecha: <i className="text-truncate text-truncate-xl">{inc.fecha}</i></span>
                                            {/* Diente */}
                                            <span>Diente: <span className="text-truncate text-truncate-xl">{inc.start_tooth_key ? `${inc.start_tooth_key} - ${inc.diente}` : inc.diente}</span></span>
                                            {/* Log */}
                                            {!inc.log ? ""
                                                : <span>Notacion: <i className="text-truncate text-truncate-xl">{inc.log}</i></span>
                                            }
                                            {/* Estado */}
                                            {inc.state == undefined ? ""
                                                : <span>Estado: <b className="text-truncate text-truncate-xl" style={{ color: inc.state ? "blue" : "red" }}>{inc.state ? "Bueno" : "Malo"}</b></span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-7">
                                <div className="p-3">
                                    <div className="d-flex">
                                        <div className="d-inline-flex flex-column">
                                            {/* <span>Detalle:</span> */}
                                            {/* Detalle */}
                                            {inc.detalle && inc.detalle.length != 0
                                                ? (
                                                    <>
                                                        <span className="mt-1 d-block fs-sm fw-400 text-dark" style={{ paddingLeft: "10px", lineBreak: "auto" }}>
                                                            {inc.detalle}
                                                        </span>
                                                        <DetalleIsDeleted incident={inc} />
                                                    </>
                                                ) : <DetalleIsDeleted incident={inc} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style={{ borderBottomStyle: "solid", marginTop: "5px", marginBottom: "5px" }} />
                    </div>
                ))
            }
        </div>
    </div>
}

export default OdontogramHistory;
