import React, { useState } from "react"
import { Switch, Route } from "react-router-dom"
import { Icon, PageTitle } from "../bits";
import CustomCard from "../utils/CustomCard";
import WebhookModalForm from "./webhook/WebhookModalForm";
import Chat from "./chat/Chat";
import WabachatProvider from "./context/WabachatContext";

function Wabachat() {
  return (
    <WabachatProvider>
      <Switch>
        <Route exact path="/nav/wabachat/">
          <WabachatNavigation/>
        </Route>
        <Route exact path="/nav/wabachat/chat/">
          <Chat></Chat>
        </Route>
      </Switch>
    </WabachatProvider>
  )
}

function WabachatNavigation(){
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => {
    setIsModalOpen(false)
  }

  return(
    <>
      <PageTitle title={"WhatsApp"} />
      <div className="row">
        <CustomCard onClick={openModal}>
          <Icon type="webhook" />
          <span style={{fontSize: "0.9rem"}}>Webhook</span>
        </CustomCard>
        <CustomCard redirect_to="/nav/wabachat/chat/" >
          <Icon type="chat"/>
          <span style={{fontSize: "0.9rem"}}>Chat</span>
        </CustomCard>
        <WebhookModalForm isOpen={isModalOpen} closeModal={closeModal}/>
      </div>
    </>
  )
}

export default Wabachat
