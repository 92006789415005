import React, { useEffect, useState } from 'react'
import { useMessage } from '../context/MessageContext';
import { get_created_at_as_lima_tz } from '../utils/main';
import { useWebsocketConnection } from '../context/WebsocketConnection';

function ChatSendForm({selectedContact}) {
  const { sendMessage, addMessage, getListMessages } = useMessage();
  const [isDisabled, setIsDisabled] = useState(false);
  const { isConnected } = useWebsocketConnection();

  const handleSendMessage = (event) => {
    event.preventDefault();

    const content_message = event.target.content.value;
    const data = {
        content: content_message,
        phone: selectedContact.phone,
        contact_id: selectedContact.id,
        is_sender: true,
        status: "2",
        created_at_as_lima_tz: get_created_at_as_lima_tz(),
    };

    setIsDisabled(true);

    addMessage(data)
    sendMessage(data);

    setTimeout(() => {
      setIsDisabled(false);
    }, 2000);

    event.target.reset();
  }

  useEffect(() => {
    if (!isConnected){
      setIsDisabled(true);
    }
  }, [isConnected])

  return (
    <form className='card p-3' onSubmit={handleSendMessage}  style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
       <div className='d-flex'>
          <input type="text" name='content' className='form-control mr-2' placeholder='Escribe tu mensaje...' required disabled={isDisabled} />
          <button type="submit" className='btn btn-primary align-self-end' disabled={isDisabled}>
           <i className="fas fa-chevron-right text-white"></i>
          </button>
       </div>
    </form>
  )
}

export default ChatSendForm
