import React, { createContext, useContext, useState } from "react";
import { handleErrorResponse, simpleGet } from "../../../functions";

export const KardexContext = createContext();

export const useKardex = () => {
  const context = useContext(KardexContext);
  if (!context) {
      throw new Error("useKardex must be used within a KardexContext");
    }
  return context;
}

export const KardexContextProvider = ({ children }) => {
  const [kardexList, setKardexList] = useState(false)

  const getListKardex = () => {
    simpleGet("inventario/almacen/kardex/")
    .then(setKardexList)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  return (
    <KardexContext.Provider
      value={{
        kardexList,
        getListKardex,
      }}
    >
    {children}
    </KardexContext.Provider>
  )
}
