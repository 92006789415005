import { useEffect, useState } from "react"
import { getDataByPK } from "../functions"

/**
 * Hook for get patient data by PK.
 *
 * @param {number} pk - Primary key of patient.
 * @returns {Object} - An object containing patient data, a loading indicator, and errors.
 */

const usePatientData = (pk) => {
  const [patientData, setPatientData] = useState(null)
  const [isLoading, setIsloading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const getData = async () => {
      try {
        const patient = await getDataByPK("atencion/paciente", pk)
        setPatientData(patient)
        setIsloading(false)
      } catch (error) {
        setError(error)
        setIsloading(false)
      }
    }
    getData()
  }, [pk]);

  return { patientData, isLoading, error }
}

export default usePatientData;
