import React from "react"
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PageTitle } from "../../bits";
import { useProcedure } from "../context/ProcedimientoContext";
import { useProcedureProduct } from "../context/ProcedimientoProductoContext";
import { useProduct } from "../../inventario/context/ProductoContext";
import ProcedimientoProductoList from "./ProcedimientoProductoList";
import ProcedimientoProductoActions from "./ProcedimientoProductoActions";
import ModalFormProcedimientoProducto from "./ModalFormProcedimientoProducto";

function AdminProcedimientoProducto() {
  const {procedure_id} = useParams()
  const {procedure, getProcedure} = useProcedure()
  const {defaultProcedureProduct, setProcedureProduct, getListProcedureProducts} = useProcedureProduct();
  const {getListProducts} = useProduct();
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => {
    setIsModalOpen(false)
    getListProcedureProducts(procedure_id);
    setProcedureProduct(defaultProcedureProduct)
  }

  useEffect(() => {
    if(procedure_id) getProcedure(procedure_id);
  }, [procedure_id])

  useEffect(() => {
    getListProducts();
  }, [])
  
  return (
    <>
      <PageTitle title={`${procedure.nombre} - Productos`} />
      <div className="row">
        <div className="col-lg-9">
          <div style={{marginBottom: "25px"}}>
            <ProcedimientoProductoList openModal={openModal}/>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="panel">
            <ProcedimientoProductoActions openModal={openModal}/>
          </div>
        </div>
        <ModalFormProcedimientoProducto isOpen={isModalOpen} closeModal={closeModal}/>
      </div>
    </>
  )
}

export default AdminProcedimientoProducto
