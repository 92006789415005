import React from "react"
import { useCompra } from "../context/CompraContext"

function DCProductoActions({openModal}) {
  const {compra} = useCompra()

  return (
    <div className="card-title w-100">
      <div className="col d-flex align-items-center justify-content-between">
        <span>Producto</span>
        {compra.estado === "1" && (
          <button className="btn btn-primary" onClick={openModal}>Agregar Producto</button>
        )}
      </div>
    </div>
  )
}

export default DCProductoActions
