export const get_created_at_as_lima_tz = () => {
  const date = new Date();

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'America/Lima'
  };
  let formattedDate = date.toLocaleString('es-PE', options).replace(',', '');

  if (date.getHours() === 12) {
    formattedDate = formattedDate.replace('00', '12');
  }

  return formattedDate;
}
