import React, { createContext, useContext, useState } from "react";
import { getDataByPK, handleErrorResponse, simpleGet, simplePostData } from "../../../functions";

export const ProductPresentationContext = createContext();

export const useProductPresentation = () => {
  const context = useContext(ProductPresentationContext);
  if (!context) {
      throw new Error("useProductPresentationContext must be used within a ProductPresentationContext");
  }
  return context;
};

export const ProductPresentationContextProvider = ({ children }) => {
  let defaultProductPresentation = {
    id: "",
    presentacion: "",
    cantidad_unidad: 0,
    precio_compra_presentacion: 0,
    is_base: false
  }
  const defaultBasePPCantidadUnidad = 1
  const [productPresentation, setProductPresentation] = useState(defaultProductPresentation)
  const [productPresentations, setProductPresentations] = useState(false)

  const createProductPresentation = (product_id, product_presentation, closeModal) => {
    simplePostData(`maestro/empresa/producto/${product_id}/presentacion/`, product_presentation)
    .then(() => handleErrorResponse('custom', "Exito", "Presentación creada exitosamente", 'success'))
    .catch(() => {
      handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger');
    })
    .finally(closeModal)
  }

  const getListProductPresentations = (product_id) => {
    simpleGet(`maestro/empresa/producto/${product_id}/presentacion/`)
    .then(setProductPresentations)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const getProductPresentation = (id) => {
    getDataByPK("maestro/empresa/producto/presentacion", id)
    .then(setProductPresentation)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const updateProductPresentation = (id, product_presentation, closeModal) => {
    simplePostData(`maestro/empresa/producto/presentacion/${id}/`, product_presentation, "PUT")
    .then(() => handleErrorResponse('custom', "Exito", "Presentación editada exitosamente", 'success'))
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
    .finally(closeModal)
  }

  const changeisBaseProductPresentation = (id, product_presentation, is_base) => {
    simplePostData(`maestro/empresa/producto/presentacion/${id}/`, {...product_presentation, is_base: is_base}, "PUT")
    .then(() => {
      let updatedProductPresentations = productPresentations.map((pp) => {
        if (pp.id === id) {
          pp.cantidad_unidad = defaultBasePPCantidadUnidad
          pp.precio_compra_unidad = pp.precio_compra_presentacion
        }
        return pp
      })

      setProductPresentations(updatedProductPresentations)
    })
    .then(() => handleErrorResponse('custom', "Exito", "Presentación editada exitosamente", 'success'))
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  return (
      <ProductPresentationContext.Provider
          value={{
              defaultProductPresentation,
              defaultBasePPCantidadUnidad,
              productPresentation,
              setProductPresentation,
              productPresentations,
              createProductPresentation, 
              getListProductPresentations, 
              getProductPresentation, 
              updateProductPresentation,
              changeisBaseProductPresentation,
          }}
      >
          {children}
      </ProductPresentationContext.Provider>
  );
};
