import React from 'react'

const TipoNotasSelect = ({tipoNotas, nota, setNota}) => {
  const getOptionList = () => {
    let options = null
  
    if (!tipoNotas) {
      return options
    }

    options = tipoNotas.map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {item.descripcion}
        </option>
      )
    })

    return options
  }

  return (
    <div className="form-group">
      <label className="form-label" htmlFor="id-tiponota">Tipo:</label>
      <select
        className="form-control"
        id="id-tiponota"
        value={nota.tiponota || ""}
        onChange={({target}) => {
          setNota({...nota, tiponota: target.value})
        }}
      >
        {getOptionList() || "Loading..."}
      </select>
    </div>
)
}

export default TipoNotasSelect
