import React, { createContext, useContext, useState } from "react";
import { getDataByPK, handleErrorResponse, simpleGet, simplePostData } from "../../../functions";
import { NavigationContext } from "../../Navigation"

export const CompraContext = createContext();

export const useCompra = () => {
  const context = useContext(CompraContext);
  if (!context) {
      throw new Error("useCompra must be used within a CompraContext");
    }
  return context;
};

let defaultCompra = {
  id: "",
  proveedor: "",
  asignado: "",
  total_final: 0,
}

export const CompraContextProvider = ({ children }) => {
  const {redirectTo} = useContext(NavigationContext)
  const [compra, setCompra] = useState(defaultCompra)
  const [compras, setCompras] = useState(false)

  const getRedirectTo = (response) => {
    if(response.compra_id) {
      redirectTo(`/nav/inventario/compra/${response.compra_id}/detallecompra/`)
      return
    }
    redirectTo(`/nav/inventario/compra/${response.id}/detallecompra/`)
  }

  const createCompra = (compra, closeModal) => {
    simplePostData("inventario/almacen/compra/", compra)
    .then((response) => getRedirectTo(response))
    .catch(() => {
      handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger');
    })
    .finally(closeModal)
  }

  const getListCompras = () => {
    simpleGet("inventario/almacen/compra/")
    .then(setCompras)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const getCompra = (id) => {
    getDataByPK("inventario/almacen/compra", id)
    .then(setCompra)
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
  }

  const finishCompra = (id, compra) => {
    simplePostData(`inventario/almacen/compra/${id}/`, compra, "PUT")
    .then(() => handleErrorResponse('custom', "Exito", "Compra finalizada exitosamente", 'success'))
    .catch(() => handleErrorResponse('custom', "Error", "Ha ocurrido un error", 'danger'))
    .finally(redirectTo("/nav/inventario/compra/"))
  }

  return (
    <CompraContext.Provider
      value={{
        defaultCompra,
        compra,
        setCompra,
        compras,
        createCompra,
        getListCompras,
        getCompra,
        finishCompra,
      }}
    >
      {children}
    </CompraContext.Provider>
  );
};
