import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Row, Col, Container } from 'reactstrap';
import { simpleGet, simplePostData } from '../../../functions';
import { useRef } from 'react';
import { TwitterPicker } from 'react-color';

const PersonalForm = ({personal, status, submitRef, sucursalPk}) => {

  const formRef = useRef()
  /* El boton submit es propio del modal, por lo que se usa un ref para escuchar el evento  */
  useEffect(() => {
    const submitButton = submitRef.current;

    if (submitButton) {
      submitButton.addEventListener('click', (e) => {
        if(formRef.current.reportValidity()){
          formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
        else{
          e.preventDefault();
          e.stopPropagation();
        }
      });
    }
    return () => {
      if (submitButton) {
        submitButton.removeEventListener('click', handleSubmit);
      }
    };
  },[])

  const nombre_principalRef = useRef()
  const nombre_secundarioRef = useRef()
  const ape_paternoRef = useRef()
  const ape_maternoRef = useRef()
  const dniRef = useRef()
  const fecha_nacimientoRef = useRef()
  const sexoRef = useRef()
  const userRef = useRef()
  const colorRef = useRef()
  const firmaRef = useRef()
  const is_adminRef = useRef()
  const codigo_colegioRef = useRef()
  const personal_atencionRef = useRef()
  const especialidadRef = useRef()

  const [especialidadSelected, setEspecialidadSelected] = useState()
  const [colorSelected, setColorSelected] = useState()
  const [fileSelected, setFileSelected] = useState(null)

  useEffect(() => {
    if (nombre_principalRef.current) nombre_principalRef.current.value = personal?.nombre_principal || null
    if (nombre_secundarioRef.current) nombre_secundarioRef.current.value = personal?.nombre_secundario || null
    if (ape_paternoRef.current) ape_paternoRef.current.value = personal?.ape_paterno || null
    if (ape_maternoRef.current) ape_maternoRef.current.value = personal?.ape_materno || null
    if (dniRef.current) dniRef.current.value = personal?.dni || null
    if (fecha_nacimientoRef.current) fecha_nacimientoRef.current.value = personal?.fecha_nacimiento || null
    if (sexoRef.current) sexoRef.current.value = personal?.sexo || ''
    if(especialidadRef.current) especialidadRef.current.value = personal?.especialidad || null
    if (userRef.current) userRef.current.value = personal?.user?.username || null
    if (colorRef.current) colorRef.current.state.hex = personal?.color || null
    if (is_adminRef.current) is_adminRef.current.checked = personal?.is_admin || false
    if (codigo_colegioRef.current) codigo_colegioRef.current.value = personal?.codigo_colegio || null
    if (personal_atencionRef.current) personal_atencionRef.current.checked = personal?.personal_atencion || false
    setEspecialidadSelected(personal?.especialidad || '')
    setColorSelected(personal?.color || '')
  }, [])


  const statusForm = status === 'view' ? 'view' : 'edit'

  const isViewing = statusForm === 'view' ? true : false

  const [especialidades, setEspecialidades] = useState([])

  const isRequiredField = statusForm === 'edit' ? false : true


  useEffect(() => {
    const getEspecialidades = async () => {
      simpleGet('maestro/profesion/especialidad')
      .then((res) => setEspecialidades(res))
    }
    getEspecialidades()
  }, [])

  const handleFileChange = (e) => {
    setFileSelected(e.target.files[0])
  }

  const handleSubmit = (e) => {

    const form_data = {
      nombre_principal: nombre_principalRef.current.value,
      nombre_secundario: nombre_secundarioRef.current.value,
      ape_paterno: ape_paternoRef.current.value,
      ape_materno: ape_maternoRef.current.value,
      dni: dniRef.current.value,
      fecha_nacimiento: fecha_nacimientoRef.current.value || null,
      sexo: sexoRef.current.value,
      especialidad: especialidadRef.current.value,
      color: colorRef.current.state.hex.slice(1),
      codigo_colegio: codigo_colegioRef.current.value,
      personal_atencion: personal_atencionRef.current.checked,
      is_admin: is_adminRef.current.checked,
      username: userRef.current.value,
    }

    const formData = new FormData()
    formData.append('nombre_principal', nombre_principalRef.current.value)
    formData.append('nombre_secundario', nombre_secundarioRef.current.value)
    formData.append('ape_paterno', ape_paternoRef.current.value)
    formData.append('ape_materno', ape_maternoRef.current.value)
    formData.append('dni', dniRef.current.value)
    formData.append('fecha_nacimiento', fecha_nacimientoRef.current.value || '')
    formData.append('sexo', sexoRef.current.value)
    formData.append('especialidad', especialidadRef.current.value)
    formData.append('color', colorRef.current.state.hex.slice(1))
    formData.append('codigo_colegio', codigo_colegioRef.current.value)
    formData.append('personal_atencion', personal_atencionRef.current.checked)
    formData.append('is_admin', is_adminRef.current.checked)
    formData.append('username', userRef.current.value)
    if (fileSelected) formData.append('firma', fileSelected)

    const METHOD = status === 'edit' ? 'PUT' : 'POST'
    const URL = METHOD === 'PUT' ? `maestro/empresa/personal/${personal?.pk || ''}` : `maestro/empresa/${sucursalPk}/personal/`
    fetch(`http://127.0.0.1:8000/${URL}`,{
      method: METHOD,
      headers: {
        'Authorization': localStorage.getItem('access_token'),  // Token
        // For some reason Content-Type: multipart/form-data just make it fail, if removed it works like a charm
      },
      body: formData
    } )
  }
  
  return (
    <Container
    >
        <Form
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="nombre_principal">Nombre Principal</Label>
              <Input
                type="text"
                name="nombre_principal"
                id="nombre_principal"
                innerRef={nombre_principalRef}
                disabled={isViewing}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="nombre_secundario">Nombre Secundario</Label>
              <Input
                type="text"
                name="nombre_secundario"
                id="nombre_secundario"
                innerRef={nombre_secundarioRef}
                disabled={isViewing}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="ape_paterno">Apellido Paterno</Label>
              <Input
                type="text"
                name="ape_paterno"
                id="ape_paterno"
                innerRef={ape_paternoRef}
                disabled={isViewing}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="ape_materno">Apellido Materno</Label>
              <Input
                type="text"
                name="ape_materno"
                id="ape_materno"
                innerRef={ape_maternoRef}
                disabled={isViewing}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="dni">DNI</Label>
              <Input
                type="text"
                name="dni"
                id="dni"
                innerRef={dniRef}
                disabled={isViewing}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="fecha_nacimiento">Fecha de Nacimiento</Label>
              <Input
                type="date"
                name="fecha_nacimiento"
                id="fecha_nacimiento"
                innerRef={fecha_nacimientoRef}
                disabled={isViewing}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="sexo">Sexo</Label>
              <Input
                type="select"
                name="sexo"
                id="sexo"
                innerRef={sexoRef}
                disabled={isViewing}
                required
              >
                <option value="">Seleccione</option>
                <option value="1">Masculino</option>
                <option value="2">Femenino</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="especialidad">Especialidad</Label>
              <Input
                type="select"
                name="especialidad"
                id="especialidad"
                value={especialidadSelected}
                onChange={(e) => {
                  setEspecialidadSelected(e.target.value)}}
                disabled={isViewing}
                innerRef={especialidadRef}
                required={isRequiredField}
              >
                <option value="">Seleccione</option>
                {especialidades.map((especialidad) => (
                  <option key={especialidad.pk} value={especialidad.pk}>{especialidad.descripcion}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="color">Color (HEX)</Label>
              {/* <Input
                type="text"
                name="color"
                id="color"
                innerRef={colorRef}
                disabled={isViewing}
              /> */}
              <TwitterPicker 
                color={colorSelected}
                onChange={status !== 'view' ? setColorSelected : null}
                colors={
                  ["#6E4E9E", "#179C8E", "#4580B3", "#FF9800", "#FC077A", "#363636", "#B0410B", "#1521cc"]
                }
                ref={colorRef}
              />
            </FormGroup>
          </Col>
          </Row>
          <Row>
          <Col md={6}>
            <FormGroup>
            <Label for="firma">Firma</Label>
              <Input
                type="file"
                name="firma"
                id="firma"
                disabled={isViewing}
                onChange={handleFileChange}
                style={ status === 'view' ? {display: 'none'} : {
                  display: 'block'
                }}
              />
            </FormGroup>
          </Col>
          <Col>
              {
                fileSelected && (
                  <a href={
                    fileSelected
                  }>Archivo seleccionado: </a>
                )
              }
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="codigo_colegio">Código de Colegio</Label>
              <Input
                type="text"
                name="codigo_colegio"
                id="codigo_colegio"
                innerRef={codigo_colegioRef}
                disabled={isViewing}
                required={isRequiredField}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="personal_atencion"
                  innerRef={personal_atencionRef}
                  disabled={isViewing}
                />{' '}
                Personal de Atención
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="user">Usuario</Label>
              <Input
                type="text"
                name="user"
                id="user"
                innerRef={userRef}
                disabled={isViewing}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="is_admin"
                  innerRef={is_adminRef}
                  disabled={isViewing}
                />
                Administrador
              </Label>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      </Container>
  );
};

export default PersonalForm;
