import React from 'react';

const HeaderColumnDatatable = ({column, handleSortClick, sortOrder, sortField}) => {

  const { id, columnHeaderLabel, isSortable } = column;

  const styles = {
    fontSize: '1.3rem',
  }

  const styleDisabled = {
    ...styles,
    color: 'gray',
  }

  const styleEnabled = {
    ...styles,
    color: 'black',
  }

  return (
    <th key={id} style={{cursor: isSortable &&  'pointer', userSelect: 'none'}} onClick={isSortable ? (() => handleSortClick(id)) : undefined}>
      {columnHeaderLabel}
      {
        isSortable && ( sortField === id ?
          <>
            <span style={sortOrder === 'asc'? styleEnabled : styleDisabled }> ↑</span>
            <span style={sortOrder === 'desc'? styleEnabled : styleDisabled }> ↓</span>
          </>
        :
          <span style={styleDisabled}> ↑ ↓ </span>
        )
      }
    </th>
  );
}
export default HeaderColumnDatatable;
