import React, { useContext, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NotaList from "./NotaList";
import NotaForm from "./NotaForm";
import { NotaContext } from "./context/NotaContext";
import CloseBtn from "./components/CloseBtn";
import { VL_NOTA_ESTADO_PENDIENTE } from "./params";

const NotaModal = ({ isOpen, handleToggle }) => {
  const {
    current_sucursal,
    setNotas,
    tipoNotas,
    listNotas,
    setTipoNotas,
    handleToggleNotaForm,
    listTipoNotas,
  } = useContext(NotaContext);

  ////---- CRUD functions -----------------------------------------------------
  // - Notas
  const filterNotas = () => {
    listNotas(
      `sucursal_id=${current_sucursal}&estado=${VL_NOTA_ESTADO_PENDIENTE}`,
      setNotas
    );
  };

  ////---- Hooks --------------------------------------------------------------
  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      listTipoNotas(setTipoNotas);
    }

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_sucursal]);

  ////---- Components ---------------------------------------------------------
  const closeBtn = <CloseBtn onClick={handleToggle} />;

  ////---- Return -------------------------------------------------------------
  return (
    tipoNotas && (
      <>
        <Modal
          isOpen={isOpen}
          toggle={handleToggle}
          size={"lg"}
          returnFocusAfterClose={false}>
          <ModalHeader tag="h2" close={closeBtn} style={{ color: "#666666" }}>
            Notas
          </ModalHeader>
          <ModalBody>
            <NotaList filterNotas={filterNotas} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleToggleNotaForm}>
              Crear
            </Button>
            <Button color="secondary" onClick={handleToggle}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>

        <NotaForm />
      </>
    )
  );
};

export default NotaModal;
