import React from "react";
import { Icon } from "../bits";

const NotaActions = ({ handleAddClick }) => {
  return (
    <div className="card col-12" style={{ padding: "0px" }}>
      <div className="card-header">
        <div className="card-title">Acciones</div>
      </div>
      <div className="card-body">
        <div
          className="col-3"
          style={{ display: "inline-block", textAlign: "center" }}>
          <Icon type="add" onClick={handleAddClick} />
          <span style={{ fontSize: "0.9rem" }}>Nuevo</span>
        </div>
      </div>
    </div>
  );
};

export default NotaActions;
