import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import useModal from "../../../hooks/useModal";

const SucursalesReportModal = ({ Body }) => {
    const { modal, toggle } = useModal()
    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} scrollable={true}>
                <h3 style={{padding:"10px"}}>Reportes Por Mes</h3>
                <ModalBody>
                    <Body />
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={toggle}>
                        cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default SucursalesReportModal;
